import styled from "styled-components";
import { Card, ProgressBar } from "react-bootstrap";
export const CardContainer = styled(Card)`
  border-radius: 8px;
  overflow: hidden;
  border: 0px;
`;
export const CardHeader = styled.div`
  background: ${(props) => (props.img ? `url('${props.img}')` : "#0ccdb3")};
  background-size: cover;
  height: ${(props) => (props.height ? props.height : "93px")};
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-size: cover;
  .image-circle {
    width: ${(props) => (props.sizeCircle ? props.sizeCircle : "57px")};
    height: ${(props) => (props.sizeCircle ? props.sizeCircle : "57px")};
    border-radius: 50%;
    border: 4px solid white;
    transform: translateY(28px);
    background-color: #edf1f7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${(props) =>
      props.sizeTextCircle ? props.sizeTextCircle : "16px"};
    font-weight: 400;
  }
  .card-options {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 16px;
  }
`;
export const CardBody = styled.div`
  padding: ${(props) => (props.padding ? props.padding : "24px")};
  display: flex;
  height: 100%;
  flex-direction: column;
`;
export const CardTitle = styled.div`
  margin-top: 10px;
  font-size: var(--font-size-lg);
  font-weight: bold;
`;

export const RowDetail = styled.div`
  margin: 8px 0px;
  .colorBlue {
    color: var(--secondary-color);
  }
  font-size: var(--font-size-caption);
`;
export const ProgressBarN = styled(ProgressBar)`
  background-color: #dfe4ed;
  height: 6px !important;
  .progress-bar {
    background-color: var(--secondary-color);
  }
`;
export const CardDescription = styled.div`
  font-family: Nova;
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-caption);
  line-height: 140%;
  text-align: center;
  color: #2a2b2e;
  margin-top: 10px;
`;
