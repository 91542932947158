import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LoginComponents } from "containers";
import { NNavbar } from "components/Nimbiv2";

const PasswordRecovery = () => {
  const history = useHistory();
  const session = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (session) {
      history.replace({ pathname: "/dashboard" });
    }
  }, [history, session]);

  return (
    <>
      <div style={{ width: "100%", minHeight: "100vh" }}>
        <NNavbar />
        <LoginComponents.PasswordRecoveryForm />
      </div>
    </>
  );
};
export default PasswordRecovery;
