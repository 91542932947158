import React from "react";

const momentSVG = () => {
  return (
    <svg
      width="244"
      height="200"
      viewBox="0 0 244 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="121.501" cy="100" rx="87.9343" ry="86.5631" fill="#E8F0FC" />
      <path
        opacity="0.7"
        d="M221.968 61.915H43.3994C38.9811 61.915 35.3994 65.4968 35.3994 69.915V148.79C35.3994 153.208 38.9811 156.79 43.3994 156.79H221.968C226.387 156.79 229.968 153.208 229.968 148.79V69.915C229.968 65.4968 226.387 61.915 221.968 61.915Z"
        fill="#DDE6F8"
      />
      <g filter="url(#filter0_d_2054_4813)">
        <path
          d="M215.168 50.4663H28C23.5817 50.4663 20 54.048 20 58.4663V141.534C20 145.952 23.5817 149.534 28 149.534H215.168C219.586 149.534 223.168 145.952 223.168 141.534V58.4663C223.168 54.048 219.586 50.4663 215.168 50.4663Z"
          fill="white"
        />
      </g>
      <path
        d="M53.0064 98.039C61.1227 98.039 67.7023 91.4204 67.7023 83.256C67.7023 75.0917 61.1227 68.4731 53.0064 68.4731C44.8901 68.4731 38.3105 75.0917 38.3105 83.256C38.3105 91.4204 44.8901 98.039 53.0064 98.039Z"
        fill="#D9E1F2"
      />
      <path
        d="M86.9454 73.4004C86.2958 73.4004 85.6728 73.66 85.2135 74.122C84.7541 74.5841 84.4961 75.2108 84.4961 75.8642C84.4961 76.5177 84.7541 77.1443 85.2135 77.6064C85.6728 78.0684 86.2958 78.328 86.9454 78.328H202.413C203.062 78.3264 203.683 78.0661 204.141 77.6042C204.599 77.1423 204.856 76.5166 204.856 75.8642C204.856 75.2118 204.599 74.586 204.141 74.1242C203.683 73.6623 203.062 73.402 202.413 73.4004H86.9454Z"
        fill="#DDE6F8"
      />
      <path
        d="M86.9459 88.1831C86.624 88.182 86.305 88.2448 86.0072 88.3678C85.7094 88.4908 85.4387 88.6716 85.2104 88.8999C84.9822 89.1283 84.8009 89.3997 84.677 89.6985C84.5531 89.9974 84.489 90.3179 84.4883 90.6417C84.4876 90.9655 84.5503 91.2863 84.673 91.5857C84.7957 91.8851 84.9758 92.1572 85.2031 92.3865C85.4304 92.6159 85.7004 92.7979 85.9977 92.9221C86.2949 93.0464 86.6137 93.1105 86.9356 93.1107H136.632C136.954 93.1107 137.272 93.047 137.569 92.9232C137.866 92.7994 138.136 92.6179 138.364 92.3891C138.591 92.1603 138.772 91.8887 138.895 91.5898C139.018 91.2909 139.081 90.9705 139.081 90.6469C139.081 90.3234 139.018 90.003 138.895 89.7041C138.772 89.4052 138.591 89.1335 138.364 88.9047C138.136 88.676 137.866 88.4945 137.569 88.3707C137.272 88.2468 136.954 88.1831 136.632 88.1831H86.9459Z"
        fill="#DDE6F8"
      />
      <path
        d="M41.7328 111.816C39.8697 111.816 38.3535 112.922 38.3535 114.28C38.3535 115.639 39.8697 116.744 41.7328 116.744H201.043C202.906 116.744 204.422 115.639 204.422 114.28C204.422 112.922 202.906 111.816 201.043 111.816H41.7328Z"
        fill="#DDE6F8"
      />
      <path
        d="M41.7328 126.6C39.8697 126.6 38.3535 127.705 38.3535 129.063C38.3535 130.422 39.8697 131.527 41.7328 131.527H164.372C166.235 131.527 167.751 130.422 167.751 129.063C167.751 127.705 166.235 126.6 164.372 126.6H41.7328Z"
        fill="#DDE6F8"
      />
      <defs>
        <filter
          id="filter0_d_2054_4813"
          x="0"
          y="36.4663"
          width="243.167"
          height="139.068"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.690196 0 0 0 0 0.729412 0 0 0 0 0.8 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2054_4813"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2054_4813"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default momentSVG;
