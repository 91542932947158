import { CardNimbi } from "components/Nimbiv2/styled";
import React from "react";
import ContentLoader from "react-content-loader";

const CardReportSkeletons = () => {
  return (
    <CardNimbi padding="24px 32px 24px 24px" style={{ maxHeight: "192px" }}>
      <ContentLoader
        speed={2}
        width="100%"
        height={164}
        viewBox="0 0 550 164"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="39" rx="3" ry="3" width="470" height="58" />
        <rect x="0" y="8" rx="0" ry="0" width="246" height="20" />
        <rect x="0" y="135" rx="15" ry="15" width="106" height="28" />
      </ContentLoader>
    </CardNimbi>
  );
};
export default CardReportSkeletons;
