import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { DividerH, RowItem } from "components/Nimbiv2/styled";
import { NButton, NSpinnerPoints } from "components/Nimbiv2";
import { ButtonSize } from "utils/constants";
import { Card, Col } from "react-bootstrap";
import DownloadAdvice from "./Modals/DownloadAdvice";
import { useDispatch } from "react-redux";
import { fetchGenerationPastEvents } from "store/actions/async/student-async.actions";
import { read, utils } from "xlsx";
import { Accordion } from "components";
import { ToggleHeader } from "components/Nimbiv2/NAccordion";
import { updateStudentAttendanceFile } from "services/student.service";
import { toast } from "react-toastify";

const StudentAdviceForm = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [baseFile, setBaseFile] = useState(null);
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loadErrors, setLoadErrors] = useState(null);
  const [selectMenu, setSelectMenu] = useState(null);

  const getLineErrors = (rows) => {
    setLoadErrors(true);
    let errors = [];
    for (let i = 0; i < rows.length; i++) {
      let data = [];
      let item = rows[i];
      if (!("Asistencia" in item && item["Asistencia"] !== "")) {
        data.push("Asistencia");
      }
      if (data.length > 0) {
        errors.push({
          nro_line: i + 1,
          error_description: `Las columnas ${data.join(", ")} estan vacias`,
        });
      }
    }
    setLoadErrors(false);
    return errors;
  };

  const onDrop = (listFiles) => {
    if (listFiles.length > 0) {
      var reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      setBaseFile(null);
      reader.onload = (e) => {
        const bstr = e.target.result;
        setErrors([]);
        const wb = read(bstr, { type: rABS ? "binary" : "array" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        var excelRows = utils.sheet_to_row_object_array(ws);
        var number = excelRows.length;
        if (number > 0) {
          // && number <= client_area_max_external_management) {
          const listErrors = getLineErrors(excelRows);
          if (listErrors.length === 0) {
            setBaseFile(listFiles[0]);
          } else {
            setErrors(listErrors);
          }
        } else {
          if (number === 0)
            setErrors([
              {
                nro_line: null,
                error_description: "El archivo no puede estar vacío",
              },
            ]);
        }
      };

      if (rABS) reader.readAsBinaryString(listFiles[0]);
      else reader.readAsArrayBuffer(listFiles[0]);
    }
  };

  const uploadFileAttendance = async () => {
    setLoadErrors(true);
    let form_data = new FormData();
    form_data.append("base_file", baseFile);
    const data = await updateStudentAttendanceFile(form_data);
    if (data?.status === 200) {
      toast.success(
        "Se registraron las asistencias exitosamentes. Podrás verlas en el historial de tus alumnos"
      );
      onClose();
      setBaseFile(null);
      setErrors([]);
    } else {
      if (data?.status === 400) {
        if (data?.title === "student_ignore") {
          setBaseFile(null);
          setErrors(data?.message?.students);
        } else {
          setBaseFile(null);
          toast.error(data?.message);
          setErrors([{ nro_line: null, error_description: data?.message }]);
        }
      } else {
        setErrors([
          {
            nro_line: null,
            error_description: "Hay columnas con datos no rellenados",
          },
        ]);
      }
    }
    setLoadErrors(false);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    onDrop: onDrop,
    maxSize: 5242880,
    accept: {
      "application/vnd.ms-excel": [".xlsx"],
    },
  });
  useEffect(() => {
    dispatch(fetchGenerationPastEvents());
  }, [dispatch]);
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <DownloadAdvice
        show={show}
        onHide={() => {
          setShow(false);
        }}
      />
      <div style={{ flexGrow: "1" }}>
        <div>
          {
            "Importa las asistencias de los alumnos a las asesorías. Las mismas se verán reflejadas en el historial de cada ficha de alumno. "
          }
        </div>
        <div style={{ marginTop: "48px" }}>
          <strong>Paso 1: Descarga el listado de alumnos</strong>
        </div>
        <div style={{ marginTop: "16px" }}>
          Descarga el listado de los alumnos que han confirmado asistencia a la
          asesoría
        </div>
        <div style={{ marginTop: "16px" }}>
          <span
            style={{ color: "var(--call-in-action-second)", cursor: "pointer" }}
            onClick={() => {
              setShow(true);
            }}
          >
            {t("download_format")}
          </span>
        </div>
        <div style={{ marginTop: "48px" }}>
          <strong>Paso 2: Sube las asistencias</strong>
        </div>
        <div style={{ marginTop: "16px" }}>
          Marca las asistencias en el listado que te has descargado y sube el
          archivo a continuación:
        </div>
        <div style={{ marginTop: "20px" }}>
          <div className="container">
            <ContainerDropZone
              {...getRootProps({ className: "dropzone" })}
              style={{ padding: baseFile ? "0px" : "40px" }}
            >
              {baseFile && (
                <div style={{ width: "100%" }}>
                  <div style={{ padding: "20px 30px 15px 30px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {baseFile.path}
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        style={{ color: "#E23232" }}
                        cursor="pointer"
                        onClick={() => {
                          setBaseFile(null);
                        }}
                      />
                    </div>
                  </div>
                  <DividerH />
                  <div style={{ padding: "50px 30px 70px 30px" }}>
                    <div
                      style={{
                        color: "#00B098",
                        fontSize: "var(--font-size-lg)",
                        lineHeight: "140%",
                      }}
                    >
                      Archivo cargado con éxito
                    </div>
                    <div>
                      <div
                        style={{
                          background: "#00B098",
                          height: "5px",
                          borderRadius: "50px",
                          marginTop: "4px",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              )}
              {!baseFile && (
                <>
                  <input {...getInputProps()} />
                  <p style={{ marginBottom: "4px", fontSize: "18px" }}>
                    {t("drag_excel")}
                  </p>
                  <p
                    style={{
                      marginBottom: "4px",
                      fontSize: "18px",
                    }}
                  >
                    {t("or")}
                  </p>
                  <NButton
                    sizeStyle={ButtonSize.small}
                    type="button"
                    onClick={open}
                    icon={<FontAwesomeIcon icon={faSearch} />}
                  >
                    {t("select_on_computer")}
                  </NButton>
                </>
              )}
            </ContainerDropZone>
          </div>
        </div>
        {loadErrors && (
          <div style={{ marginTop: "20px" }}>
            <NSpinnerPoints />
          </div>
        )}
        {errors.length > 0 && (
          <div
            style={{
              background: "#FFEBEB",
              borderRadius: "8px",
              marginTop: "20px",
            }}
          >
            <NAccordionError
              onSelect={(item) => {
                setSelectMenu(item);
              }}
            >
              <Card>
                <ToggleHeader
                  eventKey="0"
                  title={"Hay errores detectados"}
                  selectMenu={selectMenu}
                />
                <Accordion.Collapse eventKey="0">
                  <Card.Body style={{ background: "#FFEBEB" }}>
                    <div style={{ marginTop: "10px" }}>
                      Hemos registrado errores{" "}
                      {errors.filter((item) => item.nro_line !== null).length >
                      0
                        ? "en las siguientes filas:"
                        : "."}{" "}
                      <br />
                      <strong>
                        {errors.map((item) => item.nro_line).join(", ")}
                      </strong>
                    </div>
                    <div style={{ marginTop: "15px" }}>
                      Puedes:
                      <ul>
                        <li>Volver a subir el archivo con las correcciones</li>
                      </ul>
                    </div>
                    <div style={{ marginTop: "15px" }}>
                      Detalle de errores:
                      <ul>
                        {errors.map((item, key) => (
                          <li key={`${key}-error`}>
                            {item.nro_line ? `Fila ${item.nro_line}: ` : ""}
                            {item.error_description}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </NAccordionError>
          </div>
        )}
      </div>

      <div className="footer mt-4">
        <RowItem>
          <Col xs="4">
            <NButton
              style={{ width: "100%", color: "#3B4567" }}
              transparent
              onClick={() => {
                setErrors([]);
                setBaseFile(null);
                onClose();
              }}
            >
              {_.upperFirst(t("cancel"))}
            </NButton>
          </Col>
          <Col xs="8">
            <NButton
              style={{ width: "100%" }}
              color={"second"}
              onClick={uploadFileAttendance}
              disabled={baseFile ? false : true}
            >
              {_.upperFirst(t("import"))}
            </NButton>
          </Col>
        </RowItem>
      </div>
    </div>
  );
};
export default StudentAdviceForm;
const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};
const ContainerDropZone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 40px;
  border-width: 2px;
  border-radius: 10px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  // background-color: #fafafa;
  color: black;
  font-size: var(--font-size-lg);
  outline: none;
  transition: border 0.24s ease-in-out;
`;
const NAccordionError = styled(Accordion)`
  .card-header {
    background: #ffebeb !important;
    font-weight: 600;
  }
  .card {
    border: 0px;
  }
`;
