import React, { useRef } from "react";
import _ from "lodash";
import { components } from "./constants";
import { Col } from "react-bootstrap";
import PropTypes from "prop-types";
const FieldComponent = ({ data, onChange, filterset, isDisabled, version }) => {
  const elementComponent = useRef();
  const Component = _.get(components, data.type_component, components["empty"]);
  const centerElementScroll = () => {
    const y =
      elementComponent.current.getBoundingClientRect().top +
      window.pageYOffset -
      150;
    window.scrollTo({ top: y, behavior: "smooth" });
  };
  return (
    <Col className="mb-3" xs={4}>
      <div
        ref={elementComponent}
        style={{ width: "100%" }}
        onClick={centerElementScroll}
      >
        <Component
          dataset={data}
          onChange={onChange}
          initialValue={filterset[data.filter_field]}
          meta={{ filterField: data.filter_field }}
          isDisabled={isDisabled}
          canAutoFit
          isMultiple={data.subtype_component === "multi_select"}
          parent={data.parent ? filterset[data.parent] : {}}
          versionInput={version}
        />
      </div>
    </Col>
  );
};

export default FieldComponent;

FieldComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
};

FieldComponent.propsDefault = {
  isDisabled: false,
};
