import React from "react";
import { useTranslation } from "react-i18next";
import { TitleCard } from "../detail.styled";
import PreviewAcademicProgress from "./PreviewAcademicProgress";
import PreviewCheckbox from "./PreviewCheckbox";

const PreviewComponent = ({
  responseQuestion,
  title,
  showTitle = true,
  inline = false,
}) => {
  const { t } = useTranslation();
  return (
    <div className={inline ? "" : "mt-2"}>
      {showTitle && <TitleCard>{title ?? t("questions")}</TitleCard>}
      <div className={showTitle ? "mt-1" : ""}>
        {responseQuestion &&
          Object.keys(responseQuestion).length > 0 &&
          Object.keys(responseQuestion).map((item, key) => {
            return (
              <div
                key={`question-response-${key}`}
                className={inline ? "d-flex" : ""}
              >
                <div>
                  <strong>{responseQuestion[item]["question_text"]}</strong>
                </div>
                {inline && (
                  <div className="mr-2">
                    <strong>:</strong>
                  </div>
                )}
                {responseQuestion[item]["input_type"] === "radio-button" && (
                  <span>{responseQuestion[item]["answer_text"]}</span>
                )}
                {responseQuestion[item]["input_type"] === "academic_progress" &&
                  responseQuestion[item]["answer_text"] && (
                    <PreviewAcademicProgress
                      response={responseQuestion[item]["answer_text"]}
                    />
                  )}
                {responseQuestion[item]["input_type"] === "checkbox" &&
                  responseQuestion[item]["answer_text"] && (
                    <PreviewCheckbox
                      response={responseQuestion[item]["answer_text"]}
                    />
                  )}
                {["only_comment", "academic_assistance"].includes(
                  responseQuestion[item]["input_type"]
                ) && <span>{responseQuestion[item]["question_comment"]}</span>}
                {responseQuestion[item]["input_type"] !== "only_comment" &&
                  responseQuestion[item]["input_type"] !==
                    "academic_assistance" &&
                  responseQuestion[item]["question_comment"] && (
                    <div>
                      <span style={{ fontWeight: "500" }}>
                        Comentario de pregunta:{" "}
                      </span>
                      <span>{responseQuestion[item]["question_comment"]}</span>
                    </div>
                  )}
                {!responseQuestion[item]?.input_type && (
                  <span>{responseQuestion[item]["answer_text"]}</span>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default PreviewComponent;
