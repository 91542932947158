import { USER_GROUPS } from "./helpers";

const allUsersPermissions = [
  USER_GROUPS.ADMINISTRATOR.ROLE,
  USER_GROUPS.DIRECTOR.ROLE,
  USER_GROUPS.SUBDIRECTOR.ROLE,
  USER_GROUPS.SUPERVISOR.ROLE,
  USER_GROUPS.RETENCION.ROLE,
  USER_GROUPS.EXECUTIVE.ROLE,
  USER_GROUPS.COORDINATOR.ROLE,
  USER_GROUPS.HEAD_SCHOOL.ROLE,
  USER_GROUPS.REPORTER.ROLE,
];

export const login = {
  route: "/",
  roles: [],
};

export const loginGeneral = {
  route: "/login",
  roles: [],
};

export const loginSSO = {
  route: "/login_sso",
  roles: [],
};

export const passwordRecovery = {
  route: "/password-recovery",
  roles: [],
};
export const passwordReset = {
  route: "/password-reset",
  roles: [],
};
export const passwordCreate = {
  route: "/password-create",
  roles: [],
};
export const dashboard = {
  route: "/dashboard",
  roles: [
    USER_GROUPS.ADMINISTRATOR.ROLE,
    USER_GROUPS.DIRECTOR.ROLE,
    USER_GROUPS.SUBDIRECTOR.ROLE,
    USER_GROUPS.SUPERVISOR.ROLE,
    USER_GROUPS.RETENCION.ROLE,
    USER_GROUPS.EXECUTIVE.ROLE,
    USER_GROUPS.COORDINATOR.ROLE,
    USER_GROUPS.HEAD_SCHOOL.ROLE,
  ],
};

export const tasks = {
  mainPage: {
    route: "/tareas",
    roles: [
      USER_GROUPS.ADMINISTRATOR.ROLE,
      USER_GROUPS.DIRECTOR.ROLE,
      USER_GROUPS.SUBDIRECTOR.ROLE,
      USER_GROUPS.SUPERVISOR.ROLE,
      USER_GROUPS.RETENCION.ROLE,
      USER_GROUPS.EXECUTIVE.ROLE,
      USER_GROUPS.COORDINATOR.ROLE,
      USER_GROUPS.HEAD_SCHOOL.ROLE,
    ],
  },
  detail: {
    route: "/tareas/:id",
    roles: [
      USER_GROUPS.ADMINISTRATOR.ROLE,
      USER_GROUPS.DIRECTOR.ROLE,
      USER_GROUPS.SUBDIRECTOR.ROLE,
      USER_GROUPS.SUPERVISOR.ROLE,
      USER_GROUPS.RETENCION.ROLE,
      USER_GROUPS.EXECUTIVE.ROLE,
      USER_GROUPS.COORDINATOR.ROLE,
      USER_GROUPS.HEAD_SCHOOL.ROLE,
    ],
  },
};

export const students = {
  mainPage: {
    route: "/alumnos",
    roles: [
      USER_GROUPS.ADMINISTRATOR.ROLE,
      USER_GROUPS.DIRECTOR.ROLE,
      USER_GROUPS.SUBDIRECTOR.ROLE,
      USER_GROUPS.SUPERVISOR.ROLE,
      USER_GROUPS.RETENCION.ROLE,
      USER_GROUPS.EXECUTIVE.ROLE,
      USER_GROUPS.COORDINATOR.ROLE,
      USER_GROUPS.HEAD_SCHOOL.ROLE,
      USER_GROUPS.VIEWER.ROLE,
    ],
  },
  detail: {
    route: "/alumnos/:id",
    roles: [
      USER_GROUPS.ADMINISTRATOR.ROLE,
      USER_GROUPS.DIRECTOR.ROLE,
      USER_GROUPS.SUBDIRECTOR.ROLE,
      USER_GROUPS.SUPERVISOR.ROLE,
      USER_GROUPS.RETENCION.ROLE,
      USER_GROUPS.EXECUTIVE.ROLE,
      USER_GROUPS.COORDINATOR.ROLE,
      USER_GROUPS.HEAD_SCHOOL.ROLE,
      USER_GROUPS.VIEWER.ROLE,
    ],
  },
};

export const reports = {
  route: "/reportes/:id?",
  roles: [
    USER_GROUPS.ADMINISTRATOR.ROLE,
    USER_GROUPS.DIRECTOR.ROLE,
    USER_GROUPS.SUBDIRECTOR.ROLE,
    USER_GROUPS.RETENCION.ROLE,
    USER_GROUPS.SUPERVISOR.ROLE,
    USER_GROUPS.REPORTER.ROLE,
  ],
};

export const users = {
  mainPage: {
    route: "/user",
    roles: [USER_GROUPS.ADMINISTRATOR.ROLE],
  },
  add: {
    route: "/user/add",
    roles: [USER_GROUPS.ADMINISTRATOR.ROLE],
  },
  edit: {
    route: "/user/edit/:id",
    roles: [USER_GROUPS.ADMINISTRATOR.ROLE],
  },
};

export const moments = {
  mainPage: {
    route: "/momentos",
    roles: [
      USER_GROUPS.ADMINISTRATOR.ROLE,
      USER_GROUPS.DIRECTOR.ROLE,
      USER_GROUPS.SUBDIRECTOR.ROLE,
      USER_GROUPS.SUPERVISOR.ROLE,
      USER_GROUPS.RETENCION.ROLE,
      USER_GROUPS.EXECUTIVE.ROLE,
      USER_GROUPS.COORDINATOR.ROLE,
      USER_GROUPS.HEAD_SCHOOL.ROLE,
    ],
  },
  detail: {
    route: "/momentos/:id",
    roles: [
      USER_GROUPS.ADMINISTRATOR.ROLE,
      USER_GROUPS.DIRECTOR.ROLE,
      USER_GROUPS.SUBDIRECTOR.ROLE,
      USER_GROUPS.SUPERVISOR.ROLE,
      USER_GROUPS.RETENCION.ROLE,
      USER_GROUPS.EXECUTIVE.ROLE,
      USER_GROUPS.COORDINATOR.ROLE,
      USER_GROUPS.HEAD_SCHOOL.ROLE,
    ],
  },
};

export const initiatives = {
  mainPage: {
    route: "/initiatives",
    roles: [USER_GROUPS.ADMINISTRATOR.ROLE, USER_GROUPS.SUPERVISOR.ROLE],
  },
  add: {
    route: "/initiatives/add",
    roles: [USER_GROUPS.ADMINISTRATOR.ROLE, USER_GROUPS.SUPERVISOR.ROLE],
  },
  edit: {
    route: "/initiatives/:id/edit",
    roles: [USER_GROUPS.ADMINISTRATOR.ROLE, USER_GROUPS.SUPERVISOR.ROLE],
  },
  detail: {
    route: "/initiatives/:id",
    roles: [USER_GROUPS.ADMINISTRATOR.ROLE, USER_GROUPS.SUPERVISOR.ROLE],
  },
};

// export const help = {
//   route: "/help",
//   roles: [
//     USER_GROUPS.ADMINISTRATOR.ROLE,
//     USER_GROUPS.DIRECTOR.ROLE,
//     USER_GROUPS.SUBDIRECTOR.ROLE,
//     USER_GROUPS.SUPERVISOR.ROLE,
//     USER_GROUPS.RETENCION.ROLE,
//     USER_GROUPS.EXECUTIVE.ROLE,
//     USER_GROUPS.COORDINATOR.ROLE,
//     USER_GROUPS.HEAD_SCHOOL.ROLE,
//   ],
// };

export const help = {
  mainPage: {
    route: "/help",
    roles: allUsersPermissions,
  },
  detail: {
    route: "/help/type/:key",
    roles: allUsersPermissions,
  },
};

export const administration = {
  mainPage: {
    route: "/administracion",
    roles: [USER_GROUPS.ADMINISTRATOR.ROLE],
  },
  add: {
    route: "/administracion/user/add",
    roles: [USER_GROUPS.ADMINISTRATOR.ROLE],
  },
  edit: {
    route: "/administracion/user/edit/:id",
    roles: [USER_GROUPS.ADMINISTRATOR.ROLE],
  },
};

export const externalManagement = {
  mainPage: {
    route: "/gestiones-externas",
    roles: [
      USER_GROUPS.ADMINISTRATOR.ROLE,
      USER_GROUPS.SUPERVISOR.ROLE,
      USER_GROUPS.EXECUTIVE.ROLE,
    ],
  },
  add: {
    route: "/gestiones-externas/add",
    roles: [
      USER_GROUPS.ADMINISTRATOR.ROLE,
      USER_GROUPS.SUPERVISOR.ROLE,
      USER_GROUPS.EXECUTIVE.ROLE,
    ],
  },
  edit: {
    route: "/gestiones-externas/edit/:id",
    roles: [
      USER_GROUPS.ADMINISTRATOR.ROLE,
      USER_GROUPS.SUPERVISOR.ROLE,
      USER_GROUPS.EXECUTIVE.ROLE,
    ],
  },
  detail: {
    route: "/gestiones-externas/:id",
    roles: [
      USER_GROUPS.ADMINISTRATOR.ROLE,
      USER_GROUPS.SUPERVISOR.ROLE,
      USER_GROUPS.EXECUTIVE.ROLE,
    ],
  },
};

export const associated = {
  add: {
    route: "/administracion/funcionarios/add",
    roles: [USER_GROUPS.ADMINISTRATOR.ROLE],
  },
};
