import api from "services/api";
import logger from "./logService";
import { generateUrlWithParams } from "utils";

export const getExternalManagement = (queryParams) => {
  const finalUrl = generateUrlWithParams(
    `external_managements/external_management/`,
    queryParams
  );
  return api.get(finalUrl);
};

export const getOptionsExternalManagement = () => {
  const finalUrl = `external_managements/external_management/options/`;
  return api.get(finalUrl);
};

export const getFiltersExternalManagement = () => {
  const finalUrl = `external_managements/external_management/filters/`;
  return api.get(finalUrl);
};

export const createExternalManagement = async (payload) => {
  try {
    const response = await api.post(
      `external_managements/external_management/ `,
      payload
    );
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return Object.fromEntries(err);
  }
};

export const getExternalDetail = (id) => {
  const finalUrl = `external_managements/external_management/${id}/`;
  return api.get(finalUrl);
};

export const createMassiveExternalManagement = async (payload) => {
  try {
    const response = await api.post(
      `external_managements/external_management/external-management-massive/`,
      payload
    );
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return Object.fromEntries(err);
  }
};
