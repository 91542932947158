import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

const NSwitch = ({ label, id, onChange, checked }) => {
  return (
    <ContainerSwitch>
      <NCheckDesign
        type="switch"
        className="custom-switch"
        id={id}
        label={label}
        onChange={(evt) => {
          onChange(evt.target.checked);
        }}
        checked={checked}
      ></NCheckDesign>
    </ContainerSwitch>
  );
};
export default NSwitch;

const NCheckDesign = styled(Form.Check)``;

const ContainerSwitch = styled.div`
  .custom-switch .custom-control-label {
    padding-left: 2rem;
    padding-bottom: 0px;
    padding-top: 10px;
  }

  .custom-switch .custom-control-label::before {
    height: 2rem;
    width: calc(3rem + 0.75rem);
    border-radius: 4rem;
  }

  .custom-switch .custom-control-label::after {
    width: calc(2rem - 4px);
    height: calc(2rem - 4px);
    border-radius: calc(3rem - (2rem / 2));
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(calc(2rem - 0.25rem));
  }
`;
