import React from "react";
import { Col, Row } from "react-bootstrap";
import { StepTitle, TextTitle } from "../InitiativesComponent";
import { NButton } from "components/Nimbiv2";

const CardEmptyDashboard = ({
  title,
  subtitle,
  description,
  textButton,
  onClick,
  image,
  imageBoard,
}) => {
  return (
    <div style={{ padding: "20px" }}>
      <Row>
        <Col xs="6">
          <div className="d-flex" style={{ height: "100%", width: "100%" }}>
            <div className="d-flex" style={{ flexDirection: "column" }}>
              <StepTitle style={{ marginLeft: "0px", fontWeight: "bold" }}>
                {title}
              </StepTitle>
              <TextTitle style={{ marginTop: "11px", color: "bold" }}>
                {subtitle}
              </TextTitle>
              <div
                style={{
                  marginTop: "20px",
                  color: "#71819C",
                  fontSize: "14px",
                }}
              >
                {description}
              </div>
              <div style={{ marginTop: "auto" }}>
                <NButton color="second" outlined onClick={onClick}>
                  {textButton}
                </NButton>
              </div>
            </div>
          </div>
        </Col>
        <Col
          xs="6"
          className="d-flex align-items-center justify-content-center"
        >
          {image && (
            <img
              src={image}
              alt={`Empty ${title}`}
              style={{ height: "254px" }}
            />
          )}
          {imageBoard && (
            <div
              style={{
                height: "254px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {imageBoard}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CardEmptyDashboard;
