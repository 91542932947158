import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus } from "@fortawesome/free-solid-svg-icons";

import { TitleCard } from "containers/Task/detail/detail.styled";
import { CardNimbi, IconContainer, RowItem } from "../styled";
import ChartArea from "../ChartArea";
import NIcon from "../NIcon";
import colors from "utils/risk";

const CardIndicator = ({ item }) => {
  const { t } = useTranslation();
  return (
    <CardNimbi radius="16px">
      <RowItem>
        <div className="d-flex w-100">
          <IconContainer size="37px">
            <NIcon iconname={item.icon} />
          </IconContainer>
          <div
            style={{ marginTop: "10px", marginLeft: "11px" }}
            className="w-100"
          >
            <div className="d-flex align-items-center">
              <TitleCard style={{ color: "#2A2B2E" }}>{item.title}</TitleCard>
              <div className="ml-auto" style={{ color: "#787C83" }}>
                ({item.empty ? "0h" : item.created_at})
              </div>
            </div>

            {!item.empty && (
              <CardNumber isRisk={item.is_risk}>{item.subtitle}</CardNumber>
            )}
          </div>
        </div>
        {item.empty && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <img src={item.emptyImage} alt="empty imagen" />
          </div>
        )}

        {!item.empty && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <ChartArea color="blue" data={item.data} />
          </div>
        )}
        {/* {!item.empty && (
          <div style={{ marginTop: "16px" }}>
            <FontAwesomeIcon
              icon={item.down ? faCircleArrowDownRight : faCircleArrowUpRight}
              className="mr-1"
              style={{ color: item.down ? "#E23232" : "#3AC8AC" }}
            ></FontAwesomeIcon>
            <strong>{item.percentage}</strong> {item.extension}
          </div>
        )} */}
        {item.empty && (
          <div style={{ marginTop: "16px" }}>
            <FontAwesomeIcon
              icon={faCircleMinus}
              className="mr-1"
              style={{ color: "#334FFF" }}
            ></FontAwesomeIcon>
            {_.upperFirst(t("no_information"))}
          </div>
        )}
      </RowItem>
    </CardNimbi>
  );
};

export default CardIndicator;

const CardNumber = styled.div`
  color: ${(props) =>
    props.isRisk ? colors[props.isRisk]["bold"] : "#334FFF"};
  font-size: 20px;
  line-height: 120%;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 2px;
`;
CardNumber.defaultProps = {
  type: "normal",
};
