import React from "react";
import { InternalServerError } from "pages";

class NErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <InternalServerError />;
    }
    return <>{this.props.children}</>;
  }
}

export default NErrorBoundary;
