import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Form } from "components";

import { toggleSidebar } from "store/actions/site.actions";

import "./sidebar-scripting.css";
import { useTranslationLocal } from "hooks";
import { useTranslation } from "react-i18next";
import { ContainerEmpty } from "components/Nimbiv2";

const NScriptTemplate = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const isScriptSidebarOpen = useSelector(
    (state) => state.site.isScriptSidebarOpen
  );
  const { user } = useSelector((state) => state.auth);
  const selectedRow = useSelector((state) => state.crm.selectedRow);

  const closeScriptSidebar = () => {
    dispatch(toggleSidebar(false, "isScriptSidebarOpen"));
  };

  const htmlTemplate = (task) => {
    if (task && task?.script_template && task?.script_template?.script) {
      const scriptMarkdown = task.script_template.script.replaceAll(
        "[user_name]",
        `${user.first_name} ${user.last_name}`
      );
      return (
        // eslint-disable-next-line react/no-danger
        <div
          style={{ paddingBottom: 20 }}
          dangerouslySetInnerHTML={{ __html: scriptMarkdown }}
        />
      );
    }
    return <ContainerEmpty text={t("does_not_have_calling_pattern")} />;
  };

  if (selectedRow && selectedRow.script_template) {
    return (
      <div
        id="sidebarscript"
        className={`scriptContainer content ${
          isScriptSidebarOpen ? "" : "active"
        }`}
      >
        <div className="d-flex justify-content-between header">
          <div>
            {translation("call_pattern", "", t("callpattern.call_pattern"))}
          </div>
          <Form.Control
            className="scriptSelect"
            as="select"
            onChange={() => {}}
          >
            <option value="1">{selectedRow.script_template.name}</option>
          </Form.Control>
          <span
            className="closeButton"
            onClick={closeScriptSidebar}
            aria-hidden="true"
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>
        </div>
        <div className="body">{htmlTemplate(selectedRow)}</div>
      </div>
    );
  }

  return null;
};

export default NScriptTemplate;
