import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { Form } from "react-bootstrap";
import TextError from "../TextError";
const NBTextArea = ({
  rows = 3,
  maxLength = 500,
  value,
  onChange,
  errorText,
  placeholder = null,
}) => {
  return (
    <>
      <Form.Control
        as="textarea"
        rows={rows}
        maxLength={maxLength}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      <div
        className="d-flex justify-content-end mt-1"
        style={{ fontSize: "10px" }}
      >
        {value.length}/{maxLength}
      </div>
      {errorText && <TextError text={errorText} icon={faExclamationCircle} />}
    </>
  );
};
export default NBTextArea;
