import React from "react";
import { replaceChars, capitalizeWord } from "utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons";
import styles from "./NFilterBadge.module.css";
const FilterBadge = ({ filterInfo, onClickDelete }) => {
  if (Object.keys(filterInfo).length === 0) return null;

  return (
    <small className={`${styles.badgeFilter} `}>
      <span className="font-weight-bold">
        {capitalizeWord(
          replaceChars(filterInfo?.label ?? filterInfo.key, "_", " ")
        )}
        :{" "}
      </span>
      <span className="">
        {filterInfo?.keyValue
          ? filterInfo.value[filterInfo?.keyValue]
              .toString()
              .replace("++", " | ")
          : filterInfo.value
          ? filterInfo.value.toString().replace("++", " | ")
          : ""}
      </span>
      <FontAwesomeIcon
        className={`ml-2 ${styles.badgeIcon}`}
        icon={faTimesCircle}
        onClick={() => {
          onClickDelete(filterInfo);
        }}
      />
    </small>
  );
};

export default FilterBadge;
