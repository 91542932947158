import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import ProtectedBlock from "routes/ProtectedBlock";
import {
  NBlockContent,
  NSearchCampus,
  NSearchCourses,
  NMassiveActions,
} from "components";
import { toast } from "react-toastify";
import {
  toggleCheckedPage,
  toggleMarkItems,
} from "store/actions/student.actions";
import {
  fetchStudents,
  fetchStudentsInCourses,
} from "store/actions/async/student-async.actions";
import { setTempFilter, updateClientFilters } from "store/actions/site.actions";
import { runTemplateDispatcher } from "store/actions/async/crm-async.action";
import { getCoursesFromCampus } from "services/crmService";
import { APP_MODULES, setSessionFilters, clientFiltersAllAdapter } from "utils";
import styles from "./Student.module.css";
import FormFilter from "components/NFormFilter";
import { useTranslation } from "react-i18next";
import { VERSIONS_FILTERS_DESIGN } from "utils/constants";
import { useTranslationLocal } from "hooks";

const {
  STUDENT: { FILTER_NAME: FILTER_STUDENT },
} = APP_MODULES;
const FILTERKEY_CAMPUS = "studenttracking__campus__name";
const FILTERKEY_COURSES = "studenttracking__tracking_object__external_id";

const AdvancedFilters = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const {
    _temp,
    clientFilters,
    pending: { clientFilters: pendingFilters },
  } = useSelector((state) => state.site);
  const { campus: listCampus } = useSelector((state) => state.crm);
  const {
    markAllItems,
    pending: { list: pendingList },
  } = useSelector((state) => state.student);

  const [listCourses, setListCourses] = useState([]);
  const [campus, setCampus] = useState([]);
  const [lockActions, setLockActions] = useState(true);
  const [tempFilters, setTempFilters] = useState({});
  const [courseIds, setCourseIds] = useState([]);

  useEffect(() => {
    // reset all filters for students.
    setSessionFilters(FILTER_STUDENT, {
      _courses: [],
      _filteredList: false,
      [FILTERKEY_CAMPUS]: "",
    });
  }, []);

  const handleApply = useCallback(() => {
    const isEmpty = Object.keys(tempFilters).length === 0;
    if (isEmpty) return false;
    // send request to the backend
    dispatch(fetchStudentsInCourses(courseIds));
  }, [dispatch, courseIds, tempFilters]);

  const handleApplyFilter = useCallback(() => {
    const isEmpty = Object.keys(tempFilters).length === 0;

    if (isEmpty) return false;
    dispatch(updateClientFilters(tempFilters));
    clientFiltersAllAdapter(tempFilters.filters.students, FILTER_STUDENT);
    if (courseIds.length > 0) {
      dispatch(fetchStudentsInCourses(courseIds));
    }
  }, [dispatch, tempFilters, courseIds]);

  useEffect(() => {
    handleApplyFilter();
  }, [tempFilters, handleApplyFilter]);

  const fetchCoursesFromCampus = async (campusName) => {
    if (!campusName) return Promise.resolve([]);

    // get all courses from this campus
    const payload = { campus: campusName };
    const {
      data: { results: filteredCourses },
    } = await getCoursesFromCampus({
      queryParams: { page: 1, page_size: 1000 },
      payload,
    });

    return Promise.resolve(filteredCourses);
  };

  const handleFilterCampus = async (selected) => {
    const isEmpty = Object.keys(selected).length === 0;
    const campusValue = isEmpty ? [] : selected;
    if (isEmpty) setSessionFilters(FILTER_STUDENT, { _filteredList: false });
    if (isEmpty) setLockActions(true);
    // set temp filters for campus...
    const filters = {
      ..._temp,
      campus: isEmpty ? null : selected.campus,
      pagination: {
        page: 1,
        page_size: 10,
      },
    };
    dispatch(setTempFilter(filters));
    dispatch(fetchStudents());
    setCampus(campusValue);

    // load courses for this campus
    const campusCourses = await fetchCoursesFromCampus(
      isEmpty ? null : selected.campus
    );
    setListCourses(campusCourses);

    // clear selections...
    dispatch(toggleMarkItems("markAllItems", false));
    dispatch(toggleMarkItems("wholePageMarked", false));
  };

  const handleFilterCourses = (courses) => {
    setSessionFilters(FILTER_STUDENT, {
      _courses: courses,
      _filteredList: true,
    });

    if (courses.length > 0) {
      setLockActions(false);
      const externalIds = Array.from(courses).map((course) =>
        Number(course.external_id)
      );
      const filters = { ..._temp, listCourses: externalIds };
      setCourseIds(externalIds);

      // update temp filter
      dispatch(setTempFilter(filters));

      // send request to the backend
      dispatch(fetchStudentsInCourses(externalIds));
    } else {
      setSessionFilters(FILTER_STUDENT, { _filteredList: false });
      setCourseIds([]);
      setLockActions(true);
      dispatch(fetchStudents());
    }

    // clear selections...
    dispatch(toggleMarkItems("markAllItems", false));
    dispatch(toggleMarkItems("wholePageMarked", false));
  };

  const handleFilterChanged2 = useCallback(
    (content) => {
      setTempFilters({
        data: clientFilters.data,
        filters: { ...clientFilters.filters, ...{ students: content } },
      });
    },
    [clientFilters]
  );

  const handleBtnAction = (payload) => {
    const finalPayload = {
      ...payload,
      apply_all: markAllItems,
      filters: {
        campus: _temp.campus,
        courses: _temp.listCourses ?? [],
      },
    };
    dispatch(runTemplateDispatcher(finalPayload));
    toast.success("La acción fue aplicada ✓");

    // clear selections...
    dispatch(toggleCheckedPage(false));
    dispatch(toggleMarkItems("markAllItems", false));
    dispatch(toggleMarkItems("wholePageMarked", false));
  };

  const renderDynamicFilters = useCallback(() => {
    if (pendingFilters) {
      return (
        <div className={`w-100 ${styles.loadingFilters}`}>
          {t("loading_other_filters")} ... &nbsp;&nbsp;&nbsp;
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      );
    }

    const listDynamicFilters = clientFilters.data?.students ?? [];
    if (listDynamicFilters.length === 0) return null;
    return (
      <NBlockContent title={t("other_filters")}>
        <FormFilter
          data={listDynamicFilters}
          onChange={handleFilterChanged2}
          filterset={clientFilters.filters?.students ?? {}}
          isDisabled={lockActions || pendingList}
          version={VERSIONS_FILTERS_DESIGN.v1}
        />
        <Button
          onClick={handleApply}
          disabled={lockActions}
          size="sm"
          className="callinaction_bg_color"
        >
          {_.upperFirst(t("apply"))}
        </Button>
      </NBlockContent>
    );
  }, [
    clientFilters.data,
    clientFilters.filters,
    pendingFilters,
    lockActions,
    pendingList,
    handleFilterChanged2,
    handleApply,
    t,
  ]);

  return (
    <Container className={styles.advFiltersBlock} fluid>
      <Row>
        <Col md={6}>
          <NBlockContent
            title={t("filter_by_course", {
              tracking_object: translation("tracking_object", "", "Curso"),
            })}
          >
            <NSearchCampus
              filterKey={FILTERKEY_CAMPUS}
              fieldName="campus"
              dataset={listCampus}
              onChange={handleFilterCampus}
              filterType={FILTER_STUDENT}
            />
            <div className={styles.separator} />
            <NSearchCourses
              filterKey={FILTERKEY_COURSES}
              fieldName="external_id"
              dataset={listCourses}
              onChange={handleFilterCourses}
              filterType={FILTER_STUDENT}
              isDisabled={campus.length === 0 || pendingList}
              searchType="campus_courses"
              isMultiple
              campusSelected={campus}
            />
          </NBlockContent>
          <div className={styles.separator} />
          {renderDynamicFilters()}
        </Col>
        <ProtectedBlock allowedCode="student_apply_actions">
          <Col md={6}>
            <NBlockContent title={t("apply_actions")}>
              <NMassiveActions
                onApply={handleBtnAction}
                isDisabled={lockActions}
              />
            </NBlockContent>
          </Col>
        </ProtectedBlock>
      </Row>
      <Row className="d-none mt-3">
        <Col className="d-none" md={6} />
      </Row>
    </Container>
  );
};

export default AdvancedFilters;
