import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt } from "@fortawesome/pro-regular-svg-icons";

import { NPagination } from "components";
import { APP_MODULES, setSessionFilters } from "utils";
import { RowItem } from "components/Nimbiv2/styled";
import { fetchStudentsTracking } from "store/actions/async/initiatives-async.actions";

const PaginationBar = ({
  campuses,
  tracking_objects,
  actionFun = fetchStudentsTracking,
  id = null,
  showMore = false,
}) => {
  const dispatch = useDispatch();

  const {
    list: studentList,
    pending: { list: isPending },
    paginationTracking: pagination,
    initiative,
  } = useSelector((state) => state.initiative);

  const loadPage = (pageNumber, pageSize) => {
    setSessionFilters(APP_MODULES.INITIATIVES.FILTER_NAME, {
      page: pageNumber,
      page_size: Number(pageSize),
      source_type:
        initiative && initiative.source_type
          ? initiative.source_type
          : "internal",
      ...{
        campuses: campuses,
        tracking_objects: tracking_objects,
      },
    });

    if (id !== null) {
      dispatch(actionFun(id));
    } else {
      dispatch(actionFun());
    }
  };

  if (studentList.length === 0) return null;

  return (
    <div className="mt-4 p-0">
      <RowItem>
        <Col className="d-flex align-items-center justify-content-start" xs={2}>
          <span className="d-none">
            <FontAwesomeIcon
              className="mr-2"
              size="1x"
              icon={faCloudDownloadAlt}
            />
            Excel
          </span>
        </Col>
        <Col
          style={{ paddingRight: 0 }}
          className="d-flex align-items-center justify-content-end"
        >
          <NPagination
            isPending={isPending}
            pagination={pagination}
            onPageChange={loadPage}
            showMore={showMore}
          />
        </Col>
      </RowItem>
    </div>
  );
};

export default PaginationBar;
