import React from "react";
import { TaskComponents } from "containers";
import { FilterBar, GenerationEventsList } from "containers/Generation";
import { NPagination } from "components";
import { useDispatch, useSelector } from "react-redux";
import { APP_MODULES, getSessionFilters } from "utils";
import { fetchGenerationEvents } from "store/actions/async/generation-async.actions";
import { Container } from "react-bootstrap";

const GenerationsPage = () => {
  const dispatch = useDispatch();
  const {
    list: listGenerationEvents,
    pending: { list: isPending },
    pagination,
  } = useSelector((state) => state.generation);

  const loadGenerationEvents = (pageNumber, pageSize) => {
    const filterGenerationEvents = getSessionFilters(
      APP_MODULES.GENERATION_EVENTS.FILTER_NAME
    );
    const filters = {
      ...filterGenerationEvents,
      ...{ page: pageNumber, page_size: Number(pageSize) },
    };
    dispatch(fetchGenerationEvents(filters));
  };
  return (
    <div style={{ marginBottom: "30px" }}>
      <TaskComponents.Toolbar>
        <FilterBar />
      </TaskComponents.Toolbar>

      <GenerationEventsList />
      {listGenerationEvents && listGenerationEvents.length > 0 && (
        <Container fluid>
          <NPagination
            isPending={isPending}
            pagination={pagination}
            onPageChange={loadGenerationEvents}
          />
        </Container>
      )}
    </div>
  );
};

export default GenerationsPage;
