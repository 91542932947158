import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserAlt,
  faInfoCircle,
  faCommentAlt,
} from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";

import { callDescriptions } from "utils";
import styles from "./NHistoryMessage.module.css";

const NHistoryMessage = ({
  fullName,
  description,
  subject,
  dateInfo,
  isBlockLeft,
  channel,
  responseCode,
  callComment,
  comment,
  emailStatus,
  momentName,
}) => {
  const [blockAlignment, setBlockAlignment] = useState("justify-content-end");

  const emailStatusChoices = {
    opened: "Abierto",
    delivered: "Entregado",
    "{'queued'}": "En cola",
    sent: "Enviado",
    clicked: "Clickeado",
    bounced: "Rebotado",
    complained: "Enviado a spam",
  };

  useEffect(() => {
    setBlockAlignment(
      isBlockLeft ? "justify-content-end" : "justify-content-start"
    );
  }, [isBlockLeft]);

  return (
    <div className={`d-flex ${blockAlignment} ${styles.container}`}>
      <div
        className={[styles.messageBLock, isBlockLeft ? {} : styles.bgDark].join(
          " "
        )}
      >
        <p className={isBlockLeft ? styles.rightBlock : styles.leftBlock}>
          {channel === "Call" && fullName ? (
            <>
              <FontAwesomeIcon className="mr-3" icon={faUserAlt} size="1x" />
              <span>{fullName} gestiona la llamada.</span>
            </>
          ) : null}
        </p>

        <p
          className={[
            styles.subject,
            isBlockLeft ? styles.rightBlock : styles.leftBlock,
          ].join(" ")}
        >
          {subject}
        </p>

        {description ? (
          <p
            className={[
              styles.description,
              isBlockLeft ? styles.rightBlock : styles.leftBlock,
            ].join(" ")}
          >
            {description} {callComment ? <span>: {callComment}</span> : null}
          </p>
        ) : null}

        {responseCode ? (
          <p
            className={[
              styles.description,
              isBlockLeft ? styles.rightBlock : styles.leftBlock,
            ].join(" ")}
          >
            {callDescriptions.get(responseCode)}
            <FontAwesomeIcon className="ml-2" icon={faInfoCircle} size="1x" />
          </p>
        ) : null}

        {comment && !isBlockLeft ? (
          <p
            className={[
              styles.description,
              isBlockLeft ? styles.rightBlock : styles.leftBlock,
            ].join(" ")}
          >
            <FontAwesomeIcon className="mr-2" icon={faCommentAlt} size="1x" />
            <i>{comment}</i>
          </p>
        ) : null}

        {dateInfo ? (
          <p
            className={[
              styles.dateInfo,
              isBlockLeft ? styles.rightBlock : styles.leftBlock,
            ].join(" ")}
          >
            {momentName ? (
              <span
                className={[
                  styles.dateInfo,
                  isBlockLeft ? styles.rightBlock : styles.leftBlock,
                ].join(" ")}
              >
                {" "}
                {momentName} -{" "}
              </span>
            ) : (
              ""
            )}
            {moment(dateInfo).format("DD/MM/YYYY - HH:mm")}h
          </p>
        ) : null}
        {
          /* Estado del mail en caso de existir */
          emailStatus in emailStatusChoices && (
            <p
              className={[
                styles.dateInfo,
                isBlockLeft ? styles.rightBlock : styles.leftBlock,
              ].join(" ")}
            >
              {emailStatusChoices[emailStatus]}
            </p>
          )
        }
      </div>
    </div>
  );
};

NHistoryMessage.propTypes = {
  fullName: PropTypes.string,
  description: PropTypes.string,
  subject: PropTypes.string,
  dateInfo: PropTypes.string,
  isBlockLeft: PropTypes.bool,
  channel: PropTypes.string,
  responseCode: PropTypes.string,
  comment: PropTypes.string,
};

NHistoryMessage.defaultProps = {
  fullName: "",
  subject: "",
  description: "",
  dateInfo: "",
  isBlockLeft: false,
  channel: "",
  responseCode: "",
  comment: "",
};

export default NHistoryMessage;
