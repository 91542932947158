import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import ClientFactory from "models/ClientFactory";
import { downloadUsersExcelAction } from "store/actions/async/user-async.actions";
import { NIcon, NButton } from "components/Nimbiv2";

const FilterBar = () => {
  const Institution = new ClientFactory().client();

  return (
    <>
      <div>
        <Institution.UserFilters>
          <AddUserBlock />
        </Institution.UserFilters>
      </div>
    </>
  );
};

const AddUserBlock = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const handleClick = () => {
    history.push("/administracion/user/add");
  };

  const dispatch = useDispatch();

  const downloadUsersExcel = () => {
    dispatch(downloadUsersExcelAction());
  };

  return (
    <div className="mt-2 mt-md-0 w-100 d-flex justify-content-md-end justify-content-between p-0">
      <NButton
        padding="9px 15px"
        border="24px"
        onClick={downloadUsersExcel}
        outlined
        style={{ minWidth: "180px" }}
        color="second"
      >
        {t("download_users")}
      </NButton>
      <NButton
        padding="9px 15px"
        border="24px"
        onClick={handleClick}
        style={{ minWidth: "180px", marginLeft: "24px" }}
        color="second"
      >
        <NIcon
          style={{ fontSize: "var(--font-size-caption)", marginRight: "5px" }}
          iconname="plus"
        />
        {t("create_user")}
      </NButton>
    </div>
    // </RowItem>
  );
};

export default FilterBar;
