import React from "react";
import styled from "styled-components";

const NBlockContent = ({ children, title }) => {
  return (
    <BlockContainer>
      {title ? <div className={"title"}>{title}</div> : null}
      {children}
    </BlockContainer>
  );
};

export default NBlockContent;

const BlockContainer = styled.div`
  border: 1px solid var(--color-dark-aqua);
  border-radius: 4px;
  padding: 6px 5px;
  .title {
    background-color: white;
    display: table;
    font-weight: 700;
    padding: 0 10px;
    position: absolute;
    margin-top: -15px;
    z-index: 1;
  }
  .react-datepicker__input-container {
    input {
      border: none;
    }
  }
`;
