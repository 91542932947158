import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Modal } from "components";
import { sendReport } from "services/crmService";
import ReportForm from "./Form";
import ReportSent from "./ReportSent";

import styles from "./Report.module.css";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const ModalReport = ({ isVisible, onClose }) => {
  const [isSent, setSent] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isVisible) setSent(false);
  }, [isVisible]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const formData = new FormData();

    Object.entries(values).map((item) => {
      const [key, value] = item;
      if (value) formData.append(key, value);
      return null;
    });

    const { status } = await sendReport(formData);
    setSubmitting(false);

    if (status === 201) {
      setSent(true);
    }
  };

  return (
    <ModalDesign show={isVisible} onHide={onClose} centered>
      <Modal.Header closeButton style={{ borderBottom: "0px" }}>
        <Modal.Title className="h4">{t("how_can_we_help_you")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className={`${styles.reportSent}`} fluid>
          {isSent ? <ReportSent /> : <ReportForm onSubmit={handleSubmit} />}
        </Container>
      </Modal.Body>
    </ModalDesign>
  );
};

ModalReport.propTypes = {
  onClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
};

ModalReport.defaultProps = {
  isVisible: false,
};

export default React.memo(ModalReport);

const ModalDesign = styled(Modal)`
  .modal-header {
    padding-top: 44px;
    padding-left: 44px;
    padding-right: 44px;
    padding-bottom: 0px;
  }
  .modal-body {
    padding-top: 8px;
    font-size: 16px;
    padding-left: 44px;
    padding-right: 44px;
    padding-bottom: 44px;
  }
  .modal-content {
    border-radius: 8px;
    min-width: 577px;
  }
`;
