import { getTaskList } from "store/actions/async/crm-async.action";
import {
  getSessionFilters,
  getDataFromLocalStorage,
  setDataToLocalStorage,
  APP_MODULES,
  setSessionFilters,
} from "utils";
import {
  markMoment,
  setTabSelectTask,
  toggleClearFilter,
} from "store/actions/crm.actions";
import { fetchClientFilters } from "store/actions/async/site-async.action";
import { markInitiative } from "store/actions/initiative.actions";
import { cleanFilter as cleanFilterTask } from "store/actions/site.actions";
export const applyMomentFilter = (
  dispatch,
  momentId,
  pageSize,
  callList = true,
  cleanFilter = false
) => {
  if (cleanFilter) {
    localStorage.removeItem("filters");
    localStorage.removeItem("clientFilters");
    dispatch(cleanFilterTask("tasks"));
  }
  setDataToLocalStorage("tabTaskSelected", { tab: "moments" });
  dispatch(setTabSelectTask("moments"));
  setDataToLocalStorage("avoidReloadTasks", true);
  const filters = getDataFromLocalStorage("filters", {});

  dispatch(toggleClearFilter({ attr: "course", sw: false }));
  let currentFilters = null;
  let storedTaskFilters = localStorage.getItem(APP_MODULES.TASK.FILTER_NAME);

  if (storedTaskFilters) {
    storedTaskFilters = JSON.parse(storedTaskFilters);
    const { client_filters: clientFilters = null } = storedTaskFilters;
    currentFilters = clientFilters;
  }
  setSessionFilters(
    APP_MODULES.TASK.FILTER_NAME,
    {
      ...filters,
      ...{
        page: 1,
        page_size: Number(pageSize),
        moment: Number(momentId),
        student_tracking__momenttracking__moment: Number(momentId),
        student_tracking__momenttracking__state: "active",
        client_filters: currentFilters,
        source: "moment",
      },
    },
    true
  );
  dispatch(markMoment(momentId));
  dispatch(fetchClientFilters({ moment_id: momentId, module: "tasks" }));
  if (callList) {
    dispatch(getTaskList());
  }
};

export const applyMomentFilterPage = (
  dispatch,
  momentId,
  pageSize,
  reloadTasks = true
) => {
  setDataToLocalStorage("tabTaskSelected", { tab: "moments" });
  dispatch(setTabSelectTask("moments"));
  setDataToLocalStorage("avoidReloadTasks", true);
  const queryParams = getSessionFilters(APP_MODULES.TASK.FILTER_NAME, true);
  let currentFilters = null;
  if (queryParams) {
    const { client_filters: clientFilters = null } = queryParams;
    currentFilters = clientFilters;
  }
  if (momentId) {
    setSessionFilters(
      APP_MODULES.TASK.FILTER_NAME,
      {
        page: 1,
        page_size: Number(pageSize),
        moment: momentId,
        student_tracking__momenttracking__moment: momentId,
        student_tracking__momenttracking__state: "active",
        client_filters: currentFilters,
        source: "moment",
      },
      true
    );
    dispatch(markMoment(momentId));
    dispatch(fetchClientFilters({ moment_id: momentId, module: "tasks" }));
  }

  const finalParams = {
    moment: momentId ?? "",
    student_tracking__momenttracking__moment: momentId ?? "",
    student_tracking__momenttracking__state: "active",
  };
  if (reloadTasks) {
    dispatch(getTaskList({ ...queryParams, ...finalParams }));
  }
};

export const applyInitiativeFilter = (
  dispatch,
  momentId,
  pageSize,
  reloadTasks = true,
  callList = true,
  sourse_type = "internal"
) => {
  setDataToLocalStorage("tabTaskSelected", { tab: "initiatives" });
  dispatch(setTabSelectTask("initiatives"));
  setDataToLocalStorage("avoidReloadTasks", true);
  const filters = getDataFromLocalStorage("filters", {});

  dispatch(toggleClearFilter({ attr: "course", sw: false }));
  let currentFilters = null;
  let storedTaskFilters = localStorage.getItem(APP_MODULES.TASK.FILTER_NAME);

  if (storedTaskFilters) {
    storedTaskFilters = JSON.parse(storedTaskFilters);
    const { client_filters: clientFilters = null } = storedTaskFilters;
    currentFilters = clientFilters;
  }
  setSessionFilters(
    APP_MODULES.TASK.FILTER_NAME,
    {
      ...filters,
      ...{
        page: 1,
        page_size: Number(pageSize),
        initiative_id: Number(momentId),
        client_filters: currentFilters,
        source: "initiative",
      },
    },
    true
  );
  setDataToLocalStorage("isExternalInitiative", !(sourse_type === "internal"));
  dispatch(markInitiative(Number(momentId)));
  dispatch(fetchClientFilters({ initiative_id: momentId, module: "tasks" }));
  if (reloadTasks && callList) {
    dispatch(getTaskList());
  }
};

export const filterMomentSelectActive = (moments) => {
  let listMoment = [];
  for (let i = 0; i < moments.length; i++) {
    if (
      moments[i].active_student_tracking_count !== 0 &&
      moments[i].count_workflow_call > 0
    ) {
      listMoment.push(moments[i]);
    }
  }
  return listMoment;
};

export const filterMomentActive = (moments) => {
  let listMoment = [];
  for (let i = 0; i < moments.length; i++) {
    if (moments[i].all_students !== 0) listMoment.push(moments[i]);
  }
  return listMoment;
};
