import {
    faGraduationCap,
    faDollarCircle,
    faHouseChimney
  } from "@fortawesome/pro-regular-svg-icons";
  
  const icons = {
    student: faGraduationCap,
    dollar: faDollarCircle,
    house: faHouseChimney
  };
  
  export default icons;
  