import React, { useMemo } from "react";
import _ from "lodash";
import NTable from "components/Nimbiv2/NTable";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const CustomAcademicAssistance = ({
  key,
  dataset,
  isDisabled,
  onChange,
  keyPosition,
  lastKeyPosition,
  style,
}) => {
  const { t } = useTranslation();
  const {
    academicProgress,
    pending: { academicProgress: isPending },
    // academicStudentId,
  } = useSelector((state) => state.student);
  // const statusMPE = {
  //   false: "redOriginal",
  //   true: "greenOriginal",
  // };

  const columns = [
    {
      title: _.upperFirst(t("modules")),
      key: "course_name",
      dataIndex: "course_name",
      width: "200",
      fixed: true,
      style: { left: 0 },
      render: (item, key) => <div>{item}</div>,
    },
    {
      title: t("average_attendance"),
      key: "risk_assistance",
      center: true,
      contentCenter: true,
      width: "50",
      render: (item, key) => {
        return <div>{item?.average_attendance?.toString()}</div>;
      },
    },
  ];

  const listAssistanceRisk = useMemo(() => {
    return (
      academicProgress?.progress?.filter((item) => {
        return item.attendances_extras["riesgo_asistencia"];
      }) ?? []
    );
  }, [academicProgress]);

  return (
    <div className="mt-2" style={style}>
      <div>{dataset.text}</div>
      <div className="mt-2">
        <NTable
          columns={columns}
          dataSource={listAssistanceRisk}
          isPending={isPending}
        ></NTable>
      </div>
    </div>
  );
};

export default CustomAcademicAssistance;
