import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import { Container, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDefault, useTranslationLocal } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import { setActiveModule } from "store/actions/site.actions";
import { NNavbarDashboard, ScrollToTopOnMount } from "components/Nimbiv2";
import { fetchMoments } from "store/actions/async/crm-async.action";
import { RowItem } from "components/Nimbiv2/styled";
import { ListMomentSkeleton, MomentList } from "containers/Moments";
import { TabContainer } from "components";

const Moments = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const momentModuleTitle = useDefault("momentModuleTitle", t("action_plans"));
  const momentModuleSubTitle = useDefault(
    "momentModuleSubTitle",
    t("page_moment_subtitle", {
      students: translation("students", "", t("students")),
    })
  );

  const {
    moments,
    pagination: { pageSize },
    singleLoads: { moments: singleMomentsLoaded },
    isMomentPending,
  } = useSelector((state) => state.crm);
  const [listMoments, setListMoments] = useState([]);
  const [momentsActive, setMomentsActive] = useState([]);
  const [momentsInactive, setMomentsInactive] = useState([]);
  const [activeKey, setActiveKey] = useState("active");

  useEffect(() => {
    if (momentModuleTitle)
      dispatch(
        setActiveModule({
          title: momentModuleTitle,
          subtitle: momentModuleSubTitle,
        })
      );
  }, [dispatch, momentModuleTitle, momentModuleSubTitle]);

  const loadMoments = useCallback(() => {
    if (!singleMomentsLoaded) dispatch(fetchMoments());
  }, [dispatch, singleMomentsLoaded]);

  useEffect(() => {
    loadMoments();
  }, [loadMoments]);

  useEffect(() => {
    let active = moments.filter(
      (moment) => moment.active_student_tracking_count !== 0
    );
    let inactive = moments.filter(
      (moment) => moment.active_student_tracking_count === 0
    );

    setMomentsActive(active);
    setMomentsInactive(inactive);
    if (moments.length > 0 && active.length === 0) {
      setListMoments(moments);
      setActiveKey("all");
    }
    if (active.length > 0) {
      setListMoments(active);
    }
  }, [moments]);

  const selectTab = (data) => {
    setActiveKey(data);
    if (data === "active") {
      setListMoments(momentsActive);
    } else if (data === "inactive") {
      setListMoments(momentsInactive);
    } else {
      setListMoments(moments);
    }
  };

  const tabs = [
    {
      key: "active",
      title: `${_.upperFirst(t("actives"))} ${momentsActive.length}`,
    },
    {
      key: "inactive",
      title: `${_.upperFirst(t("inactives"))} ${momentsInactive.length}`,
    },
    {
      key: "all",
      title: _.upperFirst(t("all")),
    },
  ];

  return (
    <>
      <ScrollToTopOnMount />
      <NNavbarDashboard />
      <Container fluid style={{ marginTop: "48px" }}>
        <RowItem>
          <Col lg="12" style={{ padding: "0px" }}>
            <TabContainer
              activeKey={activeKey}
              onSelect={selectTab}
              tabs={tabs}
              className="mb-3"
            />
          </Col>
        </RowItem>

        {isMomentPending && <ListMomentSkeleton />}
        {!isMomentPending && (
          <MomentList moments={listMoments} pageSize={pageSize} />
        )}
      </Container>
    </>
  );
};
export default Moments;
