import React from "react";
import _ from "lodash";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const colors = {
  red: {
    background: "rgba(226, 50, 50, 0.3)",
    border: "#E23232",
  },
  green: {
    background: "rgba(12, 205, 179, 0.3)",
    border: "#3AC8AC",
  },
  blue: {
    background: "rgba(51, 79, 255, 0.3)",
    border: "#334FFF",
  },
};
const options = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    point: {
      radius: 0,
    },
  },
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: false,
    },
    datalabels: false,
  },
  scales: {
    x: {
      display: false,
    },
    y: {
      display: false,
      beginAtZero: true,
    },
  },
};

const labels = ["1", "2", "3", "4"];

const ChartArea = ({ color, data, labelModal }) => {
  const { t } = useTranslation();
  const dataLine = {
    labels,
    datasets: [
      {
        fill: true,
        label: labelModal ?? _.upperFirst(t("value")),
        data: data,
        borderColor: colors[color].border,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, colors[color].background);
          gradient.addColorStop(1, "rgba(196, 196, 196, 0) ");
          return gradient;
        },
      },
    ],
  };
  return <Line options={options} data={dataLine} height={100} />;
};

export default ChartArea;
