import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import NContainerFilters from "components/Nimbiv2/NContainerFilters";
import { APP_MODULES } from "utils";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAssociatedRoles,
  fetchAssociatedTrackingObjects,
  fetchAssociateds,
} from "store/actions/async/associated-async.actions";
import { associated as path } from "routes/routePaths";
import { useTranslationLocal } from "hooks";

const FilterBar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { translation } = useTranslationLocal();

  const {
    pending: { list: isPending },
    pagination,
    listRoles,
    listTrackingObject,
    numberCalls: {
      listRoles: NumberCallRoles,
      listTrackingObject: NumberCallTracking,
    },
  } = useSelector((state) => state.associated);

  useEffect(() => {
    if (NumberCallRoles === 0) {
      dispatch(fetchAssociatedRoles());
    }
  }, [dispatch, NumberCallRoles]);

  useEffect(() => {
    if (NumberCallTracking === 0) {
      dispatch(fetchAssociatedTrackingObjects());
    }
  }, [dispatch, NumberCallTracking]);

  return (
    <>
      <div className="pt-3">
        <NContainerFilters
          isPendingList={isPending}
          filterName={APP_MODULES.ASSOCIATED.FILTER_NAME}
          persistentSearch={false}
          buttonCreateAction={() => {
            history.push(path.add.route);
          }}
          onChange={(data) => {
            let payload = { ...data };
            if (payload.hasOwnProperty("id")) {
              payload["id"] = data["id"]?.id;
            }
            if (payload.hasOwnProperty("roles"))
              payload["roles"] = payload["roles"]?.join(";");
            if (payload.hasOwnProperty("tracking_objects"))
              payload["tracking_objects"] = payload["tracking_objects"]?.join(
                ";"
              );
            dispatch(fetchAssociateds(payload));
          }}
          showCountResult
          countResult={pagination?.count}
          searchKey={"id"}
          compositeLabel={["name", "tracking_object"]}
          //customMenuItem={customMenuItem}
          urlSearch={"crm/associateds/"}
          textButtonCreate={t("create_associated")}
          placeholderSearch={t("search_enter_name_official")}
          attribSearch="search"
          textCountResult={t("officials_found")}
          customMenuItem={(option, { text }) => {
            return (
              <div>
                <div>
                  <strong>{option.name}</strong>
                </div>
                <div>
                  <small>
                    {translation("role", "startCase")}: {option.role}
                  </small>
                </div>
                <div>
                  <small>
                    {translation("tracking_object", "startCase")}:{" "}
                    {option.tracking_object}
                  </small>
                </div>
              </div>
            );
          }}
          dataFilter={[
            {
              filter_field: "roles",
              id: 1,
              label: "Roles",
              module: "associated",
              subtype_component: "multi_select",
              type_component: "select",
              values: listRoles,
            },
            {
              filter_field: "tracking_objects",
              id: 2,
              label: translation("tracking_objects", "", "cursos"),
              module: "associated",
              subtype_component: "multi_select",
              type_component: "select",
              values: listTrackingObject,
            },
          ]}
        />
      </div>
    </>
  );
};

export default FilterBar;
