import { generateUrlWithParams } from "utils";

import api from "./api";

export const getGenerationEvents = (queryParams = null) => {
  const finalUrl = generateUrlWithParams("crm/generation-event/", queryParams);
  return api.get(finalUrl);
};

export const getGenerations = () => {
  return api.get("crm/generation-event/generations/");
};

export const getTrackingObjects = () => {
  return api.get("crm/generation-event/tracking_objects/");
};
