export const GET_INITIATIVES = "GET_INITIATIVES";
export const SET_INITIATIVES = "SET_INITIATIVES";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const GET_TYPES_INITIATIVES = "GET_TYPE_INITIATIVES";
export const SET_TYPES_INITIATIVES = "SET_TYPE_INITIATIVES";
export const GET_STUDENTS_TRACKING = "GET_STUDENTS_TRACKING";
export const SET_STUDENTS_TRACKING = "SET_STUDENTS_TRACKING";
export const SET_TAB_PAGE_INITIATIVE = "SET_TAB_PAGE_INITIATIVE";
export const SET_INITIATIVE = "SET_INITIATIVE";
export const GET_INITIATIVE_OBJECT = "GET_INITIATIVE";
export const GET_INITIATIVES_CALL = "GET_INITIATIVES_CALL";
export const SET_INITIATIVES_CALL = "SET_INITIATIVES_CALL";
export const MARK_INITIATIVE = "MARK_INITIATIVE";
export const SET_INITIATIVE_INSIGHTS = "SET_INITIATIVE_INSIGHTS";
export const GET_INITIATIVES_INSIGHTS = "GET_INITIATIVES_INSIGHTS";
export const CLEAN_STUDENT_TRACKING = "CLEAN_STUDENT_TRACKING";
// Initiatives
const defaultQueryParams = {
  page: 1,
  page_size: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
  client_filters: null,
};
export const getInitiatives = (data) => ({
  type: GET_INITIATIVES,
  payload: data,
});
export const setInitiatives = (data) => ({
  type: SET_INITIATIVES,
  payload: data,
});
export const registerError = ({ err = null, key = "" }) => ({
  type: REGISTER_ERROR,
  payload: { err, key },
});

export const getTypesInitiative = () => ({
  type: GET_TYPES_INITIATIVES,
  payload: null,
});

export const setTypeInitiatives = (data) => ({
  type: SET_TYPES_INITIATIVES,
  payload: data,
});

export const getStudentsTracking = (queryParams = defaultQueryParams) => ({
  type: GET_STUDENTS_TRACKING,
  payload: queryParams,
});

export const setStudentsTracking = (results) => ({
  type: SET_STUDENTS_TRACKING,
  payload: results,
});

export const setTabSelect = (data) => ({
  type: SET_TAB_PAGE_INITIATIVE,
  payload: data,
});

export const setInitiative = (data) => ({
  type: SET_INITIATIVE,
  payload: data,
});

export const getInitiative = () => ({
  type: GET_INITIATIVE_OBJECT,
  payload: null,
});

export const setInitiativesCall = (data) => ({
  type: SET_INITIATIVES_CALL,
  payload: data,
});

export const getInitiativesCall = () => ({
  type: GET_INITIATIVES_CALL,
  payload: null,
});

export const markInitiative = (initiativeID) => ({
  type: MARK_INITIATIVE,
  payload: initiativeID,
});

export const setInitiativesInsights = (data) => ({
  type: SET_INITIATIVE_INSIGHTS,
  payload: data,
});

export const getInitiativesInsights = () => ({
  type: GET_INITIATIVES_INSIGHTS,
  payload: null,
});

export const cleanStudentTrackingInitiative = () => ({
  type: CLEAN_STUDENT_TRACKING,
  payload: null,
});
