import { generateUrlWithParams } from "utils";
import api from "services/api";

export const getMomentDetail = (id, queryParams) => {
  const finalUrl = generateUrlWithParams(`crm/moment/${id}/`, queryParams);
  return api.get(finalUrl);
};
export const getMomentStudentsTracking = (id, queryParams) => {
  const finalUrl = generateUrlWithParams(
    `crm/moment/${id}/student-trackings/`,
    queryParams
  );
  return api.get(finalUrl);
};

export const fetchListMomentReason = (queryParams) => {
  const finalUrl = generateUrlWithParams(
    `crm/moment-enter-reason/`,
    queryParams
  );
  return api.get(finalUrl);
};
