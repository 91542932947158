import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { uuidv4 } from "utils";
import TaskHistory from "containers/Task/v2/TaskHistory";

import styles from "./Task.module.css";
import { AnonymousContainer } from "components/Nimbiv2/styled";
import { useTranslation } from "react-i18next";
import { NSpinnerPoints } from "components/Nimbiv2";

const LastManagement = ({ student, isVisible, onClose }) => {
  const { t } = useTranslation();
  const {
    personHistory,
    pending: { personhistory: isPendingPersonHistory },
  } = useSelector((state) => state.crm);
  const { user } = useSelector((state) => state.auth);

  const handleClose = () => onClose();

  if (!student || !isVisible) return null;

  return (
    <Modal
      className={styles.modalHistory}
      show={isVisible}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className={styles.msgModalHeader} closeButton>
        <Modal.Title>
          <span className={styles.titleHeader}>
            {t("record")} -{" "}
            <span className={styles.studentTitle}>
              {!user.anonymous && student.full_name}
              {user.anonymous && (
                <>
                  {student.full_name.split(" ", 1)}{" "}
                  <AnonymousContainer isAnonymous={user.anonymous}>
                    {student.full_name.split(" ").splice(1).join(" ")}
                  </AnonymousContainer>
                </>
              )}
            </span>
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={`px-4 ${styles.bodyContainer} ${styles.messageBodyHistory}`}
      >
        {isPendingPersonHistory && (
          <div>
            <NSpinnerPoints />
          </div>
        )}
        {!isPendingPersonHistory &&
          personHistory &&
          personHistory.map((m) => (
            <TaskHistory
              key={uuidv4(8)}
              student={student}
              task={m}
              isPending={isPendingPersonHistory}
            />
          ))}
      </Modal.Body>
      {/* <Modal.Footer className={`d-flex justify-content-start px-4 ${styles.footerContainer}`}>
        <span>Agregar nota</span>
        <FontAwesomeIcon icon={faFileAlt} />
      </Modal.Footer> */}
    </Modal>
  );
};

LastManagement.propTypes = {
  student: PropTypes.shape({}).isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default React.memo(LastManagement);
