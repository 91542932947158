import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { NButton } from "components/Nimbiv2";
import styled from "styled-components";

const ModalConfirm = ({
  onCancel,
  onAccept,
  title,
  isVisible,
  size,
  detail,
  titleApproved,
  titleCancel,
  colorApproved,
  colorCancel,
  loading = false,
  disabledAccept = false,
  paddingApproved,
}) => {
  return (
    <ModalDesign
      show={isVisible}
      onHide={onCancel}
      size={size}
      dialogClassName="modal-50w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton style={{ borderBottom: "0px" }}>
        <Modal.Title className="h5">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={detail ? "" : "d-none"}>
        <div style={{ fontSize: "var(--font-size-lg)" }}>{detail}</div>
      </Modal.Body>
      <Modal.Footer style={{ borderTop: "0px" }}>
        <NButton
          outlined
          onClick={onCancel}
          style={{ marginRight: "14px", fontWeight: "500" }}
          color={colorCancel}
          border="24px"
          padding="5px 30px"
        >
          {titleCancel ?? "No"}
        </NButton>
        <NButton
          style={{ fontWeight: "500" }}
          onClick={onAccept}
          border="24px"
          color={colorApproved}
          loading={loading}
          disabled={disabledAccept}
          padding={paddingApproved}
        >
          {titleApproved ?? "Si"}
        </NButton>
      </Modal.Footer>
    </ModalDesign>
  );
};

ModalConfirm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
  title: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
};

ModalConfirm.defaultProps = {
  isVisible: false,
  title: "",
  size: "md",
  colorApproved: "default",
  colorCancel: "danger",
  paddingApproved: null,
};

export default ModalConfirm;

const ModalDesign = styled(Modal)`
  .modal-header {
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 0px;
  }
  .modal-body {
    padding-top: 8px;
    font-size: 16px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .modal-content {
    border-radius: 8px;
  }
`;
