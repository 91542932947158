import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NButton } from "components/Nimbiv2";
import { CardNimbi } from "../styled";

const CardReport = ({ report }) => {
  const { t } = useTranslation();
  return (
    <CardNimbi padding="24px 32px 24px 24px">
      <Card.Body className="d-flex flex-column" style={{ padding: "0px" }}>
        {1 === 0 && <CardReportImg src="" />}
        <TitleImg>{report.group}</TitleImg>
        <Title>{report.name}</Title>
        <Description>{report.description}</Description>
        <Link to={`/reportes/${report.id}`} className="mt-auto">
          <NButton outlined color="second">
            {t("see_report")}
          </NButton>
        </Link>
      </Card.Body>
    </CardNimbi>
  );
};

export default CardReport;

const Title = styled(Card.Title)`
  font-size: var(--font-size-lg);
  font-weight: bold;
  margin-bottom: 8px;
  line-height: 120%;
`;

const Description = styled(Card.Text)`
  font-size: var(--font-size-md);
  line-height: 140%;
`;
const TitleImg = styled.div`
  font-family: Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #334fff;
  margin-bottom: 8px;
`;

const CardReportImg = styled.img`
  height: 50px;
  width: 50px;
  margin-bottom: 8px;
`;
