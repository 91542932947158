import {
  SET_SESSION,
  CLEAR_SESSION,
  UPDATE_USER_SESSION,
  CHANGE_ANONYMOUS,
} from "store/actions/auth.actions";

import { getDataFromLocalStorage, setDataToLocalStorage } from "utils";

const initialState = {
  user: getDataFromLocalStorage("user", null),
  token: getDataFromLocalStorage("token", null),
  refresh: getDataFromLocalStorage("refresh", null),
  expires: getDataFromLocalStorage("expires", null),
};

const authReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION: {
      setDataToLocalStorage(
        "nimbi_version",
        process.env.REACT_APP_NIMBI_VERSION || ""
      );
      setDataToLocalStorage("token", action.payload.access);
      setDataToLocalStorage("refresh", action.payload.refresh);
      setDataToLocalStorage("user", action.payload.user);
      setDataToLocalStorage("expires", action.payload.expires);
      setDataToLocalStorage("extras", action.payload.user?.extras ?? []);
      setDataToLocalStorage("loadTaskResult", false);

      return {
        ...state,
        token: action.payload.access,
        user: action.payload.user,
        refresh: action.payload.refresh,
        expires: action.payload.expires,
        extras: action.payload.user?.extras ?? [],
      };
    }

    case CLEAR_SESSION: {
      localStorage.clear();

      return {
        ...state,
        user: null,
        token: null,
        refresh: null,
        expires: null,
      };
    }

    case UPDATE_USER_SESSION: {
      setDataToLocalStorage("user", action.payload.user);
      return {
        ...state,
        user: action.payload.user,
      };
    }

    case CHANGE_ANONYMOUS: {
      const anonymous = action.payload;
      return {
        ...state,
        user: { ...state.user, ...{ anonymous: anonymous } },
      };
    }

    default:
      return { ...state };
  }
};

export default authReducers;
