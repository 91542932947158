import {
  GET_INITIATIVES,
  GET_STUDENTS_TRACKING,
  GET_TYPES_INITIATIVES,
  SET_INITIATIVES,
  SET_STUDENTS_TRACKING,
  SET_TYPES_INITIATIVES,
  SET_TAB_PAGE_INITIATIVE,
  GET_INITIATIVE_OBJECT,
  SET_INITIATIVE,
  GET_INITIATIVES_CALL,
  SET_INITIATIVES_CALL,
  MARK_INITIATIVE,
  SET_INITIATIVE_INSIGHTS,
  GET_INITIATIVES_INSIGHTS,
  CLEAN_STUDENT_TRACKING,
} from "store/actions/initiative.actions";
import { markMoment } from "utils";

const initialState = {
  selectInitiativeCall: null,
  pending: {
    initiatives: false,
    typeInitiatives: false,
    list: false,
    initiative: false,
    initiativesCall: false,
    insights: false,
  },
  initiatives: [],
  listTypeInitiatives: [],
  list: [],
  tabSelect: "internal",
  initiative: null,
  initiativesCall: [],
  insights: null,
  pagination: {
    count: 0,
    next: null,
    previous: null,
    page: 1,
    pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
  },
  paginationTracking: {
    count: 0,
    next: null,
    previous: null,
    page: 1,
    pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
  },
  singleLoads: {
    initiativesCall: false,
  },
};

const initiativeReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_INITIATIVES: {
      return {
        ...state,
        pending: { ...state.pending, initiatives: true },
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          pageSize: action.payload.page_size,
        },
      };
    }

    case SET_INITIATIVES: {
      //const { results, page, page_size } = action.payload;
      const { count, next, previous, results } = action.payload;
      let auxPagination = { ...state.pagination };
      auxPagination = { ...auxPagination, count, next, previous };

      return {
        ...state,
        initiatives: results,
        pending: { ...state.pending, initiatives: false },
        singleLoads: { ...state.singleLoads, initiatives: true },
        pagination: auxPagination,
      };
    }

    case GET_TYPES_INITIATIVES: {
      return {
        ...state,
        pending: { ...state.pending, typeInitiatives: true },
      };
    }

    case SET_TYPES_INITIATIVES: {
      const { results } = action.payload;
      return {
        ...state,
        listTypeInitiatives: results,
        pending: { ...state.pending, typeInitiatives: false },
        singleLoads: { ...state.singleLoads, typeInitiatives: true },
      };
    }

    case SET_TAB_PAGE_INITIATIVE: {
      const tab = action.payload;
      return {
        ...state,
        tabSelect: tab,
      };
    }

    case GET_STUDENTS_TRACKING: {
      return {
        ...state,
        list: [],
        pending: { ...state.pending, list: true },
        paginationTracking: {
          ...state.paginationTracking,
          count: -1,
          page: action.payload.page,
          pageSize: action.payload.page_size,
        },
      };
    }

    case SET_STUDENTS_TRACKING: {
      const { count, next, previous, results } = action.payload;

      let auxPagination = { ...state.paginationTracking };
      auxPagination = { ...auxPagination, ...{ count, next, previous } };

      return {
        ...state,
        list: results,
        pending: { ...state.pending, list: false },
        paginationTracking: auxPagination,
      };
    }

    case GET_INITIATIVE_OBJECT: {
      return {
        ...state,
        initiative: null,
        pending: { ...state.pending, initiative: true },
      };
    }
    case SET_INITIATIVE: {
      const results = action.payload;

      return {
        ...state,
        initiative: results,
        pending: { ...state.pending, initiative: false },
      };
    }
    case GET_INITIATIVES_CALL: {
      return {
        ...state,
        pending: { ...state.pending, initiativesCall: true },
      };
    }
    case SET_INITIATIVES_CALL: {
      const results = action.payload;
      const newInitiativesCall = results.map((m) => {
        return { ...m, selected: false, id: m.pk };
      });
      return {
        ...state,
        initiativesCall: newInitiativesCall,
        singleLoads: { ...state.singleLoads, initiativesCall: true },
        pending: { ...state.pending, initiativesCall: false },
      };
    }
    case MARK_INITIATIVE: {
      return {
        ...state,
        initiativesCall: markMoment(action.payload, [...state.initiativesCall]),
        selectInitiativeCall: action.payload,
      };
    }

    case SET_INITIATIVE_INSIGHTS: {
      const results = action.payload;

      return {
        ...state,
        insights: results,
        pending: { ...state.pending, insights: false },
      };
    }
    case GET_INITIATIVES_INSIGHTS: {
      return {
        ...state,
        pending: { ...state.pending, insights: true },
      };
    }

    case CLEAN_STUDENT_TRACKING: {
      return {
        ...state,
        list: [],
        pending: { ...state.pending, list: false },
        paginationTracking: {
          count: 0,
          next: null,
          previous: null,
          page: 1,
          pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
        },
      };
    }

    default:
      return { ...state };
  }
};

export default initiativeReducers;
