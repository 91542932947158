/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { toast } from "react-toastify";
import {
  Form,
  Col,
  Button,
  ButtonGroup,
  ToggleButton,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import ConfirmAction from "../../containers/Student/Modals/ConfirmAction";
import styles from "./NMassiveActions.module.css";
import { useTranslation } from "react-i18next";
import { useTranslationLocal } from "hooks";

const actionTypes = {
  EMAIL: {
    code: "email",
    description: "Emails",
  },
  CALL: {
    code: "call",
    description: "Llamadas",
  },
};

const NMassiveActions = ({ onApply, isDisabled = false }) => {
  const { list: items } = useSelector((state) => state.student);
  const { templates } = useSelector((state) => state.crm);
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();

  const [optionSelected, setOptionSelected] = useState("");
  const [actionSelected, setActionSelected] = useState(actionTypes.EMAIL.code);
  const [actions, setActions] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [templateTitle, setTemplateTitle] = useState("");

  useEffect(() => {
    if (actionSelected === actionTypes.EMAIL.code) setActions(templates.email);
    if (actionSelected === actionTypes.CALL.code) setActions(templates.call);
  }, [actionSelected, templates]);

  const handleOptionChange = (newValue) => {
    const tpl = actions.find(
      (action) => Number(action.id) === Number(newValue)
    );
    setTemplateTitle(tpl.name);
    setOptionSelected(newValue);
  };

  const handleActionChange = (newValue) => {
    setOptionSelected("");
    setActionSelected(newValue);
  };

  const confirmAction = () => {
    const selectedItems = items.filter((t) => t.isChecked);
    if (selectedItems.length === 0) {
      toast.error(
        t("select_student_continue", {
          students: translation("students", "", t("students")),
        })
      );
      return false;
    }
    const types = Object.entries(actionTypes);
    // eslint-disable-next-line no-restricted-syntax
    for (const [, value] of types) {
      if (actionSelected === value.code) {
        setActionType(value);
        break;
      }
    }
    setShowConfirm(true);
  };

  const applyAction = () => {
    const payload = {
      action: actionSelected,
      template: Number(optionSelected),
      students: [],
    };

    const selectedItems = items.filter((t) => t.isChecked);
    selectedItems.forEach((item) => {
      payload.students = [...payload.students, item.id];
    });

    setActionSelected(actionTypes.EMAIL.code);
    setOptionSelected("");
    onApply(payload);
  };

  const onCloseConfirm = (isConfirmed) => {
    setShowConfirm(false);
    if (isConfirmed) applyAction();
  };

  return (
    <Form>
      <ConfirmAction
        action={actionType}
        templateTitle={templateTitle}
        onClose={onCloseConfirm}
        show={showConfirm}
      />
      <Form.Row>
        <Col xs={4}>
          <ButtonGroup size="sm" toggle>
            <ToggleButton
              className={styles.toggleButton}
              type="radio"
              name="channel"
              value={actionTypes.EMAIL.code}
              checked={actionSelected === actionTypes.EMAIL.code}
              onChange={(e) => handleActionChange(e.currentTarget.value)}
              disabled={isDisabled}
              custom={1}
            >
              {_.upperFirst(t("mail"))}
            </ToggleButton>
            <ToggleButton
              className={styles.toggleButton}
              type="radio"
              name="channel"
              value={actionTypes.CALL.code}
              checked={actionSelected === actionTypes.CALL.code}
              onChange={(e) => handleActionChange(e.currentTarget.value)}
              disabled={isDisabled}
              custom={1}
            >
              {_.upperFirst(t("call"))}
            </ToggleButton>
          </ButtonGroup>
        </Col>
        <Col>
          <Form.Control
            style={{ fontSize: "0.8rem" }}
            as="select"
            value={optionSelected}
            onChange={(event) => handleOptionChange(event.target.value)}
            disabled={isDisabled}
          >
            <option value="">{t("select_an_option")}</option>
            {Array.from(actions).map((opt) => (
              <option key={opt.id} value={opt.id}>
                {opt.name}
              </option>
            ))}
          </Form.Control>
        </Col>
        <Col xs={1}>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="action-tooltip">
                Aplicar <strong>acción</strong>.
              </Tooltip>
            }
          >
            <Button
              onClick={confirmAction}
              variant="light"
              size="sm"
              disabled={!optionSelected || isDisabled}
            >
              <FontAwesomeIcon icon={faCheck} size="1x" />
            </Button>
          </OverlayTrigger>
        </Col>
      </Form.Row>
    </Form>
  );
};

NMassiveActions.propTypes = {
  onApply: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export default NMassiveActions;
