import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Form, Pagination } from "components";

import styles from "./NPagination.module.css";
import { useTranslation } from "react-i18next";

const NPagination = ({
  isPending,
  pagination,
  onPageChange,
  showMore = false,
  options = [],
}) => {
  const { t } = useTranslation();
  const [startingRow, setStartingRow] = useState(0);
  const [endingRow, setEndingRow] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const isEmptyList =
      !pagination.previous && !pagination.next && pagination.count === 0;
    const hasOnePageOnly =
      !pagination.previous && !pagination.next && pagination.count > 0;

    if (isEmptyList) {
      setStartingRow(0);
      setEndingRow(0);
    } else {
      const pageCount = Math.ceil(pagination.count / pagination.pageSize);
      setTotalPages(pageCount);

      if (hasOnePageOnly) {
        setStartingRow(1);
        setEndingRow(pagination.count);
      } else {
        const isFirstPage = pagination.page === 1;
        const isLastPage =
          !pagination.next &&
          pagination.page * pagination.pageSize >= pagination.count;

        if (isFirstPage && !isLastPage) {
          setStartingRow(1);
          setEndingRow(pagination.pageSize);
        } else if (!isFirstPage && !isLastPage) {
          setStartingRow((pagination.page - 1) * pagination.pageSize + 1);
          setEndingRow(pagination.page * pagination.pageSize);
        }

        if (!isFirstPage && isLastPage) {
          setStartingRow((pagination.page - 1) * pagination.pageSize + 1);
          setEndingRow(pagination.count);
        }
      }
    }

    if (pagination.page === 1 && pagination.count > 0) {
      //
    }
  }, [pagination, isPending]);

  const loadPage = (pageNumber, pageSize) => {
    if (Number(pagination.page) !== Number(pageNumber))
      onPageChange(pageNumber, pageSize);
  };

  const handleChange = (elem) => {
    pagination.pageSize = parseInt(elem.target.value, 10);
    onPageChange(1, pagination.pageSize);
  };

  if (pagination.count <= pagination.pageSize) {
    return <div></div>;
  }

  return (
    <Container fluid className="pr-0">
      <Row className="mr-0">
        <Col className="d-flex align-items-center justify-content-end pr-0">
          <div className="mr-5">
            <Form.Control
              value={pagination.pageSize}
              as="select"
              onChange={handleChange}
              disabled={isPending}
            >
              {options.length === 0 && (
                <>
                  <option value="10">{t("show", { number: 10 })}</option>
                  <option value="50">{t("show", { number: 50 })}</option>
                  <option value="100">{t("show", { number: 100 })}</option>
                  {showMore && (
                    <>
                      <option value="250">{t("show", { number: 250 })}</option>
                      <option value="500">{t("show", { number: 500 })}</option>
                    </>
                  )}
                </>
              )}
              {options.length > 0 && (
                <>
                  {options.map((item, key) => {
                    return (
                      <option value={item} key={`key-options-${key}`}>
                        {t("show", { number: item })}
                      </option>
                    );
                  })}
                </>
              )}
            </Form.Control>
          </div>
          <div className="mr-5">
            <span className={styles.pageSummary}>
              {`${startingRow} - ${endingRow} `}
              {t("of")} {pagination.count}
            </span>
          </div>
          <Pagination style={{ margin: 0 }}>
            <Pagination.First
              onClick={() => loadPage(1, pagination.pageSize)}
              disabled={pagination.page === 1 || isPending}
            />
            <Pagination.Prev
              onClick={() => loadPage(pagination.page - 1, pagination.pageSize)}
              disabled={pagination.page === 1 || isPending}
            />
            {[...Array(Math.min(totalPages + 2, 5)).keys()]
              .map((i) => pagination.page + i - 2)
              .filter((p) => p > 0 && p <= totalPages)
              .map((page) => {
                return (
                  <Pagination.Item
                    key={page}
                    active={page === pagination.page}
                    onClick={() => loadPage(page, pagination.pageSize)}
                    disabled={isPending}
                  >
                    {page}
                  </Pagination.Item>
                );
              })}
            <Pagination.Next
              onClick={() => loadPage(pagination.page + 1, pagination.pageSize)}
              disabled={pagination.page === totalPages || isPending}
            />
            <Pagination.Last
              onClick={() => loadPage(totalPages, pagination.pageSize)}
              disabled={pagination.page === totalPages || isPending}
            />
          </Pagination>
        </Col>
      </Row>
    </Container>
  );
};

NPagination.propTypes = {
  isPending: PropTypes.bool,
  pagination: PropTypes.shape({
    count: PropTypes.number,
    next: PropTypes.string || null,
    previous: PropTypes.string || null,
    page: PropTypes.number,
    pageSize: PropTypes.number,
  }).isRequired,
};

NPagination.defaultProps = {
  isPending: false,
};

export default NPagination;
