import React from "react";
import styled from "styled-components";
const NStepItem = ({ children, showLine = true, active = false, onClick }) => {
  return (
    <>
      <StepContainer active={active} onClick={onClick}>
        {children}
      </StepContainer>
      {showLine && (
        <div className="d-flex align-items-center">
          <LineStep show={showLine}></LineStep>
        </div>
      )}
    </>
  );
};
export default NStepItem;

const StepContainer = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px;
  ${(props) => (props.active ? "cursor: pointer;" : "")}
`;

const LineStep = styled.li`
  height: 1px;
  width: 40px;
  background: #cdd4de;
  margin-left: 8px;
  list-style: none;
  ${(props) => (props.show ? "" : "visibility: hidden;")};
`;
