import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Button, Form } from "react-bootstrap";
import { BaseModal } from "components";
import { uuidv4 } from "utils";
import styles from "./ConfirmAction.module.css";

const ConfirmAction = ({ action, show, onClose, templateTitle }) => {
  const {
    pagination,
    wholePageMarked,
    markAllItems,
    list: items,
  } = useSelector((state) => state.student);
  const [isDisabled, setDisabled] = useState(true);

  if (!action || !show) return null;

  const onCloseModal = () => {
    setDisabled(true);
    onClose(false);
  };

  const handleCloseModal = () => {
    setDisabled(true);
    onClose(true);
  };

  const studentCounter = () => {
    let counter = 0;
    if (markAllItems || wholePageMarked) {
      counter = markAllItems ? pagination.count : pagination.pageSize;
    } else {
      counter = items.filter((t) => t.isChecked).length;
    }
    return counter;
  };

  const handleCheck = () => {
    setDisabled((sw) => !sw);
  };

  return (
    <BaseModal
      title={`Confirmar creación de ${action.description}`}
      isVisible={show}
      onClose={onCloseModal}
    >
      <div className={styles.mainContent}>
        {action.code === "email" ? (
          <div className={styles.actionMessage}>
            Se enviará el template <u>{templateTitle}</u> a{" "}
            <strong>{studentCounter()} Alumnos</strong>
          </div>
        ) : (
          <div className={styles.actionMessage}>
            Se crearán tareas de llamada desde el template{" "}
            <u>{templateTitle}</u>
            &nbsp; para <strong>{studentCounter()} Alumnos</strong>
          </div>
        )}
        <div>
          <Form.Check
            id={uuidv4(8)}
            type="checkbox"
            label="Confirmar ésta acción"
            checked={!isDisabled}
            onChange={(event) => handleCheck(event)}
            custom
          />
        </div>
        <Button
          className={styles.confirmButton}
          onClick={handleCloseModal}
          disabled={isDisabled}
        >
          {action.code === "email" ? "Enviar emails" : "Crear tareas"}
        </Button>
      </div>
    </BaseModal>
  );
};

ConfirmAction.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool,
  templateTitle: PropTypes.string,
};

ConfirmAction.defaultProps = {
  onClose: () => {},
  show: false,
  templateTitle: "",
};

export default ConfirmAction;
