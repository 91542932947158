import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { components } from "./constants";
import NTextArea from "components/Nimbiv2/NTextArea";

const FieldComponent = ({
  data,
  onChange,
  filterset,
  isDisabled,
  keyPosition,
  lastKeyPosition,
  style,
  onChangeComment = () => {},
  show = false,
  response,
}) => {
  const Component = _.get(components, data.input_type, components["empty"]);
  return (
    <div className="mb-2">
      <Component
        dataset={data}
        onChange={onChange}
        keyPosition={keyPosition}
        isDisabled={isDisabled}
        lastKeyPosition={lastKeyPosition}
        style={style}
      />
      {data && data.have_comment && show && (
        <div
          style={{
            marginTop: "10px",
          }}
        >
          <NTextArea
            defaultValue={response?.question_comment ?? ""}
            onChange={(text) => {
              onChangeComment(text, data);
            }}
            maxLength={500}
            showCount
          ></NTextArea>
        </div>
      )}
    </div>
  );
};

export default FieldComponent;

FieldComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
};

FieldComponent.propsDefault = {
  isDisabled: false,
};
