import { NEmpty } from "components/NFormFilter/Components";
import CustomAcademicAssistance from "../Components/CustomAcademicAssistance";
import CustomAcademicProgress from "../Components/CustomAcademicProgress";
import NCheckbox from "../Components/NCheckbox";
import NOnlyCommnet from "../Components/NOnlyComment";
import NRadioButtons from "../Components/NRadioButtons";

export const components = {
  empty: NEmpty,
  "radio-button": NRadioButtons,
  checkbox: NCheckbox,
  only_comment: NOnlyCommnet,
  academic_progress: CustomAcademicProgress,
  academic_assistance: CustomAcademicAssistance,
};
