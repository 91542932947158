/* eslint-disable no-param-reassign */
import { getTeams } from "services/team.service";

import * as TeamActions from "../team.actions";
import { handleErrorStatus } from "../../../services/errors";
import { APP_MODULES, getSessionFilters } from "utils";

const handleErrorResponse = (err) => {
  const status = err instanceof Map ? err.get("status") : 500;
  if (status > 0) return handleErrorStatus(status);
};

const fetchTeams = (initialParams = null) => {
  return (dispatch) => {
    const queryParams = getSessionFilters(APP_MODULES.TEAM.FILTER_NAME) ?? {};
    dispatch(TeamActions.getTeams(queryParams));
    getTeams({
      ...queryParams,
      ...initialParams,
    })
      .then(({ data }) => dispatch(TeamActions.setTeams(data)))
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(TeamActions.registerError({ err, key: "list" }));
      });
  };
};

export { fetchTeams };
