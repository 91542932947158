import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import LogRocket from "logrocket";
import { Container, Row, Col } from "components";
import { signIn } from "services/authService";
import { setSession } from "store/actions/auth.actions";
import FormComponent from "./Form";
import styles from "./Form.module.css";
import { useTranslation } from "react-i18next";

const LoginForm = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const rowStyles = `justify-content-md-center align-items-center ${styles.loginBlock}`;

  const [isPending, setPending] = useState(false);

  const submitForm = async (user, password) => {
    setPending(true);
    const { data, status, message } = await signIn(
      user.toLowerCase(),
      password
    );

    if (status === 200) {
      const { user } = data;
      LogRocket.identify(user.id, {
        name: `${user.first_name} ${user.last_name}`,
        email: `${user.username}`,
      });
      i18n.changeLanguage(user.user_language ?? "es");
      dispatch(setSession(data));
    } else {
      toast.error(message);
    }

    setPending(false);
  };

  return (
    <Container style={{ height: "85%" }}>
      <Row className={rowStyles}>
        <Col md={4}>
          <div
            className="d-flex justify-content-center"
            style={{ fontSize: "32px", fontWeight: "700", color: "#2A2B2E" }}
          >
            ¡Hola de nuevo!
          </div>
          <div style={{ marginTop: "60px" }}>
            <FormComponent onSubmit={submitForm} isPending={isPending} />
          </div>
          <div>
            {process.env.REACT_APP_MSAL_AUTHORITY &&
              process.env.REACT_APP_MSAL_CLIENT_ID &&
              process.env.REACT_APP_MSAL_REDIRECT &&
              process.env.REACT_APP_MSAL_PROVIDER_ID && (
                <Link
                  className={`mt-4 ${styles.textLink} d-flex justify-content-center`}
                  to="/login_sso"
                >
                  Ingresar con Azure AD
                </Link>
              )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginForm;
