import styled from "styled-components";

const CommentBlock = styled.div`
  background: var(--color-aqua);
  border-radius: 8px;
  padding: ${(props) => props.padding ?? "12px"};
  display: flex;
  overflow-wrap: anywhere;
`;

export default CommentBlock;
