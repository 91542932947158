import NInputFilter from "components/NInputFilter";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Highlighter } from "react-bootstrap-typeahead";
import { uuidv4 } from "utils";

const NCheckbox = ({
  key,
  dataset,
  isDisabled,
  onChange,
  keyPosition,
  lastKeyPosition,
  style,
  initialData,
  label,
  value,
}) => {
  const [selectedItems, setSelectedItems] = useState(initialData ?? []);
  const [load, setLoad] = useState(0);
  useEffect(() => {
    if (load === 1) {
      onChange(selectedItems);
    } else {
      setLoad(1);
    }
  }, [selectedItems]); // eslint-disable-line react-hooks/exhaustive-deps

  const customMenuItem = (option, { text }) => {
    return (
      <div>
        <Highlighter search={text}>{option.name}</Highlighter>
      </div>
    );
  };

  const getSelectItems = (new_selected, item) => {
    const position = new_selected.findIndex((data) => data.id === item.id);
    if (position >= 0) {
      return new_selected[position]["multi_select"];
    }
    return [];
  };

  const setMultiItems = (new_selected, item, array_data) => {
    const position = new_selected.findIndex((data) => data.id === item.id);
    new_selected[position]["multi_select"] = array_data;
    setSelectedItems(new_selected);
  };

  return (
    <div className="mt-2" style={style}>
      {/* <div>{dataset[label ?? "text"]}</div> */}
      <div className="mt-2">
        {dataset.map((item, key) => {
          return (
            <div className="mt-1" key={`resu-${key}-${item.id}`}>
              <Form.Check
                id={`resu-${uuidv4(4)}-${item.id}`}
                //name={`result-radiobuttons-questions-${dataset.id}`}
                type="checkbox"
                label={item[label ?? "text"]}
                value={item[value ?? "text"]}
                checked={
                  selectedItems.find((data) => data.id === item.id)
                    ? true
                    : false
                }
                onChange={(event) => {
                  let new_selected = [...selectedItems];
                  const position = new_selected.findIndex(
                    (data) => data.id === item.id
                  );
                  if (position >= 0) {
                    new_selected.splice(position, 1);
                  } else {
                    new_selected.push(item);
                  }

                  setSelectedItems([...new_selected]);
                }}
                custom
              />
              {selectedItems.find((data) => data.id === item.id) &&
                item.choices &&
                item.choices.length > 0 && (
                  <div
                    style={{
                      marginTop: "5px",
                      marginBottom: "10px",
                      paddingLeft: "20px",
                    }}
                  >
                    <NInputFilter
                      data={item.choices}
                      compositeLabel={["name"]}
                      selected={getSelectItems(selectedItems, item)}
                      renderMenuItem={customMenuItem}
                      isMultiple={true}
                      handleChange={(data) => {
                        setMultiItems([...selectedItems], item, data);
                      }}
                    />
                  </div>
                )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NCheckbox;
