import React, { useState } from "react";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { Formik } from "formik";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { RowItem } from "components/Nimbiv2/styled";
import { NButton } from "components/Nimbiv2";
import { updateInitiativeEndDate } from "services/initiativeService";
import { useDispatch } from "react-redux";
import * as InitiativeActions from "store/actions/initiative.actions";
import { toast } from "react-toastify";
import styled from "styled-components";

const FormInitiativeEndDate = ({ id, onClose }) => {
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const formValues = {};
  const { t } = useTranslation();
  const [date, setDate] = useState(null);

  const validateDate = (values) => {
    const errors = {};
    if (!values.end_date) {
      errors.end_date = t("required_selection");
    }
    return errors;
  };

  const onFormSubmit = async (value) => {
    setIsDisabled(true);
    const { status, data } = await updateInitiativeEndDate(id, value);
    if (status === 200) {
      toast.success(t("change_success"));
      dispatch(InitiativeActions.setInitiative(data));
    } else {
      toast.error(t("failed_update_date"));
    }
    onClose();
    setIsDisabled(false);
  };

  const showError = (fieldName, errors) => {
    return (
      <Form.Text className="text-danger" style={{ marginTop: "5px" }}>
        {errors[fieldName]}
      </Form.Text>
    );
  };
  return (
    <Formik
      initialValues={formValues}
      validate={validateDate}
      onSubmit={onFormSubmit}
    >
      {({
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="end_date">
            <ContainerDatePicker>
              <DatePicker
                style={{ border: "none", width: "100%" }}
                className="form-control w-100"
                dateFormat="yyyy-MM-dd"
                selected={date}
                minDate={new Date()}
                placeholderText={t("select_object", {
                  obj: t("date"),
                })}
                onChange={(date) => {
                  setDate(date);
                  if (date) {
                    setFieldValue(
                      "end_date",
                      moment(date).format("YYYY-MM-DD")
                    );
                  }
                }}
              />
            </ContainerDatePicker>
            {showError("end_date", errors)}
          </Form.Group>
          <RowItem>
            <Col className="d-flex justify-content-end">
              <NButton
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
                style={{
                  height: "100%",
                  marginLeft: "16px",
                }}
                loading={isDisabled}
              >
                {_.upperFirst(t("save"))}
              </NButton>
            </Col>
          </RowItem>
        </Form>
      )}
    </Formik>
  );
};
export default FormInitiativeEndDate;

const ContainerDatePicker = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;
