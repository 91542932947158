import { GET_ZENDESK, SET_ZENDESK } from "store/actions/integrations.actions";

const initialState = {
  zendesk: null,
  pending: {
    zendesk: false,
  },

  paginationZendesk: {
    count: 0,
    next: null,
    previous: null,
    page: 1,
    pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
  },
  singleLoads: {
    zendesk: false,
  },
};

const IntegrationReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_ZENDESK: {
      return {
        ...state,
        zendesk: null,
        pending: { ...state.pending, zendesk: true },
        paginationZendesk: {
          ...state.pagination,
          page: action.payload.page,
          pageSize: action.payload.page_size,
        },
      };
    }

    case SET_ZENDESK: {
      const { count, next, previous, results } = action.payload;
      let auxPagination = { ...state.paginationZendesk };
      auxPagination = { ...auxPagination, count, next, previous };
      return {
        ...state,
        zendesk: results,
        pending: { ...state.pending, zendesk: false },
        paginationZendesk: auxPagination,
      };
    }

    default:
      return { ...state };
  }
};

export default IntegrationReducers;
