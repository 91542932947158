import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Skeletons from "components/Skeletons";
import { APP_MODULES, setSessionFilters, setDataToLocalStorage } from "utils";
import {
  markMoment,
  setTabSelectTask,
  toggleClearFilter,
} from "store/actions/crm.actions";
import { fetchClientFilters } from "store/actions/async/site-async.action";
import {
  fetchMoments,
  getTaskList,
} from "store/actions/async/crm-async.action";
import DashboardItem from "./DashboardItem";

import MomentItem from "./MomentItem";
import styles from "./Task.module.css";

const MomentList = () => {
  const dispatch = useDispatch();

  const {
    isMomentPending,
    moments,
    pagination: { pageSize },
    singleLoads: { moments: singleMomentsLoaded },
  } = useSelector((state) => state.crm);

  const loadMoments = useCallback(() => {
    if (!singleMomentsLoaded) dispatch(fetchMoments());
  }, [dispatch, singleMomentsLoaded]);

  useEffect(() => {
    loadMoments();
  }, [loadMoments]);

  const applyMomentFilter = (momentId) => {
    setDataToLocalStorage("tabTaskSelected", { tab: "moments" });
    dispatch(setTabSelectTask("moments"));
    setDataToLocalStorage("avoidReloadTasks", true);
    dispatch(toggleClearFilter({ attr: "course", sw: false }));
    let currentFilters = null;
    let storedTaskFilters = localStorage.getItem(APP_MODULES.TASK.FILTER_NAME);
    if (storedTaskFilters) {
      storedTaskFilters = JSON.parse(storedTaskFilters);
      const { client_filters: clientFilters = null } = storedTaskFilters;
      currentFilters = clientFilters;
    }
    setSessionFilters(
      APP_MODULES.TASK.FILTER_NAME,
      {
        page: 1,
        page_size: Number(pageSize),
        moment: momentId,
        student_tracking__momenttracking__moment: momentId,
        student_tracking__momenttracking__state: "active",
        client_filters: currentFilters,
        source: "moment",
      },
      true
    );
    dispatch(markMoment(momentId));
    dispatch(fetchClientFilters({ moment_id: momentId, module: "tasks" }));
    dispatch(getTaskList());
  };

  if (isMomentPending) return <Skeletons.ThreeDots />;

  return (
    <div id="moments" className={styles.momentsContainer}>
      <DashboardItem />
      {moments.map(
        (m) =>
          m.active_student_tracking_count > 0 && (
            <MomentItem
              key={m.id}
              info={m}
              onFilterMoment={applyMomentFilter}
            />
          )
      )}
    </div>
  );
};

export default MomentList;
