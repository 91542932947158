import { setDataToLocalStorage, getDataFromLocalStorage } from "utils";

const defaultParams = {
  page: 1,
  page_size: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
};

export const getValueFilter = (key) => {
  const filters = getDataFromLocalStorage("filterv2", {});
  const merge_filters = { ...defaultParams, ...filters };
  if (merge_filters.hasOwnProperty(key)) {
    return merge_filters[key];
  }
  return merge_filters;
};

export const setValueFilter = (key, data) => {
  const filters = getDataFromLocalStorage("filterv2", {});
  filters[key] = { ...defaultParams, ...data };
  setDataToLocalStorage("filterv2", filters);
};
