import React from "react";
import styles from "./NBreadcrumb.module.css";
import { Link } from "react-router-dom";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnonymousContainer } from "components/Nimbiv2/styled";

const NBreadcrumb = ({ items, anonymous }) => {
  return (
    <div
      className={styles.breadcrumb}
      style={{ fontSize: "var(--font-size-md)" }}
    >
      {items.map((item, position) => {
        return (
          <span key={`breadcrumb${position}-key`}>
            {item.url && (
              <>
                <Link to={item.url}>{item.title}</Link>
                <FontAwesomeIcon
                  style={{
                    marginLeft: "14px",
                    marginRight: "14px",
                    fontSize: "10px",
                  }}
                  icon={faChevronRight}
                />
              </>
            )}
            {!item.url && (
              <span className={styles.reportTitle}>
                <AnonymousContainer
                  isAnonymous={anonymous}
                >{`${item.title}`}</AnonymousContainer>
              </span>
            )}
          </span>
        );
      })}
    </div>
  );
};

NBreadcrumb.defaultProps = {
  anonymous: false,
  items: [],
};

export default NBreadcrumb;
