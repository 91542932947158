import {
  NFInput,
  NEmpty,
  NFbool,
  NDatepicker,
  NSelectFetch,
  NSelectObject,
  NFRangeNumber
} from "../Components";
import NSimpleSearch from "components/NSimpleSearch/NSimpleSearch";
export const components = {
  input: NFInput,
  select: NSimpleSearch,
  empty: NEmpty,
  boolean: NFbool,
  date: NDatepicker,
  select_fetch: NSelectFetch,
  select_object: NSimpleSearch,
  selectObject: NSelectObject,
  date_context_clean: NSimpleSearch,
  range_number: NFRangeNumber,
};
