import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { NTable } from "components/Nimbiv2";
import { CardNimbi, RowItem } from "components/Nimbiv2/styled";
import { useTranslation } from "react-i18next";
import { useTranslationLocal } from "hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { fetchPeriods } from "store/actions/async/crm-async.action";
import { fetchProgressGrades } from "store/actions/async/student-async.actions";
import { getExtraColumns } from "utils";

const GradePage = ({ student_tracking_id }) => {
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const [selectOther, setSelectOther] = useState([]);
  const dispatch = useDispatch();
  const {
    grades,
    pending: { grades: isPending },
  } = useSelector((state) => state.student);
  const {
    periods,
    pending: { periods: isPendingPeriods },
  } = useSelector((state) => state.crm);

  const openProgress = useCallback(
    (item) => {
      let new_others = [...selectOther];
      const index = new_others.indexOf(item);
      if (index > -1) {
        const index = new_others.indexOf(item);
        if (index > -1) {
          new_others.splice(index, 1);
          setSelectOther(new_others);
        }
      } else {
        new_others.push(item);
        setSelectOther(new_others);
      }
    },
    [selectOther]
  );

  useEffect(() => {
    // call endpoint
    dispatch(fetchProgressGrades(student_tracking_id));
    dispatch(fetchPeriods({
      'student_tracking_id': student_tracking_id,
      'model': 'grades'
    }));
  }, [dispatch, student_tracking_id]);

  const callProgressGrades = (period) => {
    if (!isPendingPeriods) {
      if (period)
        dispatch(fetchProgressGrades(student_tracking_id, { periods: period }));
      else dispatch(fetchProgressGrades(student_tracking_id));
    }
  };

  const columns = [
    {
      title: _.upperFirst(t("modules")),
      key: "course_name",
      dataIndex: "course_name",
      width: "200",
      fixed: true,
      style: { left: 0 },
      render: (item, key) => (
        <div>
          <strong>{item}</strong>
        </div>
      ),
    },
    {
      title: _.upperFirst(t("average_grades")),
      key: "note",
      dataIndex: "average_grade",
      width: "200",
      style: { left: 0 },
      center: 1,
      contentCenter: true,
      render: (item, key) => <div>{item}</div>,
    },
    ...getExtraColumns("tableGrades", false),
    {
      title: _.upperFirst(translation("action", "", "Acción")),
      key: "icon",
      center: true,
      fixed: true,
      contentCenter: true,
      style: { right: "0px" },
      width: "80",
      render: (item, key, position) => {
        return (
          <button
            style={{ background: "transparent", border: "0" }}
            onClick={() => {
              openProgress(position);
            }}
          >
            <FontAwesomeIcon
              className="mr-2"
              icon={
                selectOther.includes(position) ? faChevronUp : faChevronDown
              }
              size="1x"
            />
          </button>
        );
      },
    },
  ];

  return (
    <div>
      {periods && periods.length > 0 && (
        <div>
          <ContainerSelect style={{ marginBottom: "16px" }}>
            <Form.Control
              as="select"
              onChange={(event) => {
                callProgressGrades(event.target.value);
              }}
            >
              <option value={""}>{t("select_period")}</option>
              {periods.map((item, key) => {
                return (
                  <option key={`${item.id}-${key}`} value={item.period}>
                    {item.period}
                  </option>
                );
              })}
            </Form.Control>
          </ContainerSelect>
        </div>
      )}
      <CardNimbi
        radius="8px"
        padding="23px 26px"
        style={{ height: "fit-content" }}
      >
        <NTable
          columns={columns}
          dataSource={grades?.progress ?? []}
          fixed={true}
          isPending={isPending}
          collapse={true}
          positionOpenCollapse={selectOther}
          textEmpty="No hay notas para mostrar"
          widgetCollapse={(item, keyRow) => {
            return (
              <div>
                <RowItem>
                  {item.grades &&
                    item.grades.map((item, key) => {
                      return (
                        <Col md="3" key={`item-grades-${keyRow}-${key}`}>
                          <div className="d-flex">
                            <div
                              style={{ fontWeight: "500", marginRight: "5px" }}
                            >
                              {item.text}:
                            </div>

                            {item.value}
                          </div>
                        </Col>
                      );
                    })}
                </RowItem>
              </div>
            );
          }}
        ></NTable>
      </CardNimbi>
    </div>
  );
};

export default GradePage;
const ContainerSelect = styled.div`
  .form-control {
    background: transparent;
    padding: 14px 12px;
    height: auto;
    width: auto;
  }
`;
