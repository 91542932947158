import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/pro-regular-svg-icons";
import { Container, Row, Col, Pagination } from "components";

const NPagination = ({ isPending, pagination, onPageChange }) => {
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const isEmptyList =
      !pagination.previous && !pagination.next && pagination.count === 0;

    if (isEmptyList) {
    } else {
      const pageCount = Math.ceil(pagination.count / pagination.pageSize);
      setTotalPages(pageCount);
    }

    if (pagination.page === 1 && pagination.count > 0) {
      //
    }
  }, [pagination, isPending]);

  const loadPage = (pageNumber, pageSize) => {
    if (Number(pagination.page) !== Number(pageNumber))
      onPageChange(pageNumber, pageSize);
  };

  return (
    <Container className="pr-0">
      <Row className="mr-0">
        <Col className="d-flex align-items-center justify-content-center pr-0">
          <Pagination style={{ margin: 0 }}>
            <PaginationButton
              onClick={() => loadPage(pagination.page - 1, pagination.pageSize)}
              disabled={pagination.page === 1 || isPending}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </PaginationButton>
            {[...Array(Math.min(totalPages + 2, 5)).keys()]
              .map((i) => pagination.page + i - 2)
              .filter((p) => p > 0 && p <= totalPages)
              .map((page) => {
                return (
                  <PaginationItem
                    key={page}
                    active={page === pagination.page}
                    onClick={() => loadPage(page, pagination.pageSize)}
                    disabled={isPending}
                  >
                    {page}
                  </PaginationItem>
                );
              })}
            <PaginationButton
              onClick={() => loadPage(pagination.page + 1, pagination.pageSize)}
              disabled={pagination.page === totalPages || isPending}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </PaginationButton>
          </Pagination>
        </Col>
      </Row>
    </Container>
  );
};

NPagination.propTypes = {
  isPending: PropTypes.bool,
  pagination: PropTypes.shape({
    count: PropTypes.number,
    next: PropTypes.string || null,
    previous: PropTypes.string || null,
    page: PropTypes.number,
    pageSize: PropTypes.number,
  }).isRequired,
};

NPagination.defaultProps = {
  isPending: false,
};

export default NPagination;

const PaginationItem = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  ${(props) => (props.active ? "background: #dde3ec !important;" : "")}
`;

const PaginationButton = styled.div`
  height: 40px;
  width: 40px;
  background: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${(props) =>
    props.disabled
      ? ` pointer-events: none;
  opacity: 0.4;`
      : ""}
`;
