/* eslint-disable no-console */
const handleError = (errorBody) => {
  const { data, status, statusText } = errorBody;
  let content = new Map([["status", 0]]);

  if (data) {
    const errorTitle =
      status === 500 ? "Unexpected Error" : Object.keys(data)[0];
    const requestErrorText = Array.isArray(data[errorTitle])
      ? data[errorTitle][0]
      : data[errorTitle];
    const errorMessage = status === 500 ? statusText : requestErrorText;
    content = new Map([
      ["status", status],
      ["title", errorTitle],
      ["message", errorMessage],
    ]);
  }

  return content;
};

const showErrorNotification = (errorData) => {
  const options = {
    title: errorData !== null ? errorData.title : "Network Error",
    message: errorData !== null ? errorData.message : "ERR_CONNECTION_REFUSED",
    type: "error",
    position: "top-right",
  };

  console.error(options);
  // toast.open(toastOptions);
};

const handleErrorStatus = (status) => {
  switch (status) {
    case 400:
      break;

    case 401:
    case 403:
      // session expired or permission denied
      localStorage.clear();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      break;

    case 404:
      console.error("Page does not exists");
      break;

    case 500:
    case 502:
      // unexpected exception
      break;

    default:
      break;
  }
};

export { handleError, showErrorNotification, handleErrorStatus };
