import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NIcon, NButton } from "components/Nimbiv2";
import { CardNimbi, DividerH, RowItem } from "components/Nimbiv2/styled";
import { Col } from "react-bootstrap";
import { StepTitle, TextTitle } from "components/Nimbiv2/InitiativesComponent";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { NBadge } from "components";
import { read, utils } from "xlsx";
import { toast } from "react-toastify";
import { ButtonSize } from "utils/constants";
import { useTranslationLocal } from "hooks";
import { getGlobalConfig } from "utils";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const PageFourthFile = ({
  onChange,
  pageNumber,
  onChangeForm,
  formValues,
  errors,
}) => {
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const client_area_max_external_students = useSelector(
    (state) => state.auth.user.client_area_max_external_students
  );

  const onDrop = (listFiles) => {
    if (listFiles.length > 0) {
      var reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = read(bstr, { type: rABS ? "binary" : "array" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        var excelRows = utils.sheet_to_row_object_array(ws);
        var number = excelRows.length;
        if (number > 0 && number <= client_area_max_external_students) {
          onChangeForm("base_file", listFiles[0]);
        } else {
          if (number === 0) toast.error("El archivo no puede estar vacío");
          if (number > client_area_max_external_students)
            toast.error(
              t("more_max_students", {
                max_students: client_area_max_external_students.toString(),
                students: translation("students", "", t("students")),
              })
            );
        }
      };
      if (rABS) reader.readAsBinaryString(listFiles[0]);
      else reader.readAsArrayBuffer(listFiles[0]);
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    onDrop: onDrop,
    maxSize: 5242880,
    accept: {
      "application/vnd.ms-excel": [".xlsx"],
    },
  });

  return (
    <div style={{ maxWidth: "922px", margin: "auto", paddingBottom: "30px" }}>
      <CardNimbi padding="0px">
        <div style={{ padding: "33px 35px 33px 35px" }}>
          <RowItem>
            <Col xs={8} style={{ padding: "0px" }}>
              <StepTitle>
                {pageNumber ? pageNumber : t("step_number", { number: "3" })}
              </StepTitle>
              <div style={{ marginTop: "18px" }}>
                <TextTitle>
                  {t("load_external_student", {
                    students: translation("students", "", t("students")),
                  })}
                </TextTitle>
              </div>
              <DividerH style={{ marginTop: "30px" }} />
            </Col>
          </RowItem>
          <div
            style={{
              marginTop: "14px",
              fontSize: "var(--font-size-lg)",
              fontWeight: "500",
            }}
          >
            {t("use_following_format")}{" "}
            <a
              href={
                getGlobalConfig("initiative_format_file", null)
                  ? getGlobalConfig("initiative_format_file", "#")
                  : "https://nimbi-demanda.s3.us-east-2.amazonaws.com/template_iniciativa_v2.xlsx"
              }
              style={{ color: "var(--call-in-action-second)" }}
            >
              {t("download_format")}
            </a>
          </div>
          <div style={{ marginTop: "20px" }}>
            <div className="container">
              <ContainerDropZone
                {...getRootProps({ className: "dropzone" })}
                style={{ padding: formValues.base_file ? "0px" : "40px" }}
              >
                {formValues.base_file && (
                  <div style={{ width: "100%" }}>
                    <div style={{ padding: "20px 30px 15px 30px" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {formValues.base_file.path}
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          style={{ color: "#E23232" }}
                          cursor="pointer"
                          onClick={() => {
                            onChangeForm("base_file", null);
                          }}
                        />
                      </div>
                    </div>
                    <DividerH />
                    <div style={{ padding: "50px 30px 70px 30px" }}>
                      <div
                        style={{
                          color: "#00B098",
                          fontSize: "var(--font-size-lg)",
                          lineHeight: "140%",
                        }}
                      >
                        Archivo cargado con éxito
                      </div>
                      <div>
                        <div
                          style={{
                            background: "#00B098",
                            height: "5px",
                            borderRadius: "50px",
                            marginTop: "4px",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                )}
                {!formValues.base_file && (
                  <>
                    <input {...getInputProps()} />
                    <p style={{ marginBottom: "4px", fontSize: "18px" }}>
                      Arrastra tu excel aquí
                    </p>
                    <p
                      style={{
                        marginBottom: "4px",
                        fontSize: "18px",
                      }}
                    >
                      ó
                    </p>
                    <NButton
                      sizeStyle={ButtonSize.lg}
                      type="button"
                      onClick={open}
                      icon={<FontAwesomeIcon icon={faSearch} />}
                    >
                      Selecciona en tu ordenador
                    </NButton>
                  </>
                )}
              </ContainerDropZone>
              <div style={{ marginTop: "24px" }}>
                <NBadge
                  text={t("initiative_max_rows_file", {
                    max_students: client_area_max_external_students.toString(),
                    students: translation("students", "", t("students")),
                  })}
                  customStyle={{
                    //background: "#EDF1F7",
                    background: "#ECF2FF",
                    color: "#334FFF",
                    fontSize: "10px",
                    padding: "6px",
                    borderRadius: "64px",
                    textTransform: "none",
                  }}
                />
              </div>

              {errors.hasOwnProperty("base_file") && (
                <div style={{ marginTop: "15px", color: "#E23232" }}>
                  * Falta cargar el archivo, es requerido
                  <NIcon
                    iconname="exclamationCircle"
                    style={{ marginLeft: "4px" }}
                  ></NIcon>
                </div>
              )}
            </div>
          </div>
        </div>
      </CardNimbi>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div>
          <NButton
            sizeStyle={ButtonSize.lg}
            style={{
              marginTop: "24px",
              borderRadius: "24px",
              marginRight: "16px",
            }}
            onClick={() => {
              onChange("last");
            }}
            outlined
            icon={<NIcon iconname="arrowLeft" />}
            color="second"
          >
            {t("go_back")}
          </NButton>
          <NButton
            sizeStyle={ButtonSize.lg}
            style={{
              marginTop: "24px",
              borderRadius: "24px",
              textTransform: "capitalize",
            }}
            onClick={() => {
              onChange("next");
            }}
            icon={<NIcon iconname="arrowRight" />}
            positionIcon="right"
            color="second"
          >
            {t("continue")}
          </NButton>
        </div>
      </div>
    </div>
  );
};

export default PageFourthFile;

const ContainerDropZone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 40px;
  border-width: 2px;
  border-radius: 10px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  // background-color: #fafafa;
  color: black;
  font-size: var(--font-size-lg);
  outline: none;
  transition: border 0.24s ease-in-out;
`;
