import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import copy from "copy-to-clipboard";
import { ButtonCall } from "./detail.styled";
import { AnonymousContainer } from "components/Nimbiv2/styled";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faClone } from "@fortawesome/pro-regular-svg-icons";
import { toast } from "react-toastify";
import styles from "../Task.module.css";
import { callNeotel } from "services/taskService";
import { useTranslation } from "react-i18next";

const InfoButtonCall = ({ task, isPending }) => {
  const { t } = useTranslation();
  const [selectNumber, setSelectNumber] = useState(null);
  const [selectNumberID, setSelectNumberID] = useState(null);
  const { user } = useSelector((state) => state.auth);

  useState(() => {
    if (task && task.student_tracking && task.student_tracking.phones) {
      if (task.student_tracking.phones.length > 0) {
        setSelectNumber(task.student_tracking.phones[0].value);
        setSelectNumberID(task.student_tracking.phones[0].id);
      }
    }
  }, [task]);

  const hasPhoneNumbers = useMemo(() => {
    return (
      task.student_tracking &&
      task.student_tracking.phones &&
      task.student_tracking.phones.length > 1
    );
  }, [task.student_tracking]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      style={{ cursor: "pointer", padding: "10px 22px 10px 0px" }}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <FontAwesomeIcon icon={faCaretDown} />
    </div>
  ));

  const copyPhoneNumber = (phoneNumber) => {
    copy(`${phoneNumber}`);
    toast.info(`Copiado: ${phoneNumber}`);
  };

  const callEndpointNeotel = useCallback(async () => {
    if (
      !(
        user.neotel_provider &&
        user.neotel_provider.user_neotel &&
        user.neotel_provider.client_area_neotel
      )
    ) {
      toast.error(t("user_config_neotel"));
    } else {
      const { status } = await callNeotel(selectNumber);
      if (status === 200) {
        toast.success(t("neotel_success"));
      } else if (status === 401) {
        toast.error(t("neotel_not_configuration"));
      } else if (status === 403) {
        toast.error(t("error_triggering"));
      } else {
        toast.error(t("error_action"));
      }
    }
  }, [selectNumber, t, user]);

  return (
    <div>
      <div style={{ marginTop: "20px", marginBottom: "23px" }}>
        <AnonymousContainer isAnonymous={user.anonymous}>
          <div
            className="d-flex align-items-center justify-content-end"
            style={{ paddingRight: "10px" }}
          >
            <ButtonCall>
              <p
                style={{
                  margin: "0px",
                  padding: `11px ${
                    hasPhoneNumbers ? "10px" : "22px"
                  } 11px 22px`,
                  fontSize: "var(--font-size-lg)",
                }}
              >
                {!(
                  user &&
                  user.neotel_provider &&
                  (user.neotel_provider.user_neotel ||
                    user.neotel_provider.client_area_neotel)
                ) && (
                  <a style={{ color: "white" }} href={`tel:${selectNumber}`}>
                    {selectNumber}
                  </a>
                )}
                {user &&
                  user.neotel_provider &&
                  (user.neotel_provider.user_neotel ||
                    user.neotel_provider.client_area_neotel) && (
                    <span
                      style={{ color: "white", cursor: "pointer" }}
                      onClick={callEndpointNeotel}
                    >
                      {selectNumber}
                    </span>
                  )}
              </p>

              {task.student_tracking &&
                task.student_tracking.phones &&
                task.student_tracking.phones.length > 1 && (
                  <Dropdown
                    style={{
                      marginLeft: "8px",
                    }}
                  >
                    <Dropdown.Toggle
                      as={CustomToggle}
                      id="dropdown-custom-components"
                    />

                    <Dropdown.Menu>
                      {task.student_tracking.phones.map((item) => (
                        <Dropdown.Item
                          key={`items-numbers-${item.id}`}
                          active={item.id === selectNumberID}
                          onClick={() => {
                            setSelectNumber(item.value);
                            setSelectNumberID(item.id);
                          }}
                        >
                          {item.value}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
            </ButtonCall>
            <OverlayTrigger overlay={<Tooltip>{t("copy_phone")}</Tooltip>}>
              <FontAwesomeIcon
                onClick={() => copyPhoneNumber(selectNumber)}
                className={`${styles.copyPhoneNumber} ml-2`}
                icon={faClone}
                style={{ color: "var(--call-in-action)", fontSize: "20px" }}
              />
            </OverlayTrigger>
          </div>
        </AnonymousContainer>
      </div>
    </div>
  );
};

export default InfoButtonCall;
