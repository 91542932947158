import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col } from "react-bootstrap";

import { TaskHistory } from "components/Skeletons";
import CardDashboard from "components/Nimbiv2/CardDashboard";

const CardDashboardFetch = ({ fnFetchData, item, pending, CustomCard }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fnFetchData());
  }, [fnFetchData, dispatch]);

  const mediumCardSize = item
    ? item.insight_type === "student_risks"
      ? "12"
      : "6"
    : "6";

  const largeCardSize = item
    ? item.insight_type === "student_risks"
      ? "6"
      : "3"
    : "3";

  return (
    <>
      {!pending && item && (
        <Col
          xl={largeCardSize}
          lg={mediumCardSize}
          md={mediumCardSize}
          style={{ marginTop: "10px" }}
        >
          {!CustomCard && <CardDashboard data={item} />}
          {CustomCard && <CustomCard data={item} />}
        </Col>
      )}
      {pending && (
        <Col xl={"3"} lg="6" md="6" style={{ marginTop: "10px" }}>
          <TaskHistory width={400} height={251} />
        </Col>
      )}
    </>
  );
};
export default CardDashboardFetch;
