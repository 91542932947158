import moment from "moment";
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import styles from "./NDatepicker.module.css";
import "react-datepicker/dist/react-datepicker.css";
import { replaceChars, capitalizeWord } from "utils";
import { useTranslation } from "react-i18next";
import ContainerInputFilter from "./ContainerInputFilter";
import { VERSIONS_FILTERS_DESIGN } from "utils/constants";
const NDatepicker = ({
  dataset,
  onChange,
  initialValue,
  isDisabled = false,
  versionInput,
}) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);

  const [listDate, setListDate] = useState([]);
  useEffect(() => {
    if (
      dataset.subtype_component === "date_range" ||
      dataset.subtype_component === "date_range_future"
    ) {
      if (initialValue) {
        let dates = initialValue.split("++");
        setDateRange([
          new Date(dates[0] + "T00:00:00"),
          new Date(dates[1] + "T00:00:00"),
        ]);
      } else {
        setDateRange([null, null]);
      }
    } else {
      setStartDate(
        initialValue
          ? initialValue.includes("T00:00:00")
            ? new Date(initialValue)
            : new Date(initialValue + "T00:00:00")
          : null
      );
    }
  }, [initialValue, dataset.subtype_component]);
  useEffect(() => {
    const dates = dataset.values.map((item) =>
      moment(item).format("YYYY-MM-DD")
    );
    setListDate(dates);
  }, [dataset]);

  const dataIsValid = (date) => {
    const dateformat = moment(date).format("YYYY-MM-DD");
    return listDate.includes(dateformat);
  };
  const onChangeDate = (date) => {
    const filter_field = dataset["filter_field"];
    if (date) {
      const dateformat = moment(date).format("YYYY-MM-DD");
      if (dataset.subtype_component === "date_context_clean") {
        onChange(filter_field, dateformat, dataset);
      } else {
        onChange(filter_field, dateformat + "T00:00:00", dataset);
      }
      setStartDate(date);
    } else {
      setStartDate(null);
      onChange(filter_field, null, dataset);
    }
  };

  const onChangeDateRange = (date) => {
    setDateRange(date);
    const filter_field = dataset["filter_field"];
    if (date[0] === null && date[1] === null) {
      setStartDate(null);
      onChange(filter_field, null, dataset);
    } else {
      if (date[0] !== null && date[1] !== null) {
        const initDateFormat = moment(date[0]).format("YYYY-MM-DD");
        const endDateFormat = moment(date[1]).format("YYYY-MM-DD");
        onChange(filter_field, initDateFormat + "++" + endDateFormat, dataset);
      }
    }
  };

  const [startDatex, endDate] = dateRange;
  return (
    <ContainerInputFilter
      label={capitalizeWord(replaceChars(dataset.label, "_", " "))}
      versionInput={versionInput}
    >
      {(dataset.subtype_component === null ||
        dataset.subtype_component === "date_context_clean") && (
        <DatePicker
          dateFormat="yyyy-MM-dd"
          className="form-control"
          selected={startDate}
          placeholderText={
            dataset ? capitalizeWord(replaceChars(dataset.label, "_", " ")) : ""
          }
          onChange={(date) => onChangeDate(date)}
          dayClassName={(date) =>
            dataIsValid(date) ? styles.colorDate : undefined
          }
          disabled={isDisabled}
          isClearable
          disabledKeyboardNavigation
        />
      )}
      {(dataset.subtype_component === "date_range" ||
        dataset.subtype_component === "date_range_future") && (
        <DatePicker
          selected={null}
          selectsRange={true}
          startDate={startDatex}
          endDate={endDate}
          dateFormat="yyyy-MM-dd"
          className="form-control"
          placeholderText={
            versionInput === VERSIONS_FILTERS_DESIGN.v2
              ? t("all")
              : dataset
              ? capitalizeWord(replaceChars(dataset.label, "_", " "))
              : ""
          }
          onChange={(date) => onChangeDateRange(date)}
          dayClassName={(date) =>
            dataIsValid(date) ? styles.colorDate : undefined
          }
          disabled={isDisabled}
          maxDate={
            dataset.subtype_component === "date_range" ? new Date() : null
          }
          // minDate={
          //   dataset.subtype_component === "date_range_future"
          //     ? new Date()
          //     : null
          // }
          isClearable
          disabledKeyboardNavigation
        />
      )}
    </ContainerInputFilter>
  );
};

export default NDatepicker;
