import React from "react";
import { Image, Row, Col } from "components";

import { reportSent } from "assets/images";

import styles from "./Report.module.css";

const imageRules = { height: 160 };

const ReportSent = () => {
  return (
    <Row className={`${styles.fadeIn}`}>
      <Col>
        <Image style={imageRules} src={reportSent} rounded />
      </Col>
      <Col className="d-flex flex-column justify-content-center align-items-center">
        Muchas gracias!! tu reporte ha sido enviado...
      </Col>
    </Row>
  );
};

export default ReportSent;
