import styled from "styled-components";
import { Button } from "react-bootstrap";
const ButtonTab = styled(Button)`
  ${(props) => (props.size ? `font-size: ${props.size}` : "")};
  background-color: var(--call-in-action);
  color: white;
  border-color: var(--call-in-action);
  border-radius: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  min-height: 40px;
  &:hover {
    background: var(--color-btn-hover) !important;
    border-color: transparent !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem ${(props) => props.hoverbuttom} !important;
  }
`;
ButtonTab.defaultProps = {
  hoverbuttom: "var(--color-btn-hover)",
};
export default ButtonTab;
