import React, { useEffect, useState } from "react";
import _ from "lodash";
import { CardNimbi } from "components/Nimbiv2/styled";
import { ContainerTitle, TitleCard } from "./styles";
import { StepTitle } from "components/Nimbiv2/InitiativesComponent";
import { NIcon } from "components/Nimbiv2";
import { validateStep3File } from "../validations";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/pro-regular-svg-icons";
import { read, utils } from "xlsx";
import { useTranslationLocal } from "hooks";

const CardFileInitiativeResume = ({
  formValues,
  edit,
  isExternal,
  onChangePosition,
  filtersApplied,
  paginationTracking,
}) => {
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const [countRows, setRowCounts] = useState(0);
  // const [headers, setHeaders] = useState([]);
  // const [data, setData] = useState([]);
  // const [showModal, setShowModal] = useState(false);

  const getCountRows = (file) => {
    var reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = read(bstr, { type: rABS ? "binary" : "array" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      var excelRows = utils.sheet_to_row_object_array(ws);
      var number = excelRows.length;
      setRowCounts(number);
      // if (number > 0) {
      //   const renderColumns = Object.keys(excelRows[0]).map((item, key) => {
      //     return {
      //       title: item,
      //       dataIndex: item,
      //       key: item,
      //       //render: (text, item) => <div>{text}</div>,
      //     };
      //   });
      //   setHeaders(renderColumns);
      //   setData(excelRows);
      // }
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (!edit) {
      getCountRows(formValues.base_file);
    }
  }, [formValues.base_file, edit]);
  return (
    <CardNimbi
      padding={"17px 35px 17px 18px"}
      bordercolor={
        !edit
          ? validateStep3File(formValues)
            ? "#E23232"
            : "#3AC8AC"
          : "#3AC8AC"
      }
    >
      <ContainerTitle>
        <TitleCard>
          {_.upperFirst(translation("students", "", t("students")))}
        </TitleCard>
        <div style={{ display: "flex" }}>
          <StepTitle>
            {t("step_number", { number: !edit ? "3" : "3" })}
          </StepTitle>
          <NIcon
            iconname="edit"
            style={{
              marginLeft: "18px",
              fontSize: "var(--font-size-lg)",
              cursor: "pointer",
            }}
            onClick={onChangePosition}
          />
        </div>
      </ContainerTitle>
      <strong>Base de datos</strong>
      <div
        style={{
          marginTop: "5px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <FontAwesomeIcon
          icon={faFileExcel}
          style={{
            fontSize: "15px",
            marginRight: "8px",
            color: "#3AC8AC",
          }}
        />
        {formValues.file_url ? (
          <a
            style={{ color: "rgb(51, 79, 255)" }}
            href={formValues.file_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("download_file")}
          </a>
        ) : (
          <>{formValues.base_file.path}</>
        )}
        {/* {formValues.base_file.path} */}
      </div>
      {!edit && (
        <>
          <strong style={{ marginTop: "24px" }}>
            {_.upperFirst(
              t("selected_students", {
                students: translation("students", "", t("students")),
              })
            )}
          </strong>
          <div style={{ marginTop: "5px" }}>
            <NIcon iconname="mail" style={{ marginRight: "8px" }} /> {countRows}{" "}
            {translation("students", "", t("students"))}
          </div>
        </>
      )}
    </CardNimbi>
  );
};
export default CardFileInitiativeResume;
