export const SEARCH = {
  STUDENT: {
    FILTER_KEY: "external_id",
    FIELD_NAME: "external_id",
  },
  TASK: {
    FILTER_KEY: "student_tracking__student__external_id",
    FIELD_NAME: "external_id",
  },
  USER: {
    FILTER_KEY: "username",
    FIELD_NAME: "username",
  },
};

export const TABKEY = {
  SEARCH: "search",
  FILTER: "filter",
};
