import { generateUrlWithParams } from "utils";
import api from "./api";

const getZendesk = (studentId, queryParams) => {
  const finalUrl = generateUrlWithParams(
    `third_party/zendesk/${studentId}/tickets/`,
    queryParams
  );
  return api.get(finalUrl);
};

export { getZendesk };
