import React, { useMemo } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Col, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faExclamationTriangle,
  faGraduationCap,
  faPhone,
} from "@fortawesome/pro-regular-svg-icons";

import {
  AnonymousContainer,
  CardNimbi,
  DividerH,
  IconContainer,
  RowItem,
} from "components/Nimbiv2/styled";
import { NBadge } from "components";
import { applyMomentFilter } from "utils/moments";
import { BadgeCardB } from "components/Nimbiv2/CardDashboard/CardDashboard.styled";
import { default as momentFormat } from "moment";
import { NButton } from "components/Nimbiv2";
import { colorsBadge } from "utils/constants";
import { useTranslationLocal } from "hooks";

const PageInformation = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const { user } = useSelector((state) => state.auth);
  const {
    moment,
    pending: { moment: isPending },
  } = useSelector((state) => state.moment);

  const actionPlanStatus = useMemo(() => {
    return moment?.active_student_tracking_count === 0
      ? {
          label: _.upperFirst(t("inactive")),
          color: colorsBadge.light.inactive,
        }
      : { label: _.upperFirst(t("active")), color: colorsBadge.light.success };
  }, [moment, t]);

  if (isPending || moment === null) {
    return (
      <Spinner
        as="span"
        animation="border"
        size="lg"
        role="status"
        aria-hidden="true"
      />
    );
  }

  return (
    <RowItem>
      <Col lg="8" md="6" className="px-0 pr-md-2 mb-1 mb-md-0">
        <CardNimbi padding="32px 24px">
          <strong style={{ fontSize: "var(--font-size-lg)" }}>
            {_.upperFirst(t("description"))}
          </strong>
          <div style={{ marginTop: "16px", fontSize: "var(--font-size-md)" }}>
            {moment.description}
          </div>

          <strong
            style={{ marginTop: "32px", fontSize: "var(--font-size-lg)" }}
          >
            {_.upperFirst(t("conditions"))}
          </strong>

          {moment && moment.condition_detail && (
            <div
              style={{ marginTop: "17px" }}
              dangerouslySetInnerHTML={{ __html: moment.condition_detail }}
            />
          )}
          {moment && !moment.condition_detail && (
            <div style={{ marginTop: "17px" }}>
              {_.upperFirst(t("no_information"))}
            </div>
          )}
        </CardNimbi>
      </Col>
      <Col lg="4" md="6" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
        <CardNimbi padding="32px 24px">
          <div className="d-flex">
            <div className="mr-auto"></div>
            <NBadge
              text={actionPlanStatus.label}
              newColor={actionPlanStatus.color}
              customStyle={{
                borderRadius: "64px",
              }}
            />
          </div>
          <strong style={{ fontSize: "var(--font-size-md)", color: "#989DA5" }}>
            <AnonymousContainer isAnonymous={user.anonymous}>
              {moment.name}
            </AnonymousContainer>
          </strong>
          <strong style={{ fontSize: "26px", marginTop: "4px" }}>
            <AnonymousContainer isAnonymous={user.anonymous} radius="6px">
              {moment.name}
            </AnonymousContainer>
          </strong>
          <div style={{ marginTop: "24px" }}>
            <div style={{ display: "flex" }}>
              <IconContainer>
                <FontAwesomeIcon icon={faGraduationCap} />
              </IconContainer>

              <div style={{ marginLeft: "14px" }}>
                <strong style={{ marginTop: "16px" }}>
                  {t("Number of students", {
                    students: translation("students", "", t("students")),
                  })}
                </strong>
                <div
                  style={{
                    marginTop: "4px",
                  }}
                >
                  {moment.active_student_tracking_count}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", marginTop: "24px" }}>
              <IconContainer>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </IconContainer>
              <div style={{ marginLeft: "14px" }}>
                <strong style={{ marginTop: "16px" }}>
                  {_.upperFirst(t("duration"))}
                </strong>
                {moment.range_type === "relative" && (
                  <div style={{ marginTop: "4px" }}>
                    {`${moment.start_range * 100} - ${moment.end_range * 100}`}%
                  </div>
                )}
                {moment.range_type === "fixed_date" && (
                  <div style={{ marginTop: "4px" }}>
                    {`${momentFormat(moment.start_date).format(
                      "DD/MM/YYYY"
                    )} - ${momentFormat(moment.end_date).format("DD/MM/YYYY")}`}
                  </div>
                )}
              </div>
            </div>
            <div style={{ display: "flex", marginTop: "24px" }}>
              <IconContainer>
                <FontAwesomeIcon icon={faPhone} />
              </IconContainer>

              <div style={{ marginLeft: "14px" }}>
                <strong
                  style={{
                    marginTop: "16px",
                    flexWrap: "wrap",
                  }}
                >
                  {_.upperFirst(
                    translation("pending_calls", "", t("Pending calls"))
                  )}
                </strong>
                <div
                  style={{
                    color: "#334FFF",
                    marginTop: "4px",
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {moment.call_active_tasks}
                  {moment && moment.task_expired_today > 0 && (
                    <BadgeCardB
                      className="font-size-caption"
                      error={true}
                      style={{
                        flexDirection: "row",
                        maxWidth: "initial",
                        fontWeight: "400",
                      }}
                    >
                      <FontAwesomeIcon
                        className="mr-1"
                        icon={faExclamationTriangle}
                      />
                      <strong
                        className="font-size-caption"
                        style={{ marginRight: "3px" }}
                      >
                        {moment.task_expired_today}
                      </strong>{" "}
                      llamados expiran hoy
                    </BadgeCardB>
                  )}
                </div>
                <div
                  style={{
                    color: "#334FFF",
                    fontWeight: "bold",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  {moment && moment.task_expired_one_day > 0 && (
                    <>
                      <div style={{ opacity: "0" }}>
                        {moment.call_active_tasks}
                      </div>
                      <BadgeCardB
                        style={{
                          flexDirection: "row",
                          maxWidth: "initial",
                          fontWeight: "400",
                          marginTop: "4px",
                          justifyContent: "center",
                        }}
                        className="font-size-caption"
                        background="#FFF2E7"
                        colorText="#CC9345"
                        colorTextBold="#CC660A"
                      >
                        <FontAwesomeIcon
                          className="mr-1"
                          icon={faExclamationTriangle}
                        />
                        <strong
                          className="font-size-caption"
                          style={{ marginRight: "3px" }}
                        >
                          {moment.task_expired_one_day}
                        </strong>{" "}
                        llamados expiran en 1 día
                      </BadgeCardB>
                    </>
                  )}
                </div>
                <div
                  style={{
                    color: "#334FFF",
                    fontWeight: "bold",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  {moment && moment.task_expired_two_day > 0 && (
                    <>
                      <div style={{ opacity: "0" }}>
                        {moment.call_active_tasks}
                      </div>
                      <BadgeCardB
                        className="font-size-caption"
                        style={{
                          flexDirection: "row",
                          maxWidth: "initial",
                          fontWeight: "400",
                          marginTop: "4px",
                          justifyContent: "center",
                        }}
                        background="rgb(253, 248, 227)"
                        colorText="#E0BC0B"
                        colorTextBold="rgb(235, 196, 25)"
                      >
                        <FontAwesomeIcon
                          className="mr-1"
                          icon={faExclamationTriangle}
                        />
                        <strong
                          className="font-size-caption"
                          style={{ marginRight: "3px" }}
                        >
                          {moment.task_expired_two_day}
                        </strong>{" "}
                        llamados expiran en 2 día
                      </BadgeCardB>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {moment && moment?.count_workflow_call > 0 && (
            <DividerH style={{ marginTop: "32px", marginBottom: "32px" }} />
          )}
          {moment && moment?.count_workflow_call > 0 && (
            <NButton
              padding="10px 15px"
              size="14px"
              disabled={moment.active_student_tracking_count === 0}
              onClick={() => {
                applyMomentFilter(dispatch, moment.id, 10, false, true);
                history.push("/tareas");
              }}
              color="second"
            >
              {t("Go to tasks")}
            </NButton>
          )}
        </CardNimbi>
      </Col>
    </RowItem>
  );
};

export default PageInformation;
