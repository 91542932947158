import React from "react";
import { useDispatch } from "react-redux";
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileInvoice,
  faClone,
  faPhoneAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { setRowInfo } from "store/actions/crm.actions";
import { toggleSidebar } from "store/actions/site.actions";
import copy from "copy-to-clipboard";
import styles from "./Dashboard.module.css";

const CallingActionHeader = ({ task }) => {
  const dispatch = useDispatch();

  const copyPhoneNumber = (phoneNumber) => {
    copy(`+${phoneNumber}`);
  };

  const openScriptSidebar = () => {
    if (!task.script_template) return false;
    dispatch(setRowInfo(task));
    dispatch(toggleSidebar(true, "isScriptSidebarOpen"));
  };

  return (
    <>
      <Col className="d-flex justify-content-center align-items-center" xs={4}>
        <FontAwesomeIcon className="mr-2" size="1x" icon={faPhoneAlt} />
        <span className={styles.callActionTitle}>Llamada</span>
      </Col>
      <Col
        className="d-flex justify-content-center align-items-center m-0 p-0"
        xs={8}
      >
        <div className="mr-4">
          <p
            className={styles.copyPhoneNumber}
            onClick={openScriptSidebar}
            aria-hidden="true"
          >
            Pauta de llamado
            <FontAwesomeIcon className="ml-2" size="1x" icon={faFileInvoice} />
          </p>
        </div>
        <div className={styles.phoneContactBlock}>
          <p>
            <a
              className="secondary_color"
              href={`tel:+${task.student_tracking.phone}`}
            >
              {task.student_tracking.phone}
            </a>
          </p>
          <a
            className="secondary_color"
            href={`callto:+${task.student_tracking.phone}`}
          >
            <FontAwesomeIcon className="ml-2" size="1x" icon={faPhoneAlt} />
          </a>
          <FontAwesomeIcon
            onClick={() => copyPhoneNumber(task.student_tracking.phone)}
            className={`ml-2 ${styles.copyPhoneNumber}`}
            size="1x"
            icon={faClone}
          />
        </div>
      </Col>
    </>
  );
};

export default CallingActionHeader;
