import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import ToggleHeader from "components/Nimbiv2/NAccordion/ToggleHeader";
import ModalContactInfo from "./Modals/ModalContactInfo";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch, useSelector } from "react-redux";
import {
  AnonymousContainer,
  CardNimbi,
  DividerH,
} from "components/Nimbiv2/styled";
import { Card, Col, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import {
  Title,
  NameTitle,
  AccordionDesign,
  CardBody,
  ContainerItem,
  ZendeskTicket,
  ZendeskDetail,
  ZendeskItem,
  ContainerTags,
} from "./detail.styled";
import { ContactInfo } from "models";
import moment from "moment";
import { editPersonProfile } from "services/crmService";
import { updateTaskStudentInfo } from "store/actions/crm.actions";
import { updateStudentInfo } from "store/actions/student.actions";
import { toast } from "react-toastify";
import {
  fetchPersonHistory,
  fetchPersonsProfile,
  fetchStudentTrackingsPerson,
  fetchStudentCourses,
  fetchStudentMoments,
} from "store/actions/async/crm-async.action";
import {
  fetchInfoCustomPerson,
  fetchStudentNotes,
} from "store/actions/async/student-async.actions";
import {
  applyTextType,
  getGlobalConfig,
  getObjectAttribute,
  getText,
} from "utils";
import { useTranslation } from "react-i18next";
import Score from "./Score";
import ContainerItemPerson from "components/Nimbiv2/PageTask/ContainerItemPerson";
import { useTranslationLocal } from "hooks";
import { ModalProfile, NBadge } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import copy from "copy-to-clipboard";

const InfoStudent = ({ task, isPending }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const [selectMenu, setSelectMenu] = useState(null);
  const [showModalContact, setShowModalContact] = useState(null);
  const [person, setPerson] = useState(null);
  const [isSaving, setSaving] = useState(false);
  const [studentBirthDate, setStudentBirthDate] = useState(new Date());
  const [showProfile, setShowProfile] = useState(false);

  const { customInfoPerson } = useSelector((state) => state.task);
  const { user } = useSelector((state) => state.auth);

  const {
    pending: { zendesk: isPendingZendesk },
    zendesk,
  } = useSelector((state) => state.integrations);

  const {
    personsProfile,
    pending: { profile: isPendingProfile },
  } = useSelector((state) => state.crm);

  useEffect(() => {
    if (personsProfile) {
      let contacts = [];

      if ("contactinfo_set" in personsProfile) {
        contacts = new ContactInfo(personsProfile.contactinfo_set).getList();
      }

      const auxProfile = { ...personsProfile, contactinfo_set: contacts };
      setPerson(auxProfile);
    }
  }, [personsProfile]);

  useEffect(() => {
    if (person) {
      setStudentBirthDate(moment(person.birth_date).toDate());

      // update the ref inputs
      // if (refInputFirstName.current && refInputLastName.current) {
      //   refInputFirstName.current.value = person.first_name;
      //   refInputLastName.current.value = person.last_name;
      // }
    } else {
      setStudentBirthDate(new Date());
    }
  }, [person]);

  const updateStudent = ({ key, value }) => {
    const auxPerson = { ...person, [key]: value };
    setPerson(auxPerson);
  };

  const handleCloseProfile = () => setShowProfile(false);

  const toggleProfileModal = (task) => {
    const { student_id: studentId } = task.student_tracking;
    dispatch(fetchStudentTrackingsPerson(studentId));
    dispatch(fetchPersonHistory(studentId));
    dispatch(fetchStudentNotes(studentId));
    dispatch(fetchStudentCourses(studentId));
    dispatch(fetchStudentMoments(studentId));
    if (
      task &&
      task?.student_tracking &&
      task?.student_tracking?.tracking_object
    ) {
      dispatch(
        fetchInfoCustomPerson(studentId, {
          course: task.student_tracking.tracking_object.id,
        })
      );
    }
    setShowProfile(true);
  };

  const handleSaveChanges = async () => {
    const finalPerson = { ...person };
    let error = false;
    const contacts = [...finalPerson.contactinfo_set].map((c) => {
      if (c.error && !error) {
        error = true;
      }
      return c.deserialize();
    });
    if (!error) {
      finalPerson.contactinfo_set = contacts;

      setSaving(true);
      const payload = {
        studentId: finalPerson.id,
        payload: {
          first_name: finalPerson.first_name,
          last_name: finalPerson.last_name,
          birth_date: moment(studentBirthDate).format("YYYY-MM-DD"),
          gender: finalPerson.gender,
          contactinfo_set: finalPerson.contactinfo_set,
        },
      };

      const { status } = await editPersonProfile(payload);

      if (status === 200) {
        dispatch(updateTaskStudentInfo(finalPerson));
        dispatch(updateStudentInfo(finalPerson));
        setSaving(false);
        dispatch(fetchPersonsProfile(finalPerson.id));
        setShowModalContact(false);
        // notifyClose();
      } else {
        toast.error("Error!, no se pudo realizar la actualización");
      }
    } else {
      toast.error("Error en el formato ingresado");
    }
  };

  const dataStudent = useMemo(() => {
    const searchStudent = customInfoPerson.find(
      (item) => item.key_value === "person"
    );
    return searchStudent;
  }, [customInfoPerson]);

  const textInfoStudent = useMemo(() => {
    if (task) {
      const items = getGlobalConfig("info_student_tags", []);
      if (items && items.length > 0) {
        const array_items = items.map((item, key) => {
          const value = applyTextType(
            getObjectAttribute(task, item.key_item, t("no_information")),
            item.format,
            null,
            null,
            t("no_information")
          );
          if (item.hide_label) {
            return value;
          }
          return (
            <div className="flex-basic-50" key={`info-student-${key}`}>
              <span style={{ fontWeight: "500" }}>{item.label}</span> : {value}
            </div>
          );
        });
        return array_items;
      }
    }
    return [];
  }, [task, t]);

  return (
    <>
      {person && (
        <ModalContactInfo
          show={showModalContact}
          contacts={person ? person.contactinfo_set : []}
          onHide={() => {
            setShowModalContact(false);
          }}
          onUpdateStudent={updateStudent}
          callSave={handleSaveChanges}
          isSaving={isSaving}
        />
      )}
      <CardNimbi radius="8px" style={{ padding: "0px", height: "fit-content" }}>
        <div style={{ padding: "37px 25px 0px 25px" }}>
          {/* <div className="d-flex">
            <div className="mr-auto"></div>
            {task && <Score item={task} />}
          </div> */}
          <Title>
            {t("student_information", {
              student: translation("student", "", t("student")),
            })}
          </Title>
          <div style={{ marginTop: "8px" }}>
            {!user.anonymous && task && task.student_tracking && (
              <NameTitle
                style={{ cursor: "pointer" }}
                onClick={() => {
                  toggleProfileModal(task);
                }}
              >
                {task.student_tracking.full_name}
              </NameTitle>
            )}
            {user.anonymous && (
              <NameTitle>
                {task.student_tracking &&
                  task.student_tracking.full_name.split(" ", 1)}{" "}
                <AnonymousContainer isAnonymous={user.anonymous} radius="5px">
                  {task.student_tracking &&
                    task.student_tracking.full_name
                      .split(" ")
                      .splice(1)
                      .join(" ")}
                </AnonymousContainer>
              </NameTitle>
            )}
          </div>
          {!isPending && !isPendingProfile && personsProfile && (
            <AnonymousContainer isAnonymous={user.anonymous}>
              <ContainerTags
                className="d-flex justify-content-between"
                style={{
                  color: "#787C83",
                  fontSize: "var(--font-size-md)",
                  marginTop: "8px",
                  flexWrap: "wrap",
                }}
              >
                {textInfoStudent.map((item, key) => {
                  return (
                    <React.Fragment key={`key-generate-tags-${key}`}>
                      <div className="flex-basis-50">{item}</div>
                    </React.Fragment>
                  );
                })}
                {getGlobalConfig("id_student_show", "yes") === "yes" && (
                  <>
                    <div className="flex-basis-50">
                      <span style={{ fontWeight: "500" }}>ID:</span>{" "}
                      {personsProfile.external_id}
                    </div>
                  </>
                )}

                <div className="flex-basis-50">
                  <span style={{ fontWeight: "500" }}>
                    {personsProfile.document_type}:
                  </span>{" "}
                  {personsProfile.document_number}
                </div>
              </ContainerTags>
            </AnonymousContainer>
          )}
          <div
            style={{
              marginTop: "20px",
              background: "#F5F5F5",
              padding: "14px 19px",
              borderRadius: "8px",
              color: "#787C83",
            }}
            className="d-flex justify-content-between"
          >
            <div>Riesgo</div>
            {task?.student_tracking?.risk_tag &&
              task?.student_tracking?.risk_tag !== "" && (
                <NBadge
                  text={task?.student_tracking?.risk_tag}
                  customStyle={{
                    padding: "5px 18px",
                    borderRadius: "64px",
                  }}
                  backgroundColor="#C54646"
                  fontColor={"#FFFFFF"}
                  big
                ></NBadge>
              )}
            <Score showText={false} item={task} />
          </div>
        </div>
        <AccordionDesign
          style={{ marginTop: "15px" }}
          onSelect={(item) => {
            setSelectMenu(item);
          }}
        >
          <Card>
            <ToggleHeader
              eventKey="0"
              title={t("personal_information")}
              selectMenu={selectMenu}
            />
            <Accordion.Collapse eventKey="0">
              <CardBody>
                {(isPending || isPendingProfile) && (
                  <div style={{ marginTop: "10px" }}>
                    <Spinner
                      as="span"
                      animation="border"
                      size="bg"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                )}
                {dataStudent &&
                  dataStudent.content
                    .filter((item) => item.label !== "is_active")
                    .map((itemText, keyText) => {
                      return (
                        <ContainerItem
                          key={"person" + keyText}
                          marginTop="0px"
                          marginBottom="16px"
                        >
                          <strong>{itemText.label}</strong>
                          <div className="item-description">
                            {applyTextType(
                              itemText.value,
                              itemText.format,
                              itemText.extension,
                              itemText.format_string
                            )}
                          </div>
                        </ContainerItem>
                      );
                    })}
                {!isPending && !isPendingProfile && personsProfile && (
                  <>
                    <ContainerItem style={{ marginTop: "0px" }}>
                      <strong>{_.upperFirst(t("phone"))}</strong>
                      <AnonymousContainer isAnonymous={user.anonymous}>
                        {personsProfile.contactinfo_set
                          .filter((item) => {
                            return item.contact_type === "phone";
                          })
                          .map((item, key) => {
                            return (
                              <div
                                key={`${key}-phone`}
                                className="item-description"
                              >
                                {item.value}
                                {!user.anonymous && (
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip>{t("copy_phone")}</Tooltip>
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={faCopy}
                                      className="ml-1"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        copy(item.value);
                                        toast.info(`Copiado: +${item?.value}`);
                                      }}
                                    />
                                  </OverlayTrigger>
                                )}
                              </div>
                            );
                          })}
                      </AnonymousContainer>
                      {/* <div className="item-description">
                  +56 9 ContainerItem4 5633 (personal)
                </div> */}
                    </ContainerItem>
                    <ContainerItem>
                      <strong>{_.upperFirst(t("email"))}</strong>
                      <AnonymousContainer isAnonymous={user.anonymous}>
                        {personsProfile.contactinfo_set
                          .filter((item) => {
                            return item.contact_type === "email";
                          })
                          .map((item, key) => {
                            return (
                              <div
                                key={`${key}-email`}
                                className="item-description"
                              >
                                {item.value}
                                {!user.anonymous && (
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip>{t("copy_email")}</Tooltip>
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={faCopy}
                                      className="ml-1"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        copy(item.value);
                                        toast.info(`Copiado: ${item?.value}`);
                                      }}
                                    />
                                  </OverlayTrigger>
                                )}
                              </div>
                            );
                          })}
                      </AnonymousContainer>
                      {!getGlobalConfig("disable_update_contact", false) && (
                        <div
                          style={{
                            color: "#334FFF",
                            fontWeight: "bold",
                            marginTop: "16px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setShowModalContact(true);
                          }}
                        >
                          Modificar datos de contacto
                        </div>
                      )}
                    </ContainerItem>
                  </>
                )}
              </CardBody>
            </Accordion.Collapse>
          </Card>
          {customInfoPerson &&
            customInfoPerson
              .filter(
                (item) =>
                  item.key_value !== "person" && item.key_value !== "links"
              )
              .map((item, key) => {
                return (
                  <React.Fragment key={`d-${key}`}>
                    <DividerH key={`divider-${key}`} />
                    <Card key={`card-dropdown-${key}`}>
                      <ToggleHeader
                        eventKey={item.key_value}
                        title={item.label}
                        selectMenu={selectMenu}
                      />
                      <Accordion.Collapse eventKey={item.key_value}>
                        <CardBody>
                          <div
                            className="d-flex justify-content-between"
                            style={{ flexWrap: "wrap" }}
                          >
                            {item.content.map((itemText, keyText) => {
                              return (
                                <Col
                                  key={keyText}
                                  lg={itemText.format !== "array" ? "6" : "12"}
                                  className="p-0"
                                >
                                  <ContainerItemPerson
                                    keyText={keyText}
                                    itemText={itemText}
                                  />
                                </Col>
                              );
                            })}
                          </div>
                        </CardBody>
                      </Accordion.Collapse>
                    </Card>
                  </React.Fragment>
                );
              })}
          {!isPendingZendesk && zendesk && zendesk.length > 0 && <DividerH />}
          {!isPendingZendesk && zendesk && zendesk.length > 0 && (
            <Card>
              <ToggleHeader
                eventKey="4"
                title={getText("zendesk", "startCase", "Portal ayuda alumno")}
                selectMenu={selectMenu}
              />
              <Accordion.Collapse eventKey="4">
                <CardBody>
                  {(isPendingZendesk || isPendingProfile) && (
                    <div style={{ marginTop: "10px" }}>
                      <Spinner
                        as="span"
                        animation="border"
                        size="bg"
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                  {!isPendingZendesk &&
                    zendesk &&
                    zendesk.slice(0, 2).map((item, key) => {
                      return (
                        <React.Fragment key={`zendesk-${key}`}>
                          <ZendeskItem>
                            <ZendeskTicket>
                              <strong>Ticket de reclamo</strong> |{" "}
                              {moment(item.zendesk_created_at).format(
                                "DD-MM-YYYY"
                              )}
                            </ZendeskTicket>

                            <ZendeskDetail>
                              <span
                                style={{
                                  fontWeight: "bold",
                                  marginRight: "5px",
                                }}
                              >
                                {item.subject}
                              </span>
                              {item.description}
                            </ZendeskDetail>
                            <div
                              style={{ marginTop: "20px", fontWeight: "bold" }}
                            >
                              Asignado a: {item.assignee}
                            </div>
                          </ZendeskItem>
                          <DividerH
                            style={{ marginTop: "16px", marginBottom: "16px" }}
                          />
                        </React.Fragment>
                      );
                    })}
                </CardBody>
              </Accordion.Collapse>
            </Card>
          )}
        </AccordionDesign>
      </CardNimbi>
      {showProfile ? (
        <ModalProfile
          notifyClose={handleCloseProfile}
          isVisible={showProfile}
        />
      ) : null}
    </>
  );
};

export default InfoStudent;
