import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { getTicket } from "services/report.service";
import TableauReport from "tableau-react";
import { Container, Row, Col } from "react-bootstrap";

import styles from "./Report.module.css";
import { RowItem } from "components/Nimbiv2/styled";
import { NButton } from "components/Nimbiv2";
import { useTranslation } from "react-i18next";
import { downloadFileUrl } from "utils";

const ReportView = ({ report }) => {
  const [ticket, setTicket] = useState(false);
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    getTicket().then(({ data }) => {
      setTicket(data ? data.ticket : false);
    });
  }, []);

  const options = {
    width: "100%",
    height: "1000px",
    hideTabs: true,
    hideToolbar: true,
  };

  const filterDynamic = useMemo(() => {
    if (user?.tracking_objects && user?.client_area === "USS") {
      return {
        client_area_id: user.client_area_id,
        campus_id: user?.campus,
        codigo_carrera: user?.tracking_objects,
      };
    }
    return {
      client_area_id: user.client_area_id,
    };
  }, [user]);

  const downloadCsv = () => {
    downloadFileUrl(report.url);
  };

  return (
    <>
      <Container
        style={{ minWidth: 992 }}
        className={styles.reportView + `mr-0 ml-0`}
      >
        <RowItem className="mt-2 mb-2 ">
          <Col xs="7" className="p-0">
            <div
              className={styles.wbtDesc}
              style={{ fontSize: "var(--font-size-md)" }}
            >
              {report.description}
            </div>
          </Col>
        </RowItem>
        {1 === 0 && (
          <div
            className="d-flex align-items-center"
            style={{ justifyContent: "space-between" }}
          >
            <div
              style={{ color: "#787C83", fontSize: "var(--font-size-small)" }}
            >
              Actualizado el 22/05/2022
            </div>
          </div>
        )}
      </Container>
      <Container style={{ minWidth: 992, paddingBottom: "90px" }} fluid>
        {report && report.s3_filename !== "" && report.s3_filename && (
          <div className="d-flex justify-content-end">
            <NButton
              padding="9px 15px"
              boxshadow={1}
              border="24px"
              size="14px"
              onClick={downloadCsv}
              outlined={1}
              style={{ minWidth: "180px", marginBottom: "10px" }}
              color="second"
            >
              {_.upperFirst(t("download_data"))}
            </NButton>
          </div>
        )}
        <RowContainer
          className="d-flex justify-content-center no-gutters"
          style={{ width: "100%" }}
        >
          {report && report.type === "tableau" && ticket ? (
            <TableauReport
              url={`https://tableau.datalized.cl/views/${report.workbook}/${report.tab}`}
              token={ticket}
              options={options}
              filters={filterDynamic}
            />
          ) : report && report.type === "html" ? (
            <iframe
              title="html-report"
              src={report.url}
              height={report.height}
              width="100%"
              sandbox="allow-scripts"
            />
          ) : (
            <p>{t("loading_report")}...</p>
          )}
        </RowContainer>
      </Container>
    </>
  );
};

export default ReportView;
const RowContainer = styled(Row)`
  div:first-child {
    width: 100%;
  }
`;
