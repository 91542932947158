import React, { useState, useCallback } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { APP_MODULES } from "utils";
import {
  setDataToLocalStorage,
  setSessionFilters,
  getDataFromLocalStorage,
} from "utils";
import { getTaskList } from "store/actions/async/crm-async.action";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFlag,
  faHourglassEnd,
  faPhoneSlash,
  faLightbulbOn,
} from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

const {
  TASK: { FILTER_NAME: FILTER_TASK },
} = APP_MODULES;

const NPriorityFilter = React.memo(({ isDisabled = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [priorityTasks, setPriorityTasks] = useState(() => {
    const currentFilters = getDataFromLocalStorage("filters", {});
    if ("has_priority" in currentFilters) return currentFilters.has_priority;
    return false;
  });

  const [expirationOrder, setExpirationOrder] = useState(() => {
    const currentFilters = getDataFromLocalStorage("filters", {});
    if ("expiration_order" in currentFilters)
      return currentFilters.expiration_order;
    return false;
  });

  const [notCalled, setNotCalled] = useState(() => {
    const currentFilters = getDataFromLocalStorage("filters", {});
    if ("not_called" in currentFilters) return currentFilters.not_called;
    return false;
  });

  const [starredNote, setStarredNote] = useState(() => {
    const currentFilters = getDataFromLocalStorage("filters", {});
    if ("starred_note" in currentFilters) return currentFilters.starred_note;
    return false;
  });

  const handlePriorityChecked = useCallback(
    (event) => {
      setExpirationOrder(false);
      setNotCalled(false);
      setStarredNote(false);
      const isChecked = event.target.checked;
      setDataToLocalStorage("avoidReloadTasks", true);
      setSessionFilters(FILTER_TASK, {
        page: 1,
        has_priority: isChecked,
        expiration_order: false,
        not_called: false,
        starred_note: false,
      });
      setPriorityTasks(isChecked);
      dispatch(getTaskList());
    },
    [dispatch]
  );

  const handleExpirationChecked = useCallback(
    (event) => {
      setPriorityTasks(false);
      setNotCalled(false);
      setStarredNote(false);
      const isChecked = event.target.checked;
      setDataToLocalStorage("avoidReloadTasks", true);
      setSessionFilters(FILTER_TASK, {
        page: 1,
        expiration_order: isChecked,
        has_priority: false,
        not_called: false,
        starred_note: false,
      });
      setExpirationOrder(isChecked);
      dispatch(getTaskList());
    },
    [dispatch]
  );

  const handleNotCalledChecked = useCallback(
    (event) => {
      setExpirationOrder(false);
      setPriorityTasks(false);
      setStarredNote(false);
      const isChecked = event.target.checked;
      setDataToLocalStorage("avoidReloadTasks", true);
      setSessionFilters(FILTER_TASK, {
        page: 1,
        has_priority: false,
        expiration_order: false,
        not_called: isChecked,
        starred_note: false,
      });
      setNotCalled(isChecked);
      dispatch(getTaskList());
    },
    [dispatch]
  );
  const handleStarredNoteChecked = useCallback(
    (event) => {
      setExpirationOrder(false);
      setPriorityTasks(false);
      setNotCalled(false);
      const isChecked = event.target.checked;
      setDataToLocalStorage("avoidReloadTasks", true);
      setSessionFilters(FILTER_TASK, {
        page: 1,
        has_priority: false,
        expiration_order: false,
        not_called: false,
        starred_note: isChecked,
      });
      setStarredNote(isChecked);
      dispatch(getTaskList());
    },
    [dispatch]
  );

  return (
    <>
      <div className="d-flex justify-content-start align-items-center pb-2">
        <Form.Check
          className="font-weight-bold"
          type="checkbox"
          label={_.upperFirst(t("priority"))} //"Prioridad"
          id="check1"
          onChange={handlePriorityChecked}
          checked={priorityTasks}
          disabled={isDisabled}
          custom
        />
        <div className="mx-2">
          <FontAwesomeIcon className="icons_color" icon={faFlag} />
        </div>
      </div>
      <div className="d-flex justify-content-start align-items-center pb-2">
        <Form.Check
          className="font-weight-bold"
          type="checkbox"
          label={_.upperFirst(t("expiration"))} //"Expiración"
          id="check2"
          onChange={handleExpirationChecked}
          checked={expirationOrder}
          disabled={isDisabled}
          custom
        />
        <div className="mx-2">
          <FontAwesomeIcon className="icons_color" icon={faHourglassEnd} />
        </div>
      </div>
      <div className="d-flex justify-content-start align-items-center">
        <Form.Check
          className="font-weight-bold"
          type="checkbox"
          label={t("no_call")} //"Sin Llamar"
          id="check3"
          onChange={handleNotCalledChecked}
          checked={notCalled}
          disabled={isDisabled}
          custom
        />
        <div className="mx-2">
          <FontAwesomeIcon className="icons_color" icon={faPhoneSlash} />
        </div>
      </div>
      <div className="d-flex justify-content-start align-items-center">
        <Form.Check
          className="font-weight-bold"
          type="checkbox"
          label={t("starred_note")} //"Notas Destacadas"
          id="check4"
          onChange={handleStarredNoteChecked}
          checked={starredNote}
          disabled={isDisabled}
          custom
        />
        <div className="mx-2">
          <FontAwesomeIcon className="icons_color" icon={faLightbulbOn} />
        </div>
      </div>
    </>
  );
});
export default NPriorityFilter;
