import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Image, Spinner } from "react-bootstrap";
import styled from "styled-components";
import { IconContainer } from "../styled";

const CardUserPosition = ({
  data,
  background = "#8da6ff",
  titleColor = "#ffffff",
  descriptionColor = "#e4ecfc",
  position = 1,
  loading = false,
}) => {
  return (
    <ContainerPlace
      background={background}
      titleColor={titleColor}
      descriptionColor={descriptionColor}
    >
      <div className="card-number">
        <div style={{ lineHeight: "1" }}>{position}</div>
      </div>
      <div className="info mr-auto">
        {!loading && (
          <>
            <div className="info-title">
              {data?.first_name} {data?.last_name}
            </div>
            {data.total_calls && (
              <div className="info-call">{data.total_calls} llamadas</div>
            )}
          </>
        )}
        {loading && (
          <>
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
              style={{ width: "22px", height: "22px" }}
            />
          </>
        )}
      </div>
      {!loading && (
        <div className="container-image">
          {data.image && (
            <Image
              src={data?.image}
              alt="user photo"
              aria-hidden="true"
              width={"34px"}
              height={"34px"}
              roundedCircle
            />
          )}
          {!data.image && (
            <IconContainer size="34px" style={{ border: "2.4px solid white" }}>
              <FontAwesomeIcon
                className="icons_color"
                icon={faUser}
                style={{ fontSize: "var(--font-size-lg)" }}
              />
            </IconContainer>
          )}
        </div>
      )}
    </ContainerPlace>
  );
};
export default CardUserPosition;

const ContainerPlace = styled.div`
  background: ${(props) => props.background};
  height: 47px;
  width: 100%;
  margin-top: 10px;
  padding: 6.5px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  &:first-child {
    margin-top: 0px;
  }

  .card-number {
    background: #f6fafe;
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3b4567;
    font-size: 24px;
    font-weight: 700;
  }
  .info {
    overflow: hidden;
    margin-left: 13px;
    .info-title {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 120%;
      font-size: var(--font-size-md);
      font-weight: 500;
      color: ${(props) => props.titleColor};
    }
    .info-call {
      margin-top: 2px;
      line-height: 120%;
      font-weight: 325;
      font-size: 10px;
      color: ${(props) => props.descriptionColor};
    }
  }
  .container-image {
    margin-left: 13px;
  }
`;
