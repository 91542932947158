import React from "react";

import CourseCol from "containers/Task/Cols/CourseCol";
import { NBadge } from "components";
import { useTranslation } from "react-i18next";

export const CourseColumnInfo = ({ enrollment }) => {
  const { t } = useTranslation();
  const courseBadge = () => {
    const subtitle = {
      text: `${enrollment?.extras?.modalidad_carrera ?? ""}`,
      title: t("modality"),
    };
    return subtitle.text ? (
      <NBadge text={subtitle.text} title={subtitle.title} />
    ) : null;
  };

  if (!enrollment) return null;
  return (
    <CourseCol enrollment={enrollment} subtitleBadge={courseBadge()}>
      <span>
        <span>{enrollment.extras.name}</span>
      </span>
    </CourseCol>
  );
};
