import React from "react";
import {
  Dashboard,
  MainContent,
  Tasks,
  Students,
  Reports,
  Initiatives,
  HelpCenter,
  CreateInitiative,
  PageError,
  FeedbackClient,
} from "pages";
import Moments from "pages/Moments";
import InitiativesDetail from "pages/Initiatives/detail";
import EditInitiative from "pages/Initiatives/edit";
import MomentDetail from "pages/Moments/detail";
import StudentDetail from "pages/Students/detail";
import TaskDetail from "pages/Tasks/detail";
import AdministrationPage from "pages/Administration";
import ExternalManagementPage from "pages/ExternalManagement";
import { MessageTickError, MessageTickSuccess } from "assets/images";
import PageAddAssociated from "pages/Associateds/AddAssociated";

export const dashboard = () => (
  <MainContent>
    <Dashboard />
  </MainContent>
);

export const tasks = () => (
  <MainContent>
    <Tasks />
  </MainContent>
);

export const tasksDetail = () => (
  <MainContent>
    <TaskDetail />
  </MainContent>
);

export const students = () => (
  <MainContent>
    <Students />
  </MainContent>
);

export const reports = () => (
  <MainContent>
    <Reports />
  </MainContent>
);

export const administration = (action = "main") => (
  <MainContent>
    <AdministrationPage action={action} />
  </MainContent>
);

export const moments = () => (
  <MainContent>
    <Moments />
  </MainContent>
);
export const momentDetail = () => (
  <MainContent>
    <MomentDetail />
  </MainContent>
);

export const initiatives = () => (
  <MainContent>
    <Initiatives />
  </MainContent>
);

export const initiativeCreate = () => (
  <MainContent>
    <CreateInitiative />
  </MainContent>
);

export const initiativeDetail = () => (
  <MainContent>
    <InitiativesDetail />
  </MainContent>
);

export const help = (action = "main") => (
  <MainContent>
    <HelpCenter action={action} />
  </MainContent>
);

export const initiativeEdit = () => (
  <MainContent>
    <EditInitiative />
  </MainContent>
);

export const studentDetail = () => (
  <MainContent>
    <StudentDetail />
  </MainContent>
);

export const pageError = () => (
  <MainContent>
    <PageError />
  </MainContent>
);

export const pageExternalManagement = (action = "main") => (
  <MainContent>
    <ExternalManagementPage action={action} />
  </MainContent>
);

export const FeedbackClientSuccess = () => (
  <FeedbackClient
    image={MessageTickSuccess}
    title={"Gracias por tu respuesta"}
    description={
      "Te agradecemos tu participación. Tu contribución es muy valiosa <br/> y marca una gran diferencia para nosotros."
    }
    altImage={"Success"}
  />
);

export const FeedbackClientError = () => (
  <FeedbackClient
    image={MessageTickError}
    title={"No hemos podido registrar tu respuesta"}
    description={
      "Desafortunadamente, hubo un problema al intentar registrar tu respuesta. Por<br/> favor, inténtalo de nuevo más tarde o ponte en contacto con nuestro equipo<br/> de soporte si el problema persiste."
    }
    altImage={"Failed"}
  />
);

export const AddAssociated = () => (
  <MainContent>
    <PageAddAssociated />
  </MainContent>
);
