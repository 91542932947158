import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getTextByLanguage, getTextReplace } from "utils";
import { capitalize } from "lodash";

const useTranslationLocal = () => {
  const { t, i18n } = useTranslation();
  const translation = useCallback(
    (data, typeCase, def, dataSearch = null) => {
      if (dataSearch) {
        const response = getTextReplace(dataSearch, data);
        if (response) {
          return response;
        }
      }
      const text = getTextByLanguage(data, typeCase, i18n.language, def);
      if (text) {
        return text;
      }
      if (typeCase === "startCase") {
        return capitalize(t(data));
      }
      if (typeCase === "upper") {
        let text = t(data);
        return text.toUpperCase();
      }
      return t(data);
    },
    [t, i18n.language]
  );
  return { translation };
};
export default useTranslationLocal;
