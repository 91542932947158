import React from "react";
import styled from "styled-components";
import { VERSIONS_FILTERS_DESIGN } from "utils/constants";
const ContainerInputFilter = ({ label, versionInput = true, ...props }) => {
  return (
    <>
      {versionInput === VERSIONS_FILTERS_DESIGN.v2 && (
        <ContainerInputDesign>
          {props?.children}
          <LabelStyled className={"active"}>{label}</LabelStyled>
        </ContainerInputDesign>
      )}
      {versionInput === VERSIONS_FILTERS_DESIGN.v1 && props?.children}
    </>
  );
};
export default ContainerInputFilter;

const ContainerInputDesign = styled.div`
  .form-control {
    background-color: transparent !important;
    height: 46px;
    display: flex;
    align-items: center;
  }
  position: relative;
  & label {
    transform-origin: top left;
    transition: all 0.2s ease-out;
  }
  &:focus-within label {
    transform: translate(12px, -8px) scale(0.75);
    padding: 0px 10px 0px 10px;
    color: #334fff;
  }
  &:focus-within input {
    border: 1px solid #334fff;
  }
  .active {
    transform: translate(12px, -8px) scale(0.75) !important;
    padding: 0px 10px 0px 10px;
  }
  display: flex;
  flex-direction: column;
`;

const LabelStyled = styled.label`
  font-size: var(--font-size-lg);
  font-family: Arial, Helvetica, sans-serif;
  color: #999;
  pointer-events: none;
  position: absolute;
  transform: translate(12px, 12px) scale(1);
  background: #f8fbff;
`;
