import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { uuidv4 } from "utils";
import { DividerH } from "../styled";

const NRadioButton = ({
  key,
  dataset,
  isDisabled,
  onChange,
  keyPosition,
  lastKeyPosition,
  style,
  initialData,
  label,
  value,
  detail,
}) => {
  const [selected, setSelected] = useState(initialData ?? null);
  const [load, setLoad] = useState(0);
  useEffect(() => {
    if (load === 1) {
      onChange(selected);
    } else {
      setLoad(1);
    }
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="mt-2" style={style}>
      <div className="mt-2">
        {dataset?.map((item, key) => {
          return (
            <div className="mt-1">
              <div
                className="d-flex align-items-center"
                key={`resu-${key}-${item.id}`}
              >
                <Form.Check
                  id={`resu-${uuidv4(4)}-${item.id}`}
                  type="radio"
                  label={""}
                  value={item[value ?? "text"]}
                  checked={selected?.id === item.id}
                  onChange={(event) => {
                    setSelected(item);
                  }}
                  custom
                />
                <div>
                  <div style={{ fontWeight: "bold" }}>
                    {item[label ?? "text"]}
                  </div>
                  <div>{item[detail ?? "detail"]}</div>
                </div>
              </div>
              {dataset?.length - 1 !== key && (
                <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                  <DividerH />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NRadioButton;
