export const ACTIONS = {
  GET_GENERATION_EVENT: "GET_GENERATION_EVENT",
  SET_GENERATION_EVENT: "SET_GENERATION_EVENT",
  REGISTER_ERROR: "REGISTER_ERROR",
  GET_GENERATION: "GET_GENERATION",
  SET_GENERATION: "SET_GENERATION",
  GET_TRACKINGOBJECT: "GET_TRACKINGOBJECT",
  SET_TRACKINGOBJECT: "SET_TRACKINGOBJECT",
};

// getting all users
const defaultQueryParams = {
  page: 1,
  page_size: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
  client_filters: null,
};

export const registerError = ({ err = null, key = "" }) => ({
  type: ACTIONS.REGISTER_ERROR,
  payload: { err, key },
});

export const getGenerationEvent = (queryParams = defaultQueryParams) => ({
  type: ACTIONS.GET_GENERATION_EVENT,
  payload: queryParams,
});

export const setGenerationEvent = (results) => ({
  type: ACTIONS.SET_GENERATION_EVENT,
  payload: results,
});

export const getGeneration = () => ({
  type: ACTIONS.GET_GENERATION,
  payload: null,
});

export const setGeneration = (results) => ({
  type: ACTIONS.SET_GENERATION,
  payload: results,
});

export const getTrackingObject = () => ({
  type: ACTIONS.GET_TRACKINGOBJECT,
  payload: null,
});

export const setTrackingObject = (results) => ({
  type: ACTIONS.SET_TRACKINGOBJECT,
  payload: results,
});
