import { fetchHelpItemTypes, fetchHelpItems } from "services/help.service";
import { handleErrorStatus } from "services/errors";
import * as helpActions from "../help.actions";

const handleErrorResponse = (err) => {
  const status = err instanceof Map ? err.get("status") : 500;
  if (status > 0) return handleErrorStatus(status);
};

export const getHelpItemTypes = () => {
  return (dispatch) => {
    dispatch(helpActions.setIsLoading(true));
    fetchHelpItemTypes()
      .then(({ data }) => {
        dispatch(helpActions.setHelpItemTypes(data.results));
      })
      .catch((error) => {
        handleErrorResponse(error);
      })
      .finally(() => {
        dispatch(helpActions.setIsLoading(false));
      });
  };
};

export const getHelpItems = (queryParams) => {
  return (dispatch) => {
    dispatch(helpActions.getHelpItems(queryParams));
    fetchHelpItems(queryParams)
      .then(({ data }) => {
        dispatch(helpActions.setHelpItems(data));
      })
      .catch((error) => {
        handleErrorResponse(error);
      })
      .finally(() => {
        dispatch(helpActions.setIsLoading(false));
      });
  };
};
