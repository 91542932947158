import React, { useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { applyTextType } from "utils";
import NBadge from "components/NBadge";
import { colorsBadge } from "utils/constants";

const TagList = ({ tags }) => {
  const { t } = useTranslation();

  const hasSense = useMemo(() => {
    if (tags) {
      const listSence = tags.filter((item) => item.label === "Sence");
      if (listSence.length > 0) {
        const sence = listSence[0];
        if (sence.value === "1") {
          return true;
        }
      }
    }
    return false;
  }, [tags]);

  const getColorBadge = (item) => {
    if (item.value > 0 && item.value < 50) {
      return colorsBadge.dark.success;
    }
    if (item.value >= 50 && item.value < 60) {
      return colorsBadge.dark.warning;
    }
    if (item.value > 60) {
      return colorsBadge.dark.danger;
    }
    return null;
  };

  return (
    <TagListDesign className="d-flex">
      {tags &&
        tags.map((item, key) => {
          if (
            item.format &&
            item.format === "boolean" &&
            item.hide_value &&
            item.value !== null &&
            (item.value === false || item.value === "0" || item.value === 0)
          ) {
            return <span key={`key-${key}-tags`}></span>;
          }
          if (
            item.format &&
            item.format === "boolean" &&
            item.hide_value &&
            item.value !== null &&
            (item.value === true || item.value === "1" || item.value === 1)
          ) {
            return (
              <NBadge
                kind="aqua"
                text={item.label}
                title={``}
                key={`key-${key}-${item.value}`}
                newColor={
                  item.label === "Sin ingreso" && hasSense
                    ? getColorBadge(item)
                    : null
                }
                customStyle={
                  item.label === "Sin ingreso" && !hasSense
                    ? { display: "none" }
                    : {}
                }
              />
            );
          }
          return (
            <NBadge
              key={`key-${key}-${item.value}`}
              kind="aqua"
              newColor={
                item.label === "Sin ingreso" && hasSense
                  ? getColorBadge(item)
                  : null
              }
              text={`${
                item.label && item.label !== "" ? `${item.label}: ` : ""
              }${applyTextType(
                item.value,
                item.format,
                null,
                null,
                t("no_information")
              )}`}
              title={``}
              customStyle={
                item.label === "Sin ingreso" && !hasSense
                  ? { display: "none" }
                  : {}
              }
            />
          );
        })}
    </TagListDesign>
  );
};
export default React.memo(TagList);

const TagListDesign = styled.div`
  flex-wrap: wrap;
  gap: 3px;
`;
