import styled from "styled-components";

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const TitleCard = styled.div`
  font-size: var(--font-size-caption);
  line-height: 120%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #787c83;
`;
