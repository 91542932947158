import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

import { NButton } from "components/Nimbiv2";

const ModalLogout = ({
  onCancel,
  onAccept,
  title,
  isVisible,
  size,
  description,
}) => {
  return (
    <ModalDesign
      show={isVisible}
      onHide={onCancel}
      size={size}
      dialogClassName="modal-50w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title className="h5">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ fontSize: "var(--font-size-lg)" }}>{description}</div>
      </Modal.Body>
      <Modal.Footer>
        <NButton
          outlined
          onClick={onCancel}
          style={{ marginRight: "14px", fontWeight: "500" }}
          padding="5px 30px"
          border="24px"
          color="second"
        >
          Cancelar
        </NButton>
        <NButton
          style={{ fontWeight: "500" }}
          onClick={onAccept}
          padding="5px 30px"
          border="24px"
          color="second"
        >
          Cerrar sesión
        </NButton>
      </Modal.Footer>
    </ModalDesign>
  );
};

ModalLogout.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
  title: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
};

ModalLogout.defaultProps = {
  isVisible: false,
  title: "",
  size: "md",
};

export default ModalLogout;

const ModalDesign = styled(Modal)`
  .modal-content {
    border-radius: 16px;
  }
  .modal-header {
    padding: 30px 24px 8px 24px;
    border-bottom: 0px;
  }
  .modal-footer {
    border-top: 0px;
  }
  .modal-body {
    padding: 0px 24px 26px 24px;
  }
`;
