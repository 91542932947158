import { generateUrlWithParams } from "utils";
import api from "./api";
import logger from "./logService";
import { CancelToken } from "axios";

const tokens = {
  advancedSearch: null,
};

const getTeams = (queryParams = { page: 1, page_size: 100 }) => {
  return api.get(generateUrlWithParams("crm/team/", queryParams));
};

const updateTeam = async (teamID, payload) => {
  try {
    const response = await api.put(`crm/team/${teamID}/`, payload);
    return Promise.resolve(response);
  } catch (err) {
    return err;
  }
};

const createTeam = async (payload) => {
  try {
    const response = await api.post(`crm/team/`, payload);
    return Promise.resolve(response);
  } catch (err) {
    return err;
  }
};

export const teamsAdvancedSearch = async (queryParams) => {
  if (tokens.advancedSearch)
    tokens.advancedSearch.cancel("Operation canceled due to new request.");
  tokens.advancedSearch = CancelToken.source();
  const finalUrl = generateUrlWithParams("crm/team/", queryParams);

  try {
    const response = await api.get(finalUrl, {
      cancelToken: tokens.advancedSearch.token,
    });
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return err;
  }
};
export { getTeams, updateTeam, createTeam };
