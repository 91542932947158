import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Col } from "react-bootstrap";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { RowItem } from "../styled";
import { getDataFromLocalStorage, setSessionFilters } from "utils";
import NInputFilter from "components/NInputFilter";

const SearchContainer = ({
  placeholder,
  fetchSearch = async () => {},
  persist = false,
  fieldNameSearch,
  filterKeySearch,
  compositeLabel,
  isDisabled = false,
  customMenuItem,
  filterName = "filter_global",
  onChange = () => {},
  persistSearch,
  buttonsCreate = <></>,
  ...props
}) => {
  const [filterCourse, setFilterCourse] = useState(
    getDataFromLocalStorage(persistSearch, [])
  );
  const searchInformation = useCallback(
    async (query) => {
      const params = { page_size: 100, search: query.trim() };
      const response = await fetchSearch(params);
      let results;
      if (response.data) results = response.data.results;
      return Promise.resolve(results ?? []);
    },
    [fetchSearch]
  );

  const handleFilterChanged = useCallback(
    (data) => {
      const [selected = {}] = data;

      if (persist) {
        const query = selected[fieldNameSearch];
        setSessionFilters(filterName, {
          page: 1,
          page_size: 10,
          [filterKeySearch]: query ?? "",
        });
      }
      setFilterCourse(data);
      onChange(selected);
    },
    [fieldNameSearch, filterKeySearch, filterName, onChange, persist]
  );

  return (
    <div>
      <RowItem className="pt-3">
        <Col className={` px-0`} sm={12} md={6}>
          <InputDesign>
            <NInputFilter
              handleChange={handleFilterChanged}
              data={[]}
              delay={800}
              compositeLabel={compositeLabel}
              placeholder={placeholder}
              selected={filterCourse}
              // filterBy={filterFields}
              renderMenuItem={customMenuItem}
              asyncSearch={(query) => searchInformation(query)}
              isDisabled={isDisabled}
              isAsync
            />
            <FontAwesomeIcon
              icon={faSearch}
              style={{ fontSize: "18px", marginLeft: "4px", color: "#334FFF" }}
            />
          </InputDesign>
        </Col>
        <Col
          sm={12}
          md={6}
          className="d-flex justify-content-end align-items-center mt-2 mt-md-0 p-0"
        >
          {buttonsCreate}
        </Col>
        {props.children && (
          <Col xs="12" style={{ marginTop: "7px" }}>
            {props.children}
          </Col>
        )}
      </RowItem>
    </div>
  );
};

export default SearchContainer;
const InputDesign = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 10px 12px;
  .form-control {
    border: 0px solid #ced4da !important;
    padding: 0px;
  }
  .rbt {
    width: 100%;
  }
`;
