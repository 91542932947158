import React, { useState } from "react";

// import { fetchLastNote } from "services/student.service";
// import { toast } from "react-toastify";
import { setDataToLocalStorage, typesTaskEvent } from "utils";
import { faPhone } from "@fortawesome/pro-regular-svg-icons";
import { NButtonCircle } from "components/Nimbiv2/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { ButtonSize } from "utils/constants";
const Actions = ({ item, processTaskRequest, toggleTasks }) => {
  const history = useHistory();
  const [isGettingTaskStatus, setGettingTaskStatus] = useState(false);

  const toggleOpen = async (row) => {
    if (!row.isOpened) {
      setGettingTaskStatus(true);

      await Promise.resolve(
        setTimeout(async () => {
          // const response = await processTaskRequest(row, typesTaskEvent.TAKEN);
          await processTaskRequest(row, typesTaskEvent.TAKEN);
          toggleTasks(typesTaskEvent.TAKEN, item);

          /*if (response) {
            const { student_tracking: student = null } = row;
            if (student && student.id) {
              const { data, status } = await fetchLastNote(student.student_id, {
                top: 1,
              });
              if (status === 200)
                // eslint-disable-next-line no-param-reassign
                row.student_tracking.recentNote =
                  data.results.length > 0 ? data.results[0] : null;
            }
            //setOpened(true);
          } else {
            toast.error("La tarea 🎯 no está disponible!");
          }*/
          setGettingTaskStatus(false);
          setDataToLocalStorage("taskSelect", {
            id: item.id,
            external: item.external,
          });
          history.push(`/tareas/${item.id}/`);
        }, 500) // wait for 500ms to show spinner loader
      );
    } else if (row.isOpened) {
      toggleTasks(typesTaskEvent.RELEASED, item);
    }
  };
  return (
    <div>
      <NButtonCircle
        sizeStyle={ButtonSize.lg}
        onClick={() => toggleOpen(item)}
        loading={isGettingTaskStatus}
        icon={<FontAwesomeIcon icon={faPhone} style={{ marginTop: "4px" }} />}
      />
    </div>
  );
};
export default Actions;
