import React, { useEffect, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Typeahead } from "react-bootstrap-typeahead";
import { replaceChars, capitalizeWord } from "utils";
import { useTranslation } from "react-i18next";
import ContainerInputFilter from "./ContainerInputFilter";
import { VERSIONS_FILTERS_DESIGN } from "utils/constants";


const NFRangeNumber = ({
  dataset,
  initialValue,
  onChange,
  isDisabled = false,
  versionInput,
}) => {
  const { t } = useTranslation();
  const [itemsSelected, setItemsSelected] = useState([]);

  useEffect(() => {
    setItemsSelected(
      typeof initialValue !== "undefined"
        ? [(String(initialValue).replace('<>', ' - '))]
        : []
    );
  }, [initialValue]);

  const onChangeItem = (items) => {
    const x = dataset["filter_field"];
    if (items.length > 0) {
      onChange(x, items[0].replace(' - ', '<>'), dataset);
    } else {
      onChange(x, null, dataset);
    }
    setItemsSelected(items);
  };
  return (
    <ContainerInputFilter
      label={capitalizeWord(replaceChars(dataset.label, "_", " "))}
      versionInput={versionInput}
    >
      <Typeahead
        id="typeahead-range-number"
        onChange={onChangeItem}
        options={dataset.values}
        selected={itemsSelected}
        clearButton
        placeholder={
          versionInput === VERSIONS_FILTERS_DESIGN.v2
            ? t("all")
            : capitalizeWord(replaceChars(dataset.label, "_", " "))
        }
        disabled={isDisabled}
      />
    </ContainerInputFilter>
  );
};

export default NFRangeNumber;
