import {
  GET_EXTERNAL_MANAGEMENT,
  SET_EXTERNAL_MANAGEMENT,
  REGISTER_ERROR,
  GET_EXTERNAL_DETAIL,
  SET_EXTERNAL_DETAIL,
} from "store/actions/externalManagement.actions";

const initialState = {
  externalManagement: [],
  externalTask: null,
  pending: {
    externalManagement: false,
    externalTask: false,
  },
  pagination: {
    count: 0,
    next: null,
    previous: null,
    page: 1,
    pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
  },
};

const ExternalManagementReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_EXTERNAL_MANAGEMENT: {
      return {
        ...state,
        pending: { ...state.pending, externalManagement: true },
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          pageSize: action.payload.page_size,
        },
      };
    }

    case SET_EXTERNAL_MANAGEMENT: {
      const { count, next, previous, results } = action.payload;
      let auxPagination = { ...state.pagination };
      auxPagination = { ...auxPagination, count, next, previous };

      return {
        ...state,
        externalManagement: results,
        pending: { ...state.pending, externalManagement: false },
        pagination: auxPagination,
      };
    }

    case GET_EXTERNAL_DETAIL: {
      return {
        ...state,
        pending: { ...state.pending, externalTask: true },
      };
    }

    case SET_EXTERNAL_DETAIL: {
      return {
        ...state,
        externalTask: action.payload,
        pending: { ...state.pending, externalTask: false },
      };
    }

    case REGISTER_ERROR: {
      const auxPending = { ...state.pending };
      if (action.payload.key) auxPending[action.payload.key] = false;

      return { ...state, pending: auxPending };
    }

    default:
      return { ...state };
  }
};

export default ExternalManagementReducers;
