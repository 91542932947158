import Serializer from "./Serializer";
import { IModel } from "./Interfaces";

export class Report implements IModel {
  private data: object[];
  id: any;
  name: string = "";
  category: object = {};
  description: string = "";
  workbook: string = "";
  tab: string = "";
  url: string = "";
  height: string = "";
  width: string = "";
  type: string = "";
  fieldMap: object = {};
  weight: any;
  s3_filename: string = "";

  constructor(attrs: any = {}) {
    this.fieldMap = {
      id: "id",
      name: "name",
      category: "category",
      description: "description",
      workbook: "workbook",
      tab: "tab",
      code: "code",
      type: "type",
      url: "url",
      height: "height",
      width: "width",
      weight: "weight",
      s3_filename: "s3_filename",
    };
    const isArray = Array.isArray(attrs);
    this.data = [];

    if (isArray) {
      this.data = attrs;
    } else {
      Object.assign(this, Serializer.Serialize(attrs, this.fieldMap));
    }
  }

  getList(): Array<Report> {
    return this.data.map((e) => new Report(e));
  }

  deserialize(): object {
    return Serializer.Deserialize(this, this.fieldMap);
  }
}
