import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import { NButton } from "components/Nimbiv2";
import { CardNimbi, DividerH, IconContainer } from "../styled";
import {
  BadgeCardB,
  NumberCard,
  DividerV,
  ContainerRisk,
  ContainerRiskM,
  ContainerRiskB,
} from "./CardDashboard.styled";
import RiskItem from "./RiskItem";
import Icons from "../icons";
import { ButtonSize } from "utils/constants";

const CardDashboard = ({ data }) => {
  const { t } = useTranslation();
  return (
    <CardNimbi padding="20px 19px">
      <div className="d-flex h-100">
        <div className="d-flex flex-column" style={{ width: "100%" }}>
          <div className="d-flex" style={{ marginBottom: "12px" }}>
            <div>
              <IconContainer>
                <FontAwesomeIcon
                  icon={_.get(Icons, data.icon, faExclamationTriangle)}
                  size="lg"
                />
              </IconContainer>
            </div>
            <div
              className="ml-3 d-flex justify-content-center align-items-center fontNova"
              style={{ lineHeight: "120%" }}
            >
              <strong
                dangerouslySetInnerHTML={{
                  __html: data.title,
                }}
              ></strong>
            </div>
          </div>
          <DividerH />
          <div
            className="d-flex align-items-center"
            style={{ marginTop: "2px", flexWrap: "wrap" }}
          >
            <NumberCard className="fontNova" type={data.insight_type}>
              {data.successfully.total}
            </NumberCard>
            <div style={{ width: "10px" }}></div>
            {data.extras.alert && (
              <BadgeCardB
                error={data.extras.alert.error}
                style={{ width: "auto", padding: "6px 12px", flex: "1" }}
              >
                <strong className="fontNova">
                  {data.extras.alert.icon && (
                    <FontAwesomeIcon
                      className="mr-1"
                      icon={_.get(
                        Icons,
                        data.extras.alert.icon,
                        faExclamationTriangle
                      )}
                    />
                  )}
                  {data.extras.alert.title}
                </strong>
                {data.extras.alert.subtitle}
              </BadgeCardB>
            )}
          </div>
          <div
            style={{ marginTop: "6px" }}
            dangerouslySetInnerHTML={{
              __html: data.description,
            }}
          ></div>
          {data.extras.action && data.extras.action_label && (
            <div style={{ height: "16px" }}></div>
          )}
          {data.extras.action && data.extras.action_label && (
            <div style={{ marginTop: "auto" }}>
              <Link to={data.extras.action}>
                <NButton color="second" outlined sizeStyle={ButtonSize.sm}>
                  {data.extras.action_label}
                </NButton>
              </Link>
            </div>
          )}
        </div>
        {data.insight_type === "student_risks" && <DividerV />}
        {data.insight_type === "student_risks" && (
          <div className="d-flex flex-column" style={{ width: "60%" }}>
            <ContainerRisk>
              <RiskItem
                total={data.successfully.half}
                riskText={t("medium_risk_card")}
                colorBackground="#fdf8e3"
                textColor="#ebc419"
              />
            </ContainerRisk>
            <div style={{ height: "10px" }}></div>
            <DividerH />
            <div style={{ height: "10px" }}></div>
            <ContainerRiskM>
              <RiskItem
                total={data.successfully.low}
                riskText={t("low_risk_card")}
                colorBackground="#ddf8f4"
                textColor="#0fcdb3"
              />
            </ContainerRiskM>
            <div style={{ height: "10px" }}></div>
            <DividerH />
            <div style={{ height: "10px" }}></div>
            <ContainerRiskB>
              <RiskItem
                total={data.successfully.no_info}
                riskText={t("no_information_card")}
                colorBackground="#edf1f7"
                textColor="#455362"
              />
            </ContainerRiskB>
          </div>
        )}
      </div>
    </CardNimbi>
  );
};

export default CardDashboard;
