import React from "react";
import { useSelector } from "react-redux";
import { NIcon } from "..";

const CircleButton = () => {
  const { initiative } = useSelector((state) => state.initiative);
  return (
    <>
      {initiative && (
        <div className="image-circle">
          <NIcon iconname={initiative.initiative_type.icon}></NIcon>
        </div>
      )}
    </>
  );
};
export default CircleButton;
