import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { NTable } from "components/Nimbiv2";
import { CardNimbi } from "components/Nimbiv2/styled";

import { useDispatch, useSelector } from "react-redux";
import { fetchChangelogs } from "store/actions/async/student-async.actions";
import { NPagination } from "components";
import styled from "styled-components";
import { Form } from "react-bootstrap";

const ChangeLogPage = ({ student_tracking_id }) => {
  const dispatch = useDispatch();
  const {
    changeLogs,
    pending: { changeLogs: isPending },
    paginationChangeLogs,
  } = useSelector((state) => state.student);

  const { user } = useSelector((state) => state.auth);
  const [selectKey, setSelectKey] = useState("");

  useEffect(() => {
    if (student_tracking_id) {
      if (
        user &&
        user?.client_area_change_logs &&
        user?.client_area_change_logs.length > 0
      ) {
        const value = user?.client_area_change_logs[0];
        dispatch(
          fetchChangelogs(student_tracking_id, {
            page: 1,
            page_size: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
            key_change_log: value,
          })
        );
        setSelectKey(value);
      }
    }
  }, [dispatch, student_tracking_id, user]);

  const columns = [
    {
      title: _.upperFirst("Fecha de registro"),
      key: "created_at",
      dataIndex: "created_at",
      style: { left: 0 },
      center: 1,
      contentCenter: true,
      render: (item, key) => <div>{moment(item).format("DD-MM-YYYY")}</div>,
    },
    {
      title: _.upperFirst("Valor"),
      key: "note",
      dataIndex: "value",
      style: { left: 0 },
      center: 1,
      contentCenter: true,
      render: (item, key) => <div>{item}</div>,
    },
  ];

  const loadPage = useCallback(
    (pageNumber, pageSize) => {
      let payload = {
        page: pageNumber,
        page_size: parseInt(pageSize, 10),
      };
      if (selectKey && selectKey !== "") {
        payload["key_change_log"] = selectKey;
      }
      dispatch(fetchChangelogs(student_tracking_id, payload));
    },
    [selectKey, dispatch, student_tracking_id]
  );

  const changeKeyFilter = (value) => {
    dispatch(
      fetchChangelogs(student_tracking_id, {
        page: 1,
        page_size: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
        key_change_log: value,
      })
    );
  };

  return (
    <div>
      {user &&
        user?.client_area_change_logs &&
        user?.client_area_change_logs.length > 0 && (
          <div>
            <ContainerSelect style={{ marginBottom: "16px" }}>
              <Form.Control
                as="select"
                onChange={(event) => {
                  changeKeyFilter(event.target.value);
                  setSelectKey(event.target.value);
                }}
              >
                {user?.client_area_change_logs.map((item, key) => {
                  return (
                    <option key={`${item}-${key}`} value={item}>
                      {_.upperFirst(
                        _.trim(
                          item
                            .replaceAll("context_data", "")
                            .replaceAll("_", " ")
                        )
                      )}
                    </option>
                  );
                })}
              </Form.Control>
            </ContainerSelect>
          </div>
        )}
      <CardNimbi
        radius="8px"
        padding="23px 26px"
        style={{ height: "fit-content" }}
      >
        <NTable
          columns={columns}
          dataSource={changeLogs ?? []}
          fixed={true}
          isPending={isPending}
          collapse={true}
          //textEmpty="No hay cambios para mostrar"
        ></NTable>
      </CardNimbi>
      <div style={{ marginTop: "20px", width: "100%" }}>
        <NPagination
          isPending={isPending}
          pagination={paginationChangeLogs}
          onPageChange={loadPage}
        />
      </div>
    </div>
  );
};
export default ChangeLogPage;
const ContainerSelect = styled.div`
  .form-control {
    background: transparent;
    padding: 14px 12px;
    height: auto;
    width: auto;
  }
`;
