import * as MomentActions from "store/actions/moment.actions";
import { handleErrorStatus } from "services/errors";
import { APP_MODULES, cleanNullJson, getSessionFilters } from "utils";
import {
  getMomentDetail,
  getMomentStudentsTracking,
} from "services/momentService";

const handleErrorResponse = (err) => {
  const status = err instanceof Map ? err.get("status") : 500;
  if (status > 0) return handleErrorStatus(status);
};

export const fetchMoment = (id, initialParams = {}) => {
  return (dispatch) => {
    dispatch(MomentActions.getMoment());
    getMomentDetail(id, cleanNullJson(initialParams))
      .then(({ data }) => {
        dispatch(MomentActions.setMoment(data));
      })
      .catch((err) => {
        handleErrorResponse(err);
      });
  };
};

export const fetchMomentStudentsTracking = (id, params = null) => {
  return (dispatch) => {
    const queryParams = getSessionFilters(
      APP_MODULES.MOMENT_STUDENT_TRACKINGS.FILTER_NAME
    );
    dispatch(MomentActions.getStudentsTracking(params ?? queryParams));
    getMomentStudentsTracking(id, params ?? queryParams)
      .then(({ data }) => dispatch(MomentActions.setStudentsTracking(data)))
      .catch((err) => {
        handleErrorResponse(err);
        // dispatch(InitiativeActions.registerError({ err, key: "list" }));
      });
  };
};
