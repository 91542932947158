import React from "react";
import { TaskComponents, StudentComponents } from "containers";
import { NNavbarDashboard, ScrollToTopOnMount } from "components/Nimbiv2";

const Students = () => {
  return (
    <>
      <ScrollToTopOnMount />
      <NNavbarDashboard />

      <TaskComponents.Toolbar>
        <StudentComponents.FilterBar />
      </TaskComponents.Toolbar>

      <StudentComponents.StudentList />
      <StudentComponents.PaginationBar />
    </>
  );
};

export default Students;
