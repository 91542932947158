export const GET_MOMENT = "GET_MOMENT";
export const SET_MOMENT = "SET_MOMENT";
export const GET_STUDENTS_TRACKING = "GET_STUDENTS_TRACKING";
export const SET_STUDENTS_TRACKING = "SET_STUDENTS_TRACKING";

const defaultQueryParams = {
  page: 1,
  page_size: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
  client_filters: null,
};

export const setMoment = (data) => ({
  type: SET_MOMENT,
  payload: data,
});

export const getMoment = () => ({
  type: GET_MOMENT,
  payload: null,
});

export const getStudentsTracking = (queryParams = defaultQueryParams) => ({
  type: GET_STUDENTS_TRACKING,
  payload: queryParams,
});

export const setStudentsTracking = (results) => ({
  type: SET_STUDENTS_TRACKING,
  payload: results,
});
