import React from "react";
import { Form } from "react-bootstrap";
import { uuidv4 } from "utils";

const NRadioButtons = ({
  key,
  dataset,
  isDisabled,
  onChange,
  keyPosition,
  lastKeyPosition,
  style,
}) => {
  return (
    <div className="mt-2" style={style}>
      <div>{dataset.text}</div>
      <div className="mt-2">
        {dataset.answers.map((item, key) => {
          return (
            <div className="mt-1" key={`resu-${key}-${item.id}`}>
              <Form.Check
                id={`resu-${uuidv4(4)}-${item.id}`}
                name={`result-radiobuttons-questions-${dataset.id}`}
                type="radio"
                label={item.text}
                value={item.text}
                onChange={(event) =>
                  onChange(key, event.target.value, dataset, item)
                }
                custom
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NRadioButtons;
