export const GET_TEAMS = "GET_TEAMS";
export const SET_TEAMS = "SET_TEAMS";
export const REGISTER_ERROR = "REGISTER_ERROR";

const defaultQueryParams = {
  page: 1,
  page_size: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
  client_filters: null,
};

export const registerError = ({ err = null, key = "" }) => ({
  type: REGISTER_ERROR,
  payload: { err, key },
});
export const getTeams = (queryParams = defaultQueryParams) => ({
  type: GET_TEAMS,
  payload: queryParams,
});
export const setTeams = (results) => ({
  type: SET_TEAMS,
  payload: results,
});
