/* eslint-disable no-console */
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

const production = process.env.NODE_ENV !== "development";

function init() {
  if (production)
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DNS,
      environment: process.env.REACT_APP_ENVIRONMENT,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });
}

function log(message) {
  if (!production) {
    console.log(message);
  }
}

function error(message) {
  if (production) {
    Sentry.captureException(new Error(message));
  } else {
    console.error(message);
  }
}

export default {
  init,
  log,
  error,
};
