import React from "react";
import { Form } from "react-bootstrap";
import { capitalizeWord, replaceChars } from "utils";

const NFInput = ({ onChange, dataset, isDisabled }) => {
  const filter_field = dataset["filter_field"];
  return (
    <div>
      <Form.Control
        type="email"
        placeholder="Enter email"
        onChange={(event) => {
          onChange(filter_field, event.target.value, dataset);
        }}
        placeholderText={
          dataset ? capitalizeWord(replaceChars(dataset.label, "_", " ")) : ""
        }
        disabled={isDisabled}
      />
    </div>
  );
};

export default NFInput;
