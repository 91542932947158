import React from "react";
import { CardNimbi } from "components/Nimbiv2/styled";
import { ContainerTitle, TitleCard } from "./styles";
import { StepTitle } from "components/Nimbiv2/InitiativesComponent";
import { NIcon } from "components/Nimbiv2";
import { useTranslation } from "react-i18next";

const CardQuestionResume = ({
  formValues,
  edit,
  isExternal,
  onChangePosition,
  textNumber,
}) => {
  const { t } = useTranslation();

  return (
    <CardNimbi padding={"17px 35px 17px 18px"} bordercolor={"#3AC8AC"}>
      <ContainerTitle>
        <TitleCard>Cuestionario</TitleCard>
        <div style={{ display: "flex" }}>
          <StepTitle>
            {t("step_number", {
              number: textNumber,
            })}
          </StepTitle>
          {!formValues.clone && (
            <NIcon
              iconname="edit"
              style={{
                marginLeft: "18px",
                fontSize: "16px",
                cursor: "pointer",
              }}
              onClick={onChangePosition}
            />
          )}
          {/* {formValues.clone && (
            <div style={{ marginLeft: "18px", width: "16px" }} />
          )}  */}
        </div>
      </ContainerTitle>
      <div style={{ marginTop: "10px" }}>
        {formValues?.question_selected?.description ??
          "No se ha seleccionado ningun cuestionario."}
      </div>
    </CardNimbi>
  );
};

export default CardQuestionResume;
