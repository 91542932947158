import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form } from "components";
import { NButton } from "components/Nimbiv2";

const ResetFormComponent = ({ onSubmit, isPending }) => {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password === passwordConfirmation) {
      onSubmit(password);
    }
  };

  const isCreation = window.location.href.includes("create");

  return (
    <Form onSubmit={handleSubmit} className="mt-3">
      <Form.Group controlId="formBasicPassword">
        <Form.Label>Ingrese su nueva contraseña</Form.Label>
        <Form.Control
          type="password"
          placeholder="Ingrese password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="formBasicPasswordConfirmation">
        <Form.Label>
          {" "}
          Vuelva a ingresar nuevamente su nueva contraseña
        </Form.Label>
        <Form.Control
          type="password"
          placeholder="Confirme password"
          value={passwordConfirmation}
          onChange={(event) => setPasswordConfirmation(event.target.value)}
        />
      </Form.Group>
      <div style={{ marginTop: "30px" }}>
        <NButton
          variant="primary"
          type="submit"
          disabled={isPending}
          block
          colorbutton="#334FFF"
          hoverbuttom="#112C9C"
          border="8px"
          padding="10px 16px"
        >
          {isCreation ? "Crear" : "Cambiar"}
        </NButton>
      </div>
    </Form>
  );
};

ResetFormComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isPending: PropTypes.bool,
};

ResetFormComponent.defaultProps = { isPending: false };

export default ResetFormComponent;
