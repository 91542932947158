import React from "react";

const EmptyListSVG = () => {
  return (
    <svg
      width="275"
      height="200"
      viewBox="0 0 275 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="20.5"
        y="-0.00012207"
        width="200"
        height="200"
        rx="100"
        fill="#F1F6FD"
      />
      <g filter="url(#filter0_d_2055_4891)">
        <path
          d="M22.598 36.5061C22.0418 36.5066 21.5086 36.6988 21.1152 37.0404C20.7219 37.3821 20.5007 37.8454 20.5 38.3286V71.0127C20.5007 71.4959 20.7219 71.9592 21.1152 72.3009C21.5085 72.6426 22.0418 72.8347 22.5979 72.8353H212.561C213.117 72.8347 213.651 72.6425 214.044 72.3008C214.437 71.9592 214.658 71.4959 214.659 71.0127V38.3286C214.658 37.8455 214.437 37.3822 214.044 37.0405C213.651 36.6988 213.117 36.5066 212.561 36.5061H22.598Z"
          fill="white"
        />
        <path
          d="M200.006 64.0754C200.79 64.0735 201.542 63.7625 202.096 63.2105C202.65 62.6586 202.962 61.9107 202.963 61.1305C202.964 60.3504 202.654 59.6017 202.101 59.0482C201.549 58.4948 200.798 58.1818 200.014 58.1777C200.011 58.1777 200.009 58.1777 200.006 58.1777H60.2743C59.4902 58.1809 58.7394 58.493 58.1862 59.0457C57.6329 59.5983 57.3223 60.3466 57.3223 61.1266C57.3223 61.9066 57.6329 62.6548 58.1862 63.2075C58.7394 63.7602 59.4902 64.0722 60.2743 64.0754H200.006Z"
          fill="#E4ECFC"
        />
        <path
          d="M120.401 51.2644C120.791 51.2648 121.177 51.1889 121.537 51.0409C121.897 50.893 122.224 50.6758 122.499 50.402C122.775 50.1281 122.994 49.8028 123.143 49.4448C123.292 49.0868 123.369 48.7031 123.369 48.3155C123.369 47.928 123.292 47.5442 123.143 47.1862C122.994 46.8282 122.775 46.503 122.499 46.2291C122.224 45.9552 121.897 45.7381 121.537 45.5901C121.177 45.4421 120.791 45.3662 120.401 45.3667H60.2743C59.8847 45.3662 59.4989 45.4421 59.1389 45.5901C58.7789 45.7381 58.4517 45.9552 58.1761 46.2291C57.9005 46.503 57.6818 46.8282 57.5326 47.1862C57.3834 47.5442 57.3066 47.928 57.3066 48.3155C57.3066 48.7031 57.3834 49.0868 57.5326 49.4448C57.6818 49.8028 57.9005 50.1281 58.1761 50.402C58.4517 50.6758 58.7789 50.893 59.1389 51.0409C59.4989 51.1889 59.8847 51.2648 60.2743 51.2644H120.401Z"
          fill="#E4ECFC"
        />
        <ellipse
          cx="38.7584"
          cy="54.6709"
          rx="11.1329"
          ry="11.076"
          fill="#D9E1F2"
        />
      </g>
      <g filter="url(#filter1_d_2055_4891)">
        <path
          d="M52.598 81.8353C52.0418 81.8358 51.5086 82.028 51.1152 82.3697C50.7219 82.7113 50.5007 83.1746 50.5 83.6579V116.342C50.5007 116.825 50.7219 117.288 51.1152 117.63C51.5085 117.972 52.0418 118.164 52.5979 118.164H242.561C243.117 118.164 243.651 117.972 244.044 117.63C244.437 117.288 244.658 116.825 244.659 116.342V83.6579C244.658 83.1747 244.437 82.7114 244.044 82.3697C243.651 82.028 243.117 81.8359 242.561 81.8353H52.598Z"
          fill="white"
        />
        <path
          d="M230.006 109.404C230.79 109.402 231.542 109.091 232.096 108.539C232.65 107.987 232.962 107.239 232.963 106.459C232.964 105.679 232.654 104.93 232.101 104.377C231.549 103.823 230.798 103.51 230.014 103.506C230.011 103.506 230.009 103.506 230.006 103.506H90.2743C89.4902 103.509 88.7394 103.821 88.1862 104.374C87.6329 104.927 87.3223 105.675 87.3223 106.455C87.3223 107.235 87.6329 107.983 88.1862 108.536C88.7394 109.089 89.4902 109.401 90.2743 109.404H230.006Z"
          fill="#E4ECFC"
        />
        <path
          d="M150.402 96.5933C150.791 96.5938 151.177 96.5179 151.537 96.3699C151.897 96.2219 152.224 96.0048 152.5 95.7309C152.776 95.4571 152.994 95.1318 153.143 94.7738C153.293 94.4158 153.369 94.0321 153.369 93.6445C153.369 93.257 153.293 92.8732 153.143 92.5152C152.994 92.1572 152.776 91.832 152.5 91.5581C152.224 91.2842 151.897 91.0671 151.537 90.9191C151.177 90.7711 150.791 90.6952 150.402 90.6957H90.2748C89.8852 90.6952 89.4994 90.7711 89.1394 90.9191C88.7794 91.0671 88.4522 91.2842 88.1766 91.5581C87.9009 91.832 87.6823 92.1572 87.5331 92.5152C87.3839 92.8732 87.3071 93.257 87.3071 93.6445C87.3071 94.0321 87.3839 94.4158 87.5331 94.7738C87.6823 95.1318 87.9009 95.4571 88.1766 95.7309C88.4522 96.0048 88.7794 96.2219 89.1394 96.3699C89.4994 96.5179 89.8852 96.5938 90.2748 96.5933H150.402Z"
          fill="#E4ECFC"
        />
        <ellipse
          cx="68.7575"
          cy="99.9997"
          rx="11.1329"
          ry="11.076"
          fill="#D9E1F2"
        />
      </g>
      <g filter="url(#filter2_d_2055_4891)">
        <path
          d="M22.598 127.164C22.0418 127.165 21.5086 127.357 21.1152 127.699C20.7219 128.04 20.5007 128.504 20.5 128.987V161.671C20.5007 162.154 20.7219 162.618 21.1152 162.959C21.5085 163.301 22.0418 163.493 22.5979 163.494H212.561C213.117 163.493 213.651 163.301 214.044 162.959C214.437 162.617 214.658 162.154 214.659 161.671V128.987C214.658 128.504 214.437 128.041 214.044 127.699C213.651 127.357 213.117 127.165 212.561 127.164H22.598Z"
          fill="white"
        />
        <path
          d="M200.007 154.733C200.791 154.731 201.543 154.42 202.097 153.868C202.651 153.317 202.963 152.569 202.964 151.788C202.965 151.008 202.655 150.26 202.102 149.706C201.55 149.153 200.799 148.84 200.015 148.836C200.012 148.836 200.01 148.836 200.007 148.836H60.2752C59.4912 148.839 58.7404 149.151 58.1871 149.704C57.6339 150.256 57.3232 151.005 57.3232 151.785C57.3232 152.565 57.6339 153.313 58.1871 153.865C58.7404 154.418 59.4912 154.73 60.2752 154.733H200.007Z"
          fill="#E4ECFC"
        />
        <path
          d="M120.402 141.923C120.792 141.923 121.178 141.847 121.538 141.7C121.898 141.552 122.225 141.334 122.5 141.061C122.776 140.787 122.995 140.461 123.144 140.103C123.293 139.745 123.37 139.362 123.37 138.974C123.37 138.587 123.293 138.203 123.144 137.845C122.995 137.487 122.776 137.162 122.5 136.888C122.225 136.614 121.898 136.397 121.538 136.249C121.178 136.101 120.792 136.025 120.402 136.025H60.2753C59.8857 136.025 59.4999 136.101 59.1399 136.249C58.7798 136.397 58.4527 136.614 58.1771 136.888C57.9014 137.162 57.6828 137.487 57.5336 137.845C57.3844 138.203 57.3076 138.587 57.3076 138.974C57.3076 139.362 57.3844 139.745 57.5336 140.103C57.6828 140.461 57.9014 140.787 58.1771 141.061C58.4527 141.334 58.7798 141.552 59.1399 141.7C59.4999 141.847 59.8857 141.923 60.2753 141.923H120.402Z"
          fill="#E4ECFC"
        />
        <ellipse
          cx="38.7579"
          cy="145.329"
          rx="11.1329"
          ry="11.076"
          fill="#D9E1F2"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2055_4891"
          x="0.5"
          y="22.5061"
          width="234.159"
          height="76.3291"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.690196 0 0 0 0 0.729412 0 0 0 0 0.8 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2055_4891"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2055_4891"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_2055_4891"
          x="30.5"
          y="67.8353"
          width="234.159"
          height="76.3291"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.690196 0 0 0 0 0.729412 0 0 0 0 0.8 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2055_4891"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2055_4891"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_2055_4891"
          x="0.5"
          y="113.164"
          width="289"
          height="76.3291"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.690196 0 0 0 0 0.729412 0 0 0 0 0.8 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2055_4891"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2055_4891"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default EmptyListSVG;
