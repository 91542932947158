import styled from "styled-components";
import { Card } from "react-bootstrap";
const CardNimbi = styled(Card)`
  border-radius: ${(props) => (props.radius ? props.radius : "8px")};
  padding: ${(props) => (props.padding ? props.padding : "20px")};
  border: ${(props) => (props.border ? props.border : " 0px")};
  height: ${(props) => props.height ?? "100%"};
  .textBlue {
    color: var(--secondary-color);
  }
  .textBrand {
    color: var(--brand-color);
  }
  ${(props) =>
    props.bordercolor ? `border-left: 17px solid ${props.bordercolor};` : ""}
  ${(props) =>
    props.boxshadow
      ? "box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);"
      : ""}
`;

export default CardNimbi;
