import styled from "styled-components";

export const NumberCard = styled.div`
  font-size: 65px;
  color: ${(props) =>
    props.type === "student_risks" ? "#cc0200" : "var(--secondary-color)"};
  font-weight: bold;
  margin-bottom: 0px;
  line-height: 110%;
  display: flex;
  align-items: center;
  padding-top: 8px;
  @media only screen and (max-width: 1600px) {
    font-size: 50px;
  }
`;
export const BadgeCardB = styled.div`
  // max-width: 150px;
  // margin-left: 10px;
  background: ${(props) =>
    props.error ? "#fbe9e9" : props.background ?? "transparent"};
  padding: 4px 12px;
  font-size: var(--font-size-small);
  border-radius: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${(props) => (props.error ? "#e75454" : props.colorText ?? "#60cdbe")};
  strong {
    font-size: var(--font-size-md);
    text-align: center;
    color: ${(props) =>
      props.error ? "#e23232" : props.colorTextBold ?? "#0fcdb3"};
  }
`;

export const DividerV = styled.div`
heigth: 100%;
width: 1px;
background rgba(0,0,0,0.1);
margin-left:20px;
margin-right:20px;  
`;

export const TitleRisk = styled.h1`
  color: ${(props) => (props.textColor ? props.textColor : "red")};
  font-size: 23px;
  margin-bottom: 0px;
  margin-left: 10px;
`;
export const SubTitleRisk = styled.div`
  font-size: 15px;
  margin-left: 10px;
`;

export const ContainerRisk = styled.div`
  margin-bottom: auto;
  margin-top: 5px;
`;
export const ContainerRiskM = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;

export const ContainerRiskB = styled.div`
  margin-top: auto;
`;
