import styled from "styled-components";
import { Card, Accordion } from "react-bootstrap";

export const TitleCard = styled.div`
  font-family: "Nova";
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-size-caption);
  line-height: 120%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #334fff;
`;
export const Title = styled.div`
  font-family: "Nova";
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-size-small);
  line-height: 120%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #989da5;
`;
export const NameTitle = styled.div`
  font-family: "Nova";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  color: #334fff;
`;
export const AccordionDesign = styled(Accordion)`
  .card {
    border-radius: 0px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
  }
  .card-header {
    background-color: white;
    border-bottom: 0px;
    color: #2a2b2e;
    font-weight: 500;
    padding: 24px 24px;
  }
  .card:last-child {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
`;
export const CardBody = styled(Card.Body)`
  padding-top: 0px;
  padding-bottom: 24px;
  padding-right: 24px;
  padding-left: 24px;
  .flex-basis-50 {
    flex-basis: 50%;
  }
`;

export const ContainerItem = styled.div`
  color: #2a2b2e;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "16px")};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "0px"};
  .item-description {
    margin-top: 2px;
  }
`;
export const ZendeskTicket = styled.div`
  font-family: "Nova";
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-caption);
  line-height: 140%;
  color: #787c83;
`;

export const ZendeskDetail = styled.div`
  font-size: var(--font-size-md);
  margin-top: 2px;
`;
export const ZendeskItem = styled.div``;

export const ButtonCall = styled.div`
  display: flex;
  // padding: 10px 22px;
  background: var(--call-in-action);
  color: white;

  border-radius: 24px;
  width: fit-content;
`;

export const ContainerTags = styled.div`
  color: #787c83;
  font-size: var(--font-size-md);
  margin-top: 8px;
  flex-wrap: wrap;

  .flex-basis-50 {
    flex-basis: 50%;
  }
`;
