import React from "react";
import ContentLoader from "react-content-loader";

const CardIndicator = (props) => (
  <ContentLoader viewBox="0 0 240 214" height={214} width={220} {...props}>
    <rect x="3" y="3" rx="10" ry="10" width="300" height="140" />
    <rect x="6" y="150" rx="0" ry="0" width="292" height="20" />
    <rect x="4" y="175" rx="0" ry="0" width="239" height="20" />
    <rect x="4" y="200" rx="0" ry="0" width="274" height="20" />
  </ContentLoader>
);

CardIndicator.metadata = {
  name: "RJavlonbek",
  github: "RJavlonbek",
  description: "Blog item",
  filename: "BlogItem",
};

export default CardIndicator;
