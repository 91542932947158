import { APP_MODULES, getSessionFilters } from "utils";
import {
  getUsers,
  downloadUsersExcel,
  getUsertTrackingObjects,
} from "services/user.service";
import { handleErrorStatus } from "services/errors";
import * as UserActions from "../user.actions";

const handleErrorResponse = (err) => {
  const status = err instanceof Map ? err.get("status") : 500;
  if (status > 0) return handleErrorStatus(status);
};

export const fetchUsers = (initialParams = null) => {
  return (dispatch) => {
    const queryParams = getSessionFilters(APP_MODULES.USER.FILTER_NAME) ?? {};
    dispatch(UserActions.getUsers(initialParams ?? queryParams));
    getUsers({
      ...queryParams,
      ...initialParams,
    })
      .then(({ data }) => dispatch(UserActions.setUsers(data)))
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(UserActions.registerError({ err, key: "list" }));
      });
  };
};

export const downloadUsersExcelAction = () => {
  return () => {
    downloadUsersExcel().then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Listado de usuarios en Nimbi.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };
};

export const fetchUserTrackingObjects = (params = {}) => {
  return (dispatch) => {
    dispatch(UserActions.getUserTrackingObjects());
    getUsertTrackingObjects(params)
      .then(({ data }) => dispatch(UserActions.setUserTrakingObjects(data)))
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(UserActions.registerError({ err, key: "list" }));
      });
  };
};
