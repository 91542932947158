import React, { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "components";
import { passwordRecovery } from "services/authService";
import FormRecoveryComponent from "./PasswordRecoveryForm";
import styles from "./Form.module.css";

const PasswordRecoveryForm = () => {
  const rowStyles = `justify-content-md-center align-items-center ${styles.loginBlock}`;
  const { t } = useTranslation();
  const [isPending, setPending] = useState(false);

  const submitForm = async (user) => {
    setPending(true);
    const { status, message } = await passwordRecovery(user);

    if (status === 200) {
      toast.info(
        t("password_recovery_message")
      );
    } else {
      if (status === 429) {
        let second = message.match(/\d/g).join("");
        if (second === '1'){
          toast.error(t("password_recovery_message_throttle", {seconds: second}));
        }
        else{
          toast.error(t("password_recovery_message_throttle_plural", {seconds: second}));
        }
      }
      else {
        toast.error(message);
      }
    }
    setPending(false);
  };

  return (
    <Container style={{ height: "85%" }}>
      <Row className={rowStyles}>
        <Col md={4}>
          <FormRecoveryComponent onSubmit={submitForm} isPending={isPending} />
        </Col>
      </Row>
    </Container>
  );
};

export default PasswordRecoveryForm;
