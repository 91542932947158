import React, { useMemo } from "react";
import ProtectedBlock from "routes/ProtectedBlock";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NMenuItem } from "./NSiderBar.styled";

const MenuItemNormal = ({
  active = true,
  allowedCode,
  title,
  url,
  icon,
  collapsed,
  onClick,
}) => {
  const location = useLocation();
  const primaryLocation = useMemo(() => {
    let pathname = location.pathname;
    let array = pathname.split("/").filter((item) => item !== "");
    return array.length > 0 ? array[0] : "dashboard";
  }, [location]);
  const renderTooltip = (props) => {
    if (collapsed) {
      return (
        <Tooltip id="button-tooltip" {...props}>
          {title}
        </Tooltip>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <ProtectedBlock allowedCode={allowedCode} active={active}>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <NMenuItem
          collapsedx={collapsed ? 1 : 0}
          icon={<FontAwesomeIcon icon={icon} />}
          active={primaryLocation === `${url}`}
          onClick={onClick}
        >
          {title}
          <Link to={`/${url}`} aria-hidden="true"></Link>
        </NMenuItem>
      </OverlayTrigger>
    </ProtectedBlock>
  );
};
export default MenuItemNormal;
