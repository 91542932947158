import styled from "styled-components";
import { Tabs } from "react-bootstrap";
const NTabs = styled(Tabs)`
  margin-bottom: ${(props) =>
    props.marginbottom ? props.marginbottom : "22px"} !important;
  font-size: var(--font-size-lg);
  .active {
    background: transparent !important;
    border-color: transparent transparent var(--secondary-color) !important;
    border-bottom-width: 2px;
    color: var(--secondary-color) !important;
    font-weight: bold;
  }
  .nav-item {
    color: #2a2b2e;
    font-size: var(--font-size-lg);
    padding: 16px 8px;
  }
  ${(props) =>
    props.scroll
      ? `overflow-x: auto;
      overflow-y: hidden;
      flex-wrap: nowrap;`
      : ""}

  ${(props) =>
    props.scroll
      ? `.nav-link {
    white-space: nowrap;
  }`
      : ""}
`;
export default NTabs;
