import { generateUrlWithParams } from "utils";

import api from "./api";

export const getAssociateds = (queryParams = null) => {
  const finalUrl = generateUrlWithParams("crm/associateds/", queryParams);
  return api.get(finalUrl);
};

export const getAssociatedRoles = () => {
  return api.get("crm/associateds/roles/");
};

export const getAssociatedTrackingObjects = () => {
  return api.get("crm/associateds/tracking_objects/");
};

export const createAssociated = async (payload) => {
  try {
    const response = await api.post(`crm/associateds/`, payload);
    return Promise.resolve(response);
  } catch (err) {
    //logger.log(err);
    return Object.fromEntries(err);
  }
};
