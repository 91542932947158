import { useMemo } from "react";
import { getDataFromLocalStorage } from "utils";
import jsonDefault from "utils/defaults.json";
import _ from "lodash";

const useDefault = (key, initialValue = "") => {
  const user = getDataFromLocalStorage("user");

  const defaultValue = useMemo(() => {
    if (!user) return initialValue;

    if (key in user.menu) return user.menu[key];

    return initialValue;
  }, [user, key, initialValue]);

  return defaultValue;
};

export const getDefaultValue = (key) => {
  const { client_area: clientArea = "" } = getDataFromLocalStorage("user", {});

  const jsonValue = _.get(
    jsonDefault,
    clientArea.toLowerCase(),
    jsonDefault["default"]
  );
  return jsonValue[key] ?? "";
};
export default useDefault;
