export const ACTIONS = {
  TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
  SET_ACTIVE_MODULE: "SET_ACTIVE_MODULE",
  SET_TEMP_FILTER: "SET_TEMP_FILTER",
  GET_TEMP_FILTER: "GET_TEMP_FILTER",
  CLEAN_ALL_TEMP_FILTER: "CLEAN_ALL_TEMP_FILTER",
  REGISTER_ERROR: "REGISTER_ERROR",
  GET_CLIENT_FILTERS: "GET_CLIENT_FILTERS",
  SET_CLIENT_FILTERS: "SET_CLIENT_FILTERS",
  LOAD_CLIENT_FILTERS: "LOAD_CLIENT_FILTERS",
  UPDATE_CLIENT_FILTERS: "UPDATE_CLIENT_FILTERS",
  GET_ROLES: "GET_ROLES",
  SET_ROLES: "SET_ROLES",
  GET_CLIENT_AREAS: "GET_CLIENT_AREAS",
  SET_CLIENT_AREAS: "SET_CLIENT_AREAS",
  CLEAN_FILTER: "CLEAN_FILTER",
  SET_CHANNEL_TASK: "SET_CHANNEL_TASK",
  SET_PUSHER: "SET_PUSHER",
};

export const registerError = (err) => ({
  type: ACTIONS.REGISTER_ERROR,
  payload: err,
});
export const toggleSidebar = (sw, attr = "isSidebarOpen") => ({
  type: ACTIONS.TOGGLE_SIDEBAR,
  payload: { sw, attr },
});

export const toggleCollapsedSidebar = (sw, attr = "isCollapsedOpen") => ({
  type: ACTIONS.TOGGLE_SIDEBAR,
  payload: { sw, attr },
});

export const setActiveModule = (moduleName) => ({
  type: ACTIONS.SET_ACTIVE_MODULE,
  payload: moduleName,
});

export const setTempFilter = (filters) => ({
  type: ACTIONS.SET_TEMP_FILTER,
  payload: filters,
});

export const getClientFilter = () => ({
  type: ACTIONS.GET_CLIENT_FILTERS,
  payload: null,
});

export const cleanAllFilter = () => ({
  type: ACTIONS.CLEAN_ALL_TEMP_FILTER,
  payload: null,
});
export const cleanFilter = (value) => ({
  type: ACTIONS.CLEAN_FILTER,
  payload: value,
});

export const setClientFilter = (data) => ({
  type: ACTIONS.SET_CLIENT_FILTERS,
  payload: data,
});

export const loadClientFilters = () => ({
  type: ACTIONS.LOAD_CLIENT_FILTERS,
  payload: null,
});

export const updateClientFilters = (newValue) => ({
  type: ACTIONS.UPDATE_CLIENT_FILTERS,
  payload: newValue,
});

export const getRoles = () => ({
  type: ACTIONS.GET_ROLES,
  payload: null,
});

export const setRoles = (data) => ({
  type: ACTIONS.SET_ROLES,
  payload: data,
});

export const getClientAreas = () => ({
  type: ACTIONS.GET_CLIENT_AREAS,
  payload: null,
});

export const setClientAreas = (data) => ({
  type: ACTIONS.SET_CLIENT_AREAS,
  payload: data,
});

export const setChannelTask = (value) => ({
  type: ACTIONS.SET_CHANNEL_TASK,
  payload: value,
});

export const setPusher = (value) => ({
  type: ACTIONS.SET_PUSHER,
  payload: value,
});