import React, { Component, createContext } from "react";
import { connect } from "react-redux";
import Pusher from "pusher-js";

import { toggleDisableTask, removeTask } from "store/actions/crm.actions";
import { typesTaskEvent, getDataFromLocalStorage } from "utils";
import { setPusher, setChannelTask } from "./actions/site.actions";

export const PusherContext = createContext();

class PusherContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastEvent: {},
      session: null,
    };
    this.eventSignal = this.eventSignal.bind(this);
  }

  componentDidMount() {
    if (
      !this.props.pusher ||
      this.props.pusher.connection.state !== "connected"
    ) {
      Pusher.logToConsole = process.env.NODE_ENV !== "production";
      const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        encrypted: true,
      });
      const taskChannel = pusher.subscribe(
        process.env.REACT_APP_PUSHER_CHANNEL
      );
      taskChannel.bind(process.env.REACT_APP_PUSHER_EVENT, this.eventSignal);
      this.props.setChannelTask(taskChannel);
      this.props.setPusher(pusher);
    } else if (this.props.taskChannel) {
      this.props.taskChannel.bind(
        process.env.REACT_APP_PUSHER_EVENT,
        this.eventSignal
      );
    }

    this.setState({ session: this.props.session });
  }

  eventSignal(data) {
    let { session } = this.state;
    session = session ?? getDataFromLocalStorage("user", null);
    const isExternal = getDataFromLocalStorage("isExternalInitiative", false);
    const auxTaskList = [...this.props.taskList];
    const { taskId, action, user, simpleTaskId } = data;
    if ((isExternal && simpleTaskId) || (taskId && !isExternal)) {
      const task = auxTaskList.find((t) => t.id === taskId);
      const found = typeof task !== "undefined";
      let isSameUser = true;

      if (session && user) {
        isSameUser = user.username === session.username;
      }

      if (!found || (isSameUser && action !== typesTaskEvent.COMPLETED))
        return false;

      if (action === typesTaskEvent.TAKEN) {
        this.props.toggleDisableTask({
          taskId: task.id,
          sw: true,
          assigned_to: user,
        });
      } else if (action === typesTaskEvent.RELEASED) {
        this.props.toggleDisableTask({
          taskId: task.id,
          sw: false,
          assigned_to: user,
        });
      } else if (action === typesTaskEvent.COMPLETED) {
        this.props.removeTask(task.id);
      }

      this.setState({ lastEvent: data });
    }
  }

  render() {
    return (
      <PusherContext.Provider
        value={{ lastNotification: this.state.lastEvent }}
      >
        {this.props.children}
      </PusherContext.Provider>
    );
  }
}

const mapStateToProps = (state) => ({
  taskList: state.crm.tasks,
  session: state.auth.user,
  taskChannel: state.site.taskChannel,
  pusher: state.site.pusher,
});

const mapDispatchToProps = (dispatch) => ({
  toggleDisableTask: (params) => dispatch(toggleDisableTask(params)),
  removeTask: (taskId) => dispatch(removeTask(taskId)),
  setChannelTask: (value) => dispatch(setChannelTask(value)),
  setPusher: (value) => dispatch(setPusher(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PusherContextProvider);
