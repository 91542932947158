import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faUser,
  faFlag,
  faSignOutAlt,
  faExternalLinkAlt,
} from "@fortawesome/pro-regular-svg-icons";
import {
  Navbar,
  Nav,
  Modal,
  Row,
  Col,
  Form,
  Image,
  Dropdown,
  ModalReport,
} from "components";
import { USER_GROUPS } from "routes/helpers";
import "theming/_nimbi-theme.sass";
import { toggleSidebar } from "store/actions/site.actions";
import { clearSession } from "store/actions/auth.actions";
import styles from "./Task.module.css";
import { cleanAllFilter } from "store/actions/site.actions";
import { cleanThemeColor, clearCookies } from "utils";
const NavBar = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isSidebarOpen, activeModule } = useSelector((state) => state.site);
  const { token, user } = useSelector((state) => state.auth);

  const [profile, setProfile] = useState(null);
  const [show, setShow] = useState(false);
  const [showModalReport, setShowModalReport] = useState(false);

  useEffect(() => {
    if (!token) {
      history.replace({ pathname: "/" });
    }
  }, [token, history]);

  const getRole = (user) => {
    for (const group of user.groups) {
      const roleFound = Object.entries(USER_GROUPS).find(
        (item) => item[1].ROLE === group
      );
      if (roleFound !== undefined) {
        return USER_GROUPS[roleFound[0]].ROLE_NAME;
      }
    }
  };

  useEffect(() => {
    if (user) {
      let fullName = `${user.first_name} ${user.last_name}`;
      fullName = String(fullName).trim() === "" ? user.username : fullName;

      setProfile({
        fullName,
        occupation: getRole(user),
      });
    }
  }, [user]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getUserThumb = (photo, isBig = false) => {
    if (photo) {
      const imgStyles = { height: isBig ? 100 : 52, width: "auto" };
      return (
        <Image
          style={imgStyles}
          src={user.profile_picture}
          alt="thumb"
          roundedCircle
        />
      );
    }

    return <FontAwesomeIcon icon={faUser} size={isBig ? "5x" : "1x"} />;
  };

  const NToggle = React.forwardRef(({ onClick }, ref) => (
    <div
      className="d-flex justify-content-end align-items-center"
      ref={ref}
      aria-hidden="true"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div className="d-flex flex-column align-items-end">
        {profile ? (
          <>
            <div className={styles.sessionName}>{profile.fullName}</div>
            <div className={styles.sessionJob}>{profile.occupation}</div>
          </>
        ) : null}
      </div>
      <div className={`d-flex rounded-circle ${styles.thumb}`}>
        {getUserThumb(user?.profile_picture)}
      </div>
    </div>
  ));

  const openModalReport = () => setShowModalReport(true);
  const onCloseModalReport = () => setShowModalReport((sw) => !sw);

  const logout = () => {
    dispatch(cleanAllFilter());
    cleanThemeColor();
    dispatch(clearSession());
    clearCookies();
    window.location.reload();
  };

  return (
    <header>
      <Navbar className={styles.navBlock} bg="white" expand="lg">
        <FontAwesomeIcon
          className={styles.barsButton}
          icon={faBars}
          onClick={() => dispatch(toggleSidebar(!isSidebarOpen))}
        />
        <Navbar.Brand className="font-weight-bold ml-3">
          <span
            className={styles.mainTitle}
            onClick={() => dispatch(toggleSidebar(!isSidebarOpen))}
            aria-hidden="true"
          >
            {activeModule}
          </span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="w-100 mr-auto justify-content-center">{children}</Nav>
          <Dropdown className={styles.profileMenu}>
            <Dropdown.Toggle as={NToggle} id="nimbi-dropdown" />

            <Dropdown.Menu alignRight>
              <Dropdown.Item onClick={handleShow}>
                <FontAwesomeIcon
                  className={`mr-2 ${styles.menuIcon}`}
                  icon={faUser}
                />
                <span className={styles.menuIcon}> Mis datos</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={openModalReport}>
                <FontAwesomeIcon
                  className={`mr-2 ${styles.menuIcon}`}
                  icon={faFlag}
                />
                <span className={styles.menuIcon}>Reportar error</span>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={logout}>
                <FontAwesomeIcon
                  className={`mr-2 ${styles.menuIcon}`}
                  icon={faSignOutAlt}
                />
                <span className={styles.menuIcon}> Logout</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar.Collapse>
      </Navbar>
      <Modal className={styles.modalProfile} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span className={styles.titleHeader}>Mis Datos</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={`px-4 ${styles.bodyContainer}`}>
          <Row>
            <Col className="d-flex justify-content-center" md={4}>
              {getUserThumb(user?.profile_picture, true)}
            </Col>
            <Col md={8}>
              {user ? (
                <Form>
                  <Form.Group controlId="formUserName">
                    <Form.Label>Nombre completo</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Nombre"
                      value={`${user.first_name} ${user.last_name}`}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group controlId="formUserPosition">
                    <Form.Label>Cargo</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Cargo"
                      value={profile?.occupation}
                      disabled
                    />
                  </Form.Group>
                </Form>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer
          className={`d-flex justify-content-start px-4 ${styles.footerContainer}`}
        >
          <span>Ver en Panel</span>
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </Modal.Footer>
      </Modal>

      <ModalReport isVisible={showModalReport} onClose={onCloseModalReport} />
    </header>
  );
};

export default NavBar;
