import React, { useState, useEffect } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { NCallingInfo, NProfileTabs } from "components";
import { updateTask, editPersonProfile } from "services/crmService";
import {
  getTaskList,
  getStats,
} from "store/actions/async/dashboard-async.actions";
import { ContactInfo } from "models";
import moment from "moment";
import CallingActionHeader from "./CallingActionHeader";

import styles from "./Dashboard.module.css";
import { useTranslation } from "react-i18next";

const DashAction = ({ task, onSaved }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { personsProfile, personHistory } = useSelector((state) => state.crm);

  const [payload, setPayload] = useState({
    call_comment: null,
    call_response: null,
    comments: null,
    student_response: null,
    studentId: null,
    birth_date: null,
    contactinfo_set: [],
    first_name: null,
    gender: null,
    last_name: null,
  });
  const [isSaving, setSaving] = useState(false);
  const [person, setPerson] = useState(null);

  useEffect(() => {
    if (personsProfile) {
      let contacts = [];

      if ("contactinfo_set" in personsProfile) {
        contacts = new ContactInfo(personsProfile.contactinfo_set).getList();
      }

      const auxProfile = { ...personsProfile, contactinfo_set: contacts };
      setPerson(auxProfile);
    }
  }, [personsProfile]);

  const updatePayload = ({ key, value }) => {
    setPayload((current) => ({ ...current, [key]: value }));
  };

  const updateStudent = ({ key, value }) => {
    const auxPerson = { ...person, [key]: value };
    setPerson(auxPerson);
  };

  const saveTask = async () => {
    setSaving(true);
    const errorMessage = getErrorMessage(payload);

    if (errorMessage) {
      setSaving(false);
      toast.error(errorMessage);
      return false;
    }

    // prepare Person's Payload
    const finalPerson = { ...person };
    const contacts = [...finalPerson.contactinfo_set].map((c) =>
      c.deserialize()
    );
    finalPerson.contactinfo_set = contacts;
    finalPerson.birth_date = moment(finalPerson.birth_date).format(
      "YYYY-MM-DD"
    );
    const studentPayload = { studentId: finalPerson.id, payload: finalPerson };

    // save all data...
    await Promise.all([
      updateTask(task.id, payload, task.external),
      editPersonProfile(studentPayload),
    ]);
    dispatch(getTaskList());
    dispatch(getStats());

    setSaving(false);
    onSaved();
  };

  return (
    <div className={styles.actionBlockContent}>
      <div className={styles.tabProfileContent}>
        <NProfileTabs
          person={person}
          taskHistory={personHistory}
          onUpdate={updateStudent}
        />
      </div>
      <NCallingInfo
        task={task}
        resultCategory="exception"
        onChange={updatePayload}
        hideSaveButton
      >
        <CallingActionHeader task={task} />
      </NCallingInfo>
      <Button
        className={`my-3 ${styles.callingSaveButton}`}
        variant="primary"
        onClick={saveTask}
        disabled={isSaving}
      >
        {_.upperFirst(t("save"))}
      </Button>
    </div>
  );
};

DashAction.propTypes = {
  task: PropTypes.shape({}).isRequired,
  onSaved: PropTypes.func.isRequired,
};

DashAction.defaultProps = {
  //
};

const getErrorMessage = (payload) => {
  let errorMessage = null;
  if (!payload.call_response) {
    errorMessage = "Indique el resultado de la llamada para terminar la tarea";
  } else if (!payload.student_response) {
    errorMessage = "Indique la reacción del alumno para terminar la tarea";
  }

  return errorMessage;
};

export default DashAction;
