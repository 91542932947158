import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CardNimbi } from "components/Nimbiv2/styled";
import { fetchStudentRiskHistory } from "store/actions/async/student-async.actions";
import { Col } from "react-bootstrap";
import { NPagination } from "components";
import { NTable } from "components/Nimbiv2";
import { useTranslation } from "react-i18next";
import { applyTextType } from "utils";

const RiskHistoryPage = ({ student_tracking_id }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const {
    historyRisk,
    pending: { historyRisk: isPending },
    paginationRisk,
  } = useSelector((state) => state.student);
  useEffect(() => {
    if (student_tracking_id) {
      dispatch(
        fetchStudentRiskHistory(id, {
          student_tracking_id: student_tracking_id,
          page: 1,
          page_size: 10,
        })
      );
    }
  }, [student_tracking_id, id, dispatch]);

  const loadPage = useCallback(
    (pageNumber, pageSize) => {
      if (student_tracking_id) {
        dispatch(
          fetchStudentRiskHistory(id, {
            student_tracking_id: student_tracking_id,
            page: pageNumber,
            page_size: parseInt(pageSize, 10),
          })
        );
      }
    },
    [student_tracking_id, id, dispatch]
  );
  const columns = [
    {
      title: "Riesgo",
      dataIndex: "risk_score",
      key: "risk_score",
      render: (text) => {
        return (
          <strong className="font-size-caption">
            {text === null ? t("not_defined") : `${text * 100}%`}
          </strong>
        );
      },
    },
    {
      title: t("date"),
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <strong className="font-size-caption">
          {applyTextType(text, "date", null, null)}
        </strong>
      ),
    },
  ];
  return (
    <CardNimbi>
      <NTable columns={columns} dataSource={historyRisk} />
      <div>
        <div className="mt-4 pb-4">
          <Col
            style={{ paddingRight: 0 }}
            className="d-flex align-items-center justify-content-end"
          >
            <NPagination
              isPending={isPending}
              pagination={paginationRisk}
              onPageChange={loadPage}
            />
          </Col>
        </div>
      </div>
    </CardNimbi>
  );
};
export default RiskHistoryPage;
