import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Col, Image } from "react-bootstrap";
import { logoNimbiColor } from "assets/images";

const NNavbar = ({ image, itemsCenter = true, children, showLink = false }) => {
  return (
    <Header itemsCenter={itemsCenter}>
      <Col md="3"></Col>
      <Col md="6" className="d-flex justify-content-center align-items-center">
        {!children && (
          <Image
            style={{ border: 0, height: "43.02px" }}
            src={image ? image : logoNimbiColor}
          />
        )}
        {children && <>{children}</>}
      </Col>
      <Col md="3" className="d-flex justify-content-center align-items-center ">
        {showLink && (
          <a
            className="text-link d-none d-sm-none d-md-block"
            href="https://nimbiedu.com/"
          >
            Conoce más de Nimbi
          </a>
        )}
      </Col>
    </Header>
  );
};

export default NNavbar;

NNavbar.propTypes = { itemsCenter: PropTypes.bool };

const Header = styled.div`
  display: flex;
  // ${(props) => (props.itemsCenter ? "justify-content: center;" : "")}
  // align-items: center;
  width: 100%;
  height: 75.56px;
  background: #ffffff;
  .text-link {
    font-weight: 600;
    font-size: var(--font-size-lg);
    line-height: 150%;
    /* identical to box height, or 24px */

    display: flex;
    align-items: center;
    text-decoration-line: underline;

    /* Blue Extended/500 */

    color: #334fff;
  }
`;
