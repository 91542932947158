import api from "./api";
import logger from "services/logService";
import { generateUrlWithParams } from "utils";

export const getClientFilters = (payload) => {
  return api.post("crm/client-area/filters_v2/", payload);
};

export const getRoles = () => {
  return api.get("accounts/user/roles/?page_size=20");
};

export const getClientAreas = () => {
  return api.get("accounts/user/client_areas/");
};

export const getLogo = () => {
  const backendURL = process.env.REACT_APP_API_URL.replace(/\/?$/, "/"); // add '/' to url if not exists
  api.defaults.baseURL = `${backendURL}public/api/`;
  return api.get("logo");
};

export const callSearch = async (url, queryParams) => {
  const finalUrl = generateUrlWithParams(url, queryParams);
  try {
    const response = await api.get(finalUrl);
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return err;
  }
};

export const getSurveys = () => {
  return api.post("third_party/delighted/get_surveys");
};
export const saveSurveys = (data) => {
  return api.post("third_party/delighted/send_response", data);
};
