import React from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NBadge } from "components";
import { colorsBadge } from "utils/constants";
import {
  faArrowCircleUp,
  faArrowCircleDown,
  faMinusCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

const RiskStudentBadge = ({ risk }) => {
  const { t } = useTranslation();
  if (risk) {
    if (risk.category === "Alto") {
      return (
        <NBadge
          outlined
          newColor={colorsBadge.dark.danger}
          icon={
            <FontAwesomeIcon
              icon={faArrowCircleUp}
              className="mr-1"
              style={{ fontSize: "11px" }}
            />
          }
          text={`${_.upperFirst(t("high"))} ${risk.score}%`}
          customStyle={{ borderRadius: "64px" }}
          big
          textTooltip={risk?.risk_tag}
        />
      );
    }
    if (risk.category === "Medio") {
      return (
        <NBadge
          outlined
          newColor={colorsBadge.dark.warning}
          icon={
            <FontAwesomeIcon
              icon={faMinusCircle}
              className="mr-1"
              style={{ fontSize: "11px" }}
            />
          }
          text={`${_.upperFirst(t("medium"))} ${risk.score}%`}
          customStyle={{ borderRadius: "64px" }}
          big
          textTooltip={risk?.risk_tag}
        />
      );
    }
    if (risk.category === "Bajo") {
      return (
        <NBadge
          outlined
          newColor={colorsBadge.dark.success}
          icon={
            <FontAwesomeIcon
              icon={faArrowCircleDown}
              className="mr-1"
              style={{ fontSize: "11px" }}
            />
          }
          text={`${_.upperFirst(t("low"))} ${risk.score}%`}
          customStyle={{ borderRadius: "64px" }}
          big
          textTooltip={risk?.risk_tag}
        />
      );
    }
  }
  return <div style={{ textAlign: "center" }}>{t("no_information_line")}</div>;
};

export default RiskStudentBadge;

// const ContainerNoInformation = styled.span`
//   font-style: normal;
//   font-weight: bold;
//   font-size: 9px;
//   line-height: 16px;
//   text-transform: uppercase;
// `;
