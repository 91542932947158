import { APP_MODULES } from "utils";
import {
    getGenerationEvents,
    getGenerations,
    getTrackingObjects,
} from "services/generation.service";
import { handleErrorStatus } from "services/errors";
import * as GenerationActions from "../generation.actions";
import { getValueFilter } from "utils/filters";

const handleErrorResponse = (err) => {
  const status = err instanceof Map ? err.get("status") : 500;
  if (status > 0) return handleErrorStatus(status);
};

export const fetchGenerationEvents = (initialParams = null) => {
  return (dispatch) => {
    const queryParams =
      getValueFilter(APP_MODULES.GENERATION_EVENTS.FILTER_NAME) ?? {};

    const mergeParams = {
      ...queryParams,
      ...initialParams,
    };
    for (let x in mergeParams) {
      if (typeof mergeParams[x] == "object") {
        mergeParams[x] = mergeParams[x]["id"];
      }
    }
    dispatch(GenerationActions.getGenerationEvent(mergeParams));
    getGenerationEvents({
      ...queryParams,
      ...initialParams,
    })
      .then(({ data }) => {
        dispatch(GenerationActions.setGenerationEvent(data));
      })
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(GenerationActions.registerError({ err, key: "list" }));
      });
  };
};

export const fetchGenerations = () => {
  return (dispatch) => {
    getGenerations()
      .then(({ data }) => {
        dispatch(GenerationActions.setGeneration(data));
      })
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(GenerationActions.registerError({ err, key: "list" }));
      });
  };
};

export const fetchGenerationTrackingObjects = () => {
  return (dispatch) => {
    getTrackingObjects()
      .then(({ data }) => {
        dispatch(GenerationActions.setTrackingObject(data));
      })
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(GenerationActions.registerError({ err, key: "list" }));
      });
  };
};
