import { ACTIONS } from "store/actions/user.actions";

const initialState = {
  list: [],
  tracking_objects: [],
  pagination: {
    count: 0,
    next: null,
    previous: null,
    page: 1,
    pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
  },
  pending: {
    list: false,
    tracking_objects: false,
  },
};

const userReducers = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_USERS: {
      return {
        ...state,
        pending: { ...state.pending, list: true },
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          pageSize: action.payload.page_size,
        },
      };
    }

    case ACTIONS.SET_USERS: {
      const { count, next, previous, results } = action.payload;
      let auxPagination = { ...state.pagination };
      auxPagination = { ...auxPagination, count, next, previous };

      return {
        ...state,
        list: results,
        pending: { ...state.pending, list: false },
        pagination: auxPagination,
      };
    }
    case ACTIONS.REMOVE_USER: {
      const auxList = [...state.list];

      return {
        ...state,
        list: auxList.filter((user) => user.id !== action.payload),
      };
    }

    case ACTIONS.GET_TRACKING_OBJECTS: {
      return {
        ...state,
        pending: { ...state.pending, tracking_objects: true },
      };
    }
    case ACTIONS.SET_TRACKING_OBJECTS: {
      const data = action.payload;

      return {
        ...state,
        tracking_objects: data,
        pending: { ...state.pending, tracking_objects: false },
      };
    }

    default:
      return { ...state };
  }
};

export default userReducers;
