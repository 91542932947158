import styled from "styled-components";
import { Container } from "react-bootstrap";

export const FirstCircle = styled.div`
  height: 54px;
  width: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2.4px solid var(--brand-color);
`;

export const SecondCircle = styled.div`
  height: 44px;
  width: 44px;
  background: green;
  border-radius: 50%;
  background: url(${(props) => props?.src});
  background-size: cover;
`;

export const ContainerNav = styled.div`
  display: flex;
  align-items: center;
`;

export const DropdownToggle = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const ContainerDesign = styled(Container)`
  ${(props) =>
    props.textwhite === 1
      ? `color: #FFFFFF !important;
  .fontNova a{color: #FFFFFF !important;}
  .fontNova span{color: #FFFFFF !important;}
  div{color: #FFFFFF !important;}
  .dropdown-item{
    color: #212529 !important;
  }
  .dropdown-item .a{
    color: #212529 !important;
  }
  `
      : ""}
  z-index: 100;
  .image-circle {
    width: ${(props) => (props.sizeCircle ? props.sizeCircle : "57px")};
    height: ${(props) => (props.sizeCircle ? props.sizeCircle : "57px")};
    border-radius: 50%;
    border: 4px solid white;
    transform: translateY(28px);
    background-color: #edf1f7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${(props) =>
      props.sizeTextCircle ? props.sizeTextCircle : "16px"};
    font-weight: 400;
    z-index: 1;
    position: absolute;
    top: 65px;
    color: black !important;
  }
`;

export const ContainerBackground = styled.div`
  width: 100%;
  min-height: 160px;
  position: absolute;
  right: 0;
  background: url("${(props) => props.image}");
  background-size: cover;
  z-index: 0;
`;
