import {
  SET_HELP_ITEMS,
  SET_HELP_ITEM_TYPES,
  SET_IS_LOADING,
  GET_HELP_ITEMS,
} from "store/actions/help.actions";

const initialState = {
  isLoading: false,
  helpItemTypes: [],
  helpItems: [],
  isLoadingItems: false,
  pagination: {
    count: 0,
    next: null,
    previous: null,
    page: 1,
    pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
  },
};

const helpReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_HELP_ITEMS: {
      return {
        ...state,
        isLoadingItems: true,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          pageSize: action.payload.page_size,
        },
      };
    }

    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case SET_HELP_ITEM_TYPES: {
      return {
        ...state,
        helpItemTypes: action.payload,
      };
    }

    case SET_HELP_ITEMS: {
      const { count, next, previous, results } = action.payload;
      let auxPagination = { ...state.pagination };
      auxPagination = { ...auxPagination, count, next, previous };

      return {
        ...state,
        helpItems: results,
        pagination: auxPagination,
        isLoadingItems: false,
      };
    }

    default:
      return { ...state };
  }
};

export default helpReducers;
