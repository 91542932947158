import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getHelpItems } from "store/actions/async/help-async.actions";
import { FAQ, Glossary, HelpDetail } from "pages/Help/Details";
import { NSpinnerPoints } from "components/Nimbiv2";
import EmptyContainer from "./EmptyContainer";
import { NPagination } from "components";

const HelpCenterDetailContainer2 = ({ itemType, search, itemSelected }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getHelpItems({
        type: itemType,
        page_size: 10,
        page: 1,
      })
    );
  }, [dispatch, itemType]);

  const { helpItems, isLoadingItems, pagination } = useSelector(
    (state) => state.help
  );

  const searchItems = useCallback(
    (textSearch) => {
      dispatch(
        getHelpItems({
          type: itemType,
          page_size: 10,
          page: 1,
          search: textSearch,
        })
      );
    },
    [dispatch, itemType]
  );

  useEffect(() => {
    searchItems(search);
  }, [search, searchItems]);

  const loadPage = useCallback(
    (pageNumber, pageSize) => {
      const payload = {
        page: pageNumber,
        page_size: parseInt(pageSize, 10),
      };
      dispatch(
        getHelpItems({
          type: itemType,
          ...payload,
        })
      );
    },
    [itemType, dispatch]
  );

  if (isLoadingItems) return <NSpinnerPoints />;
  if (!itemSelected && !itemType) {
    return (
      <EmptyContainer text={"Seleccione una sección para mostrar resultados"} />
    );
  }
  return (
    <>
      {itemType === "faq" && (
        <FAQ items={helpItems} search={search} title={itemSelected?.name} />
      )}
      {itemType === "glossary" && (
        <Glossary
          items={helpItems}
          search={search}
          title={itemSelected?.name}
        />
      )}
      {itemType !== "faq" && itemType !== "glossary" && (
        <HelpDetail
          items={helpItems}
          search={search}
          title={itemSelected?.name}
        />
      )}
      <div style={{ marginTop: "20px" }}>
        <NPagination
          isPending={isLoadingItems}
          pagination={pagination}
          onPageChange={loadPage}
        />
      </div>
    </>
  );
};

export default HelpCenterDetailContainer2;
