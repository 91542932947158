import { combineReducers } from "redux";
import authReducers from "store/reducers/auth.reducers";
import siteReducers from "store/reducers/site.reducers";
import crmReducers from "store/reducers/crm.reducers";
import studentReducers from "store/reducers/student.reducers";
import reportReducers from "store/reducers/report.reducers";
import dashboardReducers from "store/reducers/dashboard.reducers";
import userReducers from "store/reducers/user.reducers";
import initiativeReducers from "store/reducers/initiative.reducers";
import helpReducers from "store/reducers/help.reducers";
import MomentReducers from "store/reducers/moment.reducers";
import integrationReducers from "store/reducers/integrations.reducers";
import taskReducers from "store/reducers/task.reducers";
import ExternalManagementReducers from "./externalManagement.reducers";
import teamReducers from "store/reducers/team.reducers";
import associatedReducers from "store/reducers/associated.reducers";
import generationReducers from "./generation.reducers";

export default combineReducers({
  auth: authReducers,
  site: siteReducers,
  crm: crmReducers,
  student: studentReducers,
  report: reportReducers,
  dashboard: dashboardReducers,
  user: userReducers,
  initiative: initiativeReducers,
  help: helpReducers,
  moment: MomentReducers,
  integrations: integrationReducers,
  task: taskReducers,
  team: teamReducers,
  externalManagement: ExternalManagementReducers,
  associated: associatedReducers,
  generation: generationReducers
});
