import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Col } from "react-bootstrap";
import NPagination from "components/NPagination";
import { getTaskList } from "store/actions/async/crm-async.action";
import { APP_MODULES, setSessionFilters, setDataToLocalStorage } from "utils";
import { RowItem } from "components/Nimbiv2/styled";

const PaginationBar = () => {
  const dispatch = useDispatch();

  const {
    tasks,
    pending: { list: isPending },
    pagination,
  } = useSelector((state) => state.crm);

  const loadPage = (pageNumber, pageSize) => {
    setDataToLocalStorage("avoidReloadTasks", true);
    setSessionFilters(APP_MODULES.TASK.FILTER_NAME, {
      page: pageNumber,
      page_size: Number(pageSize),
    });
    dispatch(getTaskList());
  };

  if (tasks.length === 0) return null;

  return (
    <Container
      fluid
      className="mt-4 pr-0 pl-0"
      style={{ paddingBottom: "90px" }}
    >
      <RowItem>
        <Col
          style={{ paddingRight: 0 }}
          className="d-flex align-items-center justify-content-end"
        >
          <NPagination
            isPending={isPending}
            pagination={pagination}
            onPageChange={loadPage}
          />
        </Col>
      </RowItem>
    </Container>
  );
};

export default PaginationBar;
