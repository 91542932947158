export const ACTIONS = {
  GET_USERS: "GET_USERS",
  SET_USERS: "SET_USERS",
  REGISTER_ERROR: "REGISTER_ERROR",
  REMOVE_USER: "REMOVE_USER",
  GET_TRACKING_OBJECTS: "GET_TRACKING_OBJECTS",
  SET_TRACKING_OBJECTS: "SET_TRACKING_OBJECTS",
};

// getting all users
const defaultQueryParams = {
  page: 1,
  page_size: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
  client_filters: null,
};

export const registerError = ({ err = null, key = "" }) => ({
  type: ACTIONS.REGISTER_ERROR,
  payload: { err, key },
});

export const getUsers = (queryParams = defaultQueryParams) => ({
  type: ACTIONS.GET_USERS,
  payload: queryParams,
});

export const setUsers = (results) => ({
  type: ACTIONS.SET_USERS,
  payload: results,
});

export const clearUser = (userID) => ({
  type: ACTIONS.REMOVE_USER,
  payload: userID,
});

export const getUserTrackingObjects = () => ({
  type: ACTIONS.GET_TRACKING_OBJECTS,
  payload: null,
});

export const setUserTrakingObjects = (results) => ({
  type: ACTIONS.SET_TRACKING_OBJECTS,
  payload: results,
});
