export const ACTIONS = {
  GET_ASSOCIATED: "GET_ASSOCIATED",
  SET_ASSOCIATED: "SET_ASSOCIATED",
  REGISTER_ERROR: "REGISTER_ERROR",
  GET_ROLES_ASSOCIATED: "GET_ROLES_ASSOCIATED",
  SET_ROLES_ASSOCIATED: "SET_ROLES_ASSOCIATED",
  GET_TRACKINGOBJECT_ASSOCIATED: "GET_TRACKINGOBJECT_ASSOCIATED",
  SET_TRACKINGOBJECT_ASSOCIATED: "SET_TRACKINGOBJECT_ASSOCIATED",
};

// getting all users
const defaultQueryParams = {
  page: 1,
  page_size: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
  client_filters: null,
};

export const registerError = ({ err = null, key = "" }) => ({
  type: ACTIONS.REGISTER_ERROR,
  payload: { err, key },
});

export const getAssociated = (queryParams = defaultQueryParams) => ({
  type: ACTIONS.GET_ASSOCIATED,
  payload: queryParams,
});

export const setAssociated = (results) => ({
  type: ACTIONS.SET_ASSOCIATED,
  payload: results,
});

export const getRolesAssociated = () => ({
  type: ACTIONS.GET_ROLES_ASSOCIATED,
  payload: null,
});

export const setRolesAssociated = (results) => ({
  type: ACTIONS.SET_ROLES_ASSOCIATED,
  payload: results,
});

export const getTrackingObjectAssociated = () => ({
  type: ACTIONS.GET_TRACKINGOBJECT_ASSOCIATED,
  payload: null,
});

export const setTrackingObjectAssociated = (results) => ({
  type: ACTIONS.SET_TRACKINGOBJECT_ASSOCIATED,
  payload: results,
});
