import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import { DashboardComponents } from "containers";
import { setActiveModule } from "store/actions/site.actions";
import { useDefault } from "hooks";
import { NNavbarDashboard, ScrollToTopOnMount } from "components/Nimbiv2";
import ListContainer from "containers/Dashboard/ListContainer";
import NSurvey from "components/Nimbiv2/NSurvey";
import { getSurveys } from "services/site.service";
import { getGlobalConfig } from "utils";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [survey, setSurvey] = useState(null);

  const dashboardModuleTitle = useDefault("dashboardModuleTitle", "Dashboard");
  useEffect(() => {
    if (dashboardModuleTitle)
      dispatch(
        setActiveModule({ title: dashboardModuleTitle, subtitle: null })
      );
  }, [dispatch, dashboardModuleTitle]);

  const fetchSurvey = async () => {
    const config = getGlobalConfig("enable_survey_cx", false);
    const showSurvey = localStorage.getItem("show_survey");
    if (config) {
      if (!showSurvey) {
        const { data, status } = await getSurveys();
        if (status === 200) {
          if (data?.data?.length > 0) {
            setOpen(true);
            setSurvey(data?.data[0]);
            localStorage.setItem("show_survey", 1);
          }
        }
      }
    }
  };

  useEffect(() => {
    fetchSurvey();
  }, []);

  return (
    <div style={{ paddingBottom: "30px" }}>
      <ScrollToTopOnMount />
      <NNavbarDashboard />

      <Container fluid>
        <DashboardComponents.FilterBar />
      </Container>
      <ListContainer />
      <NSurvey
        survey={survey}
        openSide={open}
        onClose={() => {
          setOpen(false);
        }}
      />
      <DashboardComponents.PaginationBar />
    </div>
  );
};

export default Dashboard;
