export const GET_EXTERNAL_MANAGEMENT = "GET_EXTERNAL_MANAGEMENT";
export const SET_EXTERNAL_MANAGEMENT = "SET_EXTERNAL_MANAGEMENT";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const GET_EXTERNAL_DETAIL = "GET_EXTERNAL_DETAIL";
export const SET_EXTERNAL_DETAIL = "SET_EXTERNAL_DETAIL";

// Initiatives
const defaultQueryParams = {
  page: 1,
  page_size: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
  client_filters: null,
};
export const registerError = ({ err = null, key = "" }) => ({
  type: REGISTER_ERROR,
  payload: { err, key },
});
export const getExternalManagement = (params = defaultQueryParams) => ({
  type: GET_EXTERNAL_MANAGEMENT,
  payload: params,
});

export const setExternalManagement = (data) => ({
  type: SET_EXTERNAL_MANAGEMENT,
  payload: data,
});

export const getExternalDetailTask = () => ({
  type: GET_EXTERNAL_DETAIL,
  payload: null,
});

export const setExternalDetailTask = (data) => ({
  type: SET_EXTERNAL_DETAIL,
  payload: data,
});
