import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFlag,
  faInfoCircle,
  faEnvelope,
  faPhoneAlt,
  faUserAlt,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { toast } from "react-toastify";
import { Collapse, Spinner } from "react-bootstrap";

import { Row, Col, OverlayTrigger, Image, Popover, NBadge } from "components";
import { EClassID, uuidv4 } from "utils";
import {
  fetchPersonsProfile,
  fetchStudentTrackingsPerson,
  fetchPersonHistory,
} from "store/actions/async/crm-async.action";
import { toggleCollapseTask } from "store/actions/dashboard.actions";
import moment from "moment";
import DashAction from "./DashAction";
import "moment/locale/es";
import styles from "./Dashboard.module.css";

// MOCK CONSTANTS
const isGettingTaskStatus = false;

const ListItem = ({ row, swModal }) => {
  const dispatch = useDispatch();
  const rowStyles = `align-items-center ${row.isDisabled ? styles.locked : ""}
   ${styles.customBox} mb-2 no-gutters`;

  const [userPhoto, setUserPhoto] = useState(null);

  useEffect(() => {
    if (row) {
      setUserPhoto(row.last_contact?.user_photo ?? null);
    }
  }, [row]);

  const popover = () => (
    <Popover className={styles.contactPopover} id="contact-popover">
      <Popover.Content>
        <div className={styles.title}>{row.last_contact.contact_reason}</div>
        {row.last_contact.closed_at ? (
          <div className={styles.dateInfo}>
            {moment(row.last_contact.closed_at).format("DD/MM/YYYY - HH:mm")}h
          </div>
        ) : null}
        {row.last_contact.user_name ? (
          <div className={styles.contactUser}>
            <FontAwesomeIcon className="mr-3" icon={faUserAlt} size="1x" />
            {row.last_contact.user_name}
          </div>
        ) : null}
        <div style={{ display: "none" }} className={styles.historyLink}>
          Ver historial completo
        </div>
      </Popover.Content>
    </Popover>
  );

  const loadStudentProfile = () => {
    const { student_id: studentId } = row.student_tracking;
    dispatch(fetchPersonsProfile(studentId));
    dispatch(fetchStudentTrackingsPerson(studentId));
    dispatch(fetchPersonHistory(studentId));
    dispatch(toggleCollapseTask({ taskId: row.id, sw: true }));
  };

  const toggleOpen = async () => {
    if (row.isDisabled) {
      toast.error(
        "Para mostrar esta información, debe cerrar las tareas que estén abiertas."
      );
      return false;
    }

    if (row.isOpened) {
      dispatch(toggleCollapseTask({ taskId: row.id, sw: false }));
    } else {
      loadStudentProfile();
    }
  };

  const printUserIcon = () => {
    return userPhoto ? (
      <Image
        className={`${styles.contactThumb} mb-2`}
        src={row.last_contact.user_photo}
        alt="photo"
        onClick={() => swModal(row)}
        aria-hidden="true"
        roundedCircle
      />
    ) : (
      <FontAwesomeIcon className="mb-2" icon={faUser} size="2x" />
    );
  };

  const displayLastContact = () => {
    if (row.last_contact) {
      const contactType = row.last_contact.channel;

      return (
        <div className="d-flex align-items-end">
          <div className="d-flex flex-column align-items-center">
            {contactType === "Call" ? (
              printUserIcon()
            ) : (
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                className="text-primary mb-2"
                size="2x"
                icon={faEnvelope}
                onClick={() => swModal(row)}
                aria-hidden="true"
              />
            )}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => swModal(row)}
              aria-hidden="true"
            >
              <u>{moment(row.last_contact?.closed_at).format("DD/MM/YYYY")}</u>
            </span>
          </div>

          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="right"
            overlay={popover()}
          >
            <FontAwesomeIcon className="text-muted ml-2" icon={faInfoCircle} />
          </OverlayTrigger>
        </div>
      );
    }

    return <span className={styles.notAssigned}>SIN REGISTRO</span>;
  };

  const getActionIcon = () => {
    let actionIcon = (
      <FontAwesomeIcon className="text-light" size="lg" icon={faPhoneAlt} />
    );

    if (row.channel === "Email")
      actionIcon = (
        <FontAwesomeIcon className="text-light" size="lg" icon={faEnvelope} />
      );

    return actionIcon;
  };

  const collapseTask = () => {
    dispatch(toggleCollapseTask({ taskId: row.id, sw: false }));
  };

  return (
    <Row className={`${styles.rowItem} ${rowStyles}`}>
      <Col xs={2}>
        <div
          className={`d-flex justify-content-left align-items-center ${styles.firstColumn}`}
        >
          <div className="d-flex flex-column justify-content-left align-items-cente">
            <div className={`mr-1 ${styles.rowText}`}>
              {row.student_tracking.full_name}&nbsp;&nbsp;
            </div>
            <div className={`mr-1 mt-2 ${styles.headerTitle}`}>
              <NBadge
                text={`${row.student_tracking.external_id}`}
                title={EClassID.STUDENT}
              />
              &nbsp;&nbsp;
              {row.student_tracking.tags.map((element) => (
                <NBadge key={uuidv4(4)} text={element.tag} kind="gray" />
              ))}
            </div>
            {row.priority ? (
              <div className={styles.callOverdueBlock}>
                <span>{row.priority}</span>&nbsp;
                <FontAwesomeIcon className="text-primary mr-2" icon={faFlag} />
              </div>
            ) : null}
          </div>
        </div>
      </Col>
      <Col xs={2}>
        <span className={`mr-1 ${styles.rowText}`}>
          {row.student_tracking.campus}
        </span>
      </Col>
      <Col xs={2}>
        <span className={`mr-1 ${styles.rowText}`}>
          {moment(row.created_at).format("DD/MM/YYYY")}
        </span>
      </Col>
      <Col className="d-flex align-items-center justify-content-between" xs={2}>
        <div
          className={`d-flex justify-content-left align-items-center ${styles.firstColumn}`}
        >
          <div className="d-flex flex-column justify-content-left align-items-cente">
            <div className={`mr-1 ${styles.rowText}`}>
              {displayLastContact()}
            </div>
          </div>
        </div>
      </Col>
      <Col className="d-flex justify-content-center" xs={3}>
        <span className={`mr-1 ${styles.rowText}`}>
          <FontAwesomeIcon className="mr-2" icon={faInfoCircle} size="1x" />
          {row.contact_reason}
        </span>
      </Col>
      <Col className={styles.actionCol} xs={1}>
        <div
          className={styles.actionColContent}
          onClick={() => toggleOpen()}
          aria-hidden="true"
        >
          <span className={styles.circleActionButton}>
            {isGettingTaskStatus ? (
              <Spinner animation="border" size="sm" variant="light" />
            ) : (
              getActionIcon()
            )}
          </span>
        </div>
      </Col>
      <Row style={{ margin: 0 }} className="w-100">
        <Col>
          <Collapse in={row.isOpened}>
            <div id="dash_collapse_content">
              <DashAction task={row} onSaved={collapseTask} />
            </div>
          </Collapse>
        </Col>
      </Row>
    </Row>
  );
};

ListItem.propTypes = {
  row: PropTypes.shape({}).isRequired,
  swModal: PropTypes.func.isRequired,
};

ListItem.defaultProps = {
  //
};

export default React.memo(ListItem);
