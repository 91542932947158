import { CardContainer } from "components/Nimbiv2/CardGeneral";
import React from "react";
import ContentLoader from "react-content-loader";
import styled from "styled-components";
const CardMomentSkeletons = (props) => {
  return (
    <CardContainerDesign>
      <ContentLoader
        speed={2}
        width="100%"
        height={410}
        viewBox="0 0 256.5 410"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <circle cx="125" cy="35" r="30" />
        <rect x="70" y="85" rx="0" ry="0" width="120" height="24" />
        <rect x="30" y="125" rx="0" ry="0" width="200" height="50" />
        <rect x="7" y="209" rx="0" ry="0" width="160" height="21" />
        <rect x="7" y="245" rx="0" ry="0" width="160" height="21" />
        <rect x="7" y="280" rx="0" ry="0" width="160" height="21" />
        <rect x="7" y="330" rx="24" ry="24" width="245" height="35" />
        <rect x="7" y="375" rx="24" ry="24" width="245" height="35" />
      </ContentLoader>
    </CardContainerDesign>
  );
};
export default CardMomentSkeletons;

const CardContainerDesign = styled(CardContainer)`
  padding: 0px 24px 0px 24px;
  @media (max-width: 800px) {
    padding: 20px 24px 20px 24px;
  }
  @media (min-width: 1560px) {
    padding: 20px 24px 20px 24px;
    max-height: 347px;
  }
`;
