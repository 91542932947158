import React, { useEffect } from "react";
import _ from "lodash";

import ItemZendesk from "./ItemZendesk";
import { useSelector, useDispatch } from "react-redux";
import { fetchZendesk } from "store/actions/async/integrations-async.actions";
import { ContainerEmpty, NSpinnerPoints } from "components/Nimbiv2";
import NPagination from "components/NPagination";
import { deleteLocalStorage, setSessionFilters } from "utils";
import { AnonymousContainer } from "components/Nimbiv2/styled";
import { useTranslation } from "react-i18next";

const TabZendesk = ({ person }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    pending: { zendesk: isPending },
    zendesk,
    paginationZendesk,
  } = useSelector((state) => state.integrations);

  const { user } = useSelector((state) => state.auth);

  const loadPage = (pageNumber, pageSize) => {
    setSessionFilters("filter_zendesk", {
      page: pageNumber,
      page_size: parseInt(pageSize, 10),
    });
    dispatch(fetchZendesk(person.id));
  };

  useEffect(() => {
    deleteLocalStorage("filter_zendesk");
    dispatch(fetchZendesk(person.id));
  }, [person, dispatch]);

  if (isPending) {
    return (
      <div className="d-flex " style={{ width: "100%", marginTop: "10px" }}>
        <NSpinnerPoints />
      </div>
    );
  }
  return (
    <div>
      {zendesk &&
        zendesk.map((item, key) => {
          return (
            <div key={`key-zendesk-item-${key}`}>
              <AnonymousContainer isAnonymous={user.anonymous}>
                <ItemZendesk item={item}></ItemZendesk>
              </AnonymousContainer>
            </div>
          );
        })}
      {zendesk && zendesk.length > 0 && (
        <div style={{ marginTop: "20px" }}>
          <NPagination
            isPending={isPending}
            pagination={paginationZendesk}
            onPageChange={loadPage}
          />
        </div>
      )}
      {zendesk && zendesk.length === 0 && (
        <div>
          <ContainerEmpty text={_.upperFirst(t("no_information_ticket"))} />
        </div>
      )}
    </div>
  );
};

export default TabZendesk;
