import React from "react";
import { Form } from "react-bootstrap";
import { CardNimbi, DividerH } from "components/Nimbiv2/styled";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useTranslationLocal } from "hooks";
import { ContainerEmpty } from "components/Nimbiv2";

const CallPattern = ({ isPending, task }) => {
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const { user } = useSelector((state) => state.auth);
  const htmlTemplate = (task) => {
    if (task && task?.script_template && task?.script_template?.script) {
      const scriptMarkdown = String(task.script_template.script).replaceAll(
        "[user_name]",
        `${user.first_name} ${user.last_name}`
      );
      return (
        // eslint-disable-next-line react/no-danger
        <div
          style={{ paddingBottom: 20 }}
          dangerouslySetInnerHTML={{ __html: scriptMarkdown }}
        />
      );
    }
    return <ContainerEmpty text={t("does_not_have_calling_pattern")} />;
  };
  return (
    <CardNimbi>
      {!isPending && task && (
        <div className="">
          <div className="d-flex justify-content-between header align-items-center">
            <div
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                lineHeight: "24px",
              }}
            >
              {translation(
                "call_pattern",
                "",
                t("callpattern.call_pattern"),
                task?.script_template?.extras
              )}
            </div>
            <Form.Control
              className="scriptSelect"
              as="select"
              onChange={() => {}}
            >
              <option value="1">{task?.script_template?.name}</option>
            </Form.Control>
          </div>
          <DividerH
            style={{ marginTop: "20px", marginBottom: "20px" }}
          ></DividerH>
          <div className="body">{htmlTemplate(task)}</div>
        </div>
      )}
    </CardNimbi>
  );
};

export default CallPattern;
