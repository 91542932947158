import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { Container, Row, Col } from "react-bootstrap";

import styles from "../Task/Task.module.css";
import { getText } from "utils";

const ListHeader = () => {
  return (
    <Container style={{ minWidth: 992 }}>
      <Row className={`align-items-center ${styles.customBoxHeader}`}>
        <Col xs={2}>
          <div
            className={`d-flex justify-content-left align-items-center ${styles.firstColumn}`}
          >
            <span className={`mr-1 ${styles.headerTitle}`}>Alumno</span>
            <FontAwesomeIcon
              className="d-none text-primary"
              icon={faChevronDown}
            />
          </div>
        </Col>
        <Col xs={2}>
          <span className={`mr-1 ${styles.headerTitle}`}>
            {getText("campus", "startCase")}
          </span>
          <FontAwesomeIcon
            className="d-none text-primary"
            icon={faChevronDown}
          />
        </Col>
        <Col xs={2}>
          <span className={`mr-1 ${styles.headerTitle}`}>Creada</span>
          <FontAwesomeIcon
            className="d-none text-primary"
            icon={faChevronDown}
          />
        </Col>
        <Col xs={2}>
          <span className={`mr-1 ${styles.headerTitle}`}>Ultima gestión</span>
          <FontAwesomeIcon
            className="d-none text-primary"
            icon={faChevronDown}
          />
        </Col>
        <Col xs={3} className="d-flex justify-content-left">
          <span className={`mr-1 ${styles.headerTitle}`}>
            Problema identificado
          </span>
          <FontAwesomeIcon
            className="d-none text-primary"
            icon={faChevronDown}
          />
        </Col>
        <Col xs={1}>
          <span className={`mr-1 ${styles.headerTitle}`}>Acción</span>
          <FontAwesomeIcon
            className="d-none text-primary"
            icon={faChevronDown}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ListHeader;
