import api from "./api";
import axios from "axios";

const signIn = async (userName, userPassword) => {
  const payload = {
    username: userName,
    password: userPassword,
  };

  try {
    const response = await api.post("accounts/token/", payload);
    return Promise.resolve(response);
  } catch (err) {
    return { ...Object.fromEntries(err), data: null };
  }
};

const signInSSO = async (username, accountId, accessToken, providerId) => {
  const payload = {
    username: username,
    account_id: accountId,
    access_token: accessToken,
    provider_id: providerId
  };
  try {
    const response = await api.post("accounts/sso/token/", payload);
    return Promise.resolve(response);
  } catch (err) {
    return { ...Object.fromEntries(err), data: null };
  }
};

const verifyGoogleToken = async (token) => {
  try {
    const response = await axios.get(
      `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${token}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }
    );
    const { data, status } = response;
    return { data, status };
  } catch (err) {
    console.error(err);
    return { data: err.message, status: err.response.status };
  }
};

const passwordRecovery = async (userName) => {
  const payload = {
    email: userName,
  };

  try {
    const response = await api.post("accounts/password-recovery/", payload);
    return Promise.resolve(response);
  } catch (err) {
    return { ...Object.fromEntries(err), data: null };
  }
};

const validateToken = async (token) => {
  const payload = {
    token,
  };

  try {
    const response = await api.post("accounts/validate-token/", payload);
    return Promise.resolve(response);
  } catch (err) {
    return { ...Object.fromEntries(err), data: null };
  }
};

const changePassword = async (token, password) => {
  const payload = {
    token,
    password,
  };

  try {
    const response = await api.post("accounts/password-reset/", payload);
    return Promise.resolve(response);
  } catch (err) {
    return { ...Object.fromEntries(err), data: null };
  }
};

export { signIn, signInSSO, passwordRecovery, validateToken, changePassword, verifyGoogleToken };
