import React from "react";
import { getGlobalConfig, uuidv4 } from "utils";
import { useSelector, useDispatch } from "react-redux";
import TaskHistory from "containers/Task/v2/TaskHistory";
import { CardNimbi } from "components/Nimbiv2/styled";
import { Col, Form, Row } from "react-bootstrap";
import { fetchPersonHistory } from "store/actions/async/crm-async.action";
import styles from "containers/Student/Student.module.css";
import { ContainerEmpty, NSpinnerPoints } from "components/Nimbiv2";
import EmptyListSVG from "assets/images/EmptyListSVG";

const HistoryTask = () => {
  const {
    personHistory,
    personMoments: studentMoments,
    personCourses: studentCourses,
    personsProfile: person,
    pending: { personhistory: isPendingPersonHistory },
  } = useSelector((state) => state.crm);

  const dispatch = useDispatch();

  const courseSelector = React.createRef();
  const momentSelector = React.createRef();

  const fetchTasks = async (filters) => {
    const queryFilters = {};
    Object.keys(filters)
      .filter((el) => filters[el] !== "0")
      .forEach((el) => {
        queryFilters[el] = filters[el];
      });
    return dispatch(fetchPersonHistory(person.id, queryFilters));
  };

  return (
    <div>
      <Row>
        <Col xs={6}>
          {studentCourses && studentCourses.length > 2 && (
            <div
              className={styles.dropDownContainer}
              style={{ marginBottom: "16px" }}
            >
              <div className={styles.dropDown}>
                <Form.Control
                  as="select"
                  ref={courseSelector}
                  style={{
                    background: "white",
                    padding: "14px 30px 14px 12px",
                    height: "49px",
                    width: "100%",
                  }}
                  onChange={(event) => {
                    if (momentSelector && momentSelector.current) {
                      momentSelector.current.value = "0";
                    }
                    fetchTasks({
                      course_id: event.target.value,
                      include_notes: (event.target.value === "0") | 0,
                    });
                  }}
                >
                  {studentCourses.map((course, key) => {
                    return (
                      <option key={`${course.id}-${key}`} value={course.id}>
                        {course.name}
                        {getGlobalConfig(
                          "show_tracking_object_external_id",
                          false
                        ) &&
                        course?.id &&
                        course?.id !== 0
                          ? ` - ${
                              person.tasks.find((task) => {
                                return task?.tracking_object?.id === course.id;
                              })?.tracking_object.external_id ?? ""
                            }`
                          : ""}
                      </option>
                    );
                  })}
                </Form.Control>
              </div>
            </div>
          )}
        </Col>
        <Col xs={6}>
          {studentMoments && studentMoments.length > 2 && (
            <div
              className={styles.dropDownContainer}
              style={{ marginBottom: "16px" }}
            >
              <div className={styles.dropDown}>
                <Form.Control
                  ref={momentSelector}
                  style={{
                    background: "white",
                    padding: "14px 30px 14px 12px",
                    height: "49px",
                    width: "100%",
                  }}
                  as="select"
                  onChange={(event) => {
                    if (courseSelector && courseSelector.current) {
                      courseSelector.current.value = "0";
                    }
                    fetchTasks({
                      moment_id: event.target.value,
                      include_externals: (event.target.value === "0") | 0,
                    });
                  }}
                >
                  {studentMoments.map((moment, key) => {
                    return (
                      <option key={`${moment.id}-${key}`} value={moment.id}>
                        {moment.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </div>
            </div>
          )}
        </Col>
      </Row>
      {isPendingPersonHistory && (
        <div>
          <NSpinnerPoints />
        </div>
      )}
      {personHistory && personHistory.length > 0 && person && (
        <CardNimbi>
          <div className="overflow-auto">
            {!isPendingPersonHistory &&
              personHistory &&
              personHistory.map((m) => (
                <TaskHistory
                  key={uuidv4(8)}
                  student={{
                    full_name: `${person.first_name} ${person.last_name}`,
                  }}
                  task={m}
                />
              ))}
          </div>
        </CardNimbi>
      )}

      {personHistory && personHistory.length === 0 && (
        <CardNimbi padding="24px">
          <div className="mb-4">
            <ContainerEmpty
              version="2"
              image={<EmptyListSVG />}
              text={"Aún no existe historial de este alumno"}
              description={
                <div>
                  Al momento de empezar a gestionar el alumno, podrás ver todos{" "}
                  <br />
                  los movimientos en esta sección
                </div>
              }
            />
          </div>
        </CardNimbi>
      )}
    </div>
  );
};
export default HistoryTask;
