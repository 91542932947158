import React from "react";

const EmptyRiskSVG = () => {
  return (
    <svg
      width="247"
      height="200"
      viewBox="0 0 247 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="23.5" width="200" height="200" rx="100" fill="#F1F6FD" />
      <g filter="url(#filter0_d_2078_2883)">
        <path
          d="M217.228 154.23H29.7723C24.726 154.23 20.5702 150.732 20.5702 146.483V53.5167C20.5702 49.2683 24.726 45.7695 29.7723 45.7695H217.228C222.274 45.7695 226.43 49.2683 226.43 53.5167V146.483C226.43 150.732 222.274 154.23 217.228 154.23Z"
          fill="white"
        />
      </g>
      <path
        d="M84.7342 84.098L84.5748 69.4903C100.88 70.6186 113.728 84.1458 114.086 100.295C114.251 110.647 108.834 119.798 100.562 124.996L93.0518 111.734C96.7187 109.084 98.9358 104.761 99.0096 99.601C98.9526 91.9956 92.5284 85.232 84.7342 84.098Z"
        fill="#B4C4DC"
      />
      <path
        d="M81.3706 69.8612L81.5285 84.35C76.8198 84.7519 72.5725 87.3136 70.0917 91.2698L57.6138 83.0037C62.553 75.3904 71.0476 70.267 81.3706 69.8612Z"
        fill="#E8F0FC"
      />
      <path
        d="M54.951 86.8411L67.7209 95.1256C67.2996 96.5855 66.8783 98.0454 67.0525 99.8697C67.0414 108.296 74.2944 115.39 83.3001 115.748C85.7945 115.713 88.0024 115.347 89.9237 114.649L97.3678 127.482C93.1712 129.442 88.424 130.44 83.4576 130.212C66.3507 129.892 52.4402 116.068 52.1309 99.4819C52.159 94.9704 53.114 90.5578 54.951 86.8411Z"
        fill="#D9E1F2"
      />
      <path
        d="M196.278 102.209H164.609C163.57 102.209 162.679 101.318 162.679 100.278V99.9814C162.679 98.9417 163.57 98.0505 164.609 98.0505H196.278C197.317 98.0505 198.207 98.9417 198.207 99.9814V100.278C198.207 101.318 197.317 102.209 196.278 102.209Z"
        fill="#DDE6F8"
      />
      <path
        d="M150.807 104.587H146.799C145.315 104.587 144.276 103.398 144.276 102.062V98.0508C144.276 96.5654 145.463 95.5256 146.799 95.5256H150.807C152.291 95.5256 153.33 96.714 153.33 98.0508V102.062C153.33 103.547 152.143 104.587 150.807 104.587Z"
        fill="#D9E1F2"
      />
      <path
        d="M181.629 83.8848H164.609C163.57 83.8848 162.679 82.9937 162.679 81.954V81.6569C162.679 80.6172 163.57 79.7261 164.609 79.7261H181.629C182.668 79.7261 183.558 80.6172 183.558 81.6569V81.954C183.558 82.9937 182.668 83.8848 181.629 83.8848Z"
        fill="#DDE6F8"
      />
      <path
        d="M150.807 86.2623H146.799C145.315 86.2623 144.276 85.0739 144.276 83.737V79.7264C144.276 78.241 145.463 77.2012 146.799 77.2012H150.807C152.291 77.2012 153.33 78.3895 153.33 79.7264V83.737C153.33 85.2225 152.143 86.2623 150.807 86.2623Z"
        fill="#E8F0FC"
      />
      <path
        d="M189.944 120.032H164.609C163.57 120.032 162.679 119.141 162.679 118.101V117.804C162.679 116.765 163.57 115.874 164.609 115.874H189.944C190.983 115.874 191.873 116.765 191.873 117.804V118.101C192.022 119.141 191.131 120.032 189.944 120.032Z"
        fill="#DDE6F8"
      />
      <path
        d="M150.807 122.41H146.799C145.315 122.41 144.276 121.222 144.276 119.885V115.874C144.276 114.389 145.463 113.349 146.799 113.349H150.807C152.291 113.349 153.33 114.537 153.33 115.874V119.885C153.33 121.37 152.143 122.41 150.807 122.41Z"
        fill="#B4C4DC"
      />
      <defs>
        <filter
          id="filter0_d_2078_2883"
          x="0.570221"
          y="31.7695"
          width="245.86"
          height="148.461"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.690196 0 0 0 0 0.729412 0 0 0 0 0.8 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2078_2883"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2078_2883"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default EmptyRiskSVG;
