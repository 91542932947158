import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import FieldCompoment from "./FieldCompoment";
import _ from "lodash";
import styled from "styled-components";
import { RowItem } from "components/Nimbiv2/styled";
import { VERSIONS_FILTERS_DESIGN } from "utils/constants";
const FormFilter = ({
  data,
  onChange,
  isDisabled,
  filterset = {},
  transparent = false,
  version = VERSIONS_FILTERS_DESIGN.v2,
  externalUpdate = false,
}) => {
  const [filters, setFilter] = useState([]);
  const [dataSend, setDataSend] = useState(filterset);
  const [dataClientFilter, setDataClientFilter] = useState([]);
  const [render, setRender] = useState(0);
  const [external, setExternal] = useState(false);
  useEffect(() => {
    setFilter(data);
  }, [data]);

  useEffect(() => {
    if (!_.isEqual(filterset, dataSend) && externalUpdate) {
      setDataSend(filterset);
      setExternal(true);
    }
  }, [filterset, externalUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (render !== 0 && !external) {
      onChange(dataSend, dataClientFilter);
    } else {
      setExternal(false);
    }
    setRender(1);
  }, [dataSend]); // eslint-disable-line react-hooks/exhaustive-deps

  const getKeysClean = (key) => {
    let keys = [key];
    for (const item in filters) {
      if (filters[item]["parent"] === key) {
        keys = keys.concat(getKeysClean(filters[item]["filter_field"]));
      }
    }
    return keys;
  };
  const cleanChildrenData = (dataset, key) => {
    let dataClient = {};
    let keysClean = getKeysClean(key);
    for (const item in filters) {
      if (!keysClean.includes(filters[item]["parent"])) {
        if (filters[item]["filter_field"] in dataset) {
          dataClient[filters[item]["filter_field"]] =
            dataset[filters[item]["filter_field"]];
        }
      }
    }
    return dataClient;
  };

  const cleanClientChildData = (key) => {
    let listClient = dataClientFilter ?? [];
    for (const item in filters) {
      if (
        filters[item]["parent"] === key ||
        filters[item]["filter_field"] === key
      ) {
        if (listClient.includes(filters[item]["id"])) {
          _.remove(listClient, (d) => {
            return filters[item]["id"] === d;
          });
        }
      }
    }
    return listClient;
  };

  const onChangeItem = (key, data, dataset) => {
    if (data !== null) {
      const dataSendChild = cleanChildrenData(dataSend, key);
      setDataSend({ ...dataSendChild, ...{ [key]: data } });

      const o = cleanClientChildData(key);
      o.push(dataset.id);
      setDataClientFilter(o);
    } else {
      const dataSendChild = cleanChildrenData(dataSend, key);
      const x = _.omit(dataSendChild, key);
      setDataSend(x);

      let dataClient = cleanClientChildData(key);
      //const y = _.remove(dataClient, dataset.id);
      setDataClientFilter(dataClient);
    }
  };

  return (
    <ContainerFilter transparent={transparent}>
      <div>
        <RowItem>
          {filters.map((item, key) => {
            return (
              <FieldCompoment
                key={`field-${item.key}-${key}`}
                data={item}
                filterset={filterset}
                onChange={onChangeItem}
                isDisabled={isDisabled}
                version={version}
              />
            );
          })}
        </RowItem>
      </div>
    </ContainerFilter>
  );
};

export default FormFilter;
FormFilter.propTypes = {
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

const ContainerFilter = styled.div`
  ${(props) =>
    props.transparent
      ? `.form-control {
  background: transparent;
}`
      : ""}
`;
