import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TextError = ({ text, icon, marginTop }) => {
  return (
    <TextErrorDesign marginTop={marginTop}>
      {text}{" "}
      {icon && text && text !== "" && (
        <FontAwesomeIcon
          className="ml-1"
          icon={icon}
          size="1x"
          color="#e23232"
        />
      )}
    </TextErrorDesign>
  );
};

export default TextError;
const TextErrorDesign = styled.div`
  font-family: Nova;
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-md);
  line-height: 120%;
  color: #e23232;
  flex: none;
  order: 0;
  flex-grow: 0;
  display: flex;
  margin-top: ${(props) => props.marginTop ?? "0px"};
`;
