import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip, Popover } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faUserAlt } from "@fortawesome/pro-regular-svg-icons";

import styles from "./Task.module.css";
import "theming/_nimbi-theme.sass";

const MomentItem = ({ info, onFilterMoment }) => {
  const popover = () => (
    <Popover id="moment-popover" className={styles.momentPopover}>
      <Popover.Title className={styles.titleBlock}>{info.name}</Popover.Title>
      <Popover.Content>
        <div style={{ textAlign: "center" }} className={styles.titleText}>
          {info.active_student_tracking_count} alumnos
        </div>
        <hr style={{ margin: 6 }} />
        <div style={{ textAlign: "center" }} className={styles.titleText}>
          {info.call_active_tasks} tareas llamado
        </div>
        <hr style={{ margin: 6 }} />
        <div style={{ textAlign: "center" }} className={styles.titleText}>
          {info.email_active_tasks} tareas email
        </div>
        <hr style={{ margin: 6 }} />
        <div style={{ textAlign: "center" }} className={styles.titleText}>
          {info.completed_tasks} tareas completas
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <div className={`mx-3 ${styles.momentItem}`}>
      <Link
        className={`text-center ${styles.momentTitle} ${
          info.selected ? styles.selected : null
        }`}
        to="/tareas"
        onClick={() => onFilterMoment(info.id)}
        aria-hidden="true"
      >
        {info.name}
        <div
          className={`text-center ${styles.momentSubTitle} ${
            info.selected ? styles.selected : null
          }`}
        >
          <span className="badge badge-secondary n-text-secondary">
            {info.active_student_tracking_count}
          </span>

          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="bottom"
            overlay={popover()}
          >
            <FontAwesomeIcon
              className={`mr-2 ${styles.menuIcon}`}
              icon={faUserAlt}
            />
          </OverlayTrigger>
          {info.description ? (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id={`tooltip-${info.id}`}>{info.description}</Tooltip>
              }
            >
              <FontAwesomeIcon
                className="text-muted ml-2"
                icon={faInfoCircle}
              />
            </OverlayTrigger>
          ) : null}
        </div>
      </Link>
    </div>
  );
};

MomentItem.propTypes = {
  info: PropTypes.shape({}).isRequired,
  onFilterMoment: PropTypes.func.isRequired,
};

export default React.memo(MomentItem);
