import React from "react";
import { CallingActionHeader } from "containers/Task";
import NEmailInfo from "../NEmailInfo";
import NCallingInfo from "../NCallingInfo";

const NActionBlock = ({ task, channel }) => {
  const { id, contact_reason: contactReason } = task;

  if (channel === "Email") {
    return <NEmailInfo eventId={id} taskId={id} contactReason={contactReason} task={task} />;
  }
  if (channel === "Call") {
    return (
      <NCallingInfo task={task} resultCategory="normal">
        <CallingActionHeader task={task} contactReason={contactReason} />
      </NCallingInfo>
    );
  }

  return null;
};

export default React.memo(NActionBlock);
