import React from "react";
import CardMomentSkeletons from "components/Skeletons/CardMoment";
import { ColLargeScreen, RowItem } from "components/Nimbiv2/styled";
const ListMomentSkeleton = () => {
  return (
    <div>
      <RowItem>
        {[1, 2, 3, 4, 5, 6, 7, 8].map((item, key) => (
          <ColLargeScreen
            lg="3"
            md="4"
            key={`Skeletons-card-initiative-${key}`}
            style={{ marginTop: "20px" }}
          >
            <CardMomentSkeletons />
          </ColLargeScreen>
        ))}
      </RowItem>
    </div>
  );
};

export default ListMomentSkeleton;
