import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Modal, NProfileTabs } from "components";
import * as Skeletons from "components/Skeletons";

import styles from "./ModalProfile.module.css";
import { useTranslation } from "react-i18next";
const ModalProfile = ({ notifyClose, isVisible }) => {
  const { t } = useTranslation();
  const [tabSelect, setTabSelect] = useState("profile");
  const {
    personsProfile,
    pending: { profile: isPending },
    personHistory,
    personCourses,
    personMoments,
  } = useSelector((state) => state.crm);

  if (!personsProfile) return null;

  return (
    <ModalDesign
      className={styles.modalProfile}
      show={isVisible}
      onHide={notifyClose}
      size="lg"
    >
      <Modal.Header className={styles.msgModalHeader} closeButton>
        <Modal.Title>
          <span className={styles.titleHeader}>
            {!isPending && personsProfile
              ? `${personsProfile.first_name} ${personsProfile.last_name}`
              : "Perfil"}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={`pt-0 ${styles.bodyContainer} ${styles.messageBodyHistory} ${tabSelect !== "profile" ? styles.bodyBorderRadius : ""
          }`}
      >
        {!isPending && personsProfile ? (
          <NProfileTabs
            person={personsProfile}
            taskHistory={personHistory}
            studentCourses={personCourses}
            studentMoments={personMoments}
            onChangeTab={(data) => {
              setTabSelect(data);
            }}
          />
        ) : (
          <Skeletons.PersonsProfile />
        )}
      </Modal.Body>
      {tabSelect === "profile" && (
        <Modal.Footer
          className={`d-flex justify-content-between ${styles.footerContainer}`}
        >
          <div className="d-flex">
            <div>
              {personsProfile && personsProfile.link_inquisidor ? (
                <a
                  className="secondary_color"
                  href={personsProfile.link_inquisidor}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{t("see_inquisitor")}</span>
                </a>
              ) : (
                ""
              )}
            </div>
            <div className="ml-4">
              {personsProfile && personsProfile.link_panel ? (
                <a
                  className="secondary_color"
                  href={personsProfile.link_panel}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{t("view_in_dashboard")}</span>
                </a>
              ) : (
                ""
              )}
            </div>
            <div className="ml-4">
              {personsProfile && personsProfile.link_zendesk ? (
                <a
                  className="secondary_color"
                  href={personsProfile.link_zendesk}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{t("view_in_zendesk")}</span>
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal.Footer>
      )}
    </ModalDesign>
  );
};

ModalProfile.propTypes = {
  notifyClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
};

ModalProfile.defaultProps = {
  isVisible: false,
};

export default ModalProfile;

const ModalDesign = styled(Modal)`
  .modal-content {
    border-radius: 8px;
  }
  .modal-body {
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 25px;
  }
  .modal-footer {
    padding-right: 30px;
    padding-left: 30px;
    background-color: var(--color-background-secundary);
  }
`;
