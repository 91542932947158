import {
  getClientFilters,
  getRoles,
  getClientAreas,
} from "services/site.service";

import * as SiteActions from "../site.actions";
import { handleErrorStatus } from "../../../services/errors";

const handleErrorResponse = (err) => {
  const status = err instanceof Map ? err.get("status") : 500;

  if (status > 0) {
    return handleErrorStatus(status);
  }

  return (dispatch) => dispatch(SiteActions.registerError(err));
};

export const fetchClientFilters = (payload) => {
  return (dispatch) => {
    dispatch(SiteActions.getClientFilter());
    getClientFilters(payload)
      .then(({ data }) => {
        dispatch(SiteActions.setClientFilter(data));
      })
      .catch(handleErrorResponse);
  };
};

export const fetchRoles = () => {
  return (dispatch) => {
    dispatch(SiteActions.getRoles());
    getRoles()
      .then(({ data }) => dispatch(SiteActions.setRoles(data)))
      .catch(handleErrorResponse);
  };
};

export const fetchClientAreas = () => {
  return (dispatch) => {
    dispatch(SiteActions.getClientAreas());
    getClientAreas()
      .then(({ data }) => dispatch(SiteActions.setClientAreas(data)))
      .catch(handleErrorResponse);
  };
};
