import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import ModalConfirm from "../ModalConfirm";

const RouteLeavingGuard = ({
  when,
  navigate,
  title,
  detail,
  textCancel,
  textAccept,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      setConfirmedNavigation(false);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ModalConfirm
        isVisible={modalVisible}
        onAccept={handleConfirmNavigationClick}
        onCancel={closeModal}
        title={title}
        detail={detail}
        titleApproved={textAccept}
        titleCancel={textCancel}
        colorCancel={"second"}
        colorApproved={"second"}
        size="lg"
        paddingApproved="5px 30px"
      />
    </>
  );
};
export default RouteLeavingGuard;
