import React from "react";
import _ from "lodash";
import { CardNimbi } from "components/Nimbiv2/styled";
import { validateSelectStudent } from "../validations";
import { ContainerTitle, TitleCard } from "./styles";
import { StepTitle } from "components/Nimbiv2/InitiativesComponent";
import { NIcon } from "components/Nimbiv2";
import { useTranslation } from "react-i18next";
import { IconCheck } from "assets/images";
import { capitalizeWord, replaceChars, isEmptyObject } from "utils";
import { useTranslationLocal } from "hooks";

const StudentsInitiativeResume = ({
  formValues,
  edit,
  isExternal,
  onChangePosition,
  filtersApplied,
  paginationTracking,
  textNumber,
  typeFormList,
}) => {
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const allItems = {
    1: "SI",
    0: "NO",
    True: "SI",
    False: "NO",
    undefined: "NO",
  };
  const getIconExclamationCircle = () => {
    return (
      <NIcon
        iconname="exclamationCircle"
        style={{ marginLeft: "4px", color: "#E23232" }}
      ></NIcon>
    );
  };
  return (
    <CardNimbi
      padding={"17px 35px 17px 18px"}
      bordercolor={
        !isEmptyObject(validateSelectStudent(formValues))
          ? "#E23232"
          : "#3AC8AC"
      }
    >
      <ContainerTitle>
        <TitleCard>
          {t("criteria_and_students", {
            students: translation("students", "", t("students")),
          })}
        </TitleCard>
        <div style={{ display: "flex" }}>
          <StepTitle>{t("step_number", { number: textNumber })}</StepTitle>
          <NIcon
            iconname="edit"
            style={{
              marginLeft: "18px",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={onChangePosition}
          />
        </div>
      </ContainerTitle>
      <div style={{ marginTop: "24px" }}>
        <strong>{t("criteria_used")}</strong>
      </div>
      <div>
        {filtersApplied.length === 0 && (
          <div style={{ marginTop: "16px" }}>{t("no_criteria_selected")}</div>
        )}
        {filtersApplied.map((item, key) => {
          return (
            <div style={{ marginTop: "16px" }} key={`filter-${key}`}>
              <img
                src={IconCheck}
                style={{ height: "19px", marginRight: "9px" }}
                alt="image1"
              />
              {capitalizeWord(replaceChars(item.key, "_", " "))}:{" "}
              {item.value
                ? _.get(
                    allItems,
                    item.value
                      .toString()
                      .replace("++", " | ")
                      .replace("##", ""),
                    item.value.toString().replace("++", " | ").replace("##", "")
                  )
                : ""}
            </div>
          );
        })}
      </div>
      <div style={{ marginTop: "16px" }}>
        <strong>
          {_.upperFirst(
            t("selected_students", {
              students: translation("students", "", t("students")),
            })
          )}
        </strong>
      </div>
      <div style={{ marginTop: "16px" }}>
        <NIcon iconname="student" style={{ marginRight: "8px" }} />
        {formValues.student_trackings &&
        formValues.student_trackings.length > 0 ? (
          (formValues.selectAll
            ? paginationTracking.count
            : formValues.student_trackings.length) +
          ` ${translation("students", "", t("students"))}`
        ) : (
          <>
            {t("select_students", {
              students: translation("students", "", t("students")),
            })}{" "}
            {getIconExclamationCircle()}{" "}
          </>
        )}
      </div>
    </CardNimbi>
  );
};

export default StudentsInitiativeResume;
