import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { Container, Row, Col } from "components";
import { validateToken, changePassword } from "services/authService";
import ResetFormComponent from "./ResetForm";
import styles from "./Form.module.css";

const PasswordResetForm = ({ token }) => {
  const rowStyles = `justify-content-md-center align-items-center ${styles.loginBlock}`;

  const [isPending, setPending] = useState(false);
  const [isChanged, setChanged] = useState(false);

  const submitForm = async (password) => {
    setPending(true);
    const { status, message } = await changePassword(token, password);

    if (status === 200) {
      toast.info("Contraseña cambiada exitosamente");
      setChanged(true);
    } else {
      toast.error(message);
    }
    setPending(false);
  };

  const isTokenValid = async () => {
    const { status } = await validateToken(token);

    if (status !== 200 && !isChanged) {
      toast.error("El token no es válido");
    }
  };

  useEffect(() => {
    isTokenValid();
  });

  return (
    <Container style={{ height: "83%" }}>
      <Row className={rowStyles}>
        <Col md={4}>
          {token ? (
            <ResetFormComponent onSubmit={submitForm} isPending={isPending} />
          ) : (
            <p>El enlace no es válido</p>
          )}
          {isChanged ? (
            <Link className={styles.loginLink} to="/">
              Ingresar
            </Link>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

export default PasswordResetForm;
