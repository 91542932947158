import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { applyTextType } from "utils";
import { DividerH } from "../styled";
const ItemInfoPerson = ({
  title,
  detail,
  format,
  extension,
  formatString,
  items,
  ...props
}) => {
  const { t } = useTranslation();

  if (
    !detail &&
    ![0, 1].includes(detail) &&
    (format !== "boolean" || typeof detail === "undefined")
  ) {
    return (
      <ContainerItem lg="6" md="12" {...props}>
        <div>
          <strong>{title}:</strong> {_.upperFirst(t("no_information"))}
        </div>
      </ContainerItem>
    );
  }
  if (format === "array") {
    return (
      <ContainerItem lg="12" {...props}>
        <div>
          <div>
            <strong>{title}:</strong>{" "}
            {detail &&
              detail?.length === 0 &&
              _.upperFirst(t("no_information"))}
          </div>
          <div style={{ marginTop: "16px" }}>
            <div className="item-description">
              {detail &&
                detail?.length > 0 &&
                detail.map((itemArray, keyArray) => {
                  return (
                    <div key={`key-value-${keyArray}`}>
                      <div
                        className="d-flex justify-content-between"
                        style={{
                          flexWrap: "wrap",
                        }}
                      >
                        {items &&
                          items.map((item, key) => {
                            return (
                              <div
                                key={`item-${keyArray}-${key}`}
                                style={{ flexBasis: "50%", marginTop: "16px" }}
                              >
                                <ContainerItem>
                                  <strong>{item.label}</strong>
                                  <div className="item-description">
                                    {applyTextType(
                                      itemArray[item.key_value] ?? "",
                                      item.format,
                                      item.extension,
                                      item.format_string
                                    )}
                                  </div>
                                </ContainerItem>
                              </div>
                            );
                          })}
                      </div>
                      <DividerH
                        style={{
                          marginTop: "16px",
                          marginBottom: "16px",
                        }}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </ContainerItem>
    );
  }

  return (
    <ContainerItem lg="6" md="12" {...props}>
      <div>
        <strong>{title}:</strong>{" "}
        {applyTextType(
          detail,
          format,
          extension,
          formatString,
          t("no_information")
        )}
      </div>
    </ContainerItem>
  );
};
export default ItemInfoPerson;

const ContainerItem = styled(Col)`
  color: #2a2b2e;
  .item-description {
    margin-top: 2px;
  }
  margin-top: 16px;
  @media (min-width: 768px) {
    margin-top: 0px;
  }
`;
