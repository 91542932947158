import Serializer from "./Serializer";
import { IModel } from "./Interfaces";

export class Student implements IModel {
  private data: object[];
  id: any;
  externalId: string = "";
  firstName: string = "";
  lastName: string = "";
  birthDate: string = "";
  documentNumber: string = "";
  documentType: string = "";
  gender: string = "";
  address1: string = "";
  address2: string = "";
  email: string = "";
  phone: string = "";
  country: string = "";
  personType: string = "";
  pastCourses: number = 0;
  currentCourses: number = 0;
  linkInquisidor: string = "";
  linkPanel: string = "";
  lastContact: any = null;
  tags: any[] = [];
  contacts: any[] = [];
  fieldMap: object = {};
  hasSenceCourse: boolean = false;
  risk: string = "";
  action_plans: any[] = [];
  context_data: object = {};

  constructor(attrs: any = {}) {
    this.fieldMap = {
      id: "id",
      externalId: "external_id",
      firstName: "first_name",
      lastName: "last_name",
      birthDate: "birth_date",
      documentNumber: "document_number",
      documentType: "document_type",
      gender: "gender",
      address1: "address1",
      address2: "address2",
      email: "email",
      phone: "phone",
      country: "country",
      personType: "person_type",
      pastCourses: "past_tracking_objects",
      currentCourses: "current_tracking_objects",
      linkInquisidor: "link_inquisidor",
      linkPanel: "link_panel",
      lastContact: "last_contact",
      tags: [],
      contacts: [],
      hasSenceCourse: "has_sence_course",
      risk: "risk",
      context_data: "context_data",
      action_plans: null,
    };
    const isArray = Array.isArray(attrs);
    this.data = [];

    if (isArray) {
      this.data = attrs;
    } else {
      Object.assign(this, Serializer.Serialize(attrs, this.fieldMap));
    }
  }

  getList(): Array<Student> {
    return this.data.map((e) => new Student(e));
  }

  deserialize(): object {
    return Serializer.Deserialize(this, this.fieldMap);
  }
}
