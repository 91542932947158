import * as Components from "../Common/components";
import { CampusColumnInfo } from "./CampusColumnInfo";
import { CourseColumnInfo } from "./CourseColumnInfo";
import { IClientArea } from "../Interfaces";
import StudentFilters from "containers/Student/Filters/Aiep/StudentFilters";

export default class AIEP implements IClientArea {
  generateSubtitle(): string {
    return "aiep";
  }

  StudentFilters(props?: any): any {
    return StudentFilters(props);
  }

  UserFilters(props?: any): any {
    return Components.UserFilters(props);
  }

  DynamicFilters(props?: any): any {
    return Components.DynamicFilters(props);
  }

  CourseColumnInfo(props?: any): any {
    return CourseColumnInfo(props);
  }

  CampusColumnInfo(props?: any): any {
    return CampusColumnInfo(props);
  }
}
