import React, { useState } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Tab,
  Form,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { fetchStudentNotes } from "store/actions/async/student-async.actions";
import { Tasks, OverlayTrigger } from "components";
import TaskHistory from "containers/Task/v2/TaskHistory";
import { createNote } from "services/student.service";
import { toast } from "react-toastify";
import { uuidv4, verifyPermissionTab, getText, getGlobalConfig } from "utils";
import StudentNotes from "./StudentNotes.jsx";
import styles from "./NProfileTabs.module.css";
import { fetchPersonHistory } from "store/actions/async/crm-async.action";
import TabZendesk from "components/ModalProfile/TabZendesk.jsx";
import { CardNimbi, NTabs } from "components/Nimbiv2/styled";
import { useTranslation } from "react-i18next";
import { InfoStudent } from "containers/Student/detail";
import { NBTextArea, NButton } from "components/Nimbiv2";
import styled from "styled-components";
import {
  faLightbulbOn,
  faInfoCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NProfileTabs = ({
  person,
  taskHistory,
  studentCourses,
  studentMoments,
  onChangeTab,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isSaving, setSaving] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [noteStarred, setStarredNote] = useState(false);

  if (!person) return null;

  const fetchTasks = async (filters) => {
    const queryFilters = {};
    Object.keys(filters)
      .filter((el) => filters[el] !== "0")
      .forEach((el) => {
        queryFilters[el] = filters[el];
      });
    return dispatch(fetchPersonHistory(person.id, queryFilters));
  };

  const courseSelector = React.createRef();
  const momentSelector = React.createRef();

  const addNewNote = async () => {
    setSaving(true);
    const payload = {
      note: noteText,
      student: person.id,
      is_starring: noteStarred,
    };
    const { status } = await createNote(payload);

    if (status === 201) {
      dispatch(fetchStudentNotes(person.id));
      setSaving(false);
      setNoteText("");
      setStarredNote(false);
      toast.success(t("create_observation_success"));
      dispatch(fetchPersonHistory(person.id));
    } else {
      setSaving(false);
      toast.error(t("error_create_note"));
    }
  };

  return (
    <NTabs
      defaultActiveKey="profile"
      id="tabs-container"
      marginbottom="0px"
      onSelect={onChangeTab}
      style={{
        borderBottom: "1px solid #dee2e6",
        marginRight: "0px",
        marginLeft: "0px",
      }}
    >
      <Tab
        eventKey="profile"
        title={getText("information", "startCase", t("information"))}
      >
        <div style={{ marginTop: "25px" }}>
          <InfoStudent defaultActiveKey={"0"} />
        </div>
      </Tab>
      <Tab
        eventKey="courses"
        title={getText("tracking_objects", "startCase", t("courses"))}
      >
        <CardNimbi radius="8px" style={{ marginTop: "25px" }}>
          <Tasks data={person ? person.tasks : []} marginTop={false} />
        </CardNimbi>
      </Tab>
      <Tab
        eventKey="history"
        title={getText("history", "startCase", t("record"))}
      >
        <Row>
          {studentCourses && studentCourses.length > 2 && (
            <Col xs="6">
              <div className={styles.dropDownContainer}>
                <div className={styles.dropDown}>
                  <Form.Control
                    as="select"
                    ref={courseSelector}
                    onChange={(event) => {
                      if (momentSelector && momentSelector.current) {
                        momentSelector.current.value = "0";
                      }
                      fetchTasks({
                        course_id: event.target.value,
                        include_notes: (event.target.value === "0") | 0,
                      });
                    }}
                    style={{
                      background: "white",
                      padding: "14px 30px 14px 12px",
                      height: "49px",
                    }}
                  >
                    {studentCourses.map((course) => {
                      return (
                        <option key={`course-${course.id}`} value={course.id}>
                          {course.name}
                          {getGlobalConfig(
                            "show_tracking_object_external_id",
                            false
                          ) &&
                          course?.id &&
                          course?.id !== 0
                            ? ` - ${course.id}`
                            : ""}
                        </option>
                      );
                    })}
                  </Form.Control>
                </div>
              </div>
            </Col>
          )}
          {studentMoments && studentMoments.length > 2 && (
            <Col>
              <div className={styles.dropDownContainer}>
                <div className={styles.dropDown}>
                  <Form.Control
                    as="select"
                    ref={momentSelector}
                    onChange={(event) => {
                      if (courseSelector && courseSelector.current) {
                        courseSelector.current.value = "0";
                      }
                      fetchTasks({
                        moment_id: event.target.value,
                        include_externals: (event.target.value === "0") | 0,
                      });
                    }}
                    style={{
                      background: "white",
                      padding: "14px 30px 14px 12px",
                      height: "49px",
                    }}
                  >
                    {studentMoments.map((moment) => {
                      return (
                        <option key={`course-${moment.id}`} value={moment.id}>
                          {moment.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </div>
              </div>
            </Col>
          )}
        </Row>
        <div
          style={{
            height: studentCourses && studentCourses.length > 2 ? 470 : 555,
            marginTop: "10px",
            background: "white",
          }}
          className="overflow-auto"
        >
          {taskHistory.map((m) => (
            <TaskHistory
              key={uuidv4(8)}
              student={{
                full_name: `${person.first_name} ${person.last_name}`,
              }}
              task={m}
            />
          ))}
        </div>
      </Tab>
      {getGlobalConfig("observations_available", true) && (
        <Tab
          eventKey="notes"
          title={getText("observations", "startCase", t("observations"))}
        >
          <Container style={{ marginTop: "25px" }}>
            <Row>
              <Col>
                <Form.Group controlId="student-note">
                  <Form.Label>{t("new_observation")}:</Form.Label>
                  <NBTextArea
                    value={noteText}
                    maxLength={1000}
                    onChange={(event) => setNoteText(event.target.value)}
                  />
                </Form.Group>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <span
                    className="mr-1"
                    onClick={() => {
                      setStarredNote(!noteStarred);
                    }}
                    style={{
                      cursor: "pointer",
                      paddingRight: "0.75rem",
                      paddingBottom: "0px",
                      paddingTop: "7px",
                    }}
                  >
                    <FontAwesomeIcon
                      className="mr-2"
                      icon={faLightbulbOn}
                      style={{ fontSize: "15px", paddingTop: "13px" }}
                    />
                    {t("student_note_starring")}
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-student-notes`}>
                          {t("starring_notes_popover")}
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon icon={faInfoCircle} className="ml-1" />
                    </OverlayTrigger>
                  </span>
                  <ContainerSwitch>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      checked={noteStarred}
                      onChange={(evt) => {
                        setStarredNote(evt.target.checked);
                      }}
                      custom
                    />
                  </ContainerSwitch>
                </div>
                <div className="d-flex justify-content-end">
                  <div></div>
                  <NButton
                    disabled={isSaving || String(noteText).trim().length === 0}
                    onClick={addNewNote}
                  >
                    {isSaving ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}{" "}
                    {_.upperFirst(t("create"))}
                  </NButton>
                </div>
              </Col>
            </Row>
            <Row className={`mt-4 ${styles.noteContainer}`}>
              <Col>
                <StudentNotes studentID={person.id} />
              </Col>
            </Row>
          </Container>
        </Tab>
      )}

      {verifyPermissionTab("tabProfile", "zendesk") && person && (
        <Tab
          eventKey="zendesk"
          title={getText("zendesk", "startCase", t("portal_help"))}
        >
          <Container style={{ marginTop: "25px" }}>
            <TabZendesk person={person} />
          </Container>
        </Tab>
      )}
    </NTabs>
  );
};

NProfileTabs.propTypes = {
  person: PropTypes.shape({}),
  taskHistory: PropTypes.arrayOf(PropTypes.object),
  onUpdate: PropTypes.func,
};

NProfileTabs.defaultProps = {
  person: null,
  taskHistory: [],
  onUpdate: () => {},
};

export default React.memo(NProfileTabs);

const ContainerSwitch = styled.div`
  .custom-switch .custom-control-label {
    padding-left: 2rem;
    padding-bottom: 0px;
    padding-top: 10px;
  }

  .custom-switch .custom-control-label::before {
    height: 2rem;
    width: calc(3rem + 0.75rem);
    border-radius: 4rem;
  }

  .custom-switch .custom-control-label::after {
    width: calc(2rem - 4px);
    height: calc(2rem - 4px);
    border-radius: calc(3rem - (2rem / 2));
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(calc(2rem - 0.25rem));
  }
`;
