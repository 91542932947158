export const getTemplateID = (id, list) => {
  for (let i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return list[i];
    }
  }
  return null;
};

export const getCoursesIDs = (ids, list) => {
  if (ids) {
    const list_id = ids.map((item) => {
      return item["id"];
    });
    let new_list = [];
    for (let i = 0; i < list.length; i++) {
      if (list_id.includes(list[i].id)) {
        new_list.push(list[i]);
      }
    }
    return new_list;
  }
  return null;
};

export const getCampusesID = (id, list) => {
  for (let i = 0; i < list.length; i++) {
    if (list[i].campus_id === id) {
      return list[i];
    }
  }
  return null;
};
