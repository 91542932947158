import React from "react";

const NOnlyCommnet = ({
  key,
  dataset,
  isDisabled,
  onChange,
  keyPosition,
  lastKeyPosition,
  style,
}) => {
  return (
    <div className="mt-2" style={style}>
      <div>{dataset.text}</div>
    </div>
  );
};

export default NOnlyCommnet;
