import {
  GET_REPORTS,
  SET_REPORTS,
  REGISTER_ERROR,
} from "store/actions/report.actions";
import { Report } from "models";

const initialState = {
  list: [],
  pending: {
    list: false,
  },
};

const reportReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORTS: {
      return {
        ...state,
        pending: { ...state.pending, list: true },
      };
    }

    case SET_REPORTS: {
      const { results } = action.payload;

      return {
        ...state,
        list: new Report([...results]).getList(),
        pending: { ...state.pending, list: false },
      };
    }

    case REGISTER_ERROR: {
      const auxPending = { ...state.pending };
      if (action.payload.key) auxPending[action.payload.key] = false;

      return { ...state, pending: auxPending };
    }

    default:
      return { ...state };
  }
};

export default reportReducers;
