import React from "react";
import styles from "./NBlockContent.module.css";

const NBLockContent = ({ children, title }) => {
  return (
    <div className={styles.blockContent}>
      {title ? <div className={styles.title}>{title}</div> : null}
      {children}
    </div>
  );
};

export default NBLockContent;
