import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form } from "components";
import { NButton } from "components/Nimbiv2";

const FormRecoveryComponent = ({ onSubmit, isPending }) => {
  const [userName, setUsername] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(userName);
  };

  return (
    <Form onSubmit={handleSubmit} className="mt-3">
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Ingrese el email de la cuenta</Form.Label>
        <Form.Control
          type="text"
          placeholder="Email"
          value={userName}
          onChange={(event) => setUsername(event.target.value)}
        />
      </Form.Group>
      <NButton
        variant="primary"
        type="submit"
        disabled={isPending}
        block
        colorbutton="#334FFF"
        hoverbuttom="#112C9C"
        border="8px"
        padding="10px 16px"
      >
        Recuperar Contraseña
      </NButton>
    </Form>
  );
};

FormRecoveryComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isPending: PropTypes.bool,
};

FormRecoveryComponent.defaultProps = { isPending: false };

export default FormRecoveryComponent;
