import React, { useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { NTable } from "components/Nimbiv2";
import { CardNimbi } from "components/Nimbiv2/styled";
import { useDispatch, useSelector } from "react-redux";
import { fetchAcademicProgress } from "store/actions/async/student-async.actions";
import { NBadge } from "components";

const AcademicAdvancement = ({ student_tracking_id, callFetch = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    academicProgress,
    pending: { academicProgress: isPending },
    academicStudentId,
  } = useSelector((state) => state.student);

  useEffect(() => {
    if (academicStudentId !== student_tracking_id && callFetch) {
      dispatch(fetchAcademicProgress(student_tracking_id));
    }
  }, [dispatch, student_tracking_id, academicStudentId, callFetch]);

  // const statusMPE = {
  //   "No aplica": "redOriginal",
  //   "En curso": "yellowOriginal",
  //   Califica: "greenOriginal",
  // };

  const searchKey = (grades, key) => {
    const array_search = grades.filter((item) => item.text === key);
    if (array_search.length > 0) {
      return array_search[0]["value"];
    }
    return "-";
  };

  const statusMPE = {
    false: "redOriginal",
    true: "greenOriginal",
  };

  const statusText = {
    failed: {
      title: _.upperFirst(t("reprobate")),
    },
    progress: {
      title: _.upperFirst(t("in_progress")),
    },
    approved: {
      title: _.upperFirst(t("approved")),
    },
    exempted: {
      title: _.upperFirst(t("exempted")),
    },
  };

  const columns = [
    {
      title: _.upperFirst(t("modules")),
      key: "course_name",
      dataIndex: "course_name",
      width: "200",
      fixed: true,
      style: { left: 0 },
      render: (item, key) => <div>{item}</div>,
    },
    {
      title: t("note", { note: 1 }),
      key: "note1",
      dataIndex: "grades",
      center: true,
      contentCenter: true,
      width: "40",
      render: (item, key) => <div>{searchKey(item, "NOTA 1")}</div>,
    },
    {
      title: t("note", { note: 2 }),
      key: "note2",
      dataIndex: "grades",
      center: true,
      contentCenter: true,
      width: "40",
      render: (item, key) => <div>{searchKey(item, "NOTA 2")}</div>,
    },
    {
      title: _.upperFirst(t("average_grades")),
      key: "notes",
      center: true,
      contentCenter: true,
      style: {
        background: "rgba(180, 196, 220, 0.1)",
      },
      width: "40",
      dataIndex: "average_grade",
    },
    {
      title: t("attendance_number", { attendance: 1 }),
      key: "attendance1",
      dataIndex: "attendances",
      center: true,
      contentCenter: true,
      width: "70",
      render: (item, key) => (
        <div>{item.length > 0 ? item[0]["value"] : "-"}</div>
      ),
    },
    {
      title: t("attendance_number", { attendance: 2 }),
      key: "attendance2",
      dataIndex: "attendances",
      center: true,
      contentCenter: true,
      width: "70",
      render: (item, key) => (
        <div>{item.length > 1 ? item[1]["value"] : "-"}</div>
      ),
    },
    {
      title: _.upperFirst(t("average_attendance")),
      key: "attendance",
      style: { background: "rgba(180, 196, 220, 0.1)" },
      dataIndex: "average_attendance",
      center: true,
      contentCenter: true,
      width: "60",
    },
    {
      title: _.upperFirst(t("state")),
      key: "status",
      dataIndex: "status",
      center: true,
      contentCenter: true,
      width: "70",
      render: (item, key) => <div>{statusText[item]["title"]}</div>,
    },

    {
      title: t("academic_risks"),
      key: "academic_risks",
      center: true,
      contentCenter: true,
      width: "50",
      render: (item, key) => {
        return (
          <div>
            <NBadge
              kind={statusMPE[item.grades_extras["riesgo_rendimiento"]]}
              text={item.grades_extras["riesgo_rendimiento"] ? "SI" : "NO"}
              customStyle={{
                padding: "5px 6px",
                borderRadius: "64px",
                fontWeight: "350",
              }}
            />
          </div>
        );
      },
    },
    {
      title: t("risk_assistance"),
      key: "risk_assistance",
      center: true,
      contentCenter: true,
      width: "50",
      render: (item, key) => {
        return (
          <div>
            <NBadge
              kind={statusMPE[item.attendances_extras["riesgo_asistencia"]]}
              text={item.attendances_extras["riesgo_asistencia"] ? "SI" : "NO"}
              customStyle={{
                padding: "5px 6px",
                borderRadius: "64px",
                fontWeight: "350",
              }}
            />
          </div>
        );
      },
    },
    {
      title: t("assistantship"),
      key: "assistantship",
      center: true,
      contentCenter: true,
      width: "50",
      render: (item, key) => {
        return (
          <div>
            <NBadge
              kind={statusMPE[item.grades_extras["ayudantia"]]}
              text={item.grades_extras["ayudantia"] ? "SI" : "NO"}
              customStyle={{
                padding: "5px 6px",
                borderRadius: "64px",
                fontWeight: "350",
              }}
            />
          </div>
        );
      },
    },
    {
      title: "Remedial",
      key: "remedial",
      center: true,
      contentCenter: true,
      width: "50",
      render: (item, key) => {
        return (
          <div>
            <NBadge
              kind={statusMPE[item.grades_extras["remedial"]]}
              text={item.grades_extras["remedial"] ? "SI" : "NO"}
              customStyle={{
                padding: "5px 6px",
                borderRadius: "64px",
                fontWeight: "350",
              }}
            />
          </div>
        );
      },
    },
    {
      title: t("teacher_attention"),
      key: "teacher_attention",
      center: true,
      contentCenter: true,
      width: "50",
      render: (item, key) => {
        return (
          <div>
            <NBadge
              kind={statusMPE[item.grades_extras["atencion_docente"]]}
              text={item.grades_extras["atencion_docente"] ? "SI" : "NO"}
              customStyle={{
                padding: "5px 6px",
                borderRadius: "64px",
                fontWeight: "350",
              }}
            />
          </div>
        );
      },
    },
    {
      title: "MPE",
      key: "mpe",
      center: true,
      contentCenter: true,
      width: "50",
      render: (item, key) => {
        return (
          <div>
            <NBadge
              kind={statusMPE[item.grades_extras["mpe"]]}
              text={item.grades_extras["mpe"] ? "SI" : "NO"}
              customStyle={{
                padding: "5px 6px",
                borderRadius: "64px",
                fontWeight: "350",
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <CardNimbi
        radius="8px"
        padding="23px 26px"
        style={{ height: "fit-content" }}
      >
        <NTable
          columns={columns}
          dataSource={academicProgress?.progress ?? []}
          fixed={true}
          isPending={isPending}
        ></NTable>
      </CardNimbi>
    </div>
  );
};

export default AcademicAdvancement;
