import React from "react";
import PropTypes from "prop-types";
import { Modal } from "components";
import styled from "styled-components";

const BaseModal = ({ children, onClose, title, isVisible, size }) => {
  return (
    <ModalDesign
      show={isVisible}
      onHide={onClose}
      size={size}
      dialogClassName="modal-50w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="h5">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer className="d-none" />
    </ModalDesign>
  );
};

BaseModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
  title: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
};

BaseModal.defaultProps = {
  isVisible: false,
  title: "",
  size: "md",
};

export default BaseModal;

const ModalDesign = styled(Modal)`
  .modal-content {
    border-radius: 8px !important;
  }
`;
