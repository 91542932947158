import React from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Page4042 from "assets/images/page404-2.png";
import Page4041 from "assets/images/page404-1.png";
import { NButton } from "components/Nimbiv2";
import { permissionRedirect } from "routes/helpers";
import { useTranslation } from "react-i18next";
const PageError = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth, _.isEqual);
  const goDashboard = () => {
    if (user) {
      history.replace({
        pathname: permissionRedirect({
          userRoles: user ? user.groups : [],
        }),
      });
    } else {
      history.replace({
        pathname: "/",
      });
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh", width: "100%" }}
    >
      <div>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ position: "relative" }}
        >
          <img
            src={Page4042}
            style={{ width: "80%", height: "80%" }}
            alt="persons"
          />
          <img
            src={Page4041}
            style={{ position: "absolute", width: "80%", height: "80%" }}
            alt="background"
          />
        </div>
        <div
          style={{
            marginTop: "20px",
            fontSize: "30px",
            fontWeight: "bold",
            color: "#2A2B2E",
            textAlign: "center",
          }}
        >
          {t("page_not_found")}
        </div>
        <p
          className="text-center mt-2"
          style={{ fontSize: "15px" }}
          dangerouslySetInnerHTML={{ __html: t("description_not_found") }}
        ></p>
        <div className="text-center" style={{ marginTop: "30px" }}>
          <NButton padding="9px 50px" border="8px" onClick={goDashboard}>
            {t("return_start")}
          </NButton>
        </div>
      </div>
    </div>
  );
};

export default PageError;
