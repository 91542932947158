import { generateUrlWithParams } from "utils";
import api from "services/api";
import logger from "./logService";

export const getTaskDetail = (id, queryParams) => {
  const finalUrl = generateUrlWithParams(`crm/task/${id}/`, queryParams);
  return api.get(finalUrl);
};

export const getInfoCustomPerson = (id, queryParams) => {
  const finalUrl = generateUrlWithParams(
    `crm/task/${id}/custom_info_person/`,
    queryParams
  );
  return api.get(finalUrl);
};

export const getTaskQuestions = (queryParams) => {
  const finalUrl = generateUrlWithParams(`call_forms/questions/`, queryParams);
  return api.get(finalUrl);
};

export const saveQuestionTask = async (payload) => {
  try {
    const headers = {
      headers: { "Content-Type": "multipart/form-data" },
    };
    const response = await api.post(
      `call_forms/student_answers/`,
      payload,
      headers
    );
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return err;
  }
};

export const callNeotel = async (number) => {
  try {
    const response = await api.post(
      `third_party/neotel/trigger_call/${number}/`,
      {}
    );
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return err;
  }
};
