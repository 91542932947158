export const GET_REPORTS = "GET_REPORTS";
export const SET_REPORTS = "SET_REPORTS";
export const REGISTER_ERROR = "REGISTER_ERROR";

export const registerError = ({ err = null, key = "" }) => ({
  type: REGISTER_ERROR,
  payload: { err, key },
});
export const getReports = () => ({ type: GET_REPORTS });
export const setReports = (results) => ({ type: SET_REPORTS, payload: results });
