import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import NSurveyNps from "./Components/NSurveyNps";
import NSurveyComment from "./Components/NSurveyComment";
import { saveSurveys } from "services/site.service";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/pro-regular-svg-icons";

const NSurvey = ({
  openSide = false,
  title,
  height = "250px",
  onClose,
  survey,
  ...props
}) => {
  const listPosition = ["question", "comment"];
  const [position, setPosition] = useState(0);
  const [score, setScore] = useState(null);
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (openSide) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [openSide]);

  const fetchSaveSurvey = useCallback(async () => {
    const formData = new FormData();
    formData.append("score", score);
    formData.append("comment", comment);
    formData.append("survey_id", survey.id);
    const { status } = await saveSurveys(formData);
    if (status === 200) {
      toast.success("Respuesta enviada exitosamente");
      onClose();
    }
  }, [score, comment, survey, onClose]);

  return (
    <div>
      <SidebarContainer opened={openSide} height={height}>
        <ButtonClose onClick={onClose}>
          <FontAwesomeIcon icon={faX} />
        </ButtonClose>
        {survey && listPosition[position] === "question" && (
          <NSurveyNps
            score={score}
            survey={survey}
            onClick={(positionSelect) => {
              setScore(positionSelect);
              setPosition(position + 1);
            }}
          />
        )}
        {survey && listPosition[position] === "comment" && (
          <NSurveyComment
            score={score}
            value={comment}
            onClick={fetchSaveSurvey}
            handleCommentChange={(value) => {
              setComment(value);
            }}
            onLastPosition={() => {
              setPosition(position - 1);
            }}
          />
        )}
      </SidebarContainer>
      {openSide && (
        <BackgroundSidebar
          onClick={onClose}
          opened={openSide}
        ></BackgroundSidebar>
      )}
    </div>
  );
};
export default NSurvey;

const BackgroundSidebar = styled.div`
  position: fixed;
  inset: 0px;
  overflow: hidden auto;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
`;

const SidebarContainer = styled.div`
  animation-duration: 2s;
  animation-name: slidein;
  background: var(--color-white);
  box-shadow: 0px 3px 16px rgba(143, 164, 189, 0.25);
  min-height: ${(props) => props.height};
  max-height: ${(props) => props.height};
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1010;
  transition: all 0.5s;
  border: 8px, 0px, 0px, 8px;
  padding: 24px;
  overflow: auto;

  ${(props) => (props.opened ? "" : `margin-bottom: -${props.height};`)}
  .body {
    border-top: 1px solid var(--color-aqua);
    padding: 24px;
    max-height: 90%;
    overflow: auto;
  }
  .sidebar-title {
    font-size: 24px;
    color: #283952;
    font-weight: 500;
  }
  @keyframes slidein {
    from {
      bottom: -${(props) => props.height};
    }

    to {
      bottom: 0px;
    }
  }
`;

const ButtonClose = styled.div`
  position: absolute;
  right: 20px;
  cursor: pointer;
`;
