import React, { useState, useEffect } from "react";
import LogRocket from "logrocket";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import { Container, Row, Col, Image } from "components";
import { signInSSO, verifyGoogleToken } from "services/authService";
import { setSession, clearSession } from "store/actions/auth.actions";
import { ImageLoginAD } from "../../assets/images";
import styles from "./Form.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindows } from "@fortawesome/free-brands-svg-icons";

import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { msal, googleAuth, isLoginMSAL, isLoginGoogle } from "utils";

import { NSpinnerPoints, NButton } from "components/Nimbiv2";
import { useTranslation } from "react-i18next";
import GoogleButton from "./components/GoogleButton";

const LoginFormSSO = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const rowStyles = `justify-content-md-center align-items-center ${styles.loginBlock}`;

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [googleToken, setGoogleToken] = useState();

  const [isPending, setPending] = useState(false);

  const handleAzureLogin = (instance) => {
    instance.loginPopup(msal.loginRequest).catch((e) => {
      console.error(e);
    });
  };

  useEffect(() => {
    const submitGoogleLogin = async (username, accountId, accessToken) => {
      const { data, status } = await signInSSO(
        username,
        accountId,
        accessToken,
        googleAuth.providerId
      );
      if (status === 200) {
        const { user } = data;
        LogRocket.identify(user.id, {
          name: `${user.first_name} ${user.last_name}`,
          email: `${user.username}`,
        });
        dispatch(setSession(data));
      } else {
        toast.error(t("sso_user_not_found"));
        dispatch(clearSession());
        setTimeout(() => {
          window.location.reload();
        }, 10000);
      }
      setPending(false);
    };

    const verifyValidToken = async (token) => {
      const { data, status } = await verifyGoogleToken(token);
      if (status === 200) {
        submitGoogleLogin(data.email, data.sub, token);
      } else {
        toast.error(t("google_token_invalid"));
        setPending(false);
      }
    };

    if (googleToken) {
      setPending(true);
      verifyValidToken(googleToken);
    }
  }, [googleToken, dispatch, t]);

  useEffect(() => {
    const submitAzureLogin = async (username, accountId, accessToken) => {
      setPending(true);
      const { data, status } = await signInSSO(
        username,
        accountId,
        accessToken,
        msal.providerId
      );
      if (status === 200) {
        const { user } = data;
        LogRocket.identify(user.id, {
          name: `${user.first_name} ${user.last_name}`,
          email: `${user.username}`,
        });
        dispatch(setSession(data));
      } else {
        toast.error(t("sso_user_not_found"));
        dispatch(clearSession());
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      setPending(false);
    };

    const getAzureToken = (account, instance) => {
      const request = {
        ...msal.loginRequest,
        account: account,
      };
      instance
        .acquireTokenSilent(request)
        .then((response) => {
          submitAzureLogin(
            account.username,
            account.localAccountId,
            response.accessToken
          );
        })
        .catch((e) => {
          toast.error("Intentar nuevamente.");
        });
    };

    if (isAuthenticated && accounts) {
      getAzureToken(accounts[0], instance);
    }
  }, [dispatch, instance, accounts, isAuthenticated, t]);

  return (
    <Container style={{ height: "83%" }}>
      <Row className={rowStyles}>
        <Col md={4}>
          <div
            className="d-flex justify-content-center"
            style={{ marginBottom: "20px" }}
          >
            <Image style={{ border: 0, width: "80%" }} src={ImageLoginAD} />
          </div>
          {isAuthenticated ? (
            <>
              <p className={`mt-3 text-center`}>
                Iniciando sesión, espere un momento.
              </p>
              <div
                className="d-flex justify-content-center"
                style={{ width: "100%" }}
              >
                <NSpinnerPoints />
              </div>
            </>
          ) : (
            <>
              {isLoginMSAL() && (
                <div
                  className="d-flex justify-content-center"
                  style={{ width: "100%" }}
                >
                  <NButton
                    variant="primary"
                    type="submit"
                    disabled={isPending}
                    loading={isPending}
                    block
                    colorbutton="#334FFF"
                    hoverbuttom="#112C9C"
                    border="8px"
                    style={{ width: "auto" }}
                    className="mt-3"
                    onClick={() => handleAzureLogin(instance)}
                    padding="10px 40px"
                  >
                    <FontAwesomeIcon
                      icon={faWindows}
                      className="fas fa-cog mr-3"
                    />
                    Iniciar sesión con Microsoft
                  </NButton>
                </div>
              )}
              {isLoginGoogle() && (
                <GoogleButton
                  onGoogleSuscess={(tokenResponse) => {
                    setGoogleToken(tokenResponse.access_token);
                  }}
                  isPending={isPending}
                />
              )}
              <Link
                className={`mt-4 ${styles.textLink} d-flex justify-content-center`}
                to="/login"
              >
                Ingresar con usuario y contraseña
              </Link>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default LoginFormSSO;
