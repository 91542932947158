import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";

import { NButton } from "components/Nimbiv2";
import { CardNimbi, DividerH, IconContainer } from "../styled";
import { NumberCard } from "../CardDashboard/CardDashboard.styled";
import Icons from "../icons";

const CardIndicator = ({
  data,
  action,
  actionLabel,
  actionIcon,
  height = "100%",
  total = null,
  description = null,
  title = null,
}) => {
  return (
    <CardNimbi padding="24px 24px" style={{ height: height }}>
      <div className="d-flex">
        <div style={{ width: "100%" }}>
          <div className="d-flex" style={{ marginBottom: "16px" }}>
            <div>
              <IconContainer>
                <FontAwesomeIcon
                  icon={_.get(Icons, data.icon, faExclamationTriangle)}
                  size="lg"
                />
              </IconContainer>
            </div>
            <div
              className="ml-3 d-flex justify-content-center align-items-center fontNova"
              style={{ lineHeight: "120%" }}
            >
              <strong
                dangerouslySetInnerHTML={{
                  __html: title !== null ? title : data.title,
                }}
              ></strong>
            </div>
          </div>
          <DividerH />
          <div
            className="d-flex align-items-center"
            style={{ marginTop: "10px" }}
          >
            <NumberCard className="fontNova">
              {total !== null ? total : data.total}
            </NumberCard>
          </div>
          <div
            style={{ marginTop: "6px" }}
            dangerouslySetInnerHTML={{
              __html: description !== null ? description : data.description,
            }}
          ></div>
          {action && actionLabel && (
            <div style={{ marginTop: "16px" }}>
              <Link to={action}>
                <NButton outlined>
                  {" "}
                  <FontAwesomeIcon
                    icon={actionIcon}
                    style={{
                      fontSize: "var(--font-size-md)",
                      marginRight: "4px",
                    }}
                  />
                  {actionLabel}
                </NButton>
              </Link>
            </div>
          )}
        </div>
      </div>
    </CardNimbi>
  );
};

export default CardIndicator;
