import React from "react";
import PropTypes from "prop-types";
import { Col, NHistoryMessage } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faEnvelope,
  faQuestion,
  faSignInAlt,
  faSignOutAlt,
  faStickyNote,
} from "@fortawesome/pro-regular-svg-icons";

import styles from "./Task.module.css";
import { RowItem } from "components/Nimbiv2/styled";

const TaskHistory = ({ student, task }) => {
  if (!task) return null;

  let subject = null;
  let channel = null;
  let responseCode = null;
  let emailStatus = null;
  let dateInfo = null;
  let icon = faQuestion;
  let description = null;
  let momentName = null;

  if (task.type === "task") {
    subject =
      task.contact_reason === "Correo Masivo"
        ? task.contact_reason + " / " + task.email_template.name
        : task.contact_reason;
    channel = task.channel;
    responseCode = task.call_response?.code;
    emailStatus = task.email_status;
    dateInfo = task.closed_at;
    description = task.course_name;
    momentName = task.moment_name;
    icon = task.channel === "Call" ? faPhoneAlt : faEnvelope;
  } else if (task.type.includes("moment")) {
    subject = `${task.type.includes("start") ? "Entrada a" : "Salida de"} ${
      task.name
    }`;
    dateInfo = task.created_at;
    description = task.course_name;
    icon = task.type.includes("start") ? faSignInAlt : faSignOutAlt;
  } else if (task.type === "note") {
    subject = "Observación agregada";
    dateInfo = task.created_at;
    icon = faStickyNote;
    description = `${task.note.substring(0, 50)}${
      task.note.length > 50 ? "..." : ""
    }`;
  }

  return (
    <RowItem
      className={`justify-content-center ${styles.historyItem} no-gutters`}
    >
      <Col md={5} style={{ paddingRight: "10px" }}>
        <NHistoryMessage
          fullName={
            task.completed_by
              ? `${task.completed_by.first_name} ${task.completed_by.last_name}`
              : null
          }
          subject={subject}
          channel={channel}
          dateInfo={dateInfo}
          momentName={momentName}
          isBlockLeft
          emailStatus={emailStatus}
          description={description}
        />
      </Col>
      <Col className={styles.colSeparator} md={2}>
        <div className={styles.separatorBar}>
          <span className={styles.iconSep}>
            <span className={styles.iconSepBorder}>
              <span className={styles.iconSepCenter}>
                <FontAwesomeIcon
                  className={`${styles.iiiiconStyle}`}
                  icon={icon}
                  size="1x"
                />
              </span>
            </span>
          </span>
        </div>
      </Col>
      <Col md={5} style={{ paddingLeft: "10px" }}>
        {responseCode ? (
          <NHistoryMessage
            subject={task.call_response.description}
            description={task.student_response}
            channel={task.channel}
            dateInfo={task.closed_at}
            comment={task.comments}
            call_comment={task.call_comment}
            isBlockLeft={false}
          />
        ) : null}
      </Col>
    </RowItem>
  );
};

TaskHistory.propTypes = {
  student: PropTypes.shape({}).isRequired,
  task: PropTypes.shape({}).isRequired,
};

export default React.memo(TaskHistory);
