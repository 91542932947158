import React from "react";
import { getGlobalConfig } from "utils";
import { AnonymousContainer, NNameContrainerUnderline } from "../styled";
import { useTranslationLocal } from "hooks";
import { useTranslation } from "react-i18next";

const ColumnInfoStudent = ({ student, isAnonymous, onClick = () => {} }) => {
  const { translation } = useTranslationLocal();
  const { t } = useTranslation();
  return (
    <div className={`d-flex justify-content-left align-items-center`}>
      <div className="d-flex flex-column justify-content-left align-items-cente">
        <div>
          <NNameContrainerUnderline>
            <span
              style={{
                cursor: "pointer",
                fontWeight: 500,
                letterSpacing: "0.3px",
              }}
              onClick={onClick}
              aria-hidden="true"
            >
              {student.firstName ?? student.first_name ?? ""}{" "}
              <AnonymousContainer isAnonymous={isAnonymous}>
                {student.lastName ?? student.last_name}
              </AnonymousContainer>
              &nbsp;&nbsp;
            </span>
          </NNameContrainerUnderline>
        </div>
        <div style={{ fontSize: "var(--font-size-small)", color: "#787C83" }}>
          <AnonymousContainer isAnonymous={isAnonymous}>{`${
            (student.documentType ?? student.document_type ?? "-") === "Other"
              ? translation("document_type_dynamic", "", t("other"))
              : student.documentType ?? student.document_type ?? "-"
          } ${
            student.documentNumber ?? student.document_number ?? "-"
          }`}</AnonymousContainer>
        </div>
        {getGlobalConfig("id_student_show", "yes") === "yes" && (
          <div
            style={{
              fontSize: "var(--font-size-small)",
              color: "#787C83",
              lineHeight: "120%",
              marginTop: "2px",
            }}
          >
            <AnonymousContainer isAnonymous={isAnonymous}>
              {student.externalId ?? student.external_id}
            </AnonymousContainer>
          </div>
        )}
      </div>
    </div>
  );
};
export default ColumnInfoStudent;
