import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { LoginComponents } from "containers";
import { permissionRedirect } from "../../routes/helpers";
import { NNavbar } from "components/Nimbiv2";
import { isLoginSSO } from "utils";

const Login = () => {
  const history = useHistory();
  const session = useSelector((state) => state.auth.user);
  const location = useLocation();

  useEffect(() => {
    const array = location.pathname.split("/").filter((item) => item !== "");
    if (
      isLoginSSO() &&
      ((array.length > 0 && array[0] !== "login") || array.length === 0)
    ) {
      history.replace({
        pathname: "/login_sso",
      });
    }
  }, [history, location]);

  useEffect(() => {
    if (session) {
      history.replace({
        pathname: permissionRedirect({
          userRoles: session ? session.groups : [],
        }),
      });
    }
  }, [history, session]);

  return (
    <>
      <div style={{ width: "100%", minHeight: "100vh" }}>
        <NNavbar showLink={true} />
        <LoginComponents.LoginForm />
      </div>
    </>
  );
};
export default Login;
