import NBadge from "components/NBadge";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { colorBase } from "utils/constants";
// const TicketTextColor = {
//   open: "#3AC8AC",
//   solved: "#989DA5",
//   pending: "#F6D42D",
//   new: "#3AC8AC",
//   hold: "#F6D42D",
//   closed: "#989DA5",
// };

const TicketTextColor = {
  open: colorBase.success,
  solved: colorBase.inactive,
  pending: colorBase.warning,
  new: colorBase.success,
  hold: colorBase.warning,
  closed: colorBase.inactive,
};
const TicketText = {
  open: "Abierto",
  solved: "Resuelto",
  pending: "Pendiente",
  new: "Nuevo",
  hold: "Hold",
  closed: "Cerrado",
};

const ItemZendesk = ({ item }) => {
  return (
    <div>
      <CardContainer>
        <div className="d-flex justify-content-between">
          <a href={item.url} style={{ color: "var(--secondary-color)" }}>
            {item.subject}
          </a>
          <NBadge
            text={_.get(TicketText, item.status.toLowerCase(), item.status)}
            customStyle={{
              background: _.get(
                TicketTextColor,
                item.status.toLowerCase(),
                "#F6D42D"
              ),
              color: "white",
              padding: "3.5px 6px",
              borderRadius: "64px",
            }}
          />
        </div>
        <div
          className="d-flex  justify-content-between font-size-caption"
          style={{ marginTop: "5px" }}
        >
          <div>{moment(item.zendesk_created_at).format("DD-MM-YYYY")}</div>
          <div>Asignado a: {item.assignee}</div>
        </div>
      </CardContainer>
    </div>
  );
};

export default ItemZendesk;

const CardContainer = styled.div`
  border: 1px solid var(--color-aqua);
  border-radius: 6px;
  padding: 10px 20px;
  margin-top: 10px;
`;
