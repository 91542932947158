import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt } from "@fortawesome/pro-regular-svg-icons";

import { NPagination } from "components";
import { getTaskList } from "store/actions/async/dashboard-async.actions";
import { APP_MODULES, setSessionFilters } from "utils";

const PaginationBar = () => {
  const dispatch = useDispatch();
  const {
    tasks,
    pending: { list: isPending },
    pagination,
  } = useSelector((state) => state.dashboard);

  const loadPage = (pageNumber, pageSize) => {
    setSessionFilters(APP_MODULES.DASHBOARD.FILTER_NAME, {
      page: pageNumber,
      page_size: parseInt(pageSize, 10),
    });
    dispatch(getTaskList());
  };

  if (tasks.length === 0) return null;

  return (
    <Container className="my-4">
      <Row>
        <Col className="d-flex align-items-center justify-content-start" xs={2}>
          <span className="d-none">
            <FontAwesomeIcon
              className="mr-2"
              size="1x"
              icon={faCloudDownloadAlt}
            />
            Excel
          </span>
        </Col>
        <Col
          style={{ paddingRight: 0 }}
          className="d-flex align-items-center justify-content-end"
        >
          <NPagination
            isPending={isPending}
            pagination={pagination}
            onPageChange={loadPage}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default PaginationBar;
