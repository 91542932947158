import { Dropdown } from "react-bootstrap";
import styled from "styled-components";

const DropdownTransparent = styled(Dropdown)`
  button {
    background-color: #edf1f7 !important;
    color: #787c83;
    border-color: #cdd4de;
    width: 100%;
    height: 49px;
    text-align: inherit;
  }
  .btn:focus,
  .btn.focus {
    outline: none !important;
    box-shadow: none !important;
  }
  .dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dropdown-toggle::after {
    position: relative;
    top: auto;
    right: auto;
    margin-left: 10px;
  }
  ${(props) => (props.disabled ? "pointer-events: none;" : "")}
`;

export default DropdownTransparent;
