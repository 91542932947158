/* eslint-disable no-underscore-dangle */
import { ACTIONS } from "store/actions/site.actions";
import { groupBy } from "utils";

const initialState = {
  isSidebarOpen: false,
  isCollapsedOpen: false,
  isScriptSidebarOpen: false,
  activeModule: { title: null, subtitle: null },
  _temp: null,
  pending: {
    clientFilters: false,
    roles: false,
    clientAreas: false,
  },
  clientFilters: {
    data: {
      students: [],
      tasks: [],
      initiative: [],
    },
    filters: {
      students: {},
      tasks: {},
      initiative: {},
    },
  },
  singleLoads: { roles: false, clientAreas: false },
  roles: [],
  clientAreas: [],
  taskChannel: null,
  pusher: null,
};

const siteReducers = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.TOGGLE_SIDEBAR: {
      const { sw, attr } = action.payload;

      return {
        ...state,
        [attr]: sw,
      };
    }

    case ACTIONS.SET_ACTIVE_MODULE: {
      return { ...state, activeModule: action.payload };
    }

    case ACTIONS.SET_TEMP_FILTER: {
      return {
        ...state,
        _temp: {
          ...action.payload,
        },
      };
    }

    case ACTIONS.GET_CLIENT_FILTERS: {
      return { ...state, pending: { ...state.pending, clientFilters: true } };
    }

    case ACTIONS.CLEAN_ALL_TEMP_FILTER: {
      return { ...state, clientFilters: initialState.clientFilters };
    }

    case ACTIONS.CLEAN_FILTER: {
      return {
        ...state,
        clientFilters: {
          data: state.clientFilters.data,
          filters: { ...state.clientFilters.filters, [action.payload]: {} },
        },
      };
    }

    case ACTIONS.SET_CLIENT_FILTERS: {
      const groupedFilters = groupBy(action.payload, "module");
      const auxClientFilters = {
        ...state.clientFilters,
        data: groupedFilters,
      };
      localStorage.setItem("clientFilters", JSON.stringify(auxClientFilters));

      return {
        ...state,
        pending: {
          ...state.pending,
          clientFilters: false,
        },
        clientFilters: auxClientFilters,
      };
    }

    case ACTIONS.REGISTER_ERROR: {
      return { ...state, pending: { clientFilters: false } };
    }

    case ACTIONS.LOAD_CLIENT_FILTERS: {
      const client = localStorage.getItem("clientFilters");
      if (client) return { ...state, clientFilters: JSON.parse(client) };
      return { ...state };
    }

    case ACTIONS.UPDATE_CLIENT_FILTERS: {
      localStorage.setItem("clientFilters", JSON.stringify(action.payload));
      return { ...state, clientFilters: action.payload };
    }

    case ACTIONS.GET_ROLES: {
      return {
        ...state,
        pending: { ...state.pending, roles: true },
        singleLoads: { ...state.singleLoads, roles: true },
      };
    }

    case ACTIONS.SET_ROLES: {
      return {
        ...state,
        pending: { ...state.pending, roles: false },
        singleLoads: { ...state.singleLoads, roles: true },
        roles: action.payload.results,
      };
    }

    case ACTIONS.GET_CLIENT_AREAS: {
      return {
        ...state,
        pending: { ...state.pending, clientAreas: true },
        singleLoads: { ...state.singleLoads, clientAreas: true },
      };
    }

    case ACTIONS.SET_CLIENT_AREAS: {
      return {
        ...state,
        pending: { ...state.pending, clientAreas: false },
        singleLoads: { ...state.singleLoads, clientAreas: true },
        clientAreas: action.payload.results,
      };
    }

    case ACTIONS.SET_CHANNEL_TASK: {
      return {
        ...state,
        taskChannel: action.payload,
      };
    }

    case ACTIONS.SET_PUSHER: {
      return {
        ...state,
        pusher: action.payload,
      };
    }

    default:
      return { ...state };
  }
};

export default siteReducers;
