import { getDataFromLocalStorage } from "utils";

export const getTabSelected = () => {
  const data = getDataFromLocalStorage("tabTaskSelected", null);
  if (data) {
    if (data["tab"]) {
      return data["tab"];
    }
  }
  return null;
};
