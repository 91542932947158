class Serializer {
  static Serialize(data: any = {}, fieldMap: any = {}): Object {
    const mapData = new Map(Object.entries(data));
    const newObject = new Map();

    Object.entries(fieldMap).map((field) => {
      const [key, value] = field;

      let keyExists = false;
      let finalValue = value;

      if (mapData.has(String(value))) {
        keyExists = true;
      } else if (mapData.has(String(key))) {
        keyExists = true;
        finalValue = key;
      }

      if (keyExists) {
        newObject.set(key, mapData.get(String(finalValue)));
      }

      return null;
    });

    return Object.fromEntries(newObject);
  }

  static Deserialize(data: any = {}, fieldMap: any = {}): Object {
    const mapData = new Map(Object.entries(data));
    const newObject = new Map();

    Object.entries(fieldMap).map((field) => {
      const [key, value] = field;

      if (mapData.has(String(key))) {
        newObject.set(value, mapData.get(String(key)));
      }

      return null;
    });

    return Object.fromEntries(newObject);
  }
}

export default Serializer;
