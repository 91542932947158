import { generateUrlWithParams } from "utils";
import { CancelToken } from "axios";
import api from "./api";
import logger from "./logService";

const tokens = {
  advancedSearch: null,
};

export const getUsers = (queryParams = null) => {
  const finalUrl = generateUrlWithParams("accounts/user/", queryParams);
  return api.get(finalUrl);
};

export const getUser = async (userID) => {
  try {
    const response = await api.get(`accounts/user/${userID}/`);
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return Object.fromEntries(err);
  }
};

export const usersAdvancedSearch = async (queryParams) => {
  if (tokens.advancedSearch)
    tokens.advancedSearch.cancel("Operation canceled due to new request.");
  tokens.advancedSearch = CancelToken.source();
  const finalUrl = generateUrlWithParams("accounts/user/", queryParams);

  try {
    const response = await api.get(finalUrl, {
      cancelToken: tokens.advancedSearch.token,
    });
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return err;
  }
};

export const removeUser = async (userID) => {
  try {
    const response = await api.delete(`accounts/user/${userID}/`);
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return err;
  }
};

export const createUser = async (payload) => {
  try {
    const response = await api.post(`accounts/user/`, payload);
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return Object.fromEntries(err);
  }
};

export const editUser = async (payload) => {
  const { id, ...data } = payload;
  try {
    const response = await api.put(`accounts/user/${id}/`, data);
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return Object.fromEntries(err);
  }
};

export const uploadImagePerson = async (userID, file) => {
  const form_data = new FormData();
  const config = {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "multipart/form-data",
    },
  };
  if (file !== null) {
    form_data.append("profile_image", file);
  }

  try {
    const response = await api.patch(
      `accounts/user-profile/${userID}/`,
      form_data,
      config
    );
    return Promise.resolve(response);
  } catch (err) {
    return { ...Object.fromEntries(err), data: null };
  }
};

export const uploadImageProfile = async (userID, file) => {
  const form_data = new FormData();
  const config = {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "multipart/form-data",
    },
  };
  if (file !== null) {
    form_data.append("profile_image", file);
  }

  try {
    const response = await api.patch(
      `accounts/user-profile/${userID}/`,
      form_data,
      config
    );
    return Promise.resolve(response);
  } catch (err) {
    return { ...Object.fromEntries(err), data: null };
  }
};

export const changeClientArea = async (payload) => {
  try {
    const response = await api.post(
      `accounts/user/change_client_area/`,
      payload
    );
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return Object.fromEntries(err);
  }
};

export const changeLanguage = async (payload) => {
  try {
    const response = await api.post(
      `accounts/user/change_preferred_language/`,
      payload
    );
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return Object.fromEntries(err);
  }
};

export const downloadUsersExcel = () => {
  const path = "/accounts/user/download_excel/";
  return api.get(path, { responseType: "blob" });
};

export const uploadProfile = async (userID, value) => {
  try {
    const response = await api.patch(`accounts/user-profile/${userID}/`, value);
    return Promise.resolve(response);
  } catch (err) {
    return { ...Object.fromEntries(err), data: null };
  }
};

export const getUsertTrackingObjects = async (queryParams = null) => {
  try {
    const finalUrl = `crm/course/from-campuses/`;
    const response = await api.post(finalUrl, queryParams);
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return Object.fromEntries(err);
  }
};

export const deactivateAssociated = async (queryParams = null) => {
  try {
    const finalUrl = `crm/associateds/deactivate/`;
    const response = await api.post(finalUrl, queryParams);
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return Object.fromEntries(err);
  }
};
