import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import styles from "./NBadge.module.css";
import { colors } from "./colors";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const NBadge = ({
  text,
  kind,
  customStyle,
  isBold,
  onClick,
  className,
  title,
  newColor,
  outlined = false,
  backgroundColor,
  fontColor,
  positionIcon = "left",
  icon,
  loading,
  big = false,
  textTooltip,
  colorIcon,
}) => {
  const [classes, setClasses] = useState([isBold ? styles.boldText : ""]);

  useEffect(() => {
    const colorSelect = colors[kind] || colors["default"];
    setClasses((c) => [
      ...c,
      // styles.nbadge,
      className,
      colorSelect.background,
      colorSelect.fontColor,
    ]);
  }, [kind, className]);

  const getTooltip = useCallback(() => {
    return <Tooltip id="tooltip-top">{textTooltip}</Tooltip>;
  }, [textTooltip]);

  return (
    <OverlayTrigger key="s" placement="top" overlay={getTooltip()}>
      {({ ref, ...triggerHandler }) => (
        <NBadgeComponent
          className={classes.join(" ")}
          style={{ ...customStyle }}
          onClick={onClick}
          aria-hidden="true"
          title={title}
          background={newColor?.background || backgroundColor}
          color={newColor?.fontColor || fontColor}
          outlined={outlined ? 1 : 0}
          big={big ? 1 : 0}
          colorIcon={colorIcon}
          {...triggerHandler}
        >
          <div className="d-flex align-items-center">
            {loading && (
              <FontAwesomeIcon
                icon={faCircleNotch}
                className="fa-spin mr-icon"
              />
            )}
            {icon && positionIcon === "left" && !loading && (
              <span className="mr-icon color-icon">{icon}</span>
            )}
            <div
              style={{ whiteSpace: "nowrap", textOverflow: "ellipsis '[..]'" }}
            >
              {text}
            </div>
            {icon && positionIcon === "right" && !loading && (
              <span className="ml-icon color-icon">{icon}</span>
            )}
            {textTooltip && (
              <PointerBadge
                ref={ref}
                color={newColor?.background || backgroundColor}
              />
            )}
          </div>
        </NBadgeComponent>
      )}
    </OverlayTrigger>
  );
};

NBadge.propTypes = {
  text: PropTypes.string.isRequired,
  customStyle: PropTypes.shape({}),
  kind: PropTypes.string,
  isBold: PropTypes.bool,
  title: PropTypes.string,
};

NBadge.defaultProps = {
  kind: "light",
  customStyle: {},
  isBold: false,
  title: "",
};

export default React.memo(NBadge);

const NBadgeComponent = styled.span`
  cursor: default;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  text-align: center;
  text-transform: capitalize;
  padding: 4px 8px;
  display: inline-block;
  ${(props) => (props.background ? ` background: ${props.background};` : "")}
  ${(props) => (props.color ? ` color: ${props.color};` : "")}
  ${(props) => (props.outlined ? `background: transparent !important;` : "")}
  ${(props) => (props.outlined ? `border: 1px solid ${props.background};` : "")}
  ${(props) => (props.outlined ? `color: ${props.background};` : "")}
  ${(props) => (props.big ? ` padding: 8px 8px;` : "")}

  ${(props) =>
    props?.colorIcon ? `.color-icon{ color: ${props?.colorIcon}; }` : ""}

  .mr-icon {
    margin-right: 6px;
  }
  .ml-icon {
    margin-left: 6px;
  }
  position: relative;
`;

const PointerBadge = styled.div`
  height: 12px;
  width: 12px;
  background: ${(props) => (props.color ? props.color : "red")};
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: -5px;
`;
