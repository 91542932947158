import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { Container, Col, Form } from "components";
import { uuidv4, fixHeaders } from "utils";
import styles from "./Task.module.css";
import { RowItem } from "components/Nimbiv2/styled";
import { useTranslationLocal } from "hooks";
import { useTranslation } from "react-i18next";
const initialHeaders = [
  {
    size: 3,
    label: "CURSO",
    ref: "name",
    canOrder: false,
    find: "tracking_object",
    typeCase: "uppercase",
  },
  {
    size: 2,
    label: "CAMPUS",
    ref: "campus",
    canOrder: false,
    find: "campus",
    typeCase: "uppercase",
  },
  {
    size: 2,
    label: "ÚLTIMA GESTIÓN",
    ref: "last_management",
    find: "last_management",
    canOrder: false,
    typeCase: "uppercase",
  },
  {
    size: 1,
    label: "SCORE",
    ref: "score",
    find: "score",
    canOrder: false,
    typeCase: "uppercase",
  },
  {
    size: 1,
    label: "EXPIRA EN",
    ref: "expires_in",
    find: "expires_in",
    canOrder: false,
    typeCase: "uppercase",
  },
  {
    size: 1,
    label: "ACCIÓN",
    ref: "action",
    find: "action",
    canOrder: false,
    typeCase: "uppercase",
  },
];

const ResultHeader = () => {
  const { translation } = useTranslationLocal();
  const { t } = useTranslation();
  const renderColumns = () => {
    const headers = fixHeaders(initialHeaders);
    return headers.map((header) => (
      <ColHeader
        key={uuidv4(8)}
        size={header.size}
        title={header.label}
        canOrder={header.canOrder}
      />
    ));
  };

  return (
    <Container style={{ minWidth: 992 }}>
      <RowItem className={`align-items-center ${styles.customBoxHeader}`}>
        <Col xs={2} style={{ padding: " 0px 8px" }}>
          <div
            className={`d-flex justify-content-left align-items-center ${styles.firstColumn}`}
            style={{ padding: "14px 0px" }}
          >
            <Form.Check
              style={{ display: "none" }}
              className="font-weight-bold"
              type="checkbox"
              id="check1"
              custom
            />
            <span className={`mr-1 ${styles.headerTitle}`}>
              {translation("student", "upper", t("student"))}
            </span>
            <FontAwesomeIcon
              style={{ display: "none" }}
              className="text-primary"
              icon={faChevronDown}
            />
          </div>
        </Col>
        {renderColumns()}
      </RowItem>
    </Container>
  );
};

const ColHeader = ({ size, title, canOrder = false }) => {
  return (
    <Col xs={size} style={{ padding: " 0px 8px" }}>
      <span className={`mr-1 ${styles.headerTitle}`}>{title}</span>
      {canOrder ? (
        <FontAwesomeIcon className="text-primary" icon={faChevronDown} />
      ) : null}
    </Col>
  );
};

export default React.memo(ResultHeader);
