import React, { useMemo } from "react";
import { CardNimbi } from "components/Nimbiv2/styled";
import { validatePageTemplate } from "../validations";
import { ContainerTitle, TitleCard } from "./styles";
import { StepTitle } from "components/Nimbiv2/InitiativesComponent";
import { NIcon } from "components/Nimbiv2";
import { useTranslation } from "react-i18next";
import { isEmptyObject } from "utils";

const CardTemplateInitiativeResume = ({
  formValues,
  edit,
  isExternal,
  onChangePosition,
  typeFormList,
}) => {
  const { t } = useTranslation();
  const getIconExclamationCircle = () => {
    return (
      <NIcon
        iconname="exclamationCircle"
        style={{ marginLeft: "4px", color: "#E23232" }}
      ></NIcon>
    );
  };
  const typeOption = useMemo(() => {
    return {
      Email: {
        title: t("mail_template"),
        nameAttrib: "email_template",
        icon: "mail",
      },
      Call: {
        title: t("call_pattern"),
        nameAttrib: "script_template",
        icon: "phone",
      },
      WhatsApp: {
        title: "Whatsapp Template",
        nameAttrib: "whatsapp_template",
        icon: "whatsapp",
      },
    };
  }, [t]);
  return (
    <CardNimbi
      padding={"17px 35px 17px 18px"}
      bordercolor={
        !isEmptyObject(validatePageTemplate(formValues)) ? "#E23232" : "#3AC8AC"
      }
    >
      <ContainerTitle>
        <TitleCard>{typeOption[formValues.optionInitiative].title}</TitleCard>
        <div style={{ display: "flex" }}>
          <StepTitle>
            {t("step_number", {
              number: typeFormList?.indexOf("selectTemplate") + 1,
            })}
          </StepTitle>
          {!formValues.clone && (
            <NIcon
              iconname="edit"
              style={{
                marginLeft: "18px",
                fontSize: "16px",
                cursor: "pointer",
              }}
              onClick={onChangePosition}
            />
          )}
          {formValues.clone && (
            <div style={{ marginLeft: "18px", width: "16px" }} />
          )}
        </div>
      </ContainerTitle>
      <div style={{ marginTop: "10px" }}>
        <NIcon
          iconname={typeOption[formValues.optionInitiative].icon}
          style={{ marginRight: "8px" }}
        />
        {formValues?.created_in_initiative ? (
          `Nuevo template: ${formValues?.script_template_name}`
        ) : formValues[typeOption[formValues.optionInitiative].nameAttrib] ? (
          formValues.name_template
        ) : (
          <>
            {t("select_template")} {getIconExclamationCircle()}
          </>
        )}
      </div>
    </CardNimbi>
  );
};

export default CardTemplateInitiativeResume;
