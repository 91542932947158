import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Image } from "react-bootstrap";
import { logoNimbiColor } from "../../assets/images";
import { getLogo } from "services/site.service";
import { NSpinnerPoints } from "components/Nimbiv2";

const FeedbackClient = ({ image, title, description, altImage }) => {
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const searchLogo = async () => {
    setLoading(true);
    const { status, data } = await getLogo();
    if (status === 200) {
      setLogo(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    searchLogo();
  }, []);

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <HeaderLogin>
        {loading && <NSpinnerPoints />}
        {!loading && (
          <Image
            style={{ border: 0, height: "45px" }}
            src={logo ?? logoNimbiColor}
          />
        )}
      </HeaderLogin>
      <ContainerMessage>
        <div>
          <div className="text-center">
            <img src={image} alt={altImage} />
          </div>

          <div className="text-center text-title ">{title}</div>
          <p
            className="text-center text-description"
            dangerouslySetInnerHTML={{ __html: description }}
          ></p>
        </div>
      </ContainerMessage>
    </div>
  );
};

export default FeedbackClient;

const HeaderLogin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75.56px;
  background: #ffffff;
`;

const ContainerMessage = styled.div`
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  .text-title {
    margin-top: 60px;
    font-family: "Gotham";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    text-align: center;
    color: #2a2b2e;
  }
  .text-description {
    margin-top: 20px;
    font-style: normal;
    font-weight: 325;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    color: #2a2b2e;
  }
`;
