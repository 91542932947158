import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import copy from "copy-to-clipboard";
import * as Models from "models";

import styles from "./ModalProfile.module.css";
import { validateEmail, validatePhone } from "utils";
import { useTranslation } from "react-i18next";

const TextContactType = {
  email: "Correo",
  phone: "Teléfono",
};
const ContactInfo = ({ contact, onUpdate, onDelete }) => {
  const { t } = useTranslation();
  const copyValue = (value) => copy(value);

  const updateContact = (event) => {
    const { target } = event;
    const { key } = target.dataset;
    const newValue = key === "whatsappAllowed" ? target.checked : target.value;
    const updated = {
      ...contact,
      [key]: newValue,
      error: key === "whatsappAllowed" ? false : validateData(target.value),
    };
    onUpdate(new Models.ContactInfo(updated));
  };
  const validateData = (text) => {
    if (contact.contactType === "email") {
      return validateEmail(text);
    }
    if (contact.contactType === "phone") {
      return validatePhone(text);
    }
    return false;
  };

  const deleteContact = () => onDelete(contact);

  if (!contact) return null;

  return (
    <Row className="mt-4">
      <Col md={8}>
        <span className={`ml-1 ${styles.label2}`}>
          {TextContactType[contact.contactType]}
        </span>
        <div className="d-flex align-items-center">
          <div style={{ width: "80%" }} className={styles.fieldBox2}>
            <input
              data-key="value"
              className={styles.inputInfo}
              type="text"
              value={contact.value}
              onChange={(event) => {
                updateContact(event);
              }}
            />
            <FontAwesomeIcon
              className={`ml-2 ${styles.iconField}`}
              size="1x"
              icon={faClone}
              onClick={() => copyValue(contact.value)}
              aria-hidden="true"
            />
          </div>
          <FontAwesomeIcon
            style={{ fontSize: "1rem" }}
            className={`icons_color ml-3 ${styles.iconField} ${styles.shadow}`}
            icon={faTrashAlt}
            onClick={deleteContact}
          />
        </div>
        {contact.error === true && contact.value.length === 0 && (
          <div style={{ color: "red", marginTop: "5px" }}>
            {t("required_field")}
          </div>
        )}
        {contact.error === true &&
          contact.contactType === "email" &&
          contact.value.length > 0 && (
            <div style={{ color: "red", marginTop: "5px" }}>
              El correo ingresado no es válido
            </div>
          )}
        {contact.error === true &&
          contact.contactType === "phone" &&
          contact.value.length > 0 && (
            <div style={{ color: "red", marginTop: "5px" }}>
              El número de teléfono no es válido
            </div>
          )}
        {contact.contactType === "phone" ? (
          <Form.Check
            id={`wallowed-${contact.id}`}
            data-key="whatsappAllowed"
            className="mt-2"
            type="checkbox"
            label="WhatsApp"
            checked={contact.whatsappAllowed === true}
            onChange={(event) => updateContact(event)}
            custom
          />
        ) : null}
      </Col>
      <Col md={4}>
        <span className={`ml-1 ${styles.label2}`}>Permitido</span>
        <div className={`d-flex align-items-center ${styles.radiosContainer}`}>
          <Form.Check
            id={`allowed-y-${contact.id}`}
            name={`isAllowed_${contact.id}`}
            data-key="isAllowed"
            className="mr-4"
            type="radio"
            label="Si"
            value="yes"
            checked={contact.isAllowed === "yes"}
            onChange={(event) => updateContact(event)}
            custom
          />
          <Form.Check
            id={`allowed-n-${contact.id}`}
            name={`isAllowed_${contact.id}`}
            data-key="isAllowed"
            className="mr-4"
            type="radio"
            label="No"
            value="no"
            checked={contact.isAllowed === "no"}
            onChange={(event) => updateContact(event)}
            custom
          />
          {1 == 0 && (
            <Form.Check
              id={`allowed-p-${contact.id}`}
              name={`isAllowed_${contact.id}`}
              data-key="isAllowed"
              className="mr-4"
              type="radio"
              label="Pendiente"
              value="pending"
              checked={contact.isAllowed === "pending"}
              onChange={(event) => updateContact(event)}
              custom
            />
          )}
        </div>
      </Col>
    </Row>
  );
};

ContactInfo.propTypes = {
  contact: PropTypes.instanceOf(Models.ContactInfo).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ContactInfo;
