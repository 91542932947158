import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { NButton } from "components/Nimbiv2";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const GoogleButton = ({ onGoogleSuscess, isPending }) => {
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      onGoogleSuscess(tokenResponse);
    },
  });
  return (
    <div className="d-flex justify-content-center" style={{ width: "100%" }}>
      <NButton
        variant="primary"
        type="submit"
        disabled={isPending}
        loading={isPending}
        block
        colorbutton="#DB4437"
        hoverbuttom="#bc3a2e"
        border="8px"
        style={{ width: "auto" }}
        className="mt-3"
        onClick={() => handleGoogleLogin()}
        padding="10px 40px"
      >
        <FontAwesomeIcon icon={faGoogle} className="fas fa-cog mr-3" />
        Iniciar sesión con Google
      </NButton>
    </div>
  );
};
export default GoogleButton;
