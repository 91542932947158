import React, { useCallback, useMemo } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import * as routePaths from "routes/routePaths";
import { useHistory } from "react-router-dom";
import { DropdownTransparent, RowItem } from "components/Nimbiv2/styled";
import { Col, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NInputFilter } from "components";
import { Highlighter } from "react-bootstrap-typeahead";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { getInitiatives } from "services/initiativeService";
import { useTranslationLocal } from "hooks";
import NCheckBadge from "components/Nimbiv2/NDynamicFilters/NCheckBadge";
import { NButton } from "components/Nimbiv2";
import { ButtonSize } from "utils/constants";

const InitiativeFilters = ({
  selectOption,
  onChangeStatus,
  tabSelected,
  changeSelectedValue,
  searchValue,
  clean,
  changeTypeInitiatives,
  orderingSelected,
  isDisabled = false,
  onSelect = () => {},
  selectTab = "internal",
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const { translation } = useTranslationLocal();

  const filterFields = ["name"];

  const optionsAction = useMemo(() => {
    return {
      all: _.upperFirst(t("all")),
      active: _.upperFirst(t("actives")),
      programmed: _.upperFirst(t("scheduled")),
      draft: _.upperFirst(t("drafts")),
      completed: _.upperFirst(t("finished")),
    };
  }, [t]);

  const optionTabs = useMemo(() => {
    return {
      all: _.upperFirst(t("all")),
      internal: _.upperFirst(t("internal")),
      external: _.upperFirst(t("external")),
    };
  }, [t]);

  const OrderingName = {
    name: _.upperFirst(t("name")),
    start_date: t("start_date"),
    "-created_at": t("latest_creation"),
  };

  const customMenuItem = (option, { text }) => {
    return (
      <div>
        <Highlighter search={text}>{`${option.name}`}</Highlighter>
        <div>
          <small>
            {option.objective && option.objective.length > 71
              ? option.objective.slice(0, 68) + "..."
              : option.objective}
          </small>
        </div>
      </div>
    );
  };

  const customInput = (text) => {
    if (text !== null) {
      return text.toString().replaceAll(".", "");
    }
    return text;
  };

  const searchInitiative = useCallback(
    async (query) => {
      const params = {
        page_size: 100,
        search: query.trim(),
        source_type: tabSelected,
        status: selectOption,
      };
      const response = await getInitiatives(params);
      let results;
      if (response.data) results = response.data.results;
      return Promise.resolve(results ?? []);
    },
    [tabSelected, selectOption]
  );

  return (
    <>
      <RowItem>
        <Col xl="9" lg="8" className="mt-2 mt-md-0">
          <InputDesign style={{ padding: "7px 12px" }}>
            <NInputFilter
              handleChange={changeSelectedValue}
              data={[]}
              delay={800}
              compositeLabel={["name"]}
              placeholder={translation(
                "placeholder_search_initiative",
                "",
                t("initiative_placeholder_search")
              )}
              selected={searchValue}
              filterBy={filterFields}
              renderMenuItem={customMenuItem}
              asyncSearch={searchInitiative}
              isDisabled={isDisabled}
              isAsync
              formatInput={customInput}
              clean={clean}
            />
            <FontAwesomeIcon
              icon={faSearch}
              style={{ height: "18px", marginLeft: "10px", color: "#334FFF" }}
            />
          </InputDesign>
        </Col>
        <Col xl="3" lg="4" className="mt-2 mt-md-0 d-flex justify-content-end">
          <NButton
            sizeStyle={ButtonSize.lg}
            onClick={() => {
              history.push(routePaths.initiatives.add.route);
            }}
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
            icon={
              <FontAwesomeIcon
                icon={faPlus}
                style={{
                  marginRight: "5px",
                  fontSize: "var(--font-size-md)",
                }}
              />
            }
            color="second"
          >
            {t("create_new_initiative")}
          </NButton>
        </Col>
      </RowItem>
      <RowItem style={{ marginTop: "24px" }}>
        <Col lg="8" className="d-flex align-items-center">
          <div className="mr-3 font-weight-bold">{t("sort_by")}: </div>
          {Object.keys(OrderingName).map((item, key) => (
            <NCheckBadge
              customStyle={{ marginRight: "5px" }}
              disabled={isDisabled}
              name="group-filters"
              id={`ordering-initiative-${key}`}
              key={`ordering-initiative-${key}`}
              label={OrderingName[item]}
              checked={orderingSelected === item}
              onChange={(event) => {
                if (item === orderingSelected) {
                  changeTypeInitiatives(null);
                } else {
                  changeTypeInitiatives(item);
                }
              }}
            />
          ))}
        </Col>
        <Col lg="4" md="12" sm="12" className="mt-3 mt-lg-0 mt-md-0 mt-xl-0">
          <div className="d-flex justify-content-end">
            <DropdownTransparent onSelect={onSelect} disabled={isDisabled}>
              <Dropdown.Toggle variant="secundary" id="dropdown-basic">
                {_.upperFirst(t("types"))}: {optionTabs[tabSelected]}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {Object.keys(optionTabs).map((item, key) => {
                  return (
                    <Dropdown.Item eventKey={item} key={`option-action-${key}`}>
                      {optionTabs[item]}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </DropdownTransparent>
            <DropdownTransparent
              onSelect={onChangeStatus}
              disabled={isDisabled}
              style={{ marginLeft: "12px" }}
            >
              <Dropdown.Toggle variant="secundary" id="dropdown-basic">
                {_.upperFirst(t("status"))}: {optionsAction[selectOption]}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {Object.keys(optionsAction).map((item, key) => {
                  return (
                    <Dropdown.Item eventKey={item} key={`option-action-${key}`}>
                      {optionsAction[item]}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </DropdownTransparent>
          </div>
        </Col>
      </RowItem>
    </>
  );
};

InitiativeFilters.propTypes = {
  onChangeStatus: PropTypes.func.isRequired,
  changeSelectedValue: PropTypes.func.isRequired,
  changeTypeInitiatives: PropTypes.func.isRequired,
};

export default InitiativeFilters;

const InputDesign = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  padding: 10px 12px;
  .form-control {
    border: 0px solid #ced4da !important;
    padding: 0px;
  }
  .rbt {
    width: 100%;
  }
`;
