import React, { useCallback, useEffect } from "react";
import {
  StepTitle,
  TextTitle,
  TextQuestion,
  ImgPosition,
} from "components/Nimbiv2/InitiativesComponent";
import { DividerH, CardNimbi } from "components/Nimbiv2/styled";
import { Col, Row } from "react-bootstrap";
import { ImageInitiatives1 } from "assets/images";
import ListOptionClip from "components/Nimbiv2/ListOptionClip";
import { NIcon, NButton } from "components/Nimbiv2";
import { useDispatch, useSelector } from "react-redux";
import { fetchListTypeInitiatives } from "store/actions/async/initiatives-async.actions";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { ButtonSize } from "utils/constants";

const PageInitial = ({
  onChange,
  onChangeForm,
  formValues,
  edit = false,
  errors,
}) => {
  const dispatch = useDispatch();
  const {
    listTypeInitiatives,
    pending: { typeInitiatives: isPending },
  } = useSelector((state) => state.initiative);

  const { t } = useTranslation();

  const loadTypeInitiatives = useCallback(() => {
    dispatch(fetchListTypeInitiatives());
  }, [dispatch]);

  useEffect(() => {
    loadTypeInitiatives();
  }, [loadTypeInitiatives]);

  return (
    <div style={{ maxWidth: "922px", margin: "auto" }}>
      <CardNimbi padding={"33px 35px 33px 35px"} style={{ minHeight: "380px" }}>
        <Row>
          <ImgPosition src={ImageInitiatives1} />
          <Col xs={9}>
            <StepTitle>{t("step_number", { number: "2" })}</StepTitle>
            <div style={{ marginTop: "18px" }}>
              <TextTitle>{t("select_the_type_of_initiative")}</TextTitle>
            </div>
            <div style={{ marginTop: "40px", marginBottom: "40px" }}>
              <DividerH></DividerH>
            </div>
            <div>
              <TextQuestion>1. {t("what_type_of_initiative")}</TextQuestion>
              <div style={{ marginTop: "16px" }}>
                {isPending && (
                  <div>
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="fas fa-cog fa-spin"
                      style={{ fontSize: "30px" }}
                    />
                  </div>
                )}
                {!isPending && (
                  <ListOptionClip
                    keyTitle="front_name"
                    keyValue="name"
                    listOptions={listTypeInitiatives}
                    selected={formValues.optionInitiative}
                    onChange={(value, item) => {
                      onChangeForm(
                        "optionInitiative",
                        value,
                        {
                          initiative_type: item.id,
                          optionInitiative: value,
                        },
                        false,
                        false,
                        true
                      );
                    }}
                    error={t("select_initiative_required")}
                    showError={errors.hasOwnProperty("optionInitiative")}
                  />
                )}
              </div>
            </div>
            <div style={{ marginTop: "57px" }}>
              <TextQuestion>
                2. ¿Qué base de datos deseas utilizar?
              </TextQuestion>
              <div style={{ marginTop: "16px" }}>
                <ListOptionClip
                  keyTitle="title"
                  keyValue="value"
                  isDisabled={
                    formValues?.optionInitiative === null ||
                    typeof formValues?.optionInitiative === "undefined"
                  }
                  listOptions={
                    formValues.optionCreate === "copy"
                      ? [
                          {
                            icon: "house",
                            title: "Interna Nimbi",
                            value: "internal",
                          },
                        ]
                      : [
                          {
                            icon: "house",
                            title: "Interna Nimbi",
                            value: "internal",
                          },
                          {
                            icon: "excel",
                            title: "Externa (carga archivo)",
                            value: "external",
                          },
                        ]
                  }
                  selected={
                    formValues.optionCreate !== "copy"
                      ? formValues.source_type
                      : "internal"
                  }
                  onChange={(value, item) => {
                    let list_keys_delete = [];
                    if (value === "external") {
                      list_keys_delete = [
                        "campuses",
                        "campos_selected",
                        "tracking_object",
                        "tracking_objects",
                        "tracking_object_selected",
                        "student_trackings",
                        "selectAll",
                      ];
                    } else {
                      list_keys_delete = ["base_file"];
                    }
                    onChangeForm(
                      "source_type",
                      value,
                      {},
                      false,
                      false,
                      false,
                      list_keys_delete
                    );
                  }}
                  error={"Selección requerido"}
                  showError={errors.hasOwnProperty("source_type")}
                />
              </div>
            </div>
          </Col>
        </Row>
      </CardNimbi>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <NButton
          sizeStyle={ButtonSize.lg}
          style={{
            marginTop: "24px",
            borderRadius: "24px",
            marginRight: "16px",
          }}
          onClick={() => {
            onChange("last");
          }}
          outlined={1}
          icon={<NIcon iconname="arrowLeft" />}
          color="second"
        >
          {t("go_back")}
        </NButton>
        <NButton
          sizeStyle={ButtonSize.lg}
          style={{
            marginTop: "24px",
            borderRadius: "24px",
            textTransform: "capitalize",
          }}
          onClick={() => {
            onChange("next");
          }}
          icon={<NIcon iconname="arrowRight" />}
          positionIcon="right"
          color="second"
        >
          {t("continue")}
        </NButton>
      </div>
    </div>
  );
};

export default PageInitial;
