import React, { useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useDefault, useTranslationLocal } from "hooks";
import { NNavbarDashboard, ScrollToTopOnMount } from "components/Nimbiv2";
import { setActiveModule } from "store/actions/site.actions";
import { TaskView } from "containers/Task";

const TaskPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();

  const taskModuleTitle = useDefault(
    "taskModuleTitle",
    _.capitalize(t("tasks"))
  );

  useEffect(() => {
    if (taskModuleTitle)
      dispatch(
        setActiveModule({
          title: taskModuleTitle,
          subtitle: t("manager_your_students", {
            students: translation("students", "", t("students")),
          }),
        })
      );
  }, [dispatch, taskModuleTitle, t, translation]);

  return (
    <div>
      <ScrollToTopOnMount />
      <NNavbarDashboard />
      <TaskView />
    </div>
  );
};
export default TaskPage;
