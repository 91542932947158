import React from "react";
import { NButton } from "components/Nimbiv2";
import NTextArea from "components/Nimbiv2/NTextArea";
import styled from "styled-components";

const NSurveyComment = ({
  score,
  handleCommentChange,
  value,
  onClick,
  onLastPosition,
}) => {
  return (
    <ContainerDesign>
      <div
        style={{
          margin: "0px",
          marginBottom: "12px",
          fontSize: "16px",
          fontWeight: "bold",
        }}
      >
        {`Cuéntanos más detalles de por qué elegiste ${score}`}
      </div>
      <SurveyContainer>
        <NTextArea
          value={value}
          rows={2}
          onChange={handleCommentChange}
          // errorText={observationRequired ? t("required_field") : null}
        />
        <div>
          <NButton style={{ marginTop: "5px" }} onClick={onClick}>
            Enviar
          </NButton>
          <div>
            <NButton
              style={{ marginTop: "5px" }}
              onClick={onLastPosition}
              transparent={true}
            >
              Pregunta anterior
            </NButton>
          </div>
        </div>
      </SurveyContainer>
    </ContainerDesign>
  );
};
export default NSurveyComment;
const ContainerDesign = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0px auto;
  text-align: center;
`;
const SurveyContainer = styled.div`
  margin-top: 10px;
  margin: 0px auto;
  width: 418px;
  margin: 0px auto;
  .container-all-numbers {
    display: flex;
  }
  .container-number {
    margin: 0px 3px;
    border-radius: 50%;
    border: 1px solid;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2f5fd;
    cursor: pointer;
    &:hover {
      transform: scale(1.25);
      background: var(--call-in-action) !important;
    }
  }
`;
