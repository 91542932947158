import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faEnvelope,
  faShare,
} from "@fortawesome/pro-regular-svg-icons";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { NSpinnerPoints } from "components/Nimbiv2";
import { CardNimbi, RowItem } from "components/Nimbiv2/styled";
import CardIndicator from "components/Nimbiv2/CardIndicator";
import { useTranslationLocal } from "hooks";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const PageIndicatorEmail = () => {
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const {
    initiative,
    insights,
    pending: { initiative: isPending, insights: isPendingInsights },
  } = useSelector((state) => state.initiative);

  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);

  useEffect(() => {
    if (insights && insights.insights && insights.insights.graph_data) {
      let labelAux = [];
      let dataAux = [];
      let total = 0;
      const { insights: listInsights } = insights;
      for (let i = 0; i < listInsights.graph_data.length; i++) {
        labelAux.push(
          listInsights.graph_data[i].day +
            " " +
            moment(listInsights.graph_data[i].date).format("DD-MM")
        );
        dataAux.push(listInsights.graph_data[i].opened);
        total = total + listInsights.graph_data[i].opened;
      }

      setLabels(labelAux);
      setData(dataAux);
      setTotal(total);
    }
  }, [insights]);

  const total_email_send = {
    title: t("total_emails_sent"),
    total: "0",
    description: t("student_have_been_contacted", {
      students: translation("students", "startCase", t("students")),
    }),
    icon: "inboxOut",
  };
  const total_email_error = {
    title: t("email_that_have_bounced"),
    total: "0",
    description: t("emails_were_not_received"),
    icon: "inboxIn",
  };
  const opening_email = {
    title: t("mail_open_rate"),
    total: "66%",
    description: t("of_your_students_have_opened_the_mail", {
      students: translation("students", "", t("students")),
    }),
    icon: "envelopeOpen",
  };
  const clicks_links_email = {
    title: t("click_rate_on_mail"),
    total: "20%",
    description: t("of_your_students_have_cliked", {
      students: translation("students", "", t("students")),
    }),
    icon: "mousePointer",
  };

  const options = {
    responsive: true,
    plugins: {
      datalabels: false,
    },
  };

  return (
    <ContainerPage>
      {(isPending || isPendingInsights) && <NSpinnerPoints />}
      {!isPending &&
        isPending !== null &&
        initiative !== null &&
        insights !== null && (
          <div>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faChartLine}
                style={{ fontSize: "var(--font-size-lg)", marginRight: "8px" }}
              />
              <TitleText>{t("overall_performance")}</TitleText>
            </div>
            <RowItem style={{ marginTop: "40px" }}>
              <Col lg="3" sm="6" md="6">
                <CardIndicator
                  data={total_email_send}
                  total={insights.insights.total + ""}
                />
              </Col>
              <Col lg="3" sm="6" md="6" className="mt-3 mt-sm-0">
                <CardIndicator
                  data={total_email_error}
                  total={insights.insights.bounced + ""}
                  action={() => {}}
                  actionLabel={t("resend")}
                  actionIcon={faShare}
                />
              </Col>
            </RowItem>
            <div
              className="d-flex align-items-center"
              style={{ marginTop: "40px" }}
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              <TitleText>{t("mail_tasks")}</TitleText>
            </div>
            <RowItem style={{ marginTop: "40px" }}>
              <Col lg="9" md="12" className="max-7" style={{ height: "100%" }}>
                <CardNimbi paddding="24px">
                  <strong style={{ fontSize: "var(--font-size-lg)" }}>
                    {t("posts_openend_total")}: {total}
                  </strong>
                  <div
                    style={{
                      color: "#787C83",
                      fontSize: "var(--font-size-small)",
                      marginTop: "8px",
                    }}
                  >
                    {moment(insights.insights.start_date).format("YYYY-MM-DD")}{" "}
                    - {moment(insights.insights.end_date).format("YYYY-MM-DD")}
                  </div>
                  <div>
                    <Line
                      options={options}
                      data={{
                        labels,
                        datasets: [
                          {
                            label: t("open_mail"),
                            data: data.map((item) => item),
                            fill: 1,
                            backgroundColor: "#0CCDB3",
                            borderColor: "#0CCDB3",
                          },
                        ],
                      }}
                    />
                  </div>
                </CardNimbi>
              </Col>
              <Col lg="3" md="12" className="max-5 pt-3 pt-lg-0">
                <RowItem>
                  <Col lg="12" className="p-0 max-6">
                    <div
                      style={{ marginBottom: "12px" }}
                      className="w-max-100 mr-max-1"
                    >
                      <CardIndicator
                        data={opening_email}
                        action={() => {}}
                        height="auto"
                        total={insights.insights.opened + "%"}
                      />
                    </div>
                  </Col>
                  <Col md="12" lg="12" xl="12" sm="12" className="p-0 max-6">
                    {/* <div style={{ marginTop: "24px" }}> */}
                    <CardIndicator
                      data={clicks_links_email}
                      action={() => {}}
                      height="auto"
                      total={insights.insights.clicked + "%"}
                    />
                    {/* </div> */}
                  </Col>
                </RowItem>
              </Col>
            </RowItem>
          </div>
        )}
    </ContainerPage>
  );
};

export default PageIndicatorEmail;

const TitleText = styled.div`
  font-family: Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 24px */

  /* text/primary */

  color: #2a2b2e;
`;

const ContainerPage = styled.div`
  @media screen and (min-width: 1480px) {
    .max-7 {
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .max-5 {
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .max-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .w-max-100 {
      height: 100%;
      .card {
        height: 100% !important;
      }
    }
    .mr-max-1 {
      margin-right: 10px;
    }
  }
`;
