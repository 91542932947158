import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NSpinnerPoints } from "components/Nimbiv2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { CardNimbi, RowItem } from "components/Nimbiv2/styled";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import CardIndicator from "components/Nimbiv2/CardIndicator";
import { useTranslationLocal } from "hooks";
import moment from "moment";
import { Line } from "react-chartjs-2";

const PageIndicatorWhatsapp = () => {
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const {
    initiative,
    insights,
    pending: { initiative: isPending, insights: isPendingInsights },
  } = useSelector((state) => state.initiative);

  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);

  const options = {
    responsive: true,
    plugins: {
      datalabels: false,
    },
  };

  useEffect(() => {
    if (insights && insights.insights && insights.insights.graph_data) {
      let labelAux = [];
      let dataAux = [];
      let total = 0;
      const { insights: listInsights } = insights;
      for (let i = 0; i < listInsights.graph_data.length; i++) {
        labelAux.push(
          listInsights.graph_data[i].day +
            " " +
            moment(listInsights.graph_data[i].date).format("DD-MM")
        );
        dataAux.push(listInsights.graph_data[i].opened);
        total = total + listInsights.graph_data[i].opened;
      }

      setLabels(labelAux);
      setData(dataAux);
      setTotal(total);
    }
  }, [insights]);

  const total_whatsapp_sent = {
    title: "Total mensajes enviados",
    total: "0",
    description: t("student_contacted_by_whatsapp", {
      students: _.upperFirst(translation("students", "", t("students"))),
    }),
    icon: "inboxOut",
  };
  const opening_whatsapp = {
    title: t("whatsapp_open_rate"),
    total: "66%",
    description: t("of_your_students_have_opened_the_whatsapp", {
      students: translation("students", "", t("students")),
    }),
    icon: "envelopeOpen",
  };

  return (
    <div>
      {(isPending || isPendingInsights) && <NSpinnerPoints />}
      {!isPending &&
        isPending !== null &&
        initiative !== null &&
        insights !== null && (
          <div>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faChartLine}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              <TitleText>{t("overall_performance")}</TitleText>
            </div>
            <RowItem style={{ marginTop: "40px" }}>
              <Col xs="3">
                <CardIndicator
                  data={total_whatsapp_sent}
                  total={insights.insights.total + ""}
                />
              </Col>
              <Col xs="3">
                <CardIndicator
                  data={opening_whatsapp}
                  total={insights.insights.opened + "%"}
                />
              </Col>
            </RowItem>
            <div
              className="d-flex align-items-center"
              style={{ marginTop: "40px" }}
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              <TitleText>{t("mail_whatsapp")}</TitleText>
            </div>
            <RowItem style={{ marginTop: "40px" }}>
              <Col lg="7" md="12" className="max-7" style={{ height: "100%" }}>
                <CardNimbi paddding="24px">
                  <strong style={{ fontSize: "var(--font-size-lg)" }}>
                    {t("total_messages_read")}: {total}
                  </strong>
                  <div
                    style={{
                      color: "#787C83",
                      fontSize: "var(--font-size-small)",
                      marginTop: "8px",
                    }}
                  >
                    {moment(insights.insights.start_date).format("YYYY-MM-DD")}{" "}
                    - {moment(insights.insights.end_date).format("YYYY-MM-DD")}
                  </div>
                  <div>
                    <Line
                      options={options}
                      data={{
                        labels,
                        datasets: [
                          {
                            label: t("messages_read"),
                            data: data.map((item) => item),
                            fill: 1,
                            backgroundColor: "#0CCDB3",
                            borderColor: "#0CCDB3",
                          },
                        ],
                      }}
                    />
                  </div>
                </CardNimbi>
              </Col>
            </RowItem>
          </div>
        )}
    </div>
  );
};

export default PageIndicatorWhatsapp;
const TitleText = styled.div`
  font-family: Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 24px */

  /* text/primary */

  color: #2a2b2e;
`;
