import React, { useEffect, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Typeahead } from "react-bootstrap-typeahead";
import { replaceChars, capitalizeWord } from "utils";
import { useTranslation } from "react-i18next";
import ContainerInputFilter from "./ContainerInputFilter";
import { VERSIONS_FILTERS_DESIGN } from "utils/constants";
const allItems = {
  1: "SI",
  0: "NO",
  True: "SI",
  False: "NO",
  undefined: "NO",
  "1##": "SI",
  "0##": "NO",
};
const numberx = {
  SI: "1##",
  NO: "0##",
};
const stringx = {
  SI: "1",
  NO: "0",
};
const boolex = {
  SI: "True",
  NO: "False",
};

const NFbool = ({
  dataset,
  initialValue,
  onChange,
  isDisabled = false,
  versionInput,
}) => {
  const { t } = useTranslation();
  const [itemsSelected, setItemsSelected] = useState([]);
  const [typeValues, setTypeValue] = useState("number");
  useEffect(() => {
    setItemsSelected(
      typeof initialValue !== "undefined"
        ? [allItems[String(initialValue)]]
        : []
    );
  }, [initialValue]);
  useEffect(() => {
    if (dataset) {
      if (dataset.values.length > 0)
        if (typeof dataset.values[0] === "boolean") {
          setTypeValue("bool");
        } else {
          if (typeof dataset.values[0] === "string") {
            setTypeValue("string");
          } else {
            setTypeValue("number");
          }
        }
    } else {
      setTypeValue("bool");
    }
  }, [dataset]);

  const onChangeItem = (items) => {
    const x = dataset["filter_field"];
    if (items.length > 0) {
      if (typeValues === "bool") {
        onChange(x, boolex[items[0]], dataset);
      }
      if (typeValues === "number") {
        onChange(x, numberx[items[0]], dataset);
      }
      if (typeValues === "string") {
        onChange(x, stringx[items[0]], dataset);
      }
    } else {
      onChange(x, null, dataset);
    }
    setItemsSelected(items);
  };
  return (
    <ContainerInputFilter
      label={capitalizeWord(replaceChars(dataset.label, "_", " "))}
      versionInput={versionInput}
    >
      <Typeahead
        id="typeahead-bool"
        onChange={onChangeItem}
        options={["NO", "SI"]}
        selected={itemsSelected}
        clearButton
        placeholder={
          versionInput === VERSIONS_FILTERS_DESIGN.v2
            ? t("all")
            : capitalizeWord(replaceChars(dataset.label, "_", " "))
        }
        disabled={isDisabled}
      />
    </ContainerInputFilter>
  );
};

export default NFbool;
