import React from "react";
import _ from "lodash";
import { Modal } from "components";
import { StepTitle } from "components/Nimbiv2/InitiativesComponent";
import { IconCheck } from "assets/images";
import { NButton } from "components/Nimbiv2";
import { boolValues } from "utils/constants";

const TitleType = {
  Email: "CORREO",
  Call: "LLAMADO",
};
const TitlePreview = {
  Email: "VISTA PREVIA CORREO",
  Call: "PAUTA DE LLAMADO",
};
const ModalInitiativeDetail = ({
  show,
  handleClose,
  initiative,
  optionInitiative,
  onClickSelect,
  hideButton = false,
  hideDetail = false,
}) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title style={{ width: "100%" }}>
          <StepTitle style={{ marginTop: "10px" }}>
            {TitleType[optionInitiative]}
          </StepTitle>
          <div className="d-flex justify-content-between">
            <span>{initiative.name}</span>
            {!hideButton && (
              <NButton onClick={onClickSelect}>Seleccionar campaña</NButton>
            )}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`px-4`} style={{ background: "#F8FBFF" }}>
        {!hideDetail && (
          <>
            {" "}
            <strong style={{ fontSize: "var(--font-size-lg)" }}>
              Objetivo
            </strong>
            <div style={{ marginTop: "16px", fontSize: "var(--font-size-md)" }}>
              {initiative.objective}
            </div>
            <div style={{ marginTop: "32px", fontSize: "var(--font-size-lg)" }}>
              <strong> Criteros empleados</strong>
            </div>
            {initiative.client_filters && (
              <div style={{ fontSize: "var(--font-size-md)" }}>
                {initiative.client_filters.map((item, key) => {
                  return (
                    <div
                      style={{ marginTop: "16px" }}
                      key={`client-filter-${key}`}
                    >
                      <img
                        src={IconCheck}
                        style={{ height: "19px", marginRight: "9px" }}
                        alt="image1"
                      />
                      {item.name}:{" "}
                      {item.type_component === "select" &&
                        initiative.clientfilter_values[item.field].toString()}
                      {item.type_component === "boolean" &&
                        _.get(
                          boolValues,
                          initiative.clientfilter_values[item.field],
                          ""
                        )}
                      {item.type_component === "date" &&
                        initiative.clientfilter_values[item.field].replace(
                          "++",
                          " | "
                        )}
                    </div>
                  );
                })}
                {initiative.client_filters.length === 0 && (
                  <div style={{ marginTop: "16px" }}>No tiene criterios</div>
                )}
              </div>
            )}
            <StepTitle style={{ marginTop: "32px" }}>
              {TitlePreview[optionInitiative]}
            </StepTitle>
          </>
        )}

        <div className="text-center" style={{ marginTop: "22px" }}>
          {initiative.initiative_type.name === "Call" &&
            initiative.script_template &&
            (initiative.script_template.preview ? (
              <img
                style={{ maxWidth: "617px" }}
                src={initiative.script_template.preview}
                alt={initiative.script_template.name}
              />
            ) : (
              <div>No tiene imagen </div>
            ))}

          {initiative.initiative_type.name === "Email" &&
            initiative.email_template &&
            (initiative.email_template.preview ? (
              <img
                style={{ maxWidth: "617px" }}
                src={initiative.email_template.preview}
                alt={initiative.email_template.name}
              />
            ) : (
              <div>No tiene imagen </div>
            ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalInitiativeDetail;
