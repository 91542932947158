import React from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NBadge } from "components";
import { colorsBadge } from "utils/constants";
import {
  faArrowCircleUp,
  faArrowCircleDown,
  faMinusCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

const RiskScoreTask = ({ item }) => {
  const { t } = useTranslation();
  if (item.priority_score === null) {
    return <div>{t("no_information")}</div>;
  }
  if (item.priority_score <= 100 && item.priority_score >= 70) {
    return (
      <NBadge
        outlined
        newColor={colorsBadge.dark.danger}
        icon={
          <FontAwesomeIcon
            icon={faArrowCircleUp}
            className="mr-1"
            style={{ fontSize: "11px" }}
          />
        }
        text={`${_.upperFirst(t("high"))} ${item.priority_score}%`}
        customStyle={{ borderRadius: "64px" }}
        big
        textTooltip={item?.student_tracking?.risk_tag}
      />
    );
  }
  if (item.priority_score < 70 && item.priority_score >= 40) {
    return (
      <NBadge
        outlined
        newColor={colorsBadge.dark.warning}
        icon={
          <FontAwesomeIcon
            icon={faMinusCircle}
            className="mr-1"
            style={{ fontSize: "11px" }}
          />
        }
        text={`${_.upperFirst(t("medium"))} ${item.priority_score}%`}
        customStyle={{ borderRadius: "64px" }}
        big
        textTooltip={item?.student_tracking?.risk_tag}
      />
    );
  }
  if (item.priority_score < 40 && item.priority_score >= 0) {
    return (
      <NBadge
        outlined
        newColor={colorsBadge.dark.success}
        icon={
          <FontAwesomeIcon
            icon={faArrowCircleDown}
            className="mr-1"
            style={{ fontSize: "11px" }}
          />
        }
        text={`${_.upperFirst(t("low"))} ${item.priority_score}%`}
        customStyle={{ borderRadius: "64px" }}
        big
        textTooltip={item?.student_tracking?.risk_tag}
      />
    );
  }
  return <div>{_.upperFirst(t("no_information_line"))}</div>;
};
export default RiskScoreTask;
