import { getDataFromLocalStorage } from "utils";
import AIEP from "./Aiep";
import EClass from "./Eclass";

export default class ClientFactory {
  clientName = "";

  institution: AIEP | EClass;

  constructor(attrs?: Object) {
    const { client_area: clientArea = "" } = getDataFromLocalStorage(
      "user",
      {}
    );
    this.clientName = String(clientArea).toLocaleLowerCase();
    Object.assign(this, attrs);

    this.institution = this.assignInstance();
  }

  assignInstance(): AIEP | EClass {
    if (this.clientName === "aiep") {
      return new AIEP();
    }
    return new EClass();
  }

  client(): AIEP | EClass {
    return this.institution;
  }
}
