import { CardContainer } from "components/Nimbiv2/CardGeneral";
import React from "react";
import ContentLoader from "react-content-loader";
import styled from "styled-components";
const CardInitiativeSkeletons = (props) => {
  return (
    <CardContainerDesign>
      <ContentLoader
        speed={2}
        width="100%"
        height={318}
        viewBox="0 0 256.5 318"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <circle cx="125" cy="35" r="35" />
        <rect x="65" y="85" rx="0" ry="0" width="120" height="24" />
        <rect x="55" y="125" rx="0" ry="0" width="140" height="20" />
        <rect x="7" y="169" rx="0" ry="0" width="160" height="21" />
        <rect x="7" y="205" rx="0" ry="0" width="160" height="21" />
        <rect x="7" y="240" rx="0" ry="0" width="160" height="21" />
        <rect x="7" y="279" rx="24" ry="24" width="245" height="40" />
      </ContentLoader>
    </CardContainerDesign>
  );
};
export default CardInitiativeSkeletons;

const CardContainerDesign = styled(CardContainer)`
  padding: 0px 24px 0px 24px;
  @media (max-width: 800px) {
    padding: 20px 24px 20px 24px;
  }
  @media (min-width: 1560px) {
    padding: 20px 24px 20px 24px;
  }
  max-height: 318px;
`;
