import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { CardNimbi, DividerH, RowItem } from "components/Nimbiv2/styled";
import { useSelector, useDispatch } from "react-redux";
import {
  ImgPosition,
  StepTitle,
  TextQuestion,
} from "components/Nimbiv2/InitiativesComponent";
import { Col, Form } from "react-bootstrap";
import { ImageInitiatives1 } from "assets/images";
import {
  NIcon,
  NTextArea,
  NDatePickerIni,
  NInput,
  NButton,
} from "components/Nimbiv2";

import { NInputFilter, NSearchCampus, NSearchCourses } from "components";
import { APP_MODULES, getGlobalConfig, setSessionFilters } from "utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faSpinner,
} from "@fortawesome/pro-regular-svg-icons";
import styled from "styled-components";
import { fetchCoursesFromCampus } from "store/actions/async/initiatives-async.actions";
import ModalInitiativeDetail from "components/Nimbiv2/ModalInitiativeDetail";
import { useTranslation } from "react-i18next";
import useTranslationLocal from "hooks/useTranslationLocal";
import { ButtonSize } from "utils/constants";
import { Highlighter } from "react-bootstrap-typeahead";
import { fetchMoments } from "store/actions/async/crm-async.action";
import { getTeams } from "services/team.service";

const FILTERKEY_CAMPUS = "studenttracking__campus__name";
const FILTERKEY_COURSES = "studenttracking__tracking_object__external_id";
const {
  STUDENT: { FILTER_NAME: FILTER_STUDENT },
} = APP_MODULES;
const PageSecond = ({
  onChange,
  onChangeForm,
  formValues,
  textStep,
  descriptionStep,
  pageNumber,
  edit = false,
  errors,
}) => {
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const dispatch = useDispatch();
  const { campus: listCampus } = useSelector((state) => state.crm);
  const [listCourses, setListCourses] = useState([]);
  const [loadingCourse, setLoadingCourse] = useState(false);
  const dateInitial = new Date();
  const [errorText, setErrorText] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [teams, setTeams] = useState([]);
  // const [selectedLoad, setSelectedLoad] = useState(false);
  const list_tracking = [{ campus: "Todos", id: -1, campus_id: -1 }];
  const {
    moments,
    singleLoads: { moments: singleMomentsLoaded },
  } = useSelector((state) => state.crm, _.isEqual);

  useEffect(() => {
    if ((moments || moments.length === 0) && !singleMomentsLoaded) {
      dispatch(fetchMoments());
    }
  }, [moments, dispatch, singleMomentsLoaded]);

  const getTeamsOptions = async () => {
    const {
      data: { results },
    } = await getTeams();
    setTeams(results);
  };

  useEffect(() => {
    getTeamsOptions();
  }, []);

  const momentsDerive = useMemo(() => {
    return moments.filter((item) => item.from_initiative === true);
  }, [moments]);

  const textShow = {
    Email: "ver vista previa correo",
    Call: "ver pauta de llamado",
  };

  const labelInputDate = {
    Email: t("mailing_date"),
    WhatsApp: "Fecha de envío",
  };

  const callCourses = useCallback(async (selected) => {
    const isEmpty = Object.keys(selected).length === 0;
    if (selected.length === 1) {
      if (selected[0] && selected[0].campus) {
        const campusCourses = await fetchCoursesFromCampus(
          isEmpty ? null : selected[0].campus
        );
        setListCourses(campusCourses);
      } else {
        setListCourses([]);
      }
    }
  }, []);

  useEffect(() => {
    if (formValues.campos_selected) {
      if (formValues.campos_selected.length === 1) {
        callCourses(formValues.campos_selected);
      }
    }
  }, [callCourses, formValues.campos_selected]);

  const handleFilterCampus = async (selected) => {
    // setSelectedCampusError(false);
    const campus = [];
    let campus_selected = [];
    if (selected.length > 0 && selected[0].campus_id === -1) {
      campus_selected = listCampus;
    } else {
      campus_selected = selected;
    }
    for (let i = 0; i < campus_selected.length; i++) {
      campus.push(campus_selected[i].campus_id);
    }
    if (selected.length !== 1) {
      onChangeForm("campuses", campus, {
        campos_selected: selected,
        tracking_object: null,
        tracking_objects: null,
        tracking_object_selected: null,
        student_trackings: [],
        selectAll: false,
      });
    } else {
      onChangeForm("campuses", campus, {
        campos_selected: selected,
        student_trackings: [],
        selectAll: false,
      });
    }
    setSessionFilters(APP_MODULES.INITIATIVES.FILTER_NAME, {
      page: 1,
      page_size: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
    });
    setLoadingCourse(true);
    await callCourses(selected);
    setLoadingCourse(false);
  };

  const handleFilterCourses = (data) => {
    const isEmpty = Object.keys(data).length === 0;
    let tracking_objects = null;
    let tracking_object_selected = null;
    if (!isEmpty) {
      tracking_objects = data.map((item) => {
        return item.id;
      });
      tracking_object_selected = data;
    }
    onChangeForm("tracking_objects", tracking_objects, {
      tracking_object_selected: tracking_object_selected,
      student_trackings: [],
      selectAll: false,
    });
    setSessionFilters(APP_MODULES.INITIATIVES.FILTER_NAME, {
      page: 1,
      page_size: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
    });
  };

  const validateTwoDate = (start_date, end_date) => {
    if (start_date && end_date) {
      if (moment(end_date).isBefore(start_date)) {
        setErrorText(
          "La fecha de término no puede ser anterior a la fecha de inicio"
        );
      } else {
        setErrorText(null);
      }
    }
  };

  return (
    <div style={{ paddingBottom: "50px" }}>
      <div style={{ maxWidth: "922px", margin: "auto" }}>
        <CardNimbi padding="0px">
          <div style={{ padding: "33px 35px 16px 35px" }}>
            <RowItem>
              <ImgPosition src={ImageInitiatives1} />
              <Col sm={12} lg="7" md="9">
                {/* <StepTitle>{textStep}</StepTitle> */}
                <StepTitle>
                  {pageNumber ? pageNumber : t("step_number", { number: "2" })}
                </StepTitle>
                <TextQuestion style={{ marginTop: "16px" }}>
                  {descriptionStep}
                </TextQuestion>
                <DividerH style={{ marginTop: "9px", marginBottom: "40px" }} />
                <div>
                  <div style={{ marginBottom: "24px" }}>
                    <NInput
                      minWidth="auto"
                      defaultValue={formValues.name}
                      label={_.upperFirst(t("name"))}
                      helpText={t("write_title_initiative")}
                      onChange={(text) => {
                        onChangeForm("name", text);
                      }}
                      errorText={errors["name"] ?? ""}
                    />
                  </div>
                  {["Email", "WhatsApp"].includes(
                    formValues.optionInitiative
                  ) && (
                    <div style={{ marginBottom: "24px" }}>
                      <NDatePickerIni
                        defaultValue={formValues.start_date}
                        label={labelInputDate[formValues.optionInitiative]}
                        onChange={(date) => {
                          onChangeForm("start_date", date);
                        }}
                        minDate={dateInitial}
                        errorText={errors["start_date"] ?? ""}
                      />
                    </div>
                  )}

                  {formValues.optionInitiative === "Call" && (
                    <div style={{ marginBottom: "24px" }}>
                      <RowItem>
                        <Col xs={6} style={{ paddingLeft: "0px" }}>
                          <NDatePickerIni
                            defaultValue={formValues.start_date}
                            label={t("start_date")}
                            onChange={(date) => {
                              onChangeForm("start_date", date);
                              validateTwoDate(date, formValues.end_date);
                            }}
                            minDate={dateInitial}
                            errorText={errors["start_date"] ?? ""}
                          />
                        </Col>
                        <Col xs={6} style={{ paddingRight: "0px" }}>
                          <NDatePickerIni
                            defaultValue={formValues.end_date}
                            label={t("end_date")}
                            onChange={(date) => {
                              onChangeForm("end_date", date);
                              validateTwoDate(formValues.start_date, date);
                            }}
                            minDate={dateInitial}
                            errorText={errors["end_date"] ?? ""}
                          />
                        </Col>
                      </RowItem>
                      {errorText && (
                        <ErrorText
                          style={{
                            whiteSpace: "normal",
                            textOverflow: "ellipsis",
                            display: "inline-block",
                          }}
                        >
                          {errorText}{" "}
                          <span
                            style={{
                              display: "inline-block",
                            }}
                          >
                            <FontAwesomeIcon
                              className="ml-1"
                              icon={faExclamationCircle}
                              size="1x"
                              color="#e23232"
                            />
                          </span>
                        </ErrorText>
                      )}
                    </div>
                  )}
                  <div style={{ marginBottom: "24px" }}>
                    <NTextArea
                      defaultValue={formValues.objective}
                      label={_.upperFirst(t("objetive"))}
                      helpText={t("help_text_objetive_initiative")}
                      rows="3"
                      onChange={(text) => {
                        onChangeForm("objective", text);
                      }}
                      errorText={errors["objective"] ?? ""}
                    />
                  </div>
                  {getGlobalConfig("active_teams_initiative", false) && (
                    <div style={{ marginBottom: "24px" }}>
                      <NInputFilter
                        handleChange={(data) => {
                          if (data.length > 0) {
                            onChangeForm("team", data[0]?.id, {
                              team_name: data[0]?.name,
                            });
                          } else {
                            onChangeForm("team", null, { team_name: null });
                          }
                        }}
                        data={teams}
                        compositeLabel={["name"]}
                        placeholder={"Equipo"}
                        selected={teams.filter((item) => {
                          if (formValues.team) {
                            return formValues.team === item.id;
                          }
                          return false;
                        })}
                        filterBy={["name"]}
                        renderMenuItem={(option, { text }) => (
                          <>
                            <Highlighter search={text}>
                              {option.name}
                            </Highlighter>
                          </>
                        )}
                      />
                    </div>
                  )}
                  {formValues.source_type === "internal" && (
                    <>
                      <div style={{ marginBottom: "24px" }}>
                        {/* <NSelect label="Asignar sede" /> */}
                        <NSearchCampus
                          filterKey={FILTERKEY_CAMPUS}
                          fieldName="campus"
                          dataset={list_tracking.concat(listCampus)}
                          onChange={handleFilterCampus}
                          filterType={FILTER_STUDENT}
                          isMultiple
                          itemsSelected={formValues.campos_selected}
                          placeholder={translation("campuses", "startCase")}
                          errorText={errors["campuses"] ?? ""}
                        />
                      </div>
                      <div style={{ marginBottom: "24px" }} className="d-flex">
                        {loadingCourse && (
                          <FontAwesomeIcon
                            icon={faSpinner}
                            className="fas fa-cog fa-spin mr-2"
                            style={{ fontSize: "30px" }}
                          />
                        )}
                        {!loadingCourse && (
                          <div style={{ width: "100%" }}>
                            <NSearchCourses
                              placeholder={translation(
                                "tracking_objects",
                                "startCase"
                              )}
                              defaultValue={true}
                              filterKey={FILTERKEY_COURSES}
                              fieldName="external_id"
                              dataset={listCourses}
                              onChange={handleFilterCourses}
                              filterType={FILTER_STUDENT}
                              isMultiple
                              isDisabled={
                                formValues.campos_selected
                                  ? formValues.campos_selected.length !== 1 ||
                                    formValues.campos_selected[0]?.id === -1
                                  : true
                              }
                              searchType="campus_courses"
                              campusSelected={
                                formValues.tracking_object_selected
                                  ? formValues.tracking_object_selected
                                  : []
                              }
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
                {getGlobalConfig("enable_send_all_contacts", false) &&
                  formValues.source_type === "internal" &&
                  formValues.optionInitiative === "Email" && (
                    <RowItem>
                      <Col className="pl-0">
                        <ContainerSwitch>
                          <Form.Check
                            checked={formValues.send_all_contacts ?? false}
                            onChange={(evt) => {
                              onChangeForm(
                                "send_all_contacts",
                                evt.target.checked
                              );
                            }}
                            type="switch"
                            id="custom-switch"
                            label={_.upperFirst(
                              t(
                                "Enviar a todos los contactos asociados de los estudiantes"
                              )
                            )}
                          />
                        </ContainerSwitch>
                      </Col>
                    </RowItem>
                  )}
                {formValues.source_type === "internal" &&
                  momentsDerive &&
                  momentsDerive.length > 0 &&
                  formValues.optionInitiative === "Call" && (
                    <RowItem>
                      <Col lg="5" className="pl-0">
                        <ContainerSwitch>
                          <Form.Check
                            checked={formValues.enter_action_plans ?? false}
                            onChange={(evt) => {
                              onChangeForm(
                                "enter_action_plans",
                                evt.target.checked,
                                { moments_selected: [] }
                              );
                            }}
                            type="switch"
                            id="custom-switch"
                            label={t("derive_initiative")}
                          />
                        </ContainerSwitch>
                      </Col>
                      <Col lg="7" className="p-0">
                        <div>
                          <NInputFilter
                            handleChange={(data) => {
                              //setErrorActionPlans(false);
                              onChangeForm(
                                "moments_selected",
                                data.map((item) => item.id)
                              );
                            }}
                            data={momentsDerive}
                            compositeLabel={["name"]}
                            placeholder={t("action_plans")}
                            selected={momentsDerive.filter((item) => {
                              if (formValues.moments_selected) {
                                return formValues.moments_selected.includes(
                                  item.id
                                );
                              }
                              return false;
                            })}
                            filterBy={["name"]}
                            renderMenuItem={(option, { text }) => (
                              <>
                                <Highlighter search={text}>
                                  {option.name}
                                </Highlighter>
                              </>
                            )}
                            isMultiple={true}
                            isDisabled={!formValues.enter_action_plans}
                          />
                        </div>
                        <div
                          style={{
                            fontSize: "10px",
                            color: "#787C83",
                            marginTop: "3px",
                          }}
                        >
                          {t("derive_actions_plans")}
                        </div>
                        {errors["enter_action_plans"] && (
                          <ErrorText
                            style={{
                              whiteSpace: "normal",
                              textOverflow: "ellipsis",
                              display: "inline-block",
                            }}
                          >
                            {"Selección requerido"}{" "}
                            <span
                              style={{
                                display: "inline-block",
                              }}
                            >
                              <FontAwesomeIcon
                                className="ml-1"
                                icon={faExclamationCircle}
                                size="1x"
                                color="#e23232"
                              />
                            </span>
                          </ErrorText>
                        )}
                      </Col>
                    </RowItem>
                  )}
              </Col>

              {formValues.clone && (
                <Col
                  xs="12"
                  style={
                    formValues.optionInitiative === "Call"
                      ? { marginTop: "24px" }
                      : {}
                  }
                >
                  <StepTitle
                    style={{ textTransform: "uppercase", cursor: "pointer" }}
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    {textShow[formValues.optionInitiative]}
                  </StepTitle>
                </Col>
              )}
              {formValues.clone && (
                <ModalInitiativeDetail
                  show={showModal}
                  handleClose={() => {
                    setShowModal(false);
                  }}
                  hideButton={true}
                  initiative={formValues.selectInitiative}
                  optionInitiative={formValues.optionInitiative}
                  hideDetail={true}
                />
              )}
            </RowItem>
          </div>
        </CardNimbi>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div>
            {!edit && (
              <NButton
                sizeStyle={ButtonSize.lg}
                style={{
                  marginTop: "24px",
                  borderRadius: "24px",
                  marginRight: "16px",
                }}
                onClick={() => {
                  onChange("last");
                }}
                outlined
                icon={<NIcon iconname="arrowLeft" />}
                color="second"
              >
                {t("go_back")}
              </NButton>
            )}
            <NButton
              sizeStyle={ButtonSize.lg}
              style={{
                marginTop: "24px",
                borderRadius: "24px",
                textTransform: "capitalize",
              }}
              onClick={() => {
                onChange("next");
              }}
              icon={<NIcon iconname="arrowRight" />}
              positionIcon="right"
              color="second"
            >
              {t("continue")}
            </NButton>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageSecond;

const ErrorText = styled.p`
  font-family: Nova;
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-md);
  line-height: 120%;
  color: #e23232;
  flex: none;
  order: 0;
  flex-grow: 0;
  display: flex;
  margin-top: 10px;
`;

const ContainerSwitch = styled.div`
  .custom-switch .custom-control-label {
    padding-left: 2rem;
    padding-bottom: 0px;
    padding-top: 10px;
  }

  .custom-switch .custom-control-label::before {
    height: 2rem;
    width: calc(3rem + 0.75rem);
    border-radius: 4rem;
  }

  .custom-switch .custom-control-label::after {
    width: calc(2rem - 4px);
    height: calc(2rem - 4px);
    border-radius: calc(3rem - (2rem / 2));
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(calc(2rem - 0.25rem));
  }
`;
