import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import NContainerFilters from "components/Nimbiv2/NContainerFilters";
import { APP_MODULES } from "utils";
import { useDispatch, useSelector } from "react-redux";
import { useTranslationLocal } from "hooks";
import { fetchGenerationEvents, fetchGenerationTrackingObjects, fetchGenerations } from "store/actions/async/generation-async.actions";

const FilterBar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();

  const {
    pending: { list: isPending },
    pagination,
    listGeneration,
    listTrackingObject,
    numberCalls: {
      listGeneration: NumberCallGeneration,
      listTrackingObject: NumberCallTracking,
    },
  } = useSelector((state) => state.generation);

  useEffect(() => {
    if (NumberCallGeneration === 0) {
      dispatch(fetchGenerations());
    }
  }, [dispatch, NumberCallGeneration]);

  useEffect(() => {
    if (NumberCallTracking === 0) {
      dispatch(fetchGenerationTrackingObjects());
    }
  }, [dispatch, NumberCallTracking]);

  return (
    <>
      <div className="pt-3">
        <NContainerFilters
          isPendingList={isPending}
          filterName={APP_MODULES.GENERATION_EVENTS.FILTER_NAME}
          showButtonCreate={false}
          persistentSearch={false}
          onChange={(data) => {
            if (data.hasOwnProperty("id")) {
              data["id"] = data["id"]?.id;
            }
            dispatch(fetchGenerationEvents(data));
          }}
          showCountResult
          countResult={pagination?.count}
          searchKey={"id"}
          compositeLabel={["event_name"]}
          urlSearch={"crm/generation-event/"}
          placeholderSearch={t("search_enter_generation_event_name")}
          attribSearch="search"
          textCountResult={t("generation_event_found")}
          customMenuItem={(option, { text }) => {
            return (
              <div>
                <div>
                  <strong>{option.event_name}</strong>
                </div>
                <div>
                  <small>
                    {translation("generation", "startCase")}: {option.generation}
                  </small>
                </div>
                <div>
                  <small>
                    {translation("tracking_object", "startCase")}:{" "}
                    {option.tracking_objects}
                  </small>
                </div>
              </div>
            );
          }}
          dataFilter={[
            {
              filter_field: "generation",
              id: 1,
              label: translation("generation", "startCase"),
              module: "generationEvent",
              subtype_component: "select",
              type_component: "select",
              values: listGeneration,
            },
            {
              filter_field: "tracking_object",
              id: 2,
              label: translation("tracking_objects", "", "cursos"),
              module: "generationEvent",
              subtype_component: "select",
              type_component: "select",
              values: listTrackingObject,
            },
          ]}
        />
      </div>
    </>
  );
};

export default FilterBar;
