import React, { useEffect, useState } from "react";
import { DropDownHelp } from "components/Nimbiv2/PageHelpCenter";
import { CardNimbi, DividerH } from "components/Nimbiv2/styled";
import styled from "styled-components";
import { EmptyContainer } from "containers/Help";

const FAQ = ({ items, search, title }) => {
  useEffect(() => {
    setListFAQ(items);
  }, [items]);
  const [listFAQ, setListFAQ] = useState(items);

  if (listFAQ?.length === 0) {
    return <EmptyContainer />;
  }

  return (
    <CardNimbi padding="24px" style={{ height: "auto", borderRadius: "8px" }}>
      <TitleHelp>{title}</TitleHelp>
      <DividerH style={{ margin: "20px 0px" }} />
      {listFAQ.map((item, key) => {
        return (
          <DropDownHelp
            key={`faq-item-${key}`}
            title={item.title}
            content={item.description}
          />
        );
      })}
    </CardNimbi>
  );
};

export default FAQ;

const TitleHelp = styled.div`
  color: var(--Dark-color, #081735);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;
