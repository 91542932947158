import React, { useMemo } from "react";

import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TitleCard } from "containers/Task/detail/detail.styled";
import ChartArea from "../ChartArea";
import { CardNimbi, IconContainer, RowItem } from "../styled";
import styled from "styled-components";
import NIcon from "../NIcon";
import { faCircleMinus } from "@fortawesome/free-solid-svg-icons";
import colors from "utils/risk";
import { useTranslation } from "react-i18next";
import { applyTextType, getGlobalConfig } from "utils";
import { IconRisk } from "assets/images";

const CardIndicatorStudent = ({ item, labelModal }) => {
  const { t } = useTranslation();

  const isEmpty = useMemo(() => {
    return !(item && item.data && item.data.length > 0);
  }, [item]);

  const colorRisk = useMemo(() => {
    return item?.category;
  }, [item]);

  return (
    <CardNimbi>
      <RowItem>
        <div className="d-flex w-100">
          <IconContainer size="37px">
            <NIcon iconname={item.icon} />
          </IconContainer>
          <div
            style={{ marginTop: "10px", marginLeft: "11px" }}
            className="w-100"
          >
            <div className="d-flex align-items-center">
              <TitleCard style={{ color: "#2A2B2E" }}>
                {getGlobalConfig("hide_nimbi_risk", false) &&
                item?.title === "Riesgo"
                  ? _.upperFirst(t("no_information"))
                  : item.title}
              </TitleCard>
              <div className="ml-auto" style={{ color: "#787C83" }}>
                ({isEmpty ? "0d" : `${item.days_difference}d`})
              </div>
            </div>

            {!isEmpty && (
              <CardNumber isRisk={colorRisk}>
                {applyTextType(
                  String(item.last_value),
                  item.type_value,
                  null,
                  null,
                  ""
                )}
              </CardNumber>
            )}
          </div>
        </div>
        {isEmpty && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <img src={IconRisk} alt="empty" />
          </div>
        )}

        {!isEmpty && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <ChartArea color="blue" data={item.data} labelModal={labelModal} />
          </div>
        )}
        {isEmpty && (
          <div style={{ marginTop: "16px" }}>
            <FontAwesomeIcon
              icon={faCircleMinus}
              className="mr-1"
              style={{ color: "#334FFF" }}
            ></FontAwesomeIcon>
            {_.upperFirst(t("no_information"))}
          </div>
        )}
      </RowItem>
    </CardNimbi>
  );
};

export default CardIndicatorStudent;

const CardNumber = styled.div`
  color: ${(props) =>
    props.isRisk ? colors[props.isRisk]["bold"] : "#334FFF"};
  font-size: 20px;
  line-height: 120%;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 2px;
`;
CardNumber.defaultProps = {
  type: "normal",
};
