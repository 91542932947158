import React from "react";
import { Col, Form } from "react-bootstrap";
// import copy from "copy-to-clipboard";
import * as Models from "models";

//mport styles from "./ModalProfile.module.css";
import { validateEmail, validatePhone } from "utils";
import { RowItem } from "../styled";
import NInput from "../NInput";

const TextContactType = {
  email: "Correo",
  phone: "Teléfono",
};
const TextContactError = {
  email: "El correo ingresado no es válido",
  phone: "El número de teléfono no es válido",
  required: "Campo requerido",
};
const ContactInfoItem = ({ contact, onUpdate, onDelete }) => {
  // const copyValue = (value) => copy(value);

  const updateContact = (event) => {
    const { target } = event;
    const { key } = target.dataset;
    const newValue = key === "whatsappAllowed" ? target.checked : target.value;
    const updated = {
      ...contact,
      [key]: newValue,
      error:
        key === "whatsappAllowed" || key === "isAllowed"
          ? false
          : validateData(target.value),
    };
    onUpdate(new Models.ContactInfo(updated));
  };
  const validateData = (text) => {
    if (contact.contactType === "email") {
      return validateEmail(text);
    }
    if (contact.contactType === "phone") {
      return validatePhone(text);
    }
    return false;
  };

  // const deleteContact = () => onDelete(contact);

  //if (!contact) return null;

  return (
    <RowItem style={{ marginTop: "20px" }}>
      <Col xs={5}>
        <NInput
          minWidth="0px"
          defaultValue={contact.value}
          label={TextContactType[contact.contactType]}
          // disabled={true}
          onChangeEvent={(event) => {
            updateContact(event);
          }}
          errorText={
            contact.error === true
              ? contact.value.length === 0
                ? TextContactError["required"]
                : contact.contactType === "email"
                ? TextContactError["email"]
                : contact.contactType === "phone"
                ? TextContactError["phone"]
                : null
              : null
          }
        />
      </Col>
      {contact.contactType === "phone" && (
        <Col xs={4}>
          <Form.Check
            id={`wallowed-${contact.id}`}
            data-key="whatsappAllowed"
            className="mt-2"
            type="checkbox"
            label="WhatsApp habilitado"
            checked={contact.whatsappAllowed === true}
            onChange={(event) => updateContact(event)}
            custom
          />
        </Col>
      )}
      <Col xs={3}>
        <div className="d-flex align-items-center">
          <div style={{ marginRight: "10px" }}>Permitido: </div>
          <div>
            <Form.Check
              id={`allowed-y-${contact.id}`}
              name={`isAllowed_${contact.id}`}
              data-key="isAllowed"
              className="mr-4"
              type="radio"
              label="Si"
              value="yes"
              checked={contact.isAllowed === "yes"}
              onChange={(event) => updateContact(event)}
              custom
            />
            <Form.Check
              id={`allowed-n-${contact.id}`}
              name={`isAllowed_${contact.id}`}
              data-key="isAllowed"
              className="mr-4"
              type="radio"
              label="No"
              value="no"
              checked={contact.isAllowed === "no"}
              onChange={(event) => updateContact(event)}
              custom
            />
          </div>
        </div>
      </Col>
    </RowItem>
  );
};
export default ContactInfoItem;
