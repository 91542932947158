import { APP_MODULES } from "utils";
import {
  getAssociatedRoles,
  getAssociatedTrackingObjects,
  getAssociateds,
} from "services/associated.service";
import { handleErrorStatus } from "services/errors";
import * as AssociatedActions from "../associated.actions";
import { getValueFilter } from "utils/filters";

const handleErrorResponse = (err) => {
  const status = err instanceof Map ? err.get("status") : 500;
  if (status > 0) return handleErrorStatus(status);
};

export const fetchAssociateds = (initialParams = null) => {
  return (dispatch) => {
    const queryParams =
      getValueFilter(APP_MODULES.ASSOCIATED.FILTER_NAME) ?? {};

    const mergeParams = {
      ...queryParams,
      ...initialParams,
    };
    for (let x in mergeParams) {
      if (typeof mergeParams[x] == "object") {
        mergeParams[x] = mergeParams[x]["id"];
      }
    }
    dispatch(AssociatedActions.getAssociated(mergeParams));
    getAssociateds({
      ...queryParams,
      ...initialParams,
    })
      .then(({ data }) => {
        dispatch(AssociatedActions.setAssociated(data));
      })
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(AssociatedActions.registerError({ err, key: "list" }));
      });
  };
};

export const fetchAssociatedRoles = () => {
  return (dispatch) => {
    getAssociatedRoles()
      .then(({ data }) => {
        dispatch(AssociatedActions.setRolesAssociated(data));
      })
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(AssociatedActions.registerError({ err, key: "list" }));
      });
  };
};

export const fetchAssociatedTrackingObjects = () => {
  return (dispatch) => {
    getAssociatedTrackingObjects()
      .then(({ data }) => {
        dispatch(AssociatedActions.setTrackingObjectAssociated(data));
      })
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(AssociatedActions.registerError({ err, key: "list" }));
      });
  };
};
