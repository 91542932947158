import React from "react";
import { ContainerItem } from "containers/Task/detail/detail.styled";
import { applyTextType } from "utils";
import { DividerH } from "../styled";

const ContainerItemPerson = ({ keyText, itemText }) => {
  return (
    <ContainerItem
      key={keyText}
      marginTop={[0, 1].includes(keyText) ? "0px" : "16px"}
    >
      <strong>{itemText.label}</strong>
      {itemText.format !== "array" && (
        <div className="item-description">
          {applyTextType(
            itemText.value,
            itemText.format,
            itemText.extension,
            itemText.format_string
          )}
        </div>
      )}
      {itemText.format === "array" && (
        <div className="item-description">
          {itemText.value &&
            itemText.value.map((itemArray, keyArray) => {
              return (
                <div key={`key-value-${keyArray}`}>
                  <div
                    className="d-flex justify-content-between"
                    style={{
                      flexWrap: "wrap",
                    }}
                  >
                    {itemText.items.map((item, key) => {
                      return (
                        <div
                          key={`item-${keyArray}-${key}`}
                          className="flex-basis-50"
                          style={{ flexBasis: "50%" }}
                        >
                          <ContainerItem>
                            <strong>{item.label}</strong>
                            <div className="item-description">
                              {applyTextType(
                                itemArray[item.key_value] ?? "",
                                item.format,
                                item.extension,
                                item.format_string
                              )}
                            </div>
                          </ContainerItem>
                        </div>
                      );
                    })}
                  </div>
                  <DividerH
                    style={{
                      marginTop: "16px",
                      marginBottom: "16px",
                    }}
                  />
                </div>
              );
            })}
        </div>
      )}
    </ContainerItem>
  );
};
export default ContainerItemPerson;
