import React, { cloneElement, Children } from "react";
import styled from "styled-components";
const NSteps = ({ children, position }) => {
  const isActive = true;

  return (
    <StepsContainer>
      {Children.map(children, (child) => {
        return cloneElement(child, { isActive });
      })}
    </StepsContainer>
  );
};
export default NSteps;

const StepsContainer = styled.ul`
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0px;
`;
