import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { CardNimbi, DividerH, RowItem } from "components/Nimbiv2/styled";
import {
  StepTitle,
  TextQuestion,
} from "components/Nimbiv2/InitiativesComponent";
import { Col } from "react-bootstrap";
import {
  NIcon,
  NInputSearch,
  NSpinnerPoints,
  NButton,
} from "components/Nimbiv2";
import CardInitiative from "components/Nimbiv2/CardInitiative";
import { useSelector, useDispatch } from "react-redux";
import { getCampusesID, getCoursesIDs, getTemplateID } from "utils/initiatives";
import {
  APP_MODULES,
  deleteLocalStorage,
  getGlobalConfig,
  setSessionFilters,
  storeClientAllFilters,
} from "utils";
import moment from "moment";
import { updateClientFilters } from "store/actions/site.actions";
import { getInitiative } from "services/initiativeService";
import {
  fetchCoursesFromCampus,
  fetchListInitiatives,
  fetchListStudentTracking,
} from "store/actions/async/initiatives-async.actions";
import { toast } from "react-toastify";
import { NPagination } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import ModalInitiativeDetail from "components/Nimbiv2/ModalInitiativeDetail";
import { useTranslation } from "react-i18next";
import { ButtonSize } from "utils/constants";
import { fetchMoments } from "store/actions/async/crm-async.action";
import { useTranslationLocal } from "hooks";

const panel = APP_MODULES.INITIATIVES.ID;
const PageSecondCopy = ({ onChange, onChangeForm, formValues }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const [initiativeSelected, setInitiativeSelected] = useState(
    formValues.selectInitiative ? formValues.selectInitiative : null
  );
  const {
    pagination,
    initiatives,
    pending: { initiatives: isPending },
  } = useSelector((state) => state.initiative);
  const { clientFilters } = useSelector((state) => state.site);
  const { templates, campus: listCampus } = useSelector((state) => state.crm);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [activeSearch, setActiveSearch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [initiativeDetail, setInitiativeDetail] = useState(null);
  const {
    moments,
    singleLoads: { moments: singleMomentsLoaded },
  } = useSelector((state) => state.crm, _.isEqual);

  useEffect(() => {
    if ((moments || moments.length === 0) && !singleMomentsLoaded) {
      dispatch(fetchMoments());
    }
  }, [moments, dispatch, singleMomentsLoaded]);

  const momentsDerive = useMemo(() => {
    return moments.filter((item) => item.from_initiative === true);
  }, [moments]);

  useEffect(() => {
    setSessionFilters("filters_initiative_create", {
      page: 1,
      page_size: 12,
      initiative_type: formValues.initiative_type,
      status: "completed",
      action: "clone",
      source_type: formValues.source_type ?? "internal",
    });
    dispatch(
      fetchListInitiatives({
        page: 1,
        page_size: 12,
        initiative_type: formValues.initiative_type,
        status: "completed",
        action: "clone",
        source_type: formValues.source_type ?? "internal",
      })
    );
  }, [formValues.initiative_type, dispatch, formValues.source_type]);

  const loadPage = (pageNumber, pageSize, searchValue) => {
    if (searchValue) {
      setSessionFilters("filters_initiative_create", {
        page: pageNumber,
        page_size: Number(pageSize),
        search: searchValue,
      });
    } else {
      setSessionFilters("filters_initiative_create", {
        page: pageNumber,
        page_size: Number(pageSize),
        search: "",
      });
    }

    dispatch(fetchListInitiatives(null, "filters_initiative_create"));
  };

  const nextSelectInitiative = async () => {
    if (initiativeSelected) {
      setLoading(true);
      const { data: initiative, status } = await getInitiative(
        initiativeSelected.pk
      );

      if (status === 200) {
        deleteLocalStorage("initiative_filters");
        let template = {};
        if (initiative.initiative_type.name === "Email") {
          const temp = getTemplateID(
            initiative.email_template.id,
            templates["email"]
          );
          if (temp) {
            let today = moment().add(1, "days");
            template = {
              start_date: today.toDate(),
              email_template: initiative.email_template.id.toString(),
              template: temp,
              name_template: temp.name,
            };
          }
        }
        if (initiative.initiative_type.name === "WhatsApp") {
          const temp = getTemplateID(
            initiative.whatsapp_template.id,
            templates["whatsapp"]
          );
          if (temp) {
            let today = moment().add(1, "days");
            template = {
              start_date: today.toDate(),
              whatsapp_template: initiative.whatsapp_template.id.toString(),
              template: temp,
              name_template: temp.name,
            };
          }
        }
        if (initiative.initiative_type.name === "Call") {
          const temp = getTemplateID(
            initiative.script_template.id,
            templates["call"]
          );
          if (temp) {
            let start_date = moment(initiative.start_date).add(1, "days");
            let end_date = moment(initiative.end_date).add(1, "days");
            let diff_date = end_date.diff(start_date, "days");
            let today = moment().add(1, "days");
            template = {
              start_date: today.toDate(),
              // end_date: initiative.end_date
              //   ? moment(initiative.end_date).toDate()
              //   : null,
              end_date: initiative.end_date
                ? today.add(diff_date, "days").toDate()
                : null,
              script_template: initiative.script_template.id.toString(),
              template: temp,
              name_template: temp.name,
            };
          }
        }
        const new_campuses = initiative.campuses.map((item) => {
          return item.id;
        });
        const client_filters = initiative.client_filters.map((item) => {
          return item.id;
        });
        let campos_selected = {};

        if (initiative.campuses.length === 1 && listCampus) {
          const select = getCampusesID(initiative.campuses[0].id, listCampus);
          if (select) {
            campos_selected = { campos_selected: [select] };
          } else {
            campos_selected = { campos_selected: [] };
          }
        } else {
          let selectx = [];
          for (let i = 0; i < initiative.campuses.length; i++) {
            const select = getCampusesID(initiative.campuses[i].id, listCampus);
            selectx.push(select);
          }
          campos_selected = { campos_selected: selectx };
        }

        setSessionFilters(APP_MODULES.INITIATIVES.FILTER_NAME, null);
        storeClientAllFilters(
          initiative.clientfilter_values,
          APP_MODULES.INITIATIVES.FILTER_NAME
        );
        let tempFilters = {
          data: clientFilters.data,
          filters: {
            ...clientFilters.filters,
            ...{ [panel]: initiative.clientfilter_values },
          },
        };
        dispatch(updateClientFilters(tempFilters));
        //--------
        let tracking_object_dict = {};

        if (
          initiative.tracking_objects &&
          campos_selected.campos_selected &&
          campos_selected.campos_selected.length > 0
        ) {
          const campusCourses = await fetchCoursesFromCampus(
            campos_selected.campos_selected[0].campus
          );

          const tracking_object_selected = getCoursesIDs(
            initiative.tracking_objects,
            campusCourses
          );

          tracking_object_dict = {
            tracking_object_selected: tracking_object_selected,
            search: true,
            tracking_objects: initiative.tracking_objects.map((item) => {
              return item.id;
            }),
          };
        }
        const { student_trackings = [] } = await fetchListStudentTracking(
          initiative.pk
        );

        //--------

        let derive = {};
        if (initiative.enter_action_plans && initiative.moments) {
          const moments_selected_ids = momentsDerive
            .filter((item) => initiative.moments.includes(item.id))
            .map((item) => item.id);
          if (moments_selected_ids.length > 0) {
            derive["enter_action_plans"] = initiative.enter_action_plans;
            derive["moments_selected"] = moments_selected_ids;
          }
        }

        let dataAll = {
          clone: true,
          selectInitiative: initiativeSelected,
          student_trackings: student_trackings,
          selectAll: false, //-----
          initiative_type: initiative.initiative_type.id,
          campuses: new_campuses,
          name: initiative.name,
          objective: initiative.objective,
          optionInitiative: initiative.initiative_type.name,
          optionCreate: "copy",
          clientfilter_values: initiative.clientfilter_values,
          client_filters: client_filters,
          client_area: initiative.client_area,
          team: initiative.team?.id,
          start_date: initiative.start_date
            ? moment(initiative.start_date).toDate()
            : null,
          ...template,
          ...derive,
          ...campos_selected,
          search: false,
          ...tracking_object_dict,
          original: initiative.pk,
          source_type: initiative.source_type ?? "internal",
        };
        if (getGlobalConfig("enable_send_all_contacts", false)) {
          dataAll["send_all_contacts"] = initiative?.send_all_contacts;
        }

        onChangeForm("selectAll", false, dataAll, false, true);
        onChange("next");
        setLoading(false);
      } else {
        toast.error(
          "Error al cargar los datos, porfavor vuelva a intentarlo nuevamente!."
        );
      }
    } else {
      toast.error("Tiene que seleccionar una campaña!.");
    }
  };
  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      if (search !== "") {
        loadPage(1, 12, search);
        setActiveSearch(true);
      } else {
        setSearch("");
        loadPage(1, 12);
        setActiveSearch(false);
      }
    }
  };
  return (
    <div style={{ paddingBottom: "50px" }}>
      <div style={{ maxWidth: "922px", margin: "auto" }}>
        <CardNimbi padding="0px">
          <div style={{ padding: "33px 35px 16px 35px" }}>
            <Col xs={6}>
              <StepTitle>{t("step_number", { number: "3" })}</StepTitle>
              <TextQuestion style={{ marginTop: "16px" }}>
                {t("description_clone_initiative", {
                  students: translation("students", "", t("students")),
                })}
              </TextQuestion>
              <DividerH style={{ marginTop: "20px", marginBottom: "20px" }} />
            </Col>
            <div>
              <NInputSearch
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
                placeholder={t("seek_initiative")}
                style={{ width: "260px" }}
                value={search}
                onClick={() => {
                  if (!activeSearch) {
                    loadPage(1, 12, search);
                  } else {
                    setSearch("");
                    loadPage(1, 12);
                  }

                  setActiveSearch(!activeSearch);
                }}
                activeSearch={activeSearch}
                handleKeypress={handleKeypress}
              />
              {pagination && (
                <div
                  style={{
                    marginTop: "21px",
                    fontSize: "var(--font-size-small)",
                  }}
                >
                  <strong>{pagination.count}</strong> Campañas de{" "}
                  {formValues.optionInitiative === "Call"
                    ? "llamada"
                    : "correo"}
                </div>
              )}
            </div>
            <div>
              <RowItem>
                {isPending && <NSpinnerPoints />}
                {!isPending &&
                  initiatives.map((item, position) => {
                    return (
                      <Col
                        key={`card-copy-${position}`}
                        xs="4"
                        style={
                          loading
                            ? {
                                pointerEvents: "none",
                                opacity: "0.4",
                                marginTop: "24px",
                              }
                            : { marginTop: "24px" }
                        }
                      >
                        <CardInitiative
                          item={item}
                          onChange={(item) => {
                            setInitiativeSelected(item);
                          }}
                          position={position + 1}
                          showCriteria={true}
                          showActive={false}
                          selected={
                            initiativeSelected
                              ? initiativeSelected.pk === item.pk
                              : false
                          }
                          copy={true}
                          onClickDetail={() => {
                            setInitiativeDetail(item);
                            setShowModal(true);
                          }}
                        />
                      </Col>
                    );
                  })}
                <div style={{ marginTop: "10px", width: "100%" }}>
                  <NPagination
                    isPending={isPending}
                    pagination={pagination}
                    onPageChange={loadPage}
                  />
                </div>
              </RowItem>
            </div>
          </div>
        </CardNimbi>
        {initiativeDetail && (
          <ModalInitiativeDetail
            show={showModal}
            handleClose={() => {
              setShowModal(false);
            }}
            initiative={initiativeDetail}
            optionInitiative={formValues.optionInitiative}
            onClickSelect={() => {
              setInitiativeSelected(initiativeDetail);
              setShowModal(false);
            }}
          />
        )}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div>
            <NButton
              sizeStyle={ButtonSize.lg}
              style={{
                marginTop: "24px",
                borderRadius: "24px",
                marginRight: "16px",
              }}
              onClick={() => {
                onChange("last");
              }}
              outlined
              icon={<NIcon iconname="arrowLeft" />}
              color="second"
            >
              {t("go_back")}
            </NButton>
            <NButton
              sizeStyle={ButtonSize.lg}
              disabled={loading}
              style={{
                marginTop: "24px",
                borderRadius: "24px",
                minWidth: "104px",
              }}
              onClick={() => {
                //onChange("next");
                nextSelectInitiative();
              }}
              color="second"
            >
              {!loading && (
                <>
                  {t("continue")}
                  <NIcon iconname="arrowRight" style={{ marginLeft: "12px" }} />
                </>
              )}
              {loading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="fas fa-cog fa-spin"
                  style={{ fontSize: "10px" }}
                />
              )}
            </NButton>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageSecondCopy;
