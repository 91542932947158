import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Row, Col, Container } from "components";
import { uuidv4, fixHeaders } from "utils";
import ClientFactory from "models/ClientFactory";
import styles from "./ModalProfile.module.css";
import { AnonymousContainer } from "components/Nimbiv2/styled";
import { useTranslation } from "react-i18next";

const Tasks = ({ data, marginTop = true, isAnonymous = false }) => {
  const { t } = useTranslation();
  const colLabels = [
    { label: "Tracking object", ref: "name", size: 5, find: "tracking_object" },
    { label: "Campus", ref: "campus", size: 2, find: "campus" },
    { label: t("type"), ref: "type", size: 2, find: "type" },
    {
      label: t("created_in_nimbi"),
      ref: "create_nimbi",
      size: 3,
      find: "create_nimbi",
    },
  ];
  const labels = fixHeaders(colLabels);
  const Institution = new ClientFactory().client();

  const renderHeaders = () => {
    return labels.map((item) => (
      <ItemHeader key={uuidv4(8)} size={item.size} label={item.label} />
    ));
  };

  return (
    <Container className={styles.coursesBlock} fluid>
      <Row className={`${styles.courseHeader} ${marginTop ? "mt-4" : ""}`}>
        {renderHeaders()}
      </Row>

      {/* rendering all courses */}
      {data.map((task) => (
        <Row key={uuidv4(4)} className={styles.rowCourse}>
          <Col md={5}>
            <Institution.CourseColumnInfo enrollment={task} />
          </Col>
          <Col className="d-flex align-items-center" md={2}>
            <AnonymousContainer isAnonymous={isAnonymous}>
              <Institution.CampusColumnInfo enrollment={task} />
            </AnonymousContainer>
          </Col>
          <Col className="d-flex align-items-center" md={2}>
            {task.student_tracking_type}
          </Col>
          <Col className="d-flex align-items-center" md={3}>
            <div>
              <div>{moment(task.created_at).format("DD-MM-YYYY")}</div>
              <div>{moment(task.created_at).format("HH:MM")}</div>
            </div>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

Tasks.propTypes = { data: PropTypes.arrayOf(PropTypes.object) };
Tasks.defaultProps = { data: [] };

export default Tasks;

const ItemHeader = ({ size, label }) => {
  return (
    <Col md={size}>
      <span className={styles.headerTitle}>{label}</span>
    </Col>
  );
};
