import { NTable } from "components/Nimbiv2";
import {
  CardNimbi,
} from "components/Nimbiv2/styled";
import { useTranslationLocal } from "hooks";
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "../User/User.module.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchGenerationEvents } from "store/actions/async/generation-async.actions";

const GenerationEventsList = () => {
  const { translation } = useTranslationLocal();
  const {
    list: listGenerationEvent,
    pending: { list: isPending },
  } = useSelector((state) => state.generation);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGenerationEvents());
  }, [dispatch]);

  const columns = [
    {
      title: translation("event", ""),
      key: "event",
      fixed: true,
      style: { left: "0px" },
      width: "30%",
      render: (item) => {
        return (
          <div
            className={`d-flex justify-content-left align-items-center ${styles.firstColumn}`}
          >
            <div className="d-flex flex-column justify-content-left align-items-center">
              <div
                className={`${styles.headerTitle}`}
                style={{
                  fontWeight: 500,
                  letterSpacing: "0.3px",
                }}
              >
                 <span>{item.event_name}</span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: translation("generation", "upper"),
      key: "generation",
      width: "20%",
      render: (item) => (
        <span className={`capitalize-text ${styles.rowText}`}>{item.generation}</span>
      ),
    },
    {
      title: translation("tracking_objects", "upper", "Curso"),
      key: "tracking_objects",
      width: "20%",
      render: (item) => (
        <span className={`capitalize-text ${styles.rowText}`}>
          <span className={`capitalize-text ${styles.rowText}`}>{item.tracking_objects}</span>
        </span>
      ),
    },
    {
      title: translation("date", "upper"),
      key: "date",
      width: "10%",
      render: (item) => (
        <span className={`mr-1 capitalize-text ${styles.rowText}`}>
          <span className={`capitalize-text ${styles.rowText}`}>{item.date}</span>
        </span>
      ),
    }
  ];
  return (
    <Container fluid style={{ paddingBottom: "20px", marginTop: "11.5px" }}>
      <CardNimbi
        padding="0px 16px 16px 16px"
        style={{ height: "auto", borderRadius: "8px" }}
      >
        <NTable
          widthSkeletons="100%"
          columns={columns}
          dataSource={listGenerationEvent}
          isPending={isPending}
          upperCaseHeader
        ></NTable>
      </CardNimbi>
    </Container>
  );
};

export default GenerationEventsList;
