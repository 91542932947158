import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import LogRocket from "logrocket";
import rootReducer from "./reducers/root.reducer";

const middlewares = [thunk];
const enhancerList = [];
// eslint-disable-next-line no-underscore-dangle
const devToolsExtension = window && window.__REDUX_DEVTOOLS_EXTENSION__;

if (typeof devToolsExtension === "function") {
  enhancerList.push(devToolsExtension());
}

const composedEnhancer = compose(
  applyMiddleware(...middlewares, LogRocket.reduxMiddleware()),
  ...enhancerList
);

const initStore = () => createStore(rootReducer, {}, composedEnhancer);

export default initStore;
