/* eslint-disable react/require-default-props */
/* eslint-disable no-underscore-dangle */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Highlighter } from "react-bootstrap-typeahead";
import NInputFilter from "components/NInputFilter";
import { replaceChars, capitalizeWord } from "utils";
import ContainerInputFilter from "./ContainerInputFilter";
import { useTranslation } from "react-i18next";
import { VERSIONS_FILTERS_DESIGN } from "utils/constants";
const NSelectFetch = ({
  onChange,
  isMultiple = false,
  isDisabled = false,
  dataset = { values: [], subtype_component: null },
  initialValue = null,
  meta = {},
  canAutoFit = false,
  parent = null,
  versionInput,
}) => {
  const { t } = useTranslation();
  const [parentValue, setParentValue] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [listValues, setListValues] = useState([]);
  useEffect(() => {
    // if (isDisabled) setItemsSelected([]);
  }, [isDisabled]);

  const customMenuItem = (option, { text }) => (
    <div>
      <Highlighter search={text}>{option}</Highlighter>
    </div>
  );

  useEffect(() => {
    if (parent !== null && parent) {
      if (parent !== parentValue) {
        setParentValue(parent);
        setListValues(_.get(dataset.values, parent, []));
      }
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [parent]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterChanged = (data) => {
    let selected = data;
    if (!isMultiple) selected = data.length > 0 ? data[0] : null;
    if (isMultiple && data.length === 0) selected = null;
    const x = dataset["filter_field"];
    onChange(x, selected, dataset);
  };

  return (
    <ContainerInputFilter
      label={capitalizeWord(replaceChars(dataset?.label, "_", " "))}
      versionInput={versionInput}
    >
      <NInputFilter
        handleChange={handleFilterChanged}
        data={listValues}
        placeholder={
          versionInput === VERSIONS_FILTERS_DESIGN.v2
            ? t("all")
            : capitalizeWord(replaceChars(dataset?.label, "_", " "))
        }
        selected={
          isMultiple
            ? initialValue
              ? initialValue
              : []
            : initialValue
            ? [initialValue]
            : []
        }
        renderMenuItem={customMenuItem}
        isMultiple={isMultiple}
        isDisabled={isDisabled ? isDisabled : disabled}
        canAutoFit={canAutoFit}
      />
    </ContainerInputFilter>
  );
};

NSelectFetch.propTypes = {
  onChange: PropTypes.func.isRequired,
  isMultiple: PropTypes.bool,
  isDisabled: PropTypes.bool,
  canAutoFit: PropTypes.bool,
  dataset: PropTypes.shape({}),
  //initialValue: PropTypes.arrayOf(PropTypes.string),
  meta: PropTypes.shape({}),
};

export default NSelectFetch;
