import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-regular-svg-icons";

const NSidebarContainer = ({
  openSide = false,
  title,
  width = "498px",
  onClose,
  ...props
}) => {
  useEffect(() => {
    if (openSide) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [openSide]);
  return (
    <div>
      <SidebarContainer opened={openSide} width={width}>
        <div
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <div
            className="d-flex justify-content-between"
            style={{ paddingBottom: "24px" }}
          >
            <div className="sidebar-title">{title}</div>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faClose}
                style={{ fontSize: "24px", cursor: "pointer" }}
                onClick={onClose}
              />
            </div>
          </div>
          <div style={{ flexGrow: "1" }}>{props.children}</div>
        </div>
      </SidebarContainer>
      {openSide && (
        <BackgroundSidebar
          onClick={onClose}
          opened={openSide}
        ></BackgroundSidebar>
      )}
    </div>
  );
};

NSidebarContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  openSide: PropTypes.bool,
};

export default NSidebarContainer;

const BackgroundSidebar = styled.div`
  position: fixed;
  inset: 0px;
  overflow: hidden auto;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
`;

const SidebarContainer = styled.div`
  background: var(--color-white);
  box-shadow: 0px 3px 16px rgba(143, 164, 189, 0.25);
  height: 100vh;
  min-width: ${(props) => props.width};
  max-width: ${(props) => props.width};
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1001;
  transition: all 0.5s;
  border: 8px, 0px, 0px, 8px;
  padding: 24px;
  overflow: auto;
  ${(props) => (props.opened ? "" : `margin-right: -${props.width};`)}
  .body {
    border-top: 1px solid var(--color-aqua);
    padding: 24px;
    max-height: 90%;
    overflow: auto;
  }
  .sidebar-title {
    font-size: 24px;
    color: #283952;
    font-weight: 500;
  }
`;
