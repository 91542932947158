import React from "react";
import moment from "moment";

import CourseCol from "containers/Task/Cols/CourseCol";
import { faLink, faCalendarAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NBadge } from "components";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { EClassID } from "utils";
import { useTranslation } from "react-i18next";

export const CourseColumnInfo = ({
  enrollment,
  hasExtension,
  hasPaymentProblem,
}) => {
  const { t } = useTranslation();
  const courseBadge = () => {
    let badgeText = `${enrollment.tracking_object.external_id} | ${enrollment.tracking_object.tracking_object_type}`;
    if (hasExtension) {
      badgeText += ` | ${t("academic_extension")}`;
    }
    if (hasPaymentProblem) {
      badgeText += ` | ${t("payment_problem")}`;
    }
    if (enrollment.enrollment_date) {
      const enrollmentDate = moment(enrollment.enrollment_date).format(
        "DD/MM/YYYY"
      );
      badgeText += ` | ${enrollmentDate}`;
    }
    const subtitle = {
      text: badgeText,
      title: EClassID.COURSE,
    };
    return <NBadge text={subtitle.text} title={subtitle.title} />;
  };

  const genCourseToolTip = (course) => {
    const startDate = moment(course.start_date).format("DD/MM/YYYY");
    const endDate = moment(course.end_date).format("DD/MM/YYYY");
    return `${startDate}  -  ${endDate}`;
  };

  const getTooltip = () => {
    return (
      <Tooltip id="tooltip-top">
        {genCourseToolTip(enrollment.tracking_object)}
      </Tooltip>
    );
  };

  return (
    <CourseCol enrollment={enrollment} subtitleBadge={courseBadge()}>
      <OverlayTrigger key="s" placement="top" overlay={getTooltip()}>
        <span>
          <span>{enrollment.tracking_object.name}</span>
          <FontAwesomeIcon className="text-muted ml-2" icon={faCalendarAlt} />
          {enrollment.tracking_object.external_link ? (
            <a
              href={enrollment.tracking_object.external_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon className="text-muted ml-2" icon={faLink} />
            </a>
          ) : null}
        </span>
      </OverlayTrigger>
    </CourseCol>
  );
};
