import React from "react";
import _ from "lodash";
import { CardNimbi, IconContainer, DividerH } from "components/Nimbiv2/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icons from "components/Nimbiv2/icons";
import {
  faExclamationTriangle,
} from "@fortawesome/pro-regular-svg-icons";


import { Row, Col } from "react-bootstrap";
import { Chart as ChartJS, RadialLinearScale, ArcElement, Tooltip, Legend } from 'chart.js';
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels'

ChartJS.register(ChartDataLabels, RadialLinearScale, ArcElement, Tooltip, Legend);

const CardTracking = ({ data = [] }) => {

  const availableColors = [
    'rgba(0,0,255,0.2)', // Blue
    'rgba(60,179,113,0.2)', // Green
    'rgba(238,130,238,0.2)', // Pink
    'rgba(255,165,0,0.2)', // Yellow
    'rgba(106,90,205,0.2)', // Violet
    'rgba(238,130,238,0.2)', // Pink
    'rgba(0,250,250,0.2)', // Pink
  ]
  const randomColors = data.items.map((el) => el.color ?? availableColors.pop())
  const formatNumber = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");  // replace commas with dots

  const dataset = {
    labels: [...data.items.map((el) => el.name.length > 16 ? el.name.slice(0, 16) + "..." : el.name)],
    datasets: [
      {
        label: '# de seguimientos de alumnos',
        data: data.items.map((el) => el.value),
        backgroundColor: randomColors,
        borderColor: randomColors,
        borderWidth: 1,
      },
    ],
    options: {
      indexAxis: 'y',
      maintainAspectRatio: false,
      plugins: {
        legend: false,
        datalabels: {
          anchor: 'center',
          align: 'right',
          formatter: (value) => formatNumber(Math.round(value)),
          font: {
            weight: 'bold'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            callback: (value) => formatNumber(value)
          }
        }
      }
    }
  };

  return (
    <CardNimbi padding="20px 19px">
      <Row className="align-items-center">
        <Col xs="3">
          <IconContainer>
            <FontAwesomeIcon
              icon={_.get(Icons, "tracking", faExclamationTriangle)}
              size="lg"
            />
          </IconContainer>
        </Col>
        <Col
          className="align-items-center fontNova "
          style={{ lineHeight: "120%" }}
        >
          <strong>Distribución de alumnos por tipo de seguimiento</strong>
        </Col>
      </Row>
      <DividerH className="my-2" />
      <Row>
        <Bar data={dataset} options={{ ...dataset.options }}></Bar>
      </Row>
    </CardNimbi>
  );
};
export default CardTracking;
