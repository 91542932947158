/* eslint-disable react/require-default-props */
import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Highlighter } from "react-bootstrap-typeahead";
import { APP_MODULES, getDataFromLocalStorage, setSessionFilters } from "utils";
import NInputFilter from "../NInputFilter";
import { useTranslation } from "react-i18next";
import styles from "./NSearchUsers.module.css";
import { useTranslationLocal } from "hooks";

const {
  USER: { FILTER_NAME: FILTER_USER },
} = APP_MODULES;
const filterFields = ["username", "email", "full_name"];

const NSearchUsers = ({
  filterKey,
  fieldName,
  onChange,
  asyncSearch,
  persist = false,
  isDisabled = false,
  persistText,
}) => {
  const [filterInfo, setFilterInfo] = useState(
    getDataFromLocalStorage(persistText, [])
  );
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();

  const handleFilterChanged = useCallback(
    (data) => {
      const [selected = {}] = data;

      if (persist) {
        const query = selected[fieldName];
        setSessionFilters(FILTER_USER, {
          page: 1,
          [filterKey]: query ?? "",
        });
      }

      setFilterInfo(data);
      onChange(selected);
    },
    [fieldName, filterKey, persist, onChange]
  );

  const customMenuItem = (option, { text }) => {
    return (
      <div className={styles.filterBody}>
        <div>
          <Highlighter
            search={text}
          >{`${option.first_name} ${option.last_name}`}</Highlighter>
        </div>
        <div>
          <small>{option.email}</small>
        </div>
      </div>
    );
  };

  return (
    <NInputFilter
      handleChange={handleFilterChanged}
      data={[]}
      delay={800}
      compositeLabel={["first_name", "last_name", "email"]}
      placeholder={translation(
        "placeholder_search_user",
        "",
        t("placeholder_search_user")
      )}
      selected={filterInfo}
      filterBy={filterFields}
      renderMenuItem={customMenuItem}
      asyncSearch={(query) => asyncSearch(query)}
      isDisabled={isDisabled}
      isAsync
    />
  );
};

NSearchUsers.propTypes = {
  /**
   * The name of the search field to add to the request header.
   *  i.e: first_name, last_name.
   * in case it's general search, this value should be: 'search' instead.
   */
  filterKey: PropTypes.string.isRequired,

  /**
   * Name of the field from which the value is obtained in selected item.
   *  i.e: id, external_id, first_name, last_name... etc.
   * this value is persisted in the localstorage
   */
  fieldName: PropTypes.string.isRequired,

  /**
   * function to execute when an item is selected.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * indicates whether the results persist in LocalStorage or not.
   */
  persist: PropTypes.bool,
};

export default NSearchUsers;
