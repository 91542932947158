import { ACTIONS } from "store/actions/associated.actions";

const initialState = {
  list: [],
  listRoles: [],
  listTrackingObject: [],
  pagination: {
    count: 0,
    next: null,
    previous: null,
    page: 1,
    pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
  },
  pending: {
    list: false,
    listRoles: false,
    listTrackingObject: false,
  },
  numberCalls: {
    listRoles: 0,
    listTrackingObject: 0,
  },
};

const userReducers = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_ASSOCIATED: {
      return {
        ...state,
        pending: { ...state.pending, list: true },
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          pageSize: action.payload.page_size,
        },
      };
    }

    case ACTIONS.SET_ASSOCIATED: {
      const { count, next, previous, results } = action.payload;
      let auxPagination = { ...state.pagination };
      auxPagination = { ...auxPagination, count, next, previous };

      return {
        ...state,
        list: results,
        pending: { ...state.pending, list: false },
        pagination: auxPagination,
      };
    }

    case ACTIONS.GET_ROLES_ASSOCIATED: {
      return {
        ...state,
        listRoles: [],
        pending: { ...state.pending, listRoles: true },
      };
    }

    case ACTIONS.SET_ROLES_ASSOCIATED: {
      const data = action.payload;
      let { listRoles } = { ...state.numberCalls };

      return {
        ...state,
        listRoles: data,
        pending: { ...state.pending, listRoles: false },
        numberCalls: { ...state.numberCalls, ...{ listRoles: listRoles + 1 } },
      };
    }

    case ACTIONS.GET_TRACKINGOBJECT_ASSOCIATED: {
      return {
        ...state,
        listTrackingObject: [],
        pending: { ...state.pending, listTrackingObject: true },
      };
    }

    case ACTIONS.SET_TRACKINGOBJECT_ASSOCIATED: {
      const data = action.payload;
      let { listTrackingObject } = { ...state.numberCalls };

      return {
        ...state,
        listTrackingObject: data,
        pending: { ...state.pending, listTrackingObject: false },
        numberCalls: {
          ...state.numberCalls,
          ...{ listTrackingObject: listTrackingObject + 1 },
        },
      };
    }

    default:
      return { ...state };
  }
};

export default userReducers;
