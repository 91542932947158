import React from "react";
import styles from "./NButton.module.css";

const NButton = ({
  children,
  caption,
  onHandleClick,
  isDisabled = false,
  cssStyles = {},
}) => {
  const handleClick = () => {
    if (isDisabled) return;
    onHandleClick();
  };

  return (
    <span
      className={`btn ${styles.callingSaveButton} ${cssStyles} ${
        isDisabled ? styles.disableEffect : null
      }`}
      onClick={handleClick}
      aria-hidden="true"
    >
      {children}
      <span>{caption}</span>
    </span>
  );
};

export default React.memo(NButton);
