import React from "react";
import taskStyles from "containers/Task/Task.module.css";

export const CampusColumnInfo = ({ enrollment }) => {
  if (!enrollment) return null;
  return (
    <span className={`mr-1 ${taskStyles.rowText}`}>
      {enrollment.extras.campus}
    </span>
  );
};
