/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getDataFromLocalStorage } from "utils";
import { clearSession } from "store/actions/auth.actions";
import { useDispatch } from "react-redux";
import { checkAccess } from "./helpers";

const RouteWrapper = ({ Component, ...props }) => {
  const dispatch = useDispatch();
  const user = getDataFromLocalStorage("user");
  const token = getDataFromLocalStorage("token");
  const { groups = [] } = user ?? {};

  const access = checkAccess({
    userRoles: groups || [],
    allowedRoles: props.roles,
  });

  // user without groups
  if (user && token && props.roles && !groups) {
    dispatch(clearSession());
    return <Redirect to="/error/401" />;
  }

  if (!token && !access) {
    return <Redirect to="/" />;
  }
  if (token && !access) {
    return <Redirect to="/error/401" />;
  }

  return (
    <Route {...props} render={(routeProps) => <Component {...routeProps} />} />
  );
};

export default React.memo(RouteWrapper);
