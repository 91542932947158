import React from "react";

const EmptyInitiativeBoard = () => {
  return (
    <svg
      width="165"
      height="163"
      viewBox="0 0 165 163"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M83.1622 150.433C124.673 150.433 158.324 116.757 158.324 75.2163C158.324 33.6755 124.673 0 83.1622 0C41.6513 0 8 33.6755 8 75.2163C8 116.757 41.6513 150.433 83.1622 150.433Z"
        fill="#F1F6FD"
      />
      <g filter="url(#filter0_d_2085_9379)">
        <path
          d="M144.753 28.1822V121.668C144.753 125.05 141.97 127.835 138.59 127.835H26.6592C23.2795 127.835 20.4961 125.05 20.4961 121.668V28.1822C20.4961 24.8 23.2795 22.0146 26.6592 22.0146H138.59C141.97 22.0146 144.753 24.8 144.753 28.1822Z"
          fill="white"
        />
      </g>
      <path
        d="M144.753 28.1822V32.6587H20.4961V28.1822C20.4961 24.8 23.2795 22.0146 26.6592 22.0146H138.59C141.97 22.0146 144.753 24.8 144.753 28.1822Z"
        fill="#D9E1F2"
      />
      <path
        d="M26.9571 29.2762C28.0002 29.2762 28.8458 28.43 28.8458 27.3862C28.8458 26.3423 28.0002 25.4961 26.9571 25.4961C25.914 25.4961 25.0684 26.3423 25.0684 27.3862C25.0684 28.43 25.914 29.2762 26.9571 29.2762Z"
        fill="#F1F6FD"
      />
      <path
        opacity="0.6"
        d="M32.8218 29.2762C33.8649 29.2762 34.7105 28.43 34.7105 27.3862C34.7105 26.3423 33.8649 25.4961 32.8218 25.4961C31.7787 25.4961 30.9331 26.3423 30.9331 27.3862C30.9331 28.43 31.7787 29.2762 32.8218 29.2762Z"
        fill="#F1F6FD"
      />
      <path
        opacity="0.4"
        d="M38.7862 29.2762C39.8293 29.2762 40.6749 28.43 40.6749 27.3862C40.6749 26.3423 39.8293 25.4961 38.7862 25.4961C37.7431 25.4961 36.8975 26.3423 36.8975 27.3862C36.8975 28.43 37.7431 29.2762 38.7862 29.2762Z"
        fill="#F1F6FD"
      />
      <path
        d="M115.845 75.1117H49.8066C47.8436 75.1117 46.1611 73.0395 46.1611 70.622V48.5188C46.1611 46.1012 47.8436 44.0291 49.8066 44.0291H115.845C117.808 44.0291 119.49 46.1012 119.49 48.5188V70.622C119.49 73.0395 117.808 75.1117 115.845 75.1117Z"
        fill="white"
      />
      <path
        d="M89.7814 56.867H55.345C54.2646 56.867 53.3193 55.921 53.3193 54.8399C53.3193 53.7587 54.2646 52.8127 55.345 52.8127H89.7814C90.8617 52.8127 91.8071 53.7587 91.8071 54.8399C91.672 55.921 90.8617 56.867 89.7814 56.867Z"
        fill="#D9E1F2"
      />
      <path
        d="M110.308 56.8672H96.6682C95.5879 56.8672 94.6426 55.9212 94.6426 54.8401C94.6426 53.759 95.5879 52.813 96.6682 52.813H110.308C111.388 52.813 112.333 53.759 112.333 54.8401C112.198 55.9212 111.388 56.8672 110.308 56.8672Z"
        fill="#D9E1F2"
      />
      <path
        d="M83.7044 66.1917H55.345C54.2646 66.1917 53.3193 65.2457 53.3193 64.1646C53.3193 63.0834 54.2646 62.1375 55.345 62.1375H83.7044C84.7847 62.1375 85.73 63.0834 85.73 64.1646C85.73 65.2457 84.7847 66.1917 83.7044 66.1917Z"
        fill="#D9E1F2"
      />
      <path
        d="M115.845 115.093H49.8066C47.8436 115.093 46.1611 113.021 46.1611 110.603V88.5002C46.1611 86.0827 47.8436 84.0105 49.8066 84.0105H115.845C117.808 84.0105 119.49 86.0827 119.49 88.5002V110.603C119.49 113.194 117.808 115.093 115.845 115.093Z"
        fill="white"
      />
      <path
        d="M89.7814 96.8487H55.345C54.2646 96.8487 53.3193 95.9027 53.3193 94.8216C53.3193 93.7404 54.2646 92.7944 55.345 92.7944H89.7814C90.8617 92.7944 91.8071 93.7404 91.8071 94.8216C91.672 96.0378 90.8617 96.8487 89.7814 96.8487Z"
        fill="#D9E1F2"
      />
      <path
        d="M110.308 96.8487H96.6682C95.5879 96.8487 94.6426 95.9027 94.6426 94.8216C94.6426 93.7404 95.5879 92.7944 96.6682 92.7944H110.308C111.388 92.7944 112.333 93.7404 112.333 94.8216C112.198 96.0378 111.388 96.8487 110.308 96.8487Z"
        fill="#D9E1F2"
      />
      <path
        d="M83.7044 106.173H55.345C54.2646 106.173 53.3193 105.227 53.3193 104.146C53.3193 103.065 54.2646 102.119 55.345 102.119H83.7044C84.7847 102.119 85.73 103.065 85.73 104.146C85.73 105.362 84.7847 106.173 83.7044 106.173Z"
        fill="#D9E1F2"
      />
      <path
        d="M109.667 143.88C109.178 145.959 108.444 148.161 107.588 149.995C105.264 154.521 101.595 158.067 97.0702 160.391C92.4227 162.715 86.9191 163.693 81.4154 162.47C68.4513 159.78 60.1347 147.06 62.8254 134.096C65.5161 121.132 78.1132 112.693 91.0774 115.506C95.7249 116.484 99.7609 118.808 103.185 121.988C108.934 127.736 111.38 136.053 109.667 143.88Z"
        fill="#D9E1F2"
      />
      <path
        d="M93.8902 136.909H88.3866V131.406C88.3866 130.305 87.5305 129.326 86.3074 129.326C85.2067 129.326 84.2283 130.183 84.2283 131.406V136.909H78.7247C77.6239 136.909 76.6455 137.765 76.6455 138.988C76.6455 140.211 77.5016 141.067 78.7247 141.067H84.2283V146.571C84.2283 147.672 85.0844 148.65 86.3074 148.65C87.4082 148.65 88.3866 147.794 88.3866 146.571V141.067H93.8902C94.9909 141.067 95.9694 140.211 95.9694 138.988C95.9694 137.765 94.9909 136.909 93.8902 136.909Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_2085_9379"
          x="0.496094"
          y="8.01465"
          width="164.257"
          height="145.821"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.690196 0 0 0 0 0.729412 0 0 0 0 0.8 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2085_9379"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2085_9379"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default EmptyInitiativeBoard;
