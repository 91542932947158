import React, { useEffect } from "react";
import _ from "lodash";
import { useDefault } from "hooks";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { NNavbarDashboard, ScrollToTopOnMount } from "components/Nimbiv2";
import { setActiveModule } from "store/actions/site.actions";
import { HelpCenterDetailContainer, HelpCenterView } from "containers/Help";

const HelpCenter = ({ action = "main" }) => {
  const { key } = useParams();

  if (action === "detail") return <HelpCenterDetailContainer itemType={key} />;

  return <HelpCenterMainPage />;
};

const HelpCenterMainPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const helpModuleTitle = useDefault(
    "helpModuleTitle",
    _.upperFirst(t("help_center"))
  );

  useEffect(() => {
    if (helpModuleTitle)
      dispatch(
        setActiveModule({
          title: helpModuleTitle,
          subtitle: t("page_help_center_subtitle"),
        })
      );
  }, [dispatch, helpModuleTitle, t]);

  return (
    <div>
      <ScrollToTopOnMount />
      <NNavbarDashboard />
      <div style={{ marginTop: "48px" }}>
        <HelpCenterView />
      </div>
    </div>
  );
};

export default HelpCenter;
