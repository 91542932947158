import React from "react";
import { Tab } from "react-bootstrap";
import { NTabs } from "../styled";
const TabContainer = ({ activeKey, onSelect = () => {}, tabs }) => {
  const onChangeTab = (item) => {
    if (activeKey !== item) {
      onSelect(item);
    }
  };
  return (
    <NTabs activeKey={activeKey} onSelect={onChangeTab}>
      {tabs.map((item, key) => {
        return (
          <Tab
            eventKey={item.key}
            title={item.title}
            key={`key-tab-${key}`}
          ></Tab>
        );
      })}
    </NTabs>
  );
};
export default TabContainer;
