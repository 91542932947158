import {
  getInitiatives,
  getTypesInitiative,
  getStudentsTracking,
  getInitiative,
  getInitiativeStudentsTracking,
  getInitiativesCall,
  getInitiativeInsights,
  getInitiativeListStudentTracking,
} from "services/initiativeService";
import * as InitiativeActions from "store/actions/initiative.actions";
import { handleErrorStatus } from "services/errors";
import { APP_MODULES, cleanNullJson, getSessionFilters } from "utils";
import { getCoursesFromCampus } from "services/crmService";

const handleErrorResponse = (err) => {
  const status = err instanceof Map ? err.get("status") : 500;
  if (status > 0) return handleErrorStatus(status);
};

export const fetchListInitiatives = (queryParams2, name_filters) => {
  return (dispatch) => {
    const queryParams = getSessionFilters(name_filters);
    dispatch(InitiativeActions.getInitiatives(queryParams2 ?? queryParams));
    getInitiatives(queryParams2 ?? queryParams ?? { page_size: 100 })
      .then(({ data }) => {
        dispatch(InitiativeActions.setInitiatives(data));
      })
      .catch((err) => {
        handleErrorResponse(err);
        //dispatch(InitiativeActions.registerError({ err, key: "initiatives" }));
      });
  };
};

export const fetchListTypeInitiatives = (queryParams) => {
  return (dispatch) => {
    dispatch(InitiativeActions.getTypesInitiative());
    getTypesInitiative(queryParams)
      .then(({ data }) => {
        dispatch(InitiativeActions.setTypeInitiatives(data));
      })
      .catch((err) => {
        handleErrorResponse(err);
        //dispatch(InitiativeActions.registerError({ err, key: "initiatives" }));
      });
  };
};

export const fetchStudentsTracking = (
  initialParams = null,
  dataPost = null
) => {
  return (dispatch) => {
    const queryParams = getSessionFilters(APP_MODULES.INITIATIVES.FILTER_NAME);

    dispatch(
      InitiativeActions.getStudentsTracking(initialParams ?? queryParams)
    );
    getStudentsTracking(cleanNullJson(initialParams ?? queryParams), dataPost)
      .then(({ data }) => {
        dispatch(InitiativeActions.setStudentsTracking(data));
      })
      .catch((err) => {
        handleErrorResponse(err);
        // dispatch(InitiativeActions.registerError({ err, key: "list" }));
      });
  };
};

export const fetchInitiative = (id, initialParams = {}) => {
  return (dispatch) => {
    dispatch(InitiativeActions.getInitiative());
    getInitiative(id, cleanNullJson(initialParams))
      .then(({ data }) => {
        dispatch(InitiativeActions.setInitiative(data));
      })
      .catch((err) => {
        handleErrorResponse(err);
        // dispatch(InitiativeActions.registerError({ err, key: "list" }));
      });
  };
};

export const fetchInitiativeStudentsTracking = (id, initialParams = null) => {
  return (dispatch) => {
    const queryParams = getSessionFilters(APP_MODULES.INITIATIVES.FILTER_NAME);
    dispatch(
      InitiativeActions.getStudentsTracking(initialParams ?? queryParams)
    );
    getInitiativeStudentsTracking(id, initialParams ?? queryParams)
      .then(({ data }) => dispatch(InitiativeActions.setStudentsTracking(data)))
      .catch((err) => {
        handleErrorResponse(err);
        // dispatch(InitiativeActions.registerError({ err, key: "list" }));
      });
  };
};

export const fetchInitiativeCallActive = () => {
  return (dispatch) => {
    dispatch(InitiativeActions.getInitiativesCall());
    getInitiativesCall()
      .then(({ data }) => {
        dispatch(InitiativeActions.setInitiativesCall(data));
      })
      .catch((err) => {
        handleErrorResponse(err);
        // dispatch(InitiativeActions.registerError({ err, key: "list" }));
      });
  };
};

export const fetchInitiativeInsights = (id, initialParams = {}) => {
  return (dispatch) => {
    dispatch(InitiativeActions.getInitiativesInsights());
    getInitiativeInsights(id, cleanNullJson(initialParams))
      .then(({ data }) => {
        dispatch(InitiativeActions.setInitiativesInsights(data));
      })
      .catch((err) => {
        handleErrorResponse(err);
        // dispatch(InitiativeActions.registerError({ err, key: "list" }));
      });
  };
};

export const fetchCoursesFromCampus = async (campusName) => {
  if (!campusName) return Promise.resolve([]);
  // get all courses from this campus
  const payload = { campus: campusName };
  const {
    data: { results: filteredCourses },
  } = await getCoursesFromCampus({
    queryParams: { page: 1, page_size: 1000 },
    payload,
  });

  return Promise.resolve(filteredCourses);
};

export const fetchListStudentTracking = async (id) => {
  if (!id) return Promise.resolve([]);
  // get all courses from this campus
  const { data } = await getInitiativeListStudentTracking(id);
  return Promise.resolve(data);
};
