/* eslint-disable no-unused-vars */
import React, { useEffect, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Row, Col } from "react-bootstrap";

import { fetchStudents } from "store/actions/async/student-async.actions";
import { fetchClientFilters } from "store/actions/async/site-async.action";
import { advancedSearch } from "services/student.service";
import { APP_MODULES, getSessionFilters, setSessionFilters } from "utils";

import taskStyles from "containers/Task/Task.module.css";
import { RowItem } from "components/Nimbiv2/styled";
import NDynamicFilters from "components/Nimbiv2/NDynamicFilters";
import { useTranslation } from "react-i18next";
import { useTranslationLocal } from "hooks";
const {
  STUDENT: { FILTER_NAME: FILTER_STUDENT },
} = APP_MODULES;

const SEARCH = {
  STUDENT: {
    FILTER_KEY: "external_id",
    FIELD_NAME: "external_id",
  },
  TASK: {
    FILTER_KEY: "student_tracking__student__external_id",
    FIELD_NAME: "external_id",
  },
};
export const StudentFilters = (props) => {
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const {
    pending: { list: isPendingList },
  } = useSelector((state) => state.student, shallowEqual);

  const getAllStudents = useCallback(
    (selected) => {
      setSessionFilters(FILTER_STUDENT, { _filteredList: false });

      // fetch students whithout persisting the filters
      const query = selected[SEARCH.STUDENT.FIELD_NAME];
      dispatch(
        fetchStudents({
          page: 1,
          page_size: 10,
          [SEARCH.STUDENT.FILTER_KEY]: query ?? "",
        })
      );
    },
    [dispatch]
  );

  const searchStudents = useCallback(async (query) => {
    const params = { page_size: 100, query: query.trim() };
    const response = await advancedSearch(params);
    let results;
    if (response.data) results = response.data.results;
    return Promise.resolve(results ?? []);
  }, []);

  const getAllResults = useCallback(() => {
    let queryParams = getSessionFilters(FILTER_STUDENT, true);
    if (step === 0 && queryParams.studenttracking__campus__name) {
      delete queryParams.studenttracking__campus__name;
      delete queryParams._courses;
      delete queryParams._filteredList;
      queryParams = setSessionFilters(FILTER_STUDENT, queryParams, true);
      setStep(1);
    }

    const payload = {
      ...queryParams,
    };
    dispatch(fetchStudents(payload));
  }, [dispatch, step]);

  useEffect(() => {
    dispatch(fetchClientFilters({ module: APP_MODULES.STUDENT.ID }));
  }, [dispatch]);

  return (
    <RowItem className={`align-items-center mt-3 ${taskStyles.toolbarBlock}`}>
      <Col className={`mb-3 p-0 ${taskStyles.tabContainer}`} md={12}>
        <NDynamicFilters
          panel={APP_MODULES.STUDENT.ID}
          isPendingList={isPendingList}
          filterNameLS={FILTER_STUDENT}
          onGetSingleStudent={getAllStudents}
          onGetSuggestStudents={searchStudents}
          onLazySecondList={getAllResults}
          countItems={props?.totalRows ?? 0}
          textCount={t("found_students", {
            students: translation("students", "", t("students")),
          })}
        />
      </Col>
    </RowItem>
  );
};
export default StudentFilters;
