import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDefault, useTranslationLocal } from "hooks";
import { getDefaultValue } from "hooks/useDefault";
import { setActiveModule } from "store/actions/site.actions";
import { ColLargeScreen, RowItem } from "components/Nimbiv2/styled";
import InitiativesList from "containers/Initiatives/InitiativesList";
import { fetchListInitiatives } from "store/actions/async/initiatives-async.actions";
import {
  getDataFromLocalStorage,
  setDataToLocalStorage,
  cleanNullJson,
} from "utils";
import { setTabSelect } from "store/actions/initiative.actions";
import { ContainerEmpty, NNavbarDashboard } from "components/Nimbiv2";
import CardInitiativeSkeletons from "components/Skeletons/CardInitiative";
import InitiativeFilters from "containers/Initiatives/InitiativeFilters";
import { NPagination } from "components";
import MomentSVG from "assets/images/momentSVG";
import * as routePaths from "routes/routePaths";
import { useHistory } from "react-router-dom";

const InitiativesPage = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const momentModule = useDefault("initiative", getDefaultValue("initiative"));
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const [selectOption, setSelectOption] = useState("active");
  const [searchValue, setSearchValue] = useState([]);
  const [savePaypload, setSavePayload] = useState({});
  const [clean, setClean] = useState(0);
  const [ordering, setOrdering] = useState(null);
  const [countInitiative, setCountInitiative] = useState(8);

  const momentModuleSubTitle = useDefault(
    "initiativeModuleSubTitle",
    t("page_initiative_subtitle", {
      students: translation("students", "", t("students")),
    })
  );

  const {
    pagination,
    initiatives,
    tabSelect,
    pending: { initiatives: isPending },
  } = useSelector((state) => state.initiative);

  useEffect(() => {
    if (initiatives && initiatives.length !== 0) {
      setCountInitiative(initiatives.length);
    }
  }, [initiatives]);

  useEffect(() => {
    if (momentModule["moduleTitle"])
      dispatch(
        setActiveModule({
          title: momentModule["moduleTitle"],
          subtitle: momentModuleSubTitle,
        })
      );
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadInitiatives = useCallback(
    (data) => {
      const dataSave = { page_size: 12, page: 1, ...data };
      delete data["selectSearch"];
      let payload = { page_size: 12, page: 1, ...data };
      payload = cleanNullJson(payload);
      if ("source_type" in payload) {
        if (payload["source_type"] === "all") {
          delete payload["source_type"];
        }
      }
      setDataToLocalStorage("tabInitiative", dataSave);
      setSavePayload(dataSave);
      dispatch(fetchListInitiatives(payload));
    },
    [dispatch]
  );

  const changeTypeInitiatives = useCallback(
    (ordering) => {
      let data = {
        ...savePaypload,
        ordering: ordering,
      };
      if ("source_type" in data) {
        if (data["source_type"] === "all") {
          delete data["source_type"];
        }
      }
      loadInitiatives(data);
      setOrdering(ordering);
    },
    [savePaypload, loadInitiatives]
  );

  const loadPage = useCallback(
    (pageNumber, pageSize) => {
      const newFilter = {
        ...savePaypload,
        page: pageNumber,
        page_size: Number(pageSize),
      };
      let payload = { ...newFilter };
      if ("source_type" in payload) {
        if (payload["source_type"] === "all") {
          delete payload["source_type"];
        }
      }
      setDataToLocalStorage("tabInitiative", newFilter);
      setSavePayload(newFilter);
      dispatch(fetchListInitiatives(payload));
    },
    [savePaypload, dispatch]
  );

  useEffect(() => {
    const data = getDataFromLocalStorage("tabInitiative", {
      source_type: "all",
    });
    dispatch(setTabSelect(data["source_type"]));
    if (data?.status) {
      setSelectOption(data.status);
    }
    if (data?.selectSearch) {
      setSearchValue(data.selectSearch);
    }
    if (data?.ordering) {
      setOrdering(data.ordering);
    }
    loadInitiatives(data);
  }, [loadInitiatives, dispatch]);

  const cleanFilters = () => {
    delete savePaypload["id"];
    delete savePaypload["selectSearch"];
    delete savePaypload["page_size"];
    delete savePaypload["page"];
    setClean((clean) => clean + 1);
  };

  return (
    <>
      <NNavbarDashboard />
      <Container fluid style={{ marginTop: "48px", paddingBottom: "40px" }}>
        <InitiativeFilters
          loadInitiatives={loadInitiatives}
          selectOption={selectOption}
          tabSelected={tabSelect}
          searchValue={searchValue}
          onChangeStatus={(value) => {
            setSelectOption(value);
            setSearchValue([]);
            cleanFilters();
            loadInitiatives({
              ...savePaypload,
              status: value,
              page_size: 12,
              page: 1,
            });
          }}
          changeSelectedValue={(value) => {
            setSearchValue(value);
            if (value.length > 0) {
              loadInitiatives({
                ...savePaypload,
                id: value[0].pk,
                selectSearch: value,
                page_size: 12,
                page: 1,
              });
            } else {
              cleanFilters();
              loadInitiatives(savePaypload);
            }
          }}
          changeTypeInitiatives={changeTypeInitiatives}
          clean={clean}
          orderingSelected={ordering}
          isDisabled={isPending}
          onSelect={(data) => {
            dispatch(setTabSelect(data));
            cleanFilters();
            setSearchValue([]);
            loadInitiatives({
              ...savePaypload,
              source_type: data,
              page_size: 12,
              page: 1,
            });
          }}
        />

        {isPending && (
          <div>
            <RowItem>
              {[...Array(countInitiative).keys()].map((item, key) => (
                <ColLargeScreen
                  xl="3"
                  lg="4"
                  key={`Skeletons-card-initiative-${key}`}
                  style={{ marginTop: "20px" }}
                >
                  <CardInitiativeSkeletons />
                </ColLargeScreen>
              ))}
            </RowItem>
          </div>
        )}

        {!isPending && initiatives && initiatives.length === 0 && (
          <ContainerEmpty
            version="2"
            text={t("there_area_no_initiative_show")}
            image={<MomentSVG />}
            description={
              <div
                style={{
                  fontSize: "16px",
                  color: "#5C5F62",
                  textAlign: "center",
                  marginTop: "5px",
                }}
              >
                Empieza creando una utilizando el botón{" "}
                <span
                  style={{ color: "var(--secondary-color)", cursor: "pointer" }}
                  onClick={() => {
                    history.push(routePaths.initiatives.add.route);
                  }}
                >
                  + Crear nueva campaña
                </span>
              </div>
            }
          />
        )}
        {!isPending && initiatives && (
          <InitiativesList
            iniciatives={initiatives}
            pagination={pagination}
            isPending={isPending}
          />
        )}
        {!isPending && initiatives && (
          <div
            style={{ marginTop: "30px", width: "100%", paddingRight: "10px" }}
          >
            <NPagination
              isPending={isPending}
              pagination={pagination}
              onPageChange={loadPage}
              options={["12", "48", "96"]}
            />
          </div>
        )}
      </Container>
    </>
  );
};
export default InitiativesPage;
