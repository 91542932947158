import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import { Typeahead } from "react-bootstrap-typeahead";

const NSelect = ({ label, helpText, errorText }) => {
  const [singleSelections, setSingleSelections] = useState([]);
  const options = ["Mis sedes", "Todas las sedes", "Elegir sedes"];
  return (
    <>
      <ContainerInput>
        <TypeaheadStyled
          id="Typeahead-key"
          labelKey="name"
          onChange={(item) => {
            setSingleSelections(item);
          }}
          options={options}
          selected={singleSelections}
          clearButton
        />
        <LabelStyled className={singleSelections.length > 0 ? "active" : ""}>
          {label}
        </LabelStyled>
      </ContainerInput>
      {helpText && <HelpText>{helpText}</HelpText>}
      {errorText && (
        <ErrorText>
          {errorText}{" "}
          <FontAwesomeIcon
            className="ml-1"
            icon={faExclamationCircle}
            size="1x"
            color="#e23232"
          />
        </ErrorText>
      )}
    </>
  );
};
export default NSelect;

const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  position: relative;
  & label {
    transform-origin: top left;
    transition: all 0.2s ease-out;
  }
  &:focus-within label {
    transform: translate(12px, -8px) scale(0.75);
    padding: 0px 10px 0px 10px;
    color: #334fff;
  }
  &:focus-within input {
    border: 1px solid #334fff;
  }
  .active {
    transform: translate(12px, -8px) scale(0.75) !important;
    padding: 0px 10px 0px 10px;
  }
`;

const TypeaheadStyled = styled(Typeahead)`
  input {
    width: 100%;
    height: 46px;
    padding: 12px;
    outline: 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
    font-family: Arial, Helvetica, sans-serif;
    font-size: var(--font-size-lg);
  }
  a {
  }
`;

const LabelStyled = styled.label`
  font-size: var(--font-size-lg);
  font-family: Arial, Helvetica, sans-serif;
  color: #999;
  pointer-events: none;
  position: absolute;
  transform: translate(12px, 12px) scale(1);
  background: white;
`;

const HelpText = styled.div`
  font-family: Nova;
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-caption);
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: right;
  color: #787c83;
  margin: 3px 0px;
`;

const ErrorText = styled.div`
  font-family: Nova;
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-md);
  line-height: 120%;
  color: #e23232;
  flex: none;
  order: 0;
  flex-grow: 0;
  display: flex;
`;
