import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt } from "@fortawesome/pro-regular-svg-icons";
import { fetchUsers } from "store/actions/async/user-async.actions";
import { NPagination } from "components";
import { APP_MODULES, setSessionFilters } from "utils";
import { RowItem } from "components/Nimbiv2/styled";

const PaginationBar = () => {
  const dispatch = useDispatch();

  const {
    list: results,
    pending: { list: isPending },
    pagination,
  } = useSelector((state) => state.user);

  const loadPage = (pageNumber, pageSize) => {
    setSessionFilters(APP_MODULES.USER.FILTER_NAME, {
      page: pageNumber,
      page_size: Number(pageSize),
    });

    dispatch(fetchUsers());
  };

  if (results.length === 0) return null;

  return (
    <Container fluid className="mt-4 w-100" style={{ paddingBottom: "90px" }}>
      <RowItem>
        <Col className="d-flex align-items-center justify-content-start" md={2}>
          <span className="d-none">
            <FontAwesomeIcon
              className="mr-2"
              size="1x"
              icon={faCloudDownloadAlt}
            />
            Excel
          </span>
        </Col>
        <Col
          style={{ paddingRight: 0 }}
          className="d-flex align-items-center justify-content-end p-0"
        >
          <NPagination
            isPending={isPending}
            pagination={pagination}
            onPageChange={loadPage}
          />
        </Col>
      </RowItem>
    </Container>
  );
};

export default PaginationBar;
