const colors = {
    Bajo: {
      soft: "rgba(12, 205, 179, 0.1)",
      bold: "#3AC8AC",
    },
    Medio: {
      soft:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F6D42D",
      bold: "#E0BC0B",
    },
    Alto: {
      soft:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #E23232",
      bold: "#E23232",
    },
  };

export default colors;