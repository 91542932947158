import React from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { changeLanguage } from "services/user.service";
import { Modal, Row } from "components";
import { getDataFromLocalStorage, setDataToLocalStorage } from "utils";
import { NSpinnerPoints, NButton } from "components/Nimbiv2";

const ModalLanguage = ({ show, handleClose }) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = React.useState(false);

  const chageLanguage = async (language) => {
    setLoading(true);
    const { status } = await changeLanguage({
      language: language,
    });
    if (status === 200) {
      toast.success(t("change_success"));
      i18n.changeLanguage(language);
      let user = getDataFromLocalStorage("user");
      user.user_language = language;
      setDataToLocalStorage("user", user);
      window.location.reload(false);
    } else {
      toast.error(t("error_change_language"));
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <span>{t("select_language")}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`px-4`}>
        {!loading && (
          <div>
            {[
              { label: _.upperFirst(t("spanish")), value: "es" },
              { label: _.upperFirst(t("english")), value: "en" },
            ].map((item, key) => {
              return (
                <Row key={`modal_client_area_${key}`}>
                  <NButton
                    border="10px"
                    active={i18n.language === item.value}
                    outlined
                    className="mt-2"
                    style={{ width: "100%" }}
                    onClick={() => {
                      chageLanguage(item.value);
                    }}
                  >
                    {item.label}
                  </NButton>
                </Row>
              );
            })}
          </div>
        )}
        {loading && (
          <div
            className="d-flex justify-content-center"
            style={{ width: "100%" }}
          >
            <NSpinnerPoints />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalLanguage;
