import React from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLightbulb,
  faClone,
  faEnvelope,
} from "@fortawesome/pro-regular-svg-icons";

import copy from "copy-to-clipboard";
import { Card, Button, Row, Col, Form } from "components";
import styles from "./NEmailInfo.module.css";
import { useTranslation } from "react-i18next";

const NEmailInfo = ({ contactReason, task }) => {
  const { t } = useTranslation();
  const saveTask = async () => {
    //
  };

  const copyEmailAddress = (email) => {
    copy(email);
  };

  return (
    <Form>
      <Card className={styles.callingActionBlock}>
        <Card.Body className="pt-0">
          <Row className={styles.callingActionHeader}>
            <Col
              md={4}
              className="d-flex justify-content-center align-items-center"
            >
              <FontAwesomeIcon className="mr-2" size="1x" icon={faEnvelope} />
              <span className={styles.callActionTitle}>Enviar correo</span>
            </Col>
            <Col md={4}>
              <div
                className={`d-flex align-items-center ${styles.callingActionReasonBlock}`}
              >
                <div className={`mr-2 ${styles.circle}`}>
                  <FontAwesomeIcon
                    style={{ width: 24 }}
                    className={`${styles.lightBulbIcon}`}
                    size="1x"
                    icon={faLightbulb}
                  />
                </div>
                <div>{contactReason}</div>
              </div>
            </Col>
            <Col
              md={4}
              className="d-flex justify-content-end align-items-center"
            >
              <div className={styles.phoneContactBlock}>
                <a href={`mailto:${task.student_tracking.email}`}>
                  {task.student_tracking.email}
                </a>
                <FontAwesomeIcon
                  onClick={() => copyEmailAddress(task.student_tracking.email)}
                  className={`ml-2 ${styles.copyEmailAddress}`}
                  size="1x"
                  icon={faClone}
                />
              </div>
            </Col>
          </Row>
          <Row className="pt-3 px-4">
            <Col md={4}>
              <Form.Group>
                <Form.Label>Enviar desde</Form.Label>
                <Form.Control as="select">
                  <option>no-reply@domain.cl</option>
                </Form.Control>
              </Form.Group>

              <Form.Group>
                <Form.Label>Template</Form.Label>
                <Form.Control as="select">
                  <option>Bienvenido (curso)</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={8}>
              <Form.Group>
                <Form.Label>Destinatario</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="email destinatario"
                  value={task.student_tracking.email ?? ""}
                  onChange={() => {}}
                />
                <Form.Text className="text-muted">
                  Utilice comas para separar varios destinatarios.
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Mensaje</Form.Label>
                <Form.Control as="textarea" rows="8" />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Button
        className={`my-3 ${styles.callingSaveButton}`}
        variant="primary"
        onClick={saveTask}
      >
        {_.upperFirst(t("save"))}
      </Button>
    </Form>
  );
};

export default NEmailInfo;
