import styled from "styled-components";

const ScoreDesign = styled.div`
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : "10px 9.5px")};
  border: 1px solid ${(props) => (props.color ? props.color : "#e23232")};
  box-sizing: border-box;
  border-radius: 8px;
  font-size: var(--font-size-small);
  line-height: 120%;
  font-weight: bold;
  color: ${(props) => (props.color ? props.color : "#e23232")};
`;

export default ScoreDesign;
