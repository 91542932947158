import styled from "styled-components";
const NNameContrainerUnderline = styled.div`
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 0.3px;
  &:hover {
    color: var(--call-in-action-second) !important;
    text-decoration: underline;
  }
`;

export default NNameContrainerUnderline;
