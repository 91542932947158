import React from "react";
import _ from "lodash";

const PreviewAcademicProgress = ({ response }) => {
  return (
    <div>
      {Object.keys(response).map((keyItem, key) => {
        return (
          <div key={`${key}-text-multi-select`}>
            {response[keyItem]["course_name"]}
            {" - "}
            {_.upperFirst(response[keyItem]["response"])}{" "}
          </div>
        );
      })}
    </div>
  );
};

export default PreviewAcademicProgress;
