import styled from "styled-components";

export const StepTitle = styled.h1`
  font-family: Nova;
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-caption);
  line-height: 120%;

  letter-spacing: 1px;
  text-transform: uppercase;
  color: #334fff;
`;

export const TextTitle = styled.div`
  font-family: Nova;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #2a2b2e;
`;
export const TextQuestion = styled.div`
  font-family: Nova;
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => props.size ?? "16px"};
  line-height: 120%;
  color: #2a2b2e;
`;

export const ImgPosition = styled.img`
  position: absolute;
  right: 0;
  height: 340px;
`;
