import React from "react";
import { TaskComponents, AssociatedComponents } from "containers";
import { AssociatedList } from "containers/Associated";
import { NPagination } from "components";
import { useDispatch, useSelector } from "react-redux";
import { APP_MODULES, getSessionFilters } from "utils";
import { fetchAssociateds } from "store/actions/async/associated-async.actions";
import { Container } from "react-bootstrap";

const AssociatedsPage = () => {
  const dispatch = useDispatch();
  const {
    list: listAssociated,
    pending: { list: isPending },
    pagination,
  } = useSelector((state) => state.associated);

  const loadAssociateds = (pageNumber, pageSize) => {
    const filterAssociated = getSessionFilters(
      APP_MODULES.ASSOCIATED.FILTER_NAME
    );
    const filters = {
      ...filterAssociated,
      ...{ page: pageNumber, page_size: Number(pageSize) },
    };
    dispatch(fetchAssociateds(filters));
  };
  return (
    <div style={{ marginBottom: "30px" }}>
      <TaskComponents.Toolbar>
        <AssociatedComponents.FilterBar />
      </TaskComponents.Toolbar>

      <AssociatedList />
      {listAssociated && listAssociated.length > 0 && (
        <Container fluid>
          <NPagination
            isPending={isPending}
            pagination={pagination}
            onPageChange={loadAssociateds}
          />
        </Container>
      )}
    </div>
  );
};

export default AssociatedsPage;
