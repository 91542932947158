import React from "react";
import PropTypes from "prop-types";

import ResultItem from "./ResultItem";
import styles from "./Task.module.css";

const GroupedTasks = ({ visible, data, swModal, modalProfile }) => {
  const renderRows = () => {
    return data.map((task) => (
      <ResultItem
        key={`${task.id}_${task.student_tracking.student_id}`}
        row={task}
        swModal={swModal}
        swProfileModal={() => modalProfile(task)}
        hideCounter
      />
    ));
  };

  if (visible) {
    return <div className={styles.addPaddingX}>{renderRows()}</div>;
  }

  return null;
};

GroupedTasks.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  swModal: PropTypes.func.isRequired,
  modalProfile: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

GroupedTasks.defaultProps = {
  visible: false,
};

export default GroupedTasks;
