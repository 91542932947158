/* eslint-disable react/require-default-props */
import React, { useState, useCallback, useEffect } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Highlighter } from "react-bootstrap-typeahead";
import NInputFilter from "../../NInputFilter";
import { useTranslation } from "react-i18next";
import styles from "../../NSearchStudents/NSearchStudents.module.css";
import { callSearch } from "services/site.service";
import { getValueFilter } from "utils/filters";

const NSearch = ({
  filterKey,
  fieldName,
  onChange,
  // asyncSearch,
  persist = false,
  isDisabled = false,
  defaultSearch,
  compositeLabel = ["document_number", "first_name", "last_name"],
  customMenuItem,
  customInput,
  placeholder,
  urlSearch,
  attribSearch = "query",
  filterName,
  persistentSearch = true,
}) => {
  const { t } = useTranslation();
  const [filterCourse, setFilterCourse] = useState([]);

  useEffect(() => {
    if (defaultSearch && !_.isEmpty(defaultSearch)) {
      const array = [defaultSearch];
      setFilterCourse(array);
    } else {
      setFilterCourse([]);
    }
  }, [defaultSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterChanged = useCallback(
    (data) => {
      const [selected = {}] = data;
      setFilterCourse(data);
      onChange(selected);
    },
    [onChange]
  );

  const customMenuItemDefault = (option, { text }) => {
    return (
      <div className={styles.filterBody}>
        <div>
          <Highlighter
            search={text}
          >{`${option.first_name} ${option.last_name}`}</Highlighter>
        </div>
        <div>
          <small>ID: {option.external_id.toLocaleString()}</small>
        </div>
        <div>
          <small>RUT: {option.document_number.toLocaleString()}</small>
        </div>
      </div>
    );
  };

  const customInputDefault = (text) => {
    if (text !== null) {
      return text.toString().replaceAll(".", "");
    }
    return text;
  };

  const asyncSearch = useCallback(
    async (query) => {
      let data = {};
      if (persistentSearch) data = getValueFilter(filterName);
      const params = {
        ...data,
        page: 1,
        page_size: 100,
        [attribSearch]: query.trim(),
      };
      const response = await callSearch(urlSearch, params);
      let results;
      if (response.data) results = response.data.results;
      return Promise.resolve(results ?? []);
    },
    [urlSearch, attribSearch, filterName, persistentSearch]
  );

  return (
    <NInputFilter
      handleChange={handleFilterChanged}
      data={[]}
      delay={800}
      compositeLabel={compositeLabel}
      placeholder={placeholder ?? t("placeholder_search_student")}
      selected={filterCourse}
      renderMenuItem={customMenuItem ?? customMenuItemDefault}
      asyncSearch={(query) => asyncSearch(query)}
      isDisabled={isDisabled}
      isAsync
      formatInput={customInput ?? customInputDefault}
    />
  );
};

NSearch.propTypes = {
  /**
   * The name of the search field to add to the request header.
   *  i.e: first_name, last_name.
   * in case it's general search, this value should be: 'search' instead.
   */
  // filterKey: PropTypes.string.isRequired,

  /**
   * Name of the field from which the value is obtained in selected item.
   *  i.e: id, external_id, first_name, last_name... etc.
   * this value is persisted in the localstorage
   */
  //fieldName: PropTypes.string.isRequired,

  /**
   * function to execute when an item is selected.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * indicates whether the results persist in LocalStorage or not.
   */
  persist: PropTypes.bool,
};

export default NSearch;
