import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Modal } from "components";
import { BackgroundSuccess } from "assets/images";
import { NButton } from "components/Nimbiv2";
import { ButtonSize } from "utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-regular-svg-icons";

const IniciativeModal = ({
  user,
  profile,
  show,
  handleClose,
  buttonAction,
  textTitle = "¡Tu campaña fue creada con éxito!",
  description = "Puedes revisarla en la sección de campañas y hacer seguimiento al avance de tus gestiones.",
  textButton,
  showButtonClose = false,
}) => {
  const { t } = useTranslation();
  return (
    <ModalDesign
      centered
      show={show}
      onHide={handleClose}
      dialogClassName="modal-size"
      backdrop="static"
      keyboard={false}
    >
      <ModalBody>
        {showButtonClose && (
          <div
            style={{
              position: "absolute",
              right: "20px",
              fontSize: "20px",
              cursor: "pointer",
              top: "10px",
            }}
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={faClose} />
          </div>
        )}
        <div style={{ width: "400px" }}>
          <TitleModal>{textTitle}</TitleModal>
          <SubTitleModal>{description}</SubTitleModal>
          <div
            style={{
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              marginTop: "32px",
              fontSize: "var(--font-size-md)",
            }}
          >
            <NButton
              sizeStyle={ButtonSize.md}
              onClick={buttonAction}
              style={{ paddingLeft: "48px", paddingRight: "48px" }}
              color="second"
            >
              {textButton ? textButton : t("see_initiative")}
            </NButton>
          </div>
        </div>
      </ModalBody>
    </ModalDesign>
  );
};

export default IniciativeModal;

const ModalBody = styled(Modal.Body)`
  height: 446px;
  position: relative;
  border-radius: 16px !important;
  background: url(${BackgroundSuccess});
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ModalDesign = styled(Modal)`
  .modal-content {
    border-radius: 16px;
  }
`;

const TitleModal = styled.div`
  font-family: Nova;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 120%;
  text-align: center;
  color: #334fff;
`;

const SubTitleModal = styled.div`
  font-family: Nova;
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-lg);
  line-height: 140%;
  text-align: center;
  color: #2a2b2e;
  margin-top: 16px;
`;
