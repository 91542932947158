import React, { useEffect, useState } from "react";
import _ from "lodash";
import AddUser from "pages/Users/AddUser";
import EditUser from "pages/Users/EditUser";
import PageTeam from "pages/Team";
import UsersPage from "pages/Users";
import AssociatedsPage from "pages/Associateds";
import GenerationsPage from "pages/Generations";
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Tab } from "react-bootstrap";

import { NNavbarDashboard, ScrollToTopOnMount } from "components/Nimbiv2";
import { fetchTeams } from "store/actions/async/team-async.actions";
import { useDefault } from "hooks";
import { setActiveModule } from "store/actions/site.actions";
import { NTabs } from "components/Nimbiv2/styled";
import { APP_MODULES, setSessionFilters } from "utils";

const AdministrationPage = ({ action = "main" }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    setSessionFilters(APP_MODULES.TEAM.FILTER_NAME, {
      page: 1,
      page_size: 1000,
    });
    dispatch(fetchTeams());
  }, [dispatch]);
  if (action === "add") return <AddUser />;
  if (action === "edit") return <EditUser userID={id} />;

  return <MainPage />;
};

const MainPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { hash } = useLocation();
  const user = useSelector((state) => state.auth.user);

  const userModuleTitle = useDefault(
    "admModuleTitle",
    _.upperFirst(t("administration"))
  );
  const [tabSelect, setTabSelect] = useState(
    hash && hash !== "" ? hash.replace("#", "") : "users"
  );
  useEffect(() => {
    if (userModuleTitle)
      dispatch(setActiveModule({ title: userModuleTitle, subtitle: null }));
  }, [dispatch, userModuleTitle]);
  return (
    <>
      <ScrollToTopOnMount />
      <NNavbarDashboard />
      <div className="mt-5">
        <Container fluid>
          <NTabs
            activeKey={tabSelect}
            id="tabs-admin"
            className="mb-3"
            onSelect={(data) => {
              setTabSelect(data);
            }}
          >
            <Tab eventKey="users" title={_.upperFirst(t("users"))}></Tab>
            <Tab eventKey="teams" title={_.upperFirst(t("teams"))}></Tab>
            <Tab
              eventKey="officials"
              title={_.upperFirst(t("officials"))}
            ></Tab>
            {user.client_area_generation_events && (
              <Tab
                eventKey="generations"
                title={_.upperFirst(t("generations"))}
              ></Tab>
            )}
          </NTabs>
        </Container>
      </div>
      {tabSelect === "users" && (
        <div>
          <UsersPage />
        </div>
      )}
      {tabSelect === "teams" && <PageTeam />}
      {tabSelect === "officials" && <AssociatedsPage />}
      {tabSelect === "generations" && <GenerationsPage />}
    </>
  );
};

export default AdministrationPage;
