/* eslint-disable no-param-reassign */
import * as ExternalManagement from "../externalManagement.actions";
import { handleErrorStatus } from "../../../services/errors";

import {
  getExternalDetail,
  getExternalManagement,
} from "services/externalManagement.service";
import { APP_MODULES } from "utils";
import { getValueFilter } from "utils/filters";

const handleErrorResponse = (err) => {
  const status = err instanceof Map ? err.get("status") : 500;
  if (status > 0) return handleErrorStatus(status);
};

const fetchExternalManagement = (initialParams = null) => {
  return (dispatch) => {
    const queryParams = getValueFilter(
      APP_MODULES.EXTERNAL_MANAGEMENT.FILTER_NAME
    );
    const mergeParams = {
      ...queryParams,
      ...initialParams,
    };
    for (let x in mergeParams) {
      if (typeof mergeParams[x] == "object") {
        mergeParams[x] = mergeParams[x]["id"];
      }
    }
    dispatch(ExternalManagement.getExternalManagement(mergeParams));
    getExternalManagement(mergeParams)
      .then(({ data }) => {
        dispatch(ExternalManagement.setExternalManagement(data));
      })
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(ExternalManagement.registerError({ err, key: "list" }));
      });
  };
};

const fetchExternalDetail = (id) => {
  return (dispatch) => {
    dispatch(ExternalManagement.getExternalDetailTask());
    getExternalDetail(id)
      .then(({ data }) => {
        dispatch(ExternalManagement.setExternalDetailTask(data));
      })
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(ExternalManagement.registerError({ err, key: "list" }));
      });
  };
};

export { fetchExternalManagement, fetchExternalDetail };
