import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { toggleMarkItems } from "store/actions/student.actions";
import { useTranslation } from "react-i18next";
import { useTranslationLocal } from "hooks";

const SelectedInfoBar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const {
    list: students,
    pagination,
    wholePageMarked,
    markAllItems,
  } = useSelector((state) => state.student);

  const handleMarkAll = (sw = true) => {
    dispatch(toggleMarkItems("markAllItems", sw));
  };

  if (markAllItems) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <span
          dangerouslySetInnerHTML={{
            __html: t("select_all_student_action", {
              count: `<strong>${pagination.count}</strong>`,
            }),
          }}
        ></span>
        <Button size="sm" variant="light" onClick={() => handleMarkAll(false)}>
          {t("unselect")}
        </Button>
      </div>
    );
  }

  if (wholePageMarked) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <span>
          Fueron seleccionados <strong>{pagination.pageSize}</strong>{" "}
          {translation("students", "", t("students"))} en esta página.
        </span>
        <Button size="sm" variant="light" onClick={() => handleMarkAll(true)}>
          Seleccionar todos los <strong>{pagination.count}</strong>{" "}
          {translation("students", "", t("students"))}
        </Button>
      </div>
    );
  }

  const totalChecked = students.filter((student) => student.isChecked).length;

  return (
    <div className="d-flex justify-content-center align-items-center">
      {totalChecked === 0 ? (
        <span>
          <strong>
            {t("there_are_no_students_selected_yet", {
              students: translation("students", "", t("students")),
            })}
          </strong>
        </span>
      ) : (
        <span
          dangerouslySetInnerHTML={{
            __html: t("select_student_action", {
              count: `<strong>${totalChecked}</strong>`,
              students: translation("students", "", t("students")),
            }),
          }}
        ></span>
      )}
    </div>
  );
};

export default React.memo(SelectedInfoBar);
