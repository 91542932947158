import React from "react";
import _ from "lodash";
import {
  faArrowCircleDown,
  faArrowCircleUp,
  faMinusCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ScoreDesign } from "components/Nimbiv2/styled";
import { useTranslation } from "react-i18next";

const Score = ({ item, showText = true }) => {
  const { t } = useTranslation();

  if (item.priority_score === null) {
    return <div>{t("no_information")}</div>;
  }
  if (item.priority_score <= 100 && item.priority_score >= 70) {
    return (
      <ScoreDesign
        className="d-flex justify-content-center"
        color="#E23232"
        padding="3.5px 6px"
      >
        <FontAwesomeIcon
          icon={faArrowCircleUp}
          className="mr-1"
          style={{ fontSize: "11px" }}
        />
        {showText ? _.upperFirst(t("high")) : ""} {item.priority_score}%
      </ScoreDesign>
    );
  }
  if (item.priority_score < 70 && item.priority_score >= 40) {
    return (
      <ScoreDesign
        className="d-flex justify-content-center"
        color="#E0BC0B"
        padding="3.5px 6px"
      >
        <FontAwesomeIcon
          icon={faMinusCircle}
          className="mr-1"
          style={{ fontSize: "11px" }}
        />
        {showText ? _.upperFirst(t("medium")) : ""} {item.priority_score}%
      </ScoreDesign>
    );
  }
  if (item.priority_score < 40 && item.priority_score >= 0) {
    return (
      <ScoreDesign
        className="d-flex justify-content-center"
        color="#00B098"
        padding="3.5px 6px"
      >
        <FontAwesomeIcon
          icon={faArrowCircleDown}
          className="mr-1"
          style={{ fontSize: "11px" }}
        />
        {showText ? _.upperFirst(t("low")) : ""} {item.priority_score}%
      </ScoreDesign>
    );
  }
  return <div>{_.upperFirst(t("no_information"))}</div>;
};

export default Score;
