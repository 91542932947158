import React from "react";

const EmpTyReport = () => {
  return (
    <svg
      width="287"
      height="239"
      viewBox="0 0 287 239"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="143.5" cy="98.9847" rx="100" ry="98.4407" fill="#E8F0FC" />
      <path
        d="M247.923 80.8199C252.079 80.8199 255.448 77.5874 255.448 73.5999C255.448 69.6124 252.079 66.3799 247.923 66.3799C243.767 66.3799 240.397 69.6124 240.397 73.5999C240.397 77.5874 243.767 80.8199 247.923 80.8199Z"
        fill="#E4ECFC"
      />
      <g filter="url(#filter0_d_2054_5040)">
        <path
          d="M173.858 175.82L79.1984 212.49C77.6142 213.06 75.8319 212.3 75.2378 210.97L20.7787 81.2001C20.1846 79.6801 20.9767 77.9701 22.3629 77.4001L117.023 40.7301C118.607 40.1601 120.389 40.9201 120.983 42.2501L175.443 171.83C176.235 173.54 175.443 175.25 173.858 175.82Z"
          fill="white"
        />
      </g>
      <path
        opacity="0.3"
        d="M132.073 99.0605L52.8598 129.651C52.0677 130.031 51.2756 129.651 50.8795 128.891C50.4834 128.131 50.8795 127.371 51.6716 126.991L130.687 96.4005C131.479 96.0205 132.271 96.4005 132.667 97.1605C133.063 97.9205 132.667 98.6805 132.073 99.0605Z"
        fill="#DDE6F8"
      />
      <path
        opacity="0.3"
        d="M135.044 106.47L56.0288 137.06C55.2366 137.44 54.4445 137.06 54.0484 136.3C53.6524 135.54 54.0484 134.78 54.8406 134.4L133.856 103.81C134.648 103.43 135.44 103.81 135.836 104.57C136.232 105.33 135.836 106.09 135.044 106.47Z"
        fill="#DDE6F8"
      />
      <path
        opacity="0.3"
        d="M138.212 113.69L59.1972 144.47C58.4051 144.85 57.613 144.47 57.2169 143.71C56.8208 142.95 57.2169 142.19 58.009 141.81L137.024 111.22C137.816 110.84 138.609 111.22 139.005 111.98C139.401 112.55 139.005 113.5 138.212 113.69Z"
        fill="#DDE6F8"
      />
      <path
        opacity="0.3"
        d="M92.0707 140.29L62.3657 151.88C61.5736 152.26 60.7814 151.88 60.3854 151.12C59.9893 150.36 60.3854 149.6 61.1775 149.22L90.8825 137.63C91.6746 137.25 92.4667 137.63 92.8628 138.39C93.0608 139.15 92.8628 139.91 92.0707 140.29Z"
        fill="#DDE6F8"
      />
      <path
        opacity="0.3"
        d="M146.59 127.381L163.164 167.651C163.56 168.791 163.164 170.121 161.976 170.501L83.753 200.901C82.5648 201.281 81.1786 200.901 80.7825 199.761L64.2081 159.491C63.812 158.351 64.2081 157.021 65.3963 156.641L143.619 126.431C144.808 125.861 146.194 126.431 146.59 127.381Z"
        fill="#E4ECFC"
      />
      <g opacity="0.3">
        <path
          d="M80.5848 92.6001C80.3868 82.1501 71.4753 73.6001 60.3854 73.6001H60.1874C49.0975 73.6001 40.186 82.3401 40.186 92.9801C40.3841 103.62 49.4936 112.17 60.5834 112.17C66.7225 112.17 72.0694 109.51 75.832 105.52C79.0005 102.1 80.7828 97.7301 80.7828 92.7901C80.5848 92.7901 80.5848 92.7901 80.5848 92.6001ZM60.5834 106.09C53.0582 106.09 46.9192 100.2 46.9192 92.9801C46.9192 85.7601 53.0582 79.8701 60.5834 79.8701H60.7815C68.3067 79.8701 74.2477 85.5701 74.4458 92.6001C74.4458 92.7901 74.4458 92.7901 74.4458 92.9801C74.4458 96.2101 73.2576 99.2501 71.0792 101.53C68.3067 104.38 64.7421 106.09 60.5834 106.09Z"
          fill="#D9E1F2"
        />
        <path
          d="M80.5846 92.6001H74.0495C74.0495 85.5701 67.9105 79.8701 60.5833 79.8701H60.3853V73.6001H60.5833C71.4751 73.6001 80.3866 82.1501 80.5846 92.6001Z"
          fill="#F6FAFE"
        />
      </g>
      <g filter="url(#filter1_d_2054_5040)">
        <path
          d="M212.475 205.27L116.825 171.07C115.24 170.5 114.448 168.79 115.042 167.27L165.739 36.3597C166.333 34.8397 168.115 34.0797 169.7 34.6497L264.868 68.6597C266.453 69.2297 266.726 71.9397 266.132 73.4597L216.435 203.56C215.841 205.08 214.059 205.84 212.475 205.27Z"
          fill="white"
        />
      </g>
      <path
        opacity="0.3"
        d="M199.801 93.5496L192.078 90.6996L198.415 73.7896L206.138 76.4496L199.801 93.5496Z"
        fill="#DDE6F8"
      />
      <path
        opacity="0.3"
        d="M210.296 97.1597L202.375 94.3097L212.079 68.4697L219.802 71.3197L210.296 97.1597Z"
        fill="#D9E1F2"
      />
      <path
        opacity="0.3"
        d="M231.09 104.19L223.367 101.53L236.041 67.5195L243.962 70.1795L231.09 104.19Z"
        fill="#D9E1F2"
      />
      <path
        opacity="0.4"
        d="M240.992 107.61L233.269 104.95L236.635 95.6396L244.556 98.4896L240.992 107.61Z"
        fill="#DDE6F8"
      />
      <path
        opacity="0.3"
        d="M235.447 121.479L155.442 92.9795C154.649 92.7895 154.253 91.8395 154.451 91.0795C154.649 90.3195 155.64 89.9395 156.432 90.1295L236.437 118.629C237.229 118.819 237.625 119.769 237.427 120.529C237.031 121.289 236.239 121.669 235.447 121.479Z"
        fill="#DDE6F8"
      />
      <path
        opacity="0.3"
        d="M232.476 128.89L152.471 100.39C151.679 100.2 151.283 99.2496 151.481 98.4896C151.679 97.7296 152.669 97.3496 153.461 97.5396L233.466 126.04C234.258 126.23 234.655 127.18 234.457 127.94C234.258 128.7 233.268 129.27 232.476 128.89Z"
        fill="#DDE6F8"
      />
      <path
        opacity="0.3"
        d="M229.704 136.3L149.698 107.8C148.906 107.61 148.51 106.66 148.708 105.9C148.906 105.14 149.896 104.76 150.689 104.95L230.694 133.45C231.486 133.64 231.882 134.59 231.684 135.35C231.288 136.3 230.496 136.68 229.704 136.3Z"
        fill="#DDE6F8"
      />
      <path
        opacity="0.3"
        d="M226.931 144.423L146.926 115.923C146.134 115.733 145.738 114.783 145.936 114.023C146.134 113.263 147.124 112.883 147.916 113.073L227.921 141.573C228.714 141.763 229.11 142.713 228.912 143.473C228.516 144.423 227.723 144.803 226.931 144.423Z"
        fill="#DDE6F8"
      />
      <path
        d="M177.027 126.04L146.926 115.4C146.134 115.21 145.738 114.26 145.936 113.5C146.134 112.74 147.124 112.36 147.916 112.55L178.017 123.19C178.809 123.38 179.205 124.33 179.007 125.09C178.611 125.85 177.819 126.23 177.027 126.04Z"
        fill="#EDF2FD"
      />
      <path
        opacity="0.3"
        d="M224.159 156.82L221.782 163.28C221.386 164.42 220 164.99 218.812 164.61L201.781 158.53L139.599 136.3C138.411 135.92 137.816 134.59 138.213 133.45L140.589 126.99C140.985 125.85 142.371 125.28 143.559 125.66L205.346 147.7L222.575 153.78C223.961 154.54 224.555 155.68 224.159 156.82Z"
        fill="#DDE6F8"
      />
      <path
        opacity="0.3"
        d="M218.416 171.45L216.04 177.91C215.643 179.05 214.257 179.62 213.069 179.24L207.326 177.15L134.054 151.12C132.866 150.74 132.272 149.41 132.668 148.27L135.044 141.81C135.44 140.67 136.826 140.1 138.015 140.48L211.485 166.7L217.03 168.79C218.218 169.17 218.812 170.31 218.416 171.45Z"
        fill="#DDE6F8"
      />
      <path
        opacity="0.3"
        d="M212.871 186.08L210.494 192.54C210.098 193.68 208.712 194.25 207.524 193.87L178.215 183.42L128.508 165.75C127.32 165.37 126.726 164.04 127.122 162.9L129.499 156.44C129.895 155.3 131.281 154.73 132.469 155.11L183.364 173.16L211.484 183.23C212.672 183.61 213.267 184.94 212.871 186.08Z"
        fill="#DDE6F8"
      />
      <path
        d="M158.412 131.17L154.451 141.62L139.599 136.3C138.411 135.92 137.816 134.59 138.213 133.45L140.589 126.99C140.985 125.85 142.371 125.28 143.559 125.66L158.412 131.17Z"
        fill="#EDF2FD"
      />
      <path
        d="M138.015 140.48L152.867 145.8L148.906 156.25L134.054 150.93C132.866 150.55 132.272 149.22 132.668 148.08L135.044 141.62C135.638 140.67 136.826 140.1 138.015 140.48Z"
        fill="#EDF2FD"
      />
      <path
        d="M132.469 155.11L147.322 160.43L143.361 170.88L128.508 165.56C127.32 165.18 126.726 163.85 127.122 162.71L129.499 156.25C129.895 155.3 131.281 154.73 132.469 155.11Z"
        fill="#EDF2FD"
      />
      <g filter="url(#filter2_d_2054_5040)">
        <path
          d="M195.444 173.54H93.4568C91.8726 173.54 90.4863 172.21 90.4863 170.69V30.85C90.4863 29.33 91.8726 28 93.4568 28H195.444C197.028 28 198.414 29.33 198.414 30.85V170.5C198.612 172.21 197.226 173.54 195.444 173.54Z"
          fill="white"
        />
      </g>
      <path
        d="M186.532 81.7682H101.378C100.586 81.7682 99.7939 81.1986 99.7939 80.2491C99.7939 79.4895 100.388 78.73 101.378 78.73H186.532C187.325 78.73 188.117 79.2997 188.117 80.2491C188.117 81.0087 187.325 81.7682 186.532 81.7682Z"
        fill="#DDE6F8"
      />
      <path
        d="M186.532 89.5583H101.378C100.586 89.5583 99.7939 88.9886 99.7939 88.0391C99.7939 87.2796 100.388 86.52 101.378 86.52H186.532C187.325 86.52 188.117 87.0897 188.117 88.0391C188.117 88.9886 187.325 89.5583 186.532 89.5583Z"
        fill="#DDE6F8"
      />
      <path
        d="M186.532 97.5382H101.378C100.586 97.5382 99.7939 96.9686 99.7939 96.0191C99.7939 95.2596 100.388 94.5 101.378 94.5H186.532C187.325 94.5 188.117 95.0697 188.117 96.0191C188.117 96.9686 187.325 97.5382 186.532 97.5382Z"
        fill="#DDE6F8"
      />
      <path
        d="M186.334 127.368H157.619C156.827 127.368 156.035 126.799 156.035 125.849C156.035 125.09 156.629 124.33 157.619 124.33H186.334C187.126 124.33 187.918 124.9 187.918 125.849C187.72 126.609 187.126 127.368 186.334 127.368Z"
        fill="#DDE6F8"
      />
      <path
        d="M139.994 135.54C139.796 125.09 130.885 116.54 119.795 116.54H119.597C108.507 116.54 99.5957 125.28 99.5957 135.92C99.5957 146.56 108.705 155.3 119.795 155.3C125.934 155.3 131.281 152.64 135.044 148.65C138.212 145.23 139.994 140.86 139.994 135.92C139.994 135.73 139.994 135.54 139.994 135.54ZM119.795 148.84C112.27 148.84 106.131 142.95 106.131 135.73C106.131 128.51 112.27 122.62 119.795 122.62H119.993C127.518 122.62 133.459 128.32 133.657 135.35C133.657 135.54 133.657 135.54 133.657 135.73C133.657 138.96 132.469 142 130.291 144.28C127.518 147.13 123.954 148.84 119.795 148.84Z"
        fill="#E8F0FC"
      />
      <path
        d="M139.995 135.54H133.459C133.261 128.51 127.32 122.81 119.795 122.81H119.597V116.54H119.795C130.885 116.54 139.797 124.9 139.995 135.54Z"
        fill="#D9E1F2"
      />
      <path
        d="M139.994 135.73C139.994 140.67 138.212 145.04 135.044 148.46L130.093 144.47C132.271 142.19 133.459 139.15 133.459 135.92C133.459 135.73 133.459 135.73 133.459 135.54H139.994V135.73Z"
        fill="#F6FAFE"
      />
      <path
        d="M150.688 127.94C151.891 127.94 152.867 127.004 152.867 125.85C152.867 124.695 151.891 123.76 150.688 123.76C149.485 123.76 148.51 124.695 148.51 125.85C148.51 127.004 149.485 127.94 150.688 127.94Z"
        fill="#D9E1F2"
      />
      <path
        d="M186.334 136.108H157.619C156.827 136.108 156.035 135.538 156.035 134.589C156.035 133.829 156.629 133.07 157.619 133.07H186.334C187.126 133.07 187.918 133.639 187.918 134.589C187.72 135.349 187.126 136.108 186.334 136.108Z"
        fill="#DDE6F8"
      />
      <path
        d="M150.688 136.68C151.891 136.68 152.867 135.744 152.867 134.59C152.867 133.436 151.891 132.5 150.688 132.5C149.485 132.5 148.51 133.436 148.51 134.59C148.51 135.744 149.485 136.68 150.688 136.68Z"
        fill="#DDE6F8"
      />
      <path
        d="M186.334 144.848H157.619C156.827 144.848 156.035 144.279 156.035 143.329C156.035 142.57 156.629 141.81 157.619 141.81H186.334C187.126 141.81 187.918 142.38 187.918 143.329C187.72 144.089 187.126 144.848 186.334 144.848Z"
        fill="#DDE6F8"
      />
      <path
        d="M150.688 145.42C151.891 145.42 152.867 144.485 152.867 143.33C152.867 142.176 151.891 141.24 150.688 141.24C149.485 141.24 148.51 142.176 148.51 143.33C148.51 144.485 149.485 145.42 150.688 145.42Z"
        fill="#EDF2FD"
      />
      <path
        d="M100.388 71.3201H187.918V37.3101L174.056 43.9601L166.729 48.9001L153.659 48.7101L142.173 54.4101L132.271 55.7401L128.706 59.5401L118.409 65.8101L108.309 67.5201L100.388 71.3201Z"
        fill="#EDF2FD"
      />
      <path
        d="M100.388 71.32H187.918V45.48L174.056 50.42L166.729 54.22L153.659 54.03L142.173 58.4L132.271 59.54L128.706 62.2L118.409 67.14L108.309 68.47L100.388 71.32Z"
        fill="#E4ECFC"
      />
      <path
        d="M100.388 71.3199H187.918V56.6899L174.056 59.5399L166.729 61.6299H153.659L142.173 63.9099L132.271 64.6699L128.706 66.1899L118.409 68.8499L108.309 69.6099L100.388 71.3199Z"
        fill="#D9E1F2"
      />
      <defs>
        <filter
          id="filter0_d_2054_5040"
          x="0.579102"
          y="26.5386"
          width="195.174"
          height="212.143"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.690196 0 0 0 0 0.729412 0 0 0 0 0.8 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2054_5040"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2054_5040"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_2054_5040"
          x="94.8452"
          y="20.4604"
          width="191.576"
          height="210.999"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.690196 0 0 0 0 0.729412 0 0 0 0 0.8 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2054_5040"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2054_5040"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_2054_5040"
          x="70.4863"
          y="14"
          width="147.947"
          height="185.54"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.690196 0 0 0 0 0.729412 0 0 0 0 0.8 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2054_5040"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2054_5040"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default EmpTyReport;
