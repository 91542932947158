/* eslint-disable react/require-default-props */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Highlighter } from "react-bootstrap-typeahead";
import NInputFilter from "../NInputFilter";
import { replaceChars, capitalizeWord } from "utils";
import { useTranslation } from "react-i18next";
import ContainerInputFilter from "components/NFormFilter/Components/ContainerInputFilter";
import { VERSIONS_FILTERS_DESIGN } from "utils/constants";

const NSimpleSearch = ({
  onChange,
  isMultiple = false,
  isDisabled = false,
  dataset = { values: [], subtype_component: null },
  initialValue = null,
  meta = {},
  canAutoFit = false,
  versionInput,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    // if (isDisabled) setItemsSelected([]);
  }, [isDisabled]);
  const customMenuItem = (option, { text }) => {
    return (
      <div>
        <Highlighter search={text}>{option}</Highlighter>
      </div>
    );
  };

  const customMenuItemObject = (option, { text }) => {
    return (
      <div>
        <Highlighter search={text}>{option.name}</Highlighter>
      </div>
    );
  };

  const handleFilterChanged = (data) => {
    let selected = data;
    if (!isMultiple) selected = data.length > 0 ? data[0] : null;
    if (isMultiple && data.length === 0) selected = null;
    const x = dataset["filter_field"];
    onChange(x, selected, dataset);
  };

  const validateIsNumber = useMemo(() => {
    if (dataset?.values) {
      if (dataset?.values.length > 0) {
        return typeof dataset.values[0] === "number";
      }
    }
    return false;
  }, [dataset]);

  return (
    <ContainerInputFilter
      label={capitalizeWord(replaceChars(dataset.label, "_", " "))}
      versionInput={versionInput}
    >
      <NInputFilter
        handleChange={handleFilterChanged}
        data={
          validateIsNumber
            ? dataset.values.map((item) => String(item))
            : dataset.values
        }
        placeholder={
          versionInput === VERSIONS_FILTERS_DESIGN.v2
            ? t("all")
            : capitalizeWord(replaceChars(dataset.label, "_", " "))
        }
        selected={
          isMultiple
            ? initialValue
              ? initialValue
              : []
            : initialValue
            ? [initialValue]
            : []
        }
        renderMenuItem={
          dataset.type_component === "select_object"
            ? customMenuItemObject
            : customMenuItem
        }
        isMultiple={isMultiple}
        isDisabled={isDisabled}
        canAutoFit={canAutoFit}
        compositeLabel={
          dataset.type_component === "select_object" ? ["name"] : []
        }
      />
    </ContainerInputFilter>
  );
};

NSimpleSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  isMultiple: PropTypes.bool,
  isDisabled: PropTypes.bool,
  canAutoFit: PropTypes.bool,
  dataset: PropTypes.shape({}),
  //initialValue: PropTypes.arrayOf(PropTypes.string),
  meta: PropTypes.shape({}),
};

export default NSimpleSearch;
