import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLightbulb,
  faFileInvoice,
  faClone,
  faPhoneAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { setRowInfo } from "store/actions/crm.actions";
import { toggleSidebar } from "store/actions/site.actions";
import copy from "copy-to-clipboard";
import styles from "./Task.module.css";
import { AnonymousContainer } from "components/Nimbiv2/styled";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { callNeotel } from "services/taskService";

const CallingActionHeader = ({ task, contactReason }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectNumber, setSelectNumber] = useState(null);
  const [selectNumberID, setSelectNumberID] = useState(null);
  const { user } = useSelector((state) => state.auth);
  useState(() => {
    if (task && task.student_tracking && task.student_tracking.phones) {
      if (task.student_tracking.phones.length > 0) {
        setSelectNumber(task.student_tracking.phones[0].value);
        setSelectNumberID(task.student_tracking.phones[0].id);
      }
    }
  }, [task]);
  const copyPhoneNumber = (phoneNumber) => {
    copy(`${phoneNumber}`);
    toast.info(`Copiado: ${phoneNumber}`);
  };

  const openScriptSidebar = () => {
    if (!task.script_template) return false;
    dispatch(setRowInfo(task));
    dispatch(toggleSidebar(true, "isScriptSidebarOpen"));
  };
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      style={{ color: "var(--call-in-action)", cursor: "pointer" }}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </div>
  ));

  const callEndpointNeotel = useCallback(async () => {
    if (
      !(
        user.neotel_provider &&
        user.neotel_provider.user_neotel &&
        user.neotel_provider.client_area_neotel
      )
    ) {
      toast.error(t("user_config_neotel"));
    } else {
      const { status } = await callNeotel(selectNumber);
      if (status === 200) {
        toast.success(t("neotel_success"));
      } else if (status === 401) {
        toast.error(t("neotel_not_configuration"));
      } else if (status === 403) {
        toast.error(t("error_triggering"));
      } else {
        toast.error(t("error_action"));
      }
    }
  }, [selectNumber, t, user]);

  return (
    <>
      <Col className="d-flex justify-content-center align-items-center">
        <FontAwesomeIcon className="mr-2" size="1x" icon={faPhoneAlt} />
        <span className={styles.callActionTitle}>{t("call_result")}</span>
      </Col>
      <Col>
        <div
          className={`d-flex align-items-center ${styles.callingActionReasonBlock}`}
        >
          <div className={`mr-2 ${styles.circle}`}>
            <FontAwesomeIcon
              style={{ width: 24 }}
              className={`${styles.lightBulbIcon}`}
              size="1x"
              icon={faLightbulb}
            />
          </div>
          <div>{contactReason}</div>
        </div>
      </Col>
      <Col
        md={4}
        className="d-flex justify-content-end align-items-center m-0 p-0"
      >
        <div className="mr-4">
          <p
            className={styles.copyPhoneNumber}
            onClick={openScriptSidebar}
            aria-hidden="true"
          >
            {t("call_pattern")}
            <FontAwesomeIcon className="ml-2" size="1x" icon={faFileInvoice} />
          </p>
        </div>
        <AnonymousContainer isAnonymous={user.anonymous}>
          <div className={styles.phoneContactBlock} style={{ display: "flex" }}>
            {!(
              user &&
              user.neotel_provider &&
              (user.neotel_provider.user_neotel ||
                user.neotel_provider.client_area_neotel)
            ) && (
              <>
                <p style={{ margin: "0px" }}>
                  <a className="secondary_color" href={`tel:${selectNumber}`}>
                    {selectNumber}
                  </a>
                </p>
                <a className="secondary_color" href={`callto:${selectNumber}`}>
                  <FontAwesomeIcon
                    className="ml-2"
                    size="1x"
                    icon={faPhoneAlt}
                  />
                </a>
              </>
            )}
            {user &&
              user.neotel_provider &&
              (user.neotel_provider.user_neotel ||
                user.neotel_provider.client_area_neotel) && (
                <>
                  <p style={{ margin: "0px" }}>
                    <span
                      style={{ cursor: "pointer" }}
                      className="secondary_color"
                      onClick={callEndpointNeotel}
                    >
                      {selectNumber}
                    </span>
                  </p>
                  <span
                    className="secondary_color"
                    onClick={callEndpointNeotel}
                    style={{ cursor: "pointer" }}
                  >
                    <FontAwesomeIcon
                      className="ml-2"
                      size="1x"
                      icon={faPhoneAlt}
                    />
                  </span>
                </>
              )}
            <OverlayTrigger overlay={<Tooltip>{t("copy_phone")}</Tooltip>}>
              <FontAwesomeIcon
                onClick={() => copyPhoneNumber(selectNumber)}
                className={`ml-2 ${styles.copyPhoneNumber}`}
                size="1x"
                icon={faClone}
              />
            </OverlayTrigger>
            {task.student_tracking.phones &&
              task.student_tracking.phones.length > 1 && (
                <Dropdown style={{ marginLeft: "8px" }}>
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-components"
                  />

                  <Dropdown.Menu>
                    {task.student_tracking.phones.map((item) => (
                      <Dropdown.Item
                        key={`items-numbers-${item.id}`}
                        active={item.id === selectNumberID}
                        onClick={() => {
                          setSelectNumber(item.value);
                          setSelectNumberID(item.id);
                        }}
                      >
                        {item.value}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
          </div>
        </AnonymousContainer>
      </Col>
    </>
  );
};

export default CallingActionHeader;
