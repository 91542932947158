import { ModalConfirm, NTable } from "components/Nimbiv2";
import {
  AnonymousContainer,
  CardNimbi,
  NButtonCircle,
} from "components/Nimbiv2/styled";
import { useTranslationLocal } from "hooks";
import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import styles from "../User/User.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { NBadge } from "components";
import { fetchAssociateds } from "store/actions/async/associated-async.actions";
import { deactivateAssociated } from "services/user.service";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { ButtonSize } from "utils/constants";

const AssociatedList = () => {
  const { translation } = useTranslationLocal();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const [visible, setVisible] = useState(false);
  const [item, setItem] = useState(null);
  const {
    list: listAssociated,
    pending: { list: isPending },
  } = useSelector((state) => state.associated);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAssociateds());
  }, [dispatch]);

  const onInactive = (item) => {
    setVisible(true);
    setItem(item);
  };

  const columns = [
    {
      title: translation("official", ""),
      key: "Official",
      fixed: true,
      style: { left: "0px" },
      width: "30%",
      render: (item) => {
        return (
          <div
            className={`d-flex justify-content-left align-items-center ${styles.firstColumn}`}
          >
            <div className="d-flex flex-column justify-content-left align-items-center">
              <div
                className={`${styles.headerTitle}`}
                style={{
                  fontWeight: 500,
                  letterSpacing: "0.3px",
                }}
              >
                <AnonymousContainer isAnonymous={user.anonymous}>
                  {item.name}{" "}
                </AnonymousContainer>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: translation("role", "upper"),
      key: "role",
      width: "20%",
      render: (item) => (
        <span className={`capitalize-text ${styles.rowText}`}>{item.role}</span>
      ),
    },
    {
      title: translation("tracking_object", "upper", "Curso"),
      key: "tracking_object",
      width: "20%",
      render: (item) => (
        <span className={`capitalize-text ${styles.rowText}`}>
          <AnonymousContainer isAnonymous={user.anonymous}>
            {item.tracking_object}{" "}
          </AnonymousContainer>
        </span>
      ),
    },
    {
      title: translation("mail", "upper"),
      key: "mail",
      width: "20%",
      render: (item) => (
        <span className={`mr-1 capitalize-text ${styles.rowText}`}>
          <AnonymousContainer isAnonymous={user.anonymous}>
            <NBadge className={styles.badgeText} text={item.email} />
          </AnonymousContainer>
        </span>
      ),
    },
    {
      title: translation("phone", "upper"),
      key: "phone",
      width: "20%",
      render: (item) => (
        <span className={`capitalize-text ${styles.rowText}`}>
          <AnonymousContainer isAnonymous={user.anonymous}>
            {item?.phone && item.phone !== ""
              ? item.phone
              : t("no_information_line")}{" "}
          </AnonymousContainer>
        </span>
      ),
    },
    {
      title: translation("action", "upper"),
      key: "mail",
      width: "10%",
      fixed: true,
      style: { right: "0px" },
      render: (item) => {
        return (
          <div>
            <NButtonCircle
              color="danger"
              sizeStyle={ButtonSize.lg}
              onClick={() => onInactive(item)}
              icon={
                <FontAwesomeIcon icon={faTrash} style={{ marginTop: "4px" }} />
              }
            />
          </div>
        );
      },
    },
  ];
  const deAssociated = useCallback(async () => {
    const data = await deactivateAssociated({ id: item?.id });
    if (data.status === 200) {
      toast.success(t("official_successfully_eliminated"));
      setVisible(false);
      dispatch(fetchAssociateds());
    } else {
      toast.error(t("error_action"));
    }
  }, [item, dispatch, t]);

  return (
    <Container fluid style={{ paddingBottom: "20px", marginTop: "11.5px" }}>
      <CardNimbi
        padding="0px 16px 16px 16px"
        style={{ height: "auto", borderRadius: "8px" }}
      >
        <NTable
          widthSkeletons="100%"
          columns={columns}
          dataSource={listAssociated}
          isPending={isPending}
          upperCaseHeader
        ></NTable>
      </CardNimbi>
      <ModalConfirm
        isVisible={visible}
        onCancel={() => setVisible(false)}
        title={t("text_remove_official", { official: item?.name })}
        onAccept={deAssociated}
        titleApproved={t("btn_remove_official")}
        colorApproved="danger"
      />
    </Container>
  );
};

export default AssociatedList;
