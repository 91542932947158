import React, { useEffect, useState } from "react";
import _ from "lodash";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Formik } from "formik";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NTextArea, NBlockContent, NButton } from "components/Nimbiv2";
import { RowItem } from "components/Nimbiv2/styled";
import { getOptionsExternalManagement } from "services/externalManagement.service";
import { Channels } from "utils/constants";
import { getFormConfig } from "utils";
import styled from "styled-components";

const FormExternalManagement = ({
  formValues,
  onFormSubmit,
  isDisabled = false,
  isEditing = false,
  fetchUser = () => {},
  isEdit = false,
  slide = false,
  onClose = () => {},
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(null);
  const [options, setOptions] = useState({
    channels: [],
    states: [],
    topics: [],
    types: [],
    subtopics: [],
  });
  const [loadingOptions, setLoadingOption] = useState(false);

  const getOptions = async () => {
    setLoadingOption(true);
    const { data } = await getOptionsExternalManagement();
    setOptions(data);
    setLoadingOption(false);
  };
  useEffect(() => {
    getOptions();
  }, []);

  const validate = (values) => {
    const errors = {};
    // if (!values.student_tracking) {
    //   errors.student_tracking = "Campo requerido";
    // }
    if (!values.closed_at) {
      errors.closed_at = "Campo requerido";
      return errors;
    }
    if (!values.management_type) {
      errors.management_type = "Campo requerido";
      return errors;
    }
    if (!values.management_topic) {
      errors.management_topic = "Campo requerido";
      return errors;
    }
    if (!values.channel) {
      errors.channel = "Campo requerido";
      return errors;
    }
    if (!values.management_state) {
      errors.management_state = "Campo requerido";
      return errors;
    }
    if (!values.comment) {
      errors.comment = "Campo requerido";
      return errors;
    }
    return errors;
  };

  const showError = (fieldName, errors) => {
    return (
      <Form.Text className="text-danger" style={{ marginTop: "5px" }}>
        {errors[fieldName]}
      </Form.Text>
    );
  };

  return (
    <div style={{ height: "100%" }}>
      {/* <form onSubmit={formik.handleSubmit}> */}
      <Formik
        initialValues={formValues}
        validate={validate}
        onSubmit={onFormSubmit}
        enableReinitialize
      >
        {({
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          values,
          resetForm,
        }) => (
          <div
            style={{ height: "100%", flexDirection: "column" }}
            className="d-flex"
          >
            <div style={{ height: "100%" }}>
              <RowItem>
                <Col md="12" style={{ marginTop: "10px" }}></Col>
                <Col sm={slide ? "6" : "4"} md="6" lg={slide ? "6" : "4"}>
                  <Form.Group controlId="closed_at">
                    <NBlockContent title={_.upperFirst(t("date"))}>
                      <DatePicker
                        style={{ border: "none" }}
                        className="form-control"
                        dateFormat="yyyy-MM-dd"
                        selected={date}
                        maxDate={new Date()}
                        placeholderText={t("select_object", {
                          obj: t("date"),
                        })}
                        disabled={loadingOptions}
                        onChange={(date) => {
                          setDate(date);
                          if (date) {
                            setFieldValue(
                              "closed_at",
                              moment(date).format("YYYY-MM-DD")
                            );
                          }
                        }}
                      />
                    </NBlockContent>
                    {showError("closed_at", errors)}
                  </Form.Group>
                </Col>
                <Col sm={slide ? "6" : "4"} md="6" lg={slide ? "6" : "4"}>
                  <Form.Group controlId="management_type">
                    <NBlockContent title={_.upperFirst(t("type"))}>
                      <Form.Control
                        as="select"
                        name="management_type"
                        onChange={handleChange}
                        placeholder={"Tipo"}
                        value={values?.management_type ?? ""}
                        disabled={loadingOptions}
                        style={{ border: "none" }}
                      >
                        <option>
                          {t("select_object", { obj: _.toLower(t("type")) })}
                        </option>
                        {options.types &&
                          options.types.map((item, key) => {
                            return (
                              <option
                                key={`option-types-${key}`}
                                value={item.id}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </NBlockContent>
                    {showError("management_type", errors)}
                  </Form.Group>
                </Col>
                <Col sm={slide ? "6" : "4"} md="6" lg={slide ? "6" : "4"}>
                  <Form.Group controlId="management_topic">
                    <NBlockContent title={_.upperFirst(t("topic"))}>
                      <Form.Control
                        as="select"
                        name="management_topic"
                        onChange={(data) => {
                          handleChange(data);
                          setFieldValue("with_notification", false, false);
                          setFieldValue("notification_email", null, false);
                          if (
                            getFormConfig(
                              "form_external_management",
                              "show_subtopic"
                            )
                          ) {
                            setFieldValue("management_subtopic", null, false);
                          }
                        }}
                        placeholder={"Tema"}
                        value={values?.management_topic ?? ""}
                        disabled={loadingOptions}
                        style={{ border: "none" }}
                      >
                        <option>
                          {t("select_object", { obj: t("topic") })}
                        </option>
                        {options.topics &&
                          options.topics.map((item, key) => {
                            return (
                              <option
                                key={`option-topic-${key}`}
                                value={item.id}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </NBlockContent>
                    {showError("management_topic", errors)}
                  </Form.Group>
                </Col>
                {getFormConfig("form_external_management", "show_subtopic") && (
                  <Col sm={slide ? "6" : "4"} md="6" lg={slide ? "6" : "4"}>
                    <Form.Group controlId="management_subtopic">
                      <NBlockContent title={_.upperFirst(t("sub_topic"))}>
                        <Form.Control
                          as="select"
                          name="management_subtopic"
                          onChange={handleChange}
                          placeholder={t("sub_topic")}
                          value={values?.management_subtopic ?? ""}
                          disabled={loadingOptions}
                          style={{ border: "none" }}
                        >
                          <option>
                            {t("select_object", { obj: t("sub_topic") })}
                          </option>
                          {options.subtopics &&
                            options.subtopics
                              .filter(
                                (item) =>
                                  parseInt(values?.management_topic) ===
                                    item?.topic || item?.topic === null
                              )
                              .map((item, key) => {
                                return (
                                  <option
                                    key={`option-subtopic-${key}-${item.id}`}
                                    value={item.id}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                        </Form.Control>
                      </NBlockContent>
                      {showError("management_sub_topic", errors)}
                    </Form.Group>
                  </Col>
                )}

                {/* <Col md="12" style={{ marginTop: "10px" }}></Col> */}
                <Col sm={slide ? "6" : "4"} md="6" lg={slide ? "6" : "4"}>
                  <Form.Group controlId="channel">
                    <NBlockContent title={_.upperFirst(t("channel"))}>
                      <Form.Control
                        as="select"
                        name="channel"
                        onChange={handleChange}
                        value={values?.channel ?? ""}
                        disabled={loadingOptions}
                        style={{ border: "none" }}
                      >
                        <option>
                          {t("select_object", { obj: t("channel") })}
                        </option>
                        {options.channels &&
                          options.channels.map((item, key) => {
                            return (
                              <option
                                key={`option-channel-${key}`}
                                value={item.id}
                              >
                                {_.upperFirst(
                                  _.get(Channels, item.name, item.name)
                                )}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </NBlockContent>
                    {showError("channel", errors)}
                  </Form.Group>
                </Col>
                <Col sm={slide ? "6" : "4"} md="6" lg={slide ? "6" : "4"}>
                  <Form.Group controlId="management_state">
                    <NBlockContent title={_.upperFirst(t("state"))}>
                      <Form.Control
                        as="select"
                        name="management_state"
                        onChange={handleChange}
                        placeholder={t("select_object", { obj: t("state") })}
                        value={values?.management_state ?? ""}
                        disabled={loadingOptions}
                        style={{ border: "none" }}
                      >
                        <option>
                          {" "}
                          {t("select_object", { obj: t("state") })}
                        </option>
                        {options.states &&
                          options.states.map((item, key) => {
                            return (
                              <option
                                key={`option-states-${key}`}
                                value={item.id}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </NBlockContent>
                    {showError("management_state", errors)}
                  </Form.Group>
                </Col>
                {getFormConfig(
                  "form_external_management",
                  "show_notification_email"
                ) &&
                  options.topics.find(
                    (topic) => topic.id === parseInt(values?.management_topic)
                  )?.notification_email != null && (
                    <Col sm={slide ? "6" : "4"} md="6" lg={slide ? "6" : "4"}>
                      <Form.Group controlId="with_notification">
                        <CustomSwitch>
                          <div className="title-label">
                            {_.upperFirst(t("notify_person_in_charge"))}
                          </div>
                          <Form.Check
                            checked={values?.with_notification ?? false}
                            type="switch"
                            id="custom-switch"
                            name="with_notification"
                            label=""
                            onChange={(evt) => {
                              setFieldValue(
                                "with_notification",
                                evt.target.checked
                              );
                              setFieldValue(
                                "notification_email",
                                options.topics.find(
                                  (topic) =>
                                    topic.id ===
                                    parseInt(values?.management_topic)
                                )?.notification_email,
                                false
                              );
                            }}
                          />
                        </CustomSwitch>
                        {/* </NBlockContent> */}
                      </Form.Group>
                    </Col>
                  )}
              </RowItem>
              <RowItem>
                <Col>
                  <Form.Group controlId="comment">
                    {/* <Form.Label className="font-weight-bold">Comentario</Form.Label> */}
                    <NTextArea
                      name="comment"
                      onChangeEvent={handleChange}
                      value={values?.comment ?? ""}
                      errorText={errors.comment ?? null}
                      placeholder={_.upperFirst(t("comment"))}
                      row={"5"}
                      helpText={t("describe_detail_management")}
                      showCount
                      maxLength={3000}
                    ></NTextArea>
                  </Form.Group>
                </Col>
              </RowItem>
            </div>
            <RowItem className={`pt-4`}>
              <Col className="d-flex justify-content-end">
                <NButton
                  disabled={isSubmitting || isDisabled}
                  outlined
                  onClick={() => {
                    resetForm();
                    setDate(null);
                    if (slide) {
                      onClose();
                    }
                  }}
                  style={slide ? { width: "100%" } : {}}
                >
                  {_.upperFirst(slide ? t("cancel") : t("clean"))}
                </NButton>
                <NButton
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isSubmitting || isDisabled}
                  style={{
                    height: "100%",
                    marginLeft: "16px",
                    width: slide ? "100%" : "auto",
                  }}
                  // icon={<FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>}
                  loading={isDisabled}
                >
                  {_.upperFirst(t("save"))}
                </NButton>
              </Col>
            </RowItem>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default FormExternalManagement;

const CustomSwitch = styled.div`
  display: flex;
  .title-label {
    margin-bottom: 2px;
    font-size: var(--font-size-caption);
    font-weight: bold;
    margin-right: 5px;
  }
`;
