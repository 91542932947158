import React, { useEffect, useState } from "react";
import _ from "lodash";
import ToggleHeader from "components/Nimbiv2/NAccordion/ToggleHeader";
import ModalContactInfo from "./Modals/ModalContactInfo";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch, useSelector } from "react-redux";
import { AnonymousContainer, CardNimbi } from "components/Nimbiv2/styled";
import { Card, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import {
  Title,
  NameTitle,
  AccordionDesign,
  CardBody,
  ContainerItem,
} from "./detail.styled";
import { ContactInfo } from "models";
import moment from "moment";
import { editPersonProfile } from "services/crmService";
import { updateTaskStudentInfo } from "store/actions/crm.actions";
import { updateStudentInfo } from "store/actions/student.actions";
import { toast } from "react-toastify";
import { fetchPersonsProfile } from "store/actions/async/crm-async.action";
import { useTranslation } from "react-i18next";
import { useTranslationLocal } from "hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import copy from "copy-to-clipboard";

const InfoSimpleStudent = ({ task, isPending, personsProfile }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const [selectMenu, setSelectMenu] = useState(null);
  const [showModalContact, setShowModalContact] = useState(null);
  const [person, setPerson] = useState(null);
  const [isSaving, setSaving] = useState(false);
  const [studentBirthDate, setStudentBirthDate] = useState(new Date());

  // const { customInfoPerson } = useSelector((state) => state.task);
  const { user } = useSelector((state) => state.auth);

  // const {
  //   pending: { zendesk: isPendingZendesk },
  //   zendesk,
  // } = useSelector((state) => state.integrations);

  // const {
  //   personsProfile,
  //   pending: { profile: isPendingProfile },
  // } = useSelector((state) => state.crm);

  useEffect(() => {
    if (personsProfile) {
      let contacts = [];

      if ("contactinfo_set" in personsProfile) {
        contacts = new ContactInfo(personsProfile.contactinfo_set).getList();
      }

      const auxProfile = { ...personsProfile, contactinfo_set: contacts };
      setPerson(auxProfile);
    }
  }, [personsProfile]);

  useEffect(() => {
    if (person) {
      setStudentBirthDate(moment(person.birth_date).toDate());

      // update the ref inputs
      // if (refInputFirstName.current && refInputLastName.current) {
      //   refInputFirstName.current.value = person.first_name;
      //   refInputLastName.current.value = person.last_name;
      // }
    } else {
      setStudentBirthDate(new Date());
    }
  }, [person]);

  const updateStudent = ({ key, value }) => {
    const auxPerson = { ...person, [key]: value };
    setPerson(auxPerson);
  };

  const handleSaveChanges = async () => {
    const finalPerson = { ...person };
    let error = false;
    const contacts = [...finalPerson.contactinfo_set].map((c) => {
      if (c.error && !error) {
        error = true;
      }
      return c.deserialize();
    });
    if (!error) {
      finalPerson.contactinfo_set = contacts;

      setSaving(true);
      const payload = {
        studentId: finalPerson.id,
        payload: {
          first_name: finalPerson.first_name,
          last_name: finalPerson.last_name,
          birth_date: moment(studentBirthDate).format("YYYY-MM-DD"),
          gender: finalPerson.gender,
          contactinfo_set: finalPerson.contactinfo_set,
        },
      };

      const { status } = await editPersonProfile(payload);

      if (status === 200) {
        dispatch(updateTaskStudentInfo(finalPerson));
        dispatch(updateStudentInfo(finalPerson));
        setSaving(false);
        dispatch(fetchPersonsProfile(finalPerson.id));
        setShowModalContact(false);
        // notifyClose();
      } else {
        toast.error("Error!, no se pudo realizar la actualización");
      }
    } else {
      toast.error("Error en el formato ingresado");
    }
  };

  return (
    <>
      {person && (
        <ModalContactInfo
          show={showModalContact}
          contacts={person ? person.contactinfo_set : []}
          onHide={() => {
            setShowModalContact(false);
          }}
          onUpdateStudent={updateStudent}
          callSave={handleSaveChanges}
          isSaving={isSaving}
        />
      )}
      <CardNimbi radius="8px" padding="0px" style={{ height: "fit-content" }}>
        <div style={{ padding: "37px 25px 0px 25px" }}>
          <Title>
            {t("student_information", {
              student: translation("student", "", t("student")),
            })}
          </Title>
          <div style={{ marginTop: "8px" }}>
            {!user.anonymous && task && task.student_tracking && (
              <NameTitle>{task.student_tracking.full_name}</NameTitle>
            )}
            {user.anonymous && (
              <NameTitle>
                {task.student_tracking &&
                  task.student_tracking.full_name.split(" ", 1)}{" "}
                <AnonymousContainer isAnonymous={user.anonymous} radius="5px">
                  {task.student_tracking &&
                    task.student_tracking.full_name
                      .split(" ")
                      .splice(1)
                      .join(" ")}
                </AnonymousContainer>
              </NameTitle>
            )}
          </div>
          {!isPending && personsProfile && (
            <AnonymousContainer isAnonymous={user.anonymous}>
              <div
                className="d-flex"
                style={{
                  color: "#787C83",
                  fontSize: "var(--font-size-md)",
                  marginTop: "8px",
                }}
              >
                {/* {getGlobalConfig("id_student_show", "yes") === "yes" && (
                  <>
                    <div>ID: {personsProfile.external_id}</div>
                    <div
                      style={{
                        marginRight: "8px",
                        marginLeft: "8px",
                      }}
                    >
                      |
                    </div>
                  </>
                )} */}
                <div>
                  {personsProfile.document_type}:{" "}
                  {personsProfile.document_number}
                </div>
              </div>
            </AnonymousContainer>
          )}
          {/* <div style={{ marginTop: "24px" }}>
            <AnonymousContainer isAnonymous={user.anonymous}>
              <div className="d-flex align-items-center">
                <ButtonCall>
                  <p
                    style={{
                      margin: "0px",
                      padding: `10px ${
                        hasPhoneNumbers ? "10px" : "22px"
                      } 10px 22px`,
                    }}
                  >
                    <a style={{ color: "white" }} href={`tel:+${selectNumber}`}>
                      +{selectNumber}
                    </a>
                  </p>

                  {task.student_tracking &&
                    task.student_tracking.phones &&
                    task.student_tracking.phones.length > 1 && (
                      <Dropdown
                        style={{
                          marginLeft: "8px",
                        }}
                      >
                        <Dropdown.Toggle
                          as={CustomToggle}
                          id="dropdown-custom-components"
                        />

                        <Dropdown.Menu>
                          {task.student_tracking.phones.map((item) => (
                            <Dropdown.Item
                              key={`items-numbers-${item.id}`}
                              active={item.id === selectNumberID}
                              onClick={() => {
                                setSelectNumber(item.value);
                                setSelectNumberID(item.id);
                              }}
                            >
                              +{item.value}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                </ButtonCall>
                <OverlayTrigger overlay={<Tooltip>Copiar teléfono</Tooltip>}>
                  <FontAwesomeIcon
                    onClick={() => copyPhoneNumber(selectNumber)}
                    className={`${styles.copyPhoneNumber} ml-2`}
                    icon={faClone}
                    style={{ color: "var(--call-in-action)", fontSize: "20px" }}
                  />
                </OverlayTrigger>
              </div>
            </AnonymousContainer>
          </div> */}
        </div>
        <AccordionDesign
          style={{ marginTop: "16px" }}
          onSelect={(item) => {
            setSelectMenu(item);
          }}
        >
          <Card style={{ border: "0px" }}>
            <ToggleHeader
              eventKey="0"
              title={t("personal_information")}
              selectMenu={selectMenu}
            />
            <Accordion.Collapse eventKey="0">
              <CardBody>
                {isPending && (
                  <div style={{ marginTop: "10px" }}>
                    <Spinner
                      as="span"
                      animation="border"
                      size="bg"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                )}
                {!isPending && personsProfile && (
                  <>
                    <ContainerItem style={{ marginTop: "0px" }}>
                      <strong>{_.upperFirst(t("phone"))}</strong>
                      <AnonymousContainer isAnonymous={user.anonymous}>
                        {personsProfile.phones.length === 0 && (
                          <div>{_.upperCase(t("no_information"))}</div>
                        )}
                        {personsProfile.phones.map((item, key) => {
                          return (
                            <div
                              key={`${key}-phone`}
                              className="item-description"
                            >
                              {item.value}
                              {!user.anonymous && (
                                <OverlayTrigger
                                  overlay={<Tooltip>{t("copy_phone")}</Tooltip>}
                                >
                                  <FontAwesomeIcon
                                    icon={faCopy}
                                    className="ml-1"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      copy(item.value);
                                      toast.info(`Copiado: +${item?.value}`);
                                    }}
                                  />
                                </OverlayTrigger>
                              )}
                            </div>
                          );
                        })}
                      </AnonymousContainer>
                      {/* <div className="item-description">
                  +56 9 ContainerItem4 5633 (personal)
                </div> */}
                    </ContainerItem>
                    <ContainerItem>
                      <strong>{_.upperFirst(t("email"))}</strong>
                      <AnonymousContainer isAnonymous={user.anonymous}>
                        {personsProfile.emails.length === 0 && (
                          <div>{_.upperCase(t("no_information"))}</div>
                        )}
                        {personsProfile.emails.map((item, key) => {
                          return (
                            <div
                              key={`${key}-email`}
                              className="item-description"
                            >
                              {item.value}
                              {!user.anonymous && (
                                <OverlayTrigger
                                  overlay={<Tooltip>{t("copy_email")}</Tooltip>}
                                >
                                  <FontAwesomeIcon
                                    icon={faCopy}
                                    className="ml-1"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      copy(item.value);
                                      toast.info(`Copiado: ${item?.value}`);
                                    }}
                                  />
                                </OverlayTrigger>
                              )}
                            </div>
                          );
                        })}
                      </AnonymousContainer>
                    </ContainerItem>
                  </>
                )}
              </CardBody>
            </Accordion.Collapse>
          </Card>

          {/* <DividerH /> */}
        </AccordionDesign>
      </CardNimbi>
    </>
  );
};

export default InfoSimpleStudent;
