import React, { useState } from "react";
import { Formik } from "formik";
import _ from "lodash";
import { Col, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import { NButton, TextError } from "components/Nimbiv2";
import { RowItem } from "components/Nimbiv2/styled";
import { useSelector } from "react-redux";
import { getDownloadDocumentAdvice } from "services/student.service";
const DownloadAdvice = ({ show, onHide }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  // const [adviceSelect, setAdviceSelect] = useState(false);
  const { generationEvents } = useSelector((state) => state.student);
  const downloadAdvice = async (values) => {
    setLoading(true);
    const { generation_event } = values;
    await getDownloadDocumentAdvice(generation_event).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "Formato de asistencias a asesorias grupales.xlsx"
      );
      document.body.appendChild(link);
      link.click();
    });
    setLoading(false);
  };
  const validate = (values) => {
    let errors = {};
    if (!values.generation_event) {
      errors.generation_event = "Campo requerido";
      return errors;
    }
    return errors;
  };
  const onFormSubmit = (values) => {
    downloadAdvice(values);
    return true;
  };
  return (
    <ModalDesign show={show} onHide={onHide} centered>
      <Modal.Header closeButton style={{ borderBottom: "0px" }}>
        <Modal.Title className="h4">{t("download_consultancies")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ marginBottom: "40px" }}>
          {t("select_for_which_consultancy")}
        </div>
        <Formik initialValues={{}} validate={validate} onSubmit={onFormSubmit}>
          {({
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            values,
          }) => (
            <div>
              <Form.Control
                as="select"
                name="generation_event"
                onChange={handleChange}
              >
                <option value="">---{"Selecciona la asesoría"}---</option>
                {generationEvents &&
                  generationEvents.map((item, key) => (
                    <option key={`generation-event-${key}`} value={item.id}>
                      {item.event_name}
                    </option>
                  ))}
              </Form.Control>
              <TextError
                text={errors["generation_event"]}
                marginTop="5px"
                icon={faExclamationCircle}
              />

              <RowItem style={{ marginTop: "56px" }}>
                <Col xs="6">
                  <NButton
                    style={{
                      marginTop: "10px",
                      float: "right",
                      width: "100%",
                    }}
                    outlined
                    onClick={() => {
                      onHide();
                    }}
                    disabled={loading}
                  >
                    {_.upperFirst(t("cancel"))}
                  </NButton>
                </Col>
                <Col xs="6">
                  <NButton
                    style={{
                      marginTop: "10px",
                      float: "right",
                      width: "100%",
                    }}
                    onClick={handleSubmit}
                    loading={loading}
                    disabled={loading}
                  >
                    {_.upperFirst(t("download"))}
                  </NButton>
                </Col>
              </RowItem>
            </div>
          )}
        </Formik>
      </Modal.Body>
    </ModalDesign>
  );
};
export default DownloadAdvice;

const ModalDesign = styled(Modal)`
  .modal-header {
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 0px;
  }
  .modal-body {
    padding-top: 8px;
    font-size: 16px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .modal-content {
    border-radius: 8px;
  }
`;
