import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";

import { clearSession } from "store/actions/auth.actions";
import { unauthorized, pageNotFound } from "assets/images";
import { getDataFromLocalStorage } from "utils";
import styles from "./NotFound.module.css";

export default ({ match }) => {
  const dispatch = useDispatch();

  const [goLogin, setGoLogin] = useState(false);
  const { code: errorCode = 0 } = match.params;

  useEffect(() => {
    const user = getDataFromLocalStorage("user");
    const { groups = [] } = user ?? {};

    // user without groups
    if (groups.length === 0) {
      dispatch(clearSession());
      setGoLogin(true);
    }
  }, [dispatch]);

  const handleBack = () => {
    window.history.back();
  };

  const getErrorBlock = () => {
    switch (Number(errorCode)) {
      case 401: {
        return (
          <div className={styles.content}>
            <img
              className={styles.imgUnauthorized}
              src={unauthorized}
              alt="unauthorized"
            />
            <div className="alert alert-danger mt-3" role="alert">
              <h5 className="mb-0">
                No tienes los accesos necesarios para ingresar a esta página.
              </h5>
            </div>
            <div className="d-flex justify-content-center">
              {goLogin ? (
                <Link className="btn btn-primary" to="/" aria-hidden="true">
                  Volver
                </Link>
              ) : (
                <Button onClick={handleBack}>Volver</Button>
              )}
            </div>
          </div>
        );
      }
      default:
        return (
          <div className={styles.content}>
            <img
              className={styles.imgNotFound}
              src={pageNotFound}
              alt="not found"
            />
            <div className="alert alert-danger" role="alert">
              <h5 className="mb-0">
                Lo sentimos... No podemos encontrar la página que estas
                buscando.{" "}
              </h5>
            </div>
            <div className="d-flex justify-content-center">
              <Button onClick={handleBack}>Volver</Button>
            </div>
          </div>
        );
    }
  };

  return <div className={styles.errorContainerBlock}>{getErrorBlock()}</div>;
};
