import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { changeClientArea } from "services/user.service";
import { updateUserSession } from "store/actions/auth.actions";
import { cleanAllFilter } from "store/actions/site.actions";
import { Modal, Row } from "components";
import { AnonymousContainer } from "../styled";
import { permissionRedirect } from "routes/helpers";
import { deleteLocalStorage, setDataToLocalStorage } from "utils";
import { NSpinnerPoints, NButton } from "components/Nimbiv2";

const ModalClientArea = ({ show, handleClose }) => {
  let history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = React.useState(false);

  const setSelectClientArea = (idClientArea) => {
    if (user.user_client_areas) {
      user.user_client_areas.forEach((item) => {
        if (item.client_area.pk === idClientArea) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    }
  };

  const handleChangeClientArea = async (idClientArea) => {
    setLoading(true);
    const { status, data } = await changeClientArea({
      client_area_id: idClientArea,
    });
    if (status === 200) {
      toast.success(t("change_success"));
      setSelectClientArea(idClientArea);
      user.client_color_palette = data.client_color_palette;
      user.client_logo = data.client_logo;
      user.menu = data.menu;
      user.extras = data.extras;
      user.client_area = data.client_area;
      user.client_area_id = data.client_area_id;
      user.client_area_ongoing_risk = data.client_area_ongoing_risk;
      user.config = data?.config ?? {};
      user.client_area_languages = data.client_area_languages;
      user.user_language = data.user_language;
      user.client_area_context_by_tracking_object =
        data?.client_area_context_by_tracking_object;
      user.anonymous = data?.anonymous;
      user.client_area_ongoing_risk = data?.client_area_ongoing_risk;
      user.neotel_provider = user?.neotel_provider;
      user.user_teams = user?.user_teams;
      user.client_area_change_logs = data?.client_area_change_logs;
      user.client_area_max_external_students =
        data?.client_area_max_external_students;
      user.client_area_generation_events = data?.client_area_generation_events;
      i18n.changeLanguage(user.user_language ?? "es");
      dispatch(cleanAllFilter());
      dispatch(updateUserSession({ user: user }));
      setDataToLocalStorage("extras", data.extras);
      deleteLocalStorage("avoidReloadTasks");
      deleteLocalStorage("filters");
      deleteLocalStorage("clientFilters");
      deleteLocalStorage("stud_filters");

      setLoading(false);
      history.replace({
        pathname: permissionRedirect({
          userRoles: user ? user.groups : [],
        }),
      });
      window.location.reload(false);
      return true;
    } else {
      toast.error(t("error_change_area"));
      setLoading(false);
      return false;
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <span>{t("select_area")}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`px-4`}>
        {!loading && (
          <div>
            {user.user_client_areas.map((item, key) => {
              return (
                <AnonymousContainer
                  isAnonymous={user.anonymous}
                  key={`anonymous_client_area_${key}`}
                >
                  <Row key={`modal_client_area_${key}`}>
                    <NButton
                      border="10px"
                      active={item.active}
                      outlined
                      className="mt-2"
                      style={{ width: "100%" }}
                      onClick={() => {
                        handleChangeClientArea(item.client_area.pk);
                      }}
                    >
                      {item.client_area.name}
                    </NButton>
                  </Row>
                </AnonymousContainer>
              );
            })}
          </div>
        )}
        {loading && (
          <div
            className="d-flex justify-content-center"
            style={{ width: "100%" }}
          >
            <NSpinnerPoints />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalClientArea;
