import React, { useState } from "react";
import styled from "styled-components";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import { TextError } from "components/Nimbiv2";

const NInput = ({
  label,
  helpText,
  errorText,
  onChange = () => {},
  defaultValue,
  name,
  minWidth = "350px",
  placeholder = "",
  disabled = false,
  onChangeEvent = () => {},
}) => {
  const [text, setText] = useState("");

  return (
    <>
      <ContainerInput minWidth={minWidth}>
        <InputStyled
          data-key="value"
          defaultValue={defaultValue || ""}
          type={"text"}
          name={name}
          onChange={(e) => {
            setText(e.target.value);
            onChange(e.target.value);
            onChangeEvent(e);
          }}
          placeholder={placeholder}
          disabled={disabled}
        />
        <LabelStyled
          className={
            text !== "" || (defaultValue !== "" && defaultValue !== undefined)
              ? "active"
              : ""
          }
        >
          {label}
        </LabelStyled>
      </ContainerInput>
      {helpText && <HelpText>{helpText}</HelpText>}
      {errorText && errorText !== "" && (
        <TextError
          text={errorText}
          icon={faExclamationCircle}
          marginTop="5px"
        />
      )}
    </>
  );
};
export default NInput;

const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${(props) => (props.minWidth !== null ? props.minWidth : "350px")};
  position: relative;
  & label {
    transform-origin: top left;
    transition: all 0.2s ease-out;
  }
  &:focus-within label {
    transform: translate(12px, -8px) scale(0.75);
    padding: 0px 10px 0px 10px;
    color: #334fff;
  }
  &:focus-within input {
    border: 1px solid #334fff;
  }
  .active {
    transform: translate(12px, -8px) scale(0.75) !important;
    padding: 0px 10px 0px 10px;
  }
`;

const InputStyled = styled.input`
  width: 100%;
  height: 46px;
  padding: 12px;
  outline: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: ${(props) => (props.disabled ? "transparent" : "#fff")};
  font-family: Arial, Helvetica, sans-serif;
  font-size: var(--font-size-lg);
`;

const LabelStyled = styled.label`
  font-size: var(--font-size-lg);
  font-family: Arial, Helvetica, sans-serif;
  color: #999;
  pointer-events: none;
  position: absolute;
  transform: translate(12px, 12px) scale(1);
  background: white;
`;

const HelpText = styled.div`
  font-family: Nova;
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-caption);
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: right;
  color: #787c83;
  margin: 3px 0px;
`;
