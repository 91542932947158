import React from "react";
import styled from "styled-components";
import { CardNimbi } from "components/Nimbiv2/styled";

import { HelpCenterImage } from "assets/images";
const EmptyContainer = ({ text }) => {
  return (
    <div>
      <CardNimbi
        padding="24px"
        style={{ minHeight: "75vh", borderRadius: "8px" }}
        className="d-flex justify-content-center align-items-center"
      >
        <div className="d-flex align-items-center flex-column">
          <img
            src={HelpCenterImage}
            height={"167px"}
            width={"210.91px"}
            alt="empty"
          />
          <EmptyDetail
            dangerouslySetInnerHTML={{
              __html: text
                ? text
                : `No se han encontrado resultados para tu búsqueda. <br /> Por favor,
            vuelve a intentarlo con otras palabras.`,
            }}
          ></EmptyDetail>
        </div>
      </CardNimbi>
    </div>
  );
};
export default EmptyContainer;

const EmptyDetail = styled.div`
  margin-top: 80px;
  color: var(--gray-scale-1100, #71819c);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
`;
