import React from "react";

const EmptyActionPlanBoard = () => {
  return (
    <svg
      width="212"
      height="166"
      viewBox="0 0 212 166"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M105.746 152.6C147.521 152.6 181.387 118.71 181.387 76.904C181.387 35.0984 147.521 1.20825 105.746 1.20825C63.9703 1.20825 30.1045 35.0984 30.1045 76.904C30.1045 118.71 63.9703 152.6 105.746 152.6Z"
        fill="#F1F6FD"
      />
      <g filter="url(#filter0_d_2084_9284)">
        <path
          d="M188.092 96.6841L113.247 137.68C109.511 139.767 103.906 139.975 100.718 138.202L23.5653 95.8496C20.3781 94.0762 20.7078 91.051 24.4446 88.9647L99.2896 47.9684C103.026 45.8821 108.631 45.6735 111.819 47.4469L188.972 89.7992C192.159 91.5726 191.829 94.5978 188.092 96.6841Z"
          fill="white"
        />
      </g>
      <path
        d="M54.1189 86.0437L40.6006 93.4501C39.5016 93.9717 37.853 93.9717 36.754 93.4501C35.6549 92.9285 35.6549 91.9897 36.754 91.3638L50.2722 83.9573C51.3713 83.4358 53.0198 83.4358 54.1189 83.9573C55.2179 84.4789 55.108 85.4178 54.1189 86.0437Z"
        fill="#D9E1F2"
      />
      <path
        d="M67.7477 93.0325L54.3393 100.335C53.4601 100.752 51.9214 100.752 51.262 100.335C50.3827 99.9174 50.3827 99.0829 51.262 98.6656L64.6703 91.3635C65.5496 90.9462 67.0882 90.9462 67.7477 91.3635C68.6269 91.7808 68.517 92.511 67.7477 93.0325Z"
        fill="#D9E1F2"
      />
      <path
        d="M88.5202 108.367L78.7387 113.687C77.9693 114.105 76.4307 114 75.7712 113.687C74.892 113.27 74.892 112.436 75.7712 112.018L85.5528 106.698C86.3221 106.281 87.8607 106.385 88.5202 106.698C88.9598 107.116 89.1796 107.95 88.5202 108.367Z"
        fill="#D9E1F2"
      />
      <path
        d="M146.329 76.6553L132.591 84.166C131.712 84.6876 130.173 84.5833 129.514 84.166C128.635 83.7488 128.745 82.9142 129.514 82.497L143.252 74.9862C144.131 74.4646 145.67 74.5689 146.329 74.9862C146.989 75.4035 147.099 76.238 146.329 76.6553Z"
        fill="#D9E1F2"
      />
      <path
        d="M124.899 88.4419L96.1038 104.194C95.2246 104.715 93.6859 104.611 93.0265 104.194C92.1472 103.776 92.2571 102.942 93.1364 102.525L121.931 86.7728C122.811 86.2512 124.349 86.3556 125.009 86.7728C125.558 87.1901 125.668 88.0246 124.899 88.4419Z"
        fill="#D9E1F2"
      />
      <path
        d="M109.951 105.238L92.1468 114.939C91.2675 115.461 89.7289 115.356 89.0694 114.939C88.1902 114.522 88.3001 113.687 89.1794 113.27L106.984 103.568C107.863 103.047 109.402 103.151 110.061 103.568C110.721 104.09 110.721 104.82 109.951 105.238Z"
        fill="#D9E1F2"
      />
      <path
        d="M126.436 96.1619L116.765 101.482C115.885 101.899 114.347 101.899 113.687 101.482C112.808 101.065 112.918 100.23 113.687 99.813L123.359 94.4929C124.238 94.0756 125.777 94.0756 126.436 94.4929C127.206 95.0145 127.096 95.849 126.436 96.1619Z"
        fill="#D9E1F2"
      />
      <path
        d="M115.886 111.497L95.3343 122.763C94.455 123.285 92.9164 123.18 92.2569 122.763C91.3777 122.346 91.4876 121.511 92.3669 121.094L112.919 109.828C113.798 109.306 115.337 109.41 115.996 109.828C116.766 110.245 116.876 110.871 115.886 111.497Z"
        fill="#D9E1F2"
      />
      <path
        d="M130.504 103.464L122.591 107.741C121.712 108.158 120.173 108.158 119.513 107.741C118.634 107.324 118.744 106.489 119.513 106.072L127.427 101.795C128.306 101.378 129.844 101.378 130.504 101.795C131.383 102.212 131.493 102.838 130.504 103.464Z"
        fill="#D9E1F2"
      />
      <path
        d="M111.82 122.241L103.138 127.04C102.258 127.561 100.72 127.457 100.06 127.04C99.1809 126.622 99.2908 125.788 100.17 125.37L108.853 120.572C109.732 120.05 111.27 120.155 111.93 120.572C112.809 120.989 112.919 121.719 111.82 122.241Z"
        fill="#D9E1F2"
      />
      <path
        d="M119.293 118.173L117.645 119.112C116.875 119.529 115.337 119.425 114.677 119.112C113.798 118.695 113.798 117.964 114.567 117.443L116.216 116.504C116.985 116.087 118.524 116.191 119.183 116.504C120.063 117.026 120.063 117.756 119.293 118.173Z"
        fill="#D9E1F2"
      />
      <path
        d="M74.3428 107.429L70.7159 109.411C69.9466 109.828 68.5179 109.724 67.8584 109.307C66.9792 108.889 66.9792 108.159 67.7485 107.742L71.3754 105.76C72.1447 105.343 73.5735 105.447 74.2329 105.864C75.0022 106.386 75.1121 107.116 74.3428 107.429Z"
        fill="#D9E1F2"
      />
      <path
        d="M106.106 90.1116L83.685 102.317C82.8058 102.838 81.2671 102.734 80.6077 102.317C79.7284 101.899 79.8383 101.065 80.6077 100.648L103.028 88.3383C103.907 87.8167 105.446 87.921 106.106 88.3383C106.985 88.8598 106.985 89.59 106.106 90.1116Z"
        fill="#D9E1F2"
      />
      <path
        d="M82.8044 93.3452L62.2522 104.611C61.373 105.133 59.8343 105.029 59.1749 104.611C58.2957 104.194 58.4056 103.36 59.2848 102.942L79.7271 91.6761C80.6063 91.1546 82.145 91.2589 82.8044 91.6761C83.7935 92.0934 83.9034 92.8236 82.8044 93.3452Z"
        fill="#D9E1F2"
      />
      <path
        d="M110.172 78.324L89.7293 89.5901C88.8501 90.1117 87.3114 90.0074 86.652 89.5901C85.7727 89.1728 85.8826 88.3383 86.7619 87.9211L107.314 76.6549C108.193 76.1333 109.732 76.2376 110.391 76.6549C111.161 77.0722 111.271 77.8024 110.172 78.324Z"
        fill="#D9E1F2"
      />
      <path
        d="M124.899 70.2916L116.986 74.5685C116.107 74.9858 114.568 74.9858 113.908 74.5685C113.029 74.1513 113.139 73.3167 113.908 72.8995L121.822 68.6225C122.701 68.2053 124.239 68.2053 124.899 68.6225C125.778 69.1441 125.888 69.77 124.899 70.2916Z"
        fill="#D9E1F2"
      />
      <g filter="url(#filter1_d_2084_9284)">
        <path
          d="M187.047 64.8433L112.202 105.84C108.465 107.926 102.86 108.135 99.6729 106.361L22.5199 64.0088C19.3327 62.2354 19.6624 59.2102 23.3992 57.1239L98.2442 16.1276C101.981 14.0413 107.586 13.8327 110.773 15.606L187.926 57.9584C191.114 59.7318 190.784 62.757 187.047 64.8433Z"
          fill="white"
        />
      </g>
      <path
        d="M53.4021 54.4109L39.8838 61.8173C38.7848 62.3389 37.1362 62.3389 36.0372 61.8173C34.9381 61.2957 34.9381 60.3569 36.0372 59.731L49.5554 52.3245C50.6545 51.803 52.3031 51.803 53.4021 52.3245C54.5011 52.8461 54.5011 53.8893 53.4021 54.4109Z"
        fill="#D9E1F2"
      />
      <path
        d="M78.4616 49.3005L47.7982 66.0954C47.1388 66.4083 46.0397 66.4083 45.3803 66.0954C44.7209 65.7824 44.7209 65.1565 45.3803 64.8436L76.0437 48.0487C76.7031 47.7357 77.8022 47.7357 78.4616 48.0487C79.121 48.3616 79.121 48.9875 78.4616 49.3005Z"
        fill="#D9E1F2"
      />
      <path
        d="M118.026 88.4184L103.848 96.1378C102.529 96.868 100.441 96.9723 99.2319 96.3464L55.8196 72.5623C54.6107 71.9364 54.8305 70.7889 56.1493 70.0587L70.327 62.3393C71.6459 61.6091 73.7341 61.5048 74.943 62.1307L118.355 85.9148C119.454 86.5407 119.344 87.6882 118.026 88.4184Z"
        fill="#D9E1F2"
      />
      <path
        d="M144.513 47.8391L105.937 68.911C104.508 69.7455 102.31 69.8498 100.991 69.1196L81.7576 58.5836C80.4387 57.8534 80.6586 56.706 82.0873 55.8714L120.664 34.6952C122.093 33.8607 124.291 33.7564 125.61 34.4866L144.843 45.0225C146.162 45.7528 145.942 47.0046 144.513 47.8391Z"
        fill="#D9E1F2"
      />
      <path
        d="M143.744 74.3365L128.577 82.6818C127.807 83.0991 126.598 83.2034 125.829 82.7861L111.102 74.7538C110.442 74.3365 110.442 73.7106 111.322 73.2934L126.489 64.9481C127.258 64.5308 128.467 64.4265 129.236 64.8438L143.963 72.8761C144.623 73.2934 144.513 73.9193 143.744 74.3365Z"
        fill="#D9E1F2"
      />
      <path
        d="M169.352 60.3575L154.186 68.7028C153.416 69.1201 152.207 69.2244 151.438 68.8071L136.711 60.7748C136.051 60.3575 136.051 59.7316 136.931 59.3144L152.097 50.9691C152.867 50.5518 154.076 50.4475 154.845 50.8648L169.572 58.8971C170.232 59.21 170.122 59.9403 169.352 60.3575Z"
        fill="#D9E1F2"
      />
      <defs>
        <filter
          id="filter0_d_2084_9284"
          x="1.38867"
          y="32.2473"
          width="209.76"
          height="133.154"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.690196 0 0 0 0 0.729412 0 0 0 0 0.8 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2084_9284"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2084_9284"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_2084_9284"
          x="0.343262"
          y="0.406494"
          width="209.76"
          height="133.154"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.690196 0 0 0 0 0.729412 0 0 0 0 0.8 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2084_9284"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2084_9284"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default EmptyActionPlanBoard;
