import React from "react";
import NSteps from "components/Nimbiv2/NSteps";
import NStepItem from "components/Nimbiv2/NSteps/NStepItem";
import styled from "styled-components";
import { NIcon } from "components/Nimbiv2";
const NStepContainer = ({
  position,
  items,
  activeSteps,
  onChangeStep,
  descriptions,
  warnings = [],
}) => {
  const renderIcon = (error) => {
    return <NIcon iconname={error ? "exclamation" : "check"} />;
  };
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ maxWidth: "1000px", overflowX: "auto" }}>
        <NSteps position={position}>
          {items.map((item, key) => {
            return (
              <NStepItem
                key={`step-${key}-${item}`}
                showLine={key !== items.length - 1}
                active={activeSteps.includes(item)}
                onClick={() => {
                  if (onChangeStep) {
                    onChangeStep(key, item);
                  }
                }}
              >
                <CircleStep
                  active={position === key}
                  activeCheck={position > key}
                  warning={warnings.includes(item)}
                >
                  {position > key
                    ? renderIcon(warnings.includes(item))
                    : key + 1}
                </CircleStep>
                <div
                  className="text-center mt-1"
                  style={{
                    fontSize: "var(--font-size-small)",
                    marginBottom: "0px",
                  }}
                  dangerouslySetInnerHTML={{ __html: descriptions[item] }}
                ></div>
              </NStepItem>
            );
          })}
        </NSteps>
      </div>
    </div>
  );
};

export default NStepContainer;

const CircleStep = styled.span`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 0;
  line-height: 1.7rem;
  text-align: center;
  background: ${(props) =>
    props.active
      ? "#334FFF"
      : props.activeCheck && !props.warning
      ? "#3AC8AC"
      : props.warning
      ? "#E23232"
      : "#989da5"};
  color: #ffffff;
  border-radius: 50%;
`;
