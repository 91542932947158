import React, { useState, useCallback, useEffect } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFlag,
  faCalendarExclamation,
  faPhoneSlash,
  faUserHeadset,
  faCalendarDay,
  faLightbulbOn,
} from "@fortawesome/pro-regular-svg-icons";

import { APP_MODULES, getGlobalConfig } from "utils";
import {
  setDataToLocalStorage,
  setSessionFilters,
  getDataFromLocalStorage,
} from "utils";
import { getTaskList } from "store/actions/async/crm-async.action";
import NCheckBadge from "./NCheckBadge";

const {
  TASK: { FILTER_NAME: FILTER_TASK },
} = APP_MODULES;

const NPriorityFilter = React.memo(
  ({ isDisabled = false, changeTab, changeMoment }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [priorityTasks, setPriorityTasks] = useState(() => {
      const currentFilters = getDataFromLocalStorage("filters", {});
      if ("has_priority" in currentFilters) return currentFilters.has_priority;
      return false;
    });

    const [expirationOrder, setExpirationOrder] = useState(() => {
      const currentFilters = getDataFromLocalStorage("filters", {});
      if ("expiration_order" in currentFilters)
        return currentFilters.expiration_order;
      return false;
    });

    const [notCalled, setNotCalled] = useState(() => {
      const currentFilters = getDataFromLocalStorage("filters", {});
      if ("not_called" in currentFilters) return currentFilters.not_called;
      return false;
    });

    const [starredNote, setStarredNote] = useState(() => {
      const currentFilters = getDataFromLocalStorage("filters", {});
      if ("starred_note" in currentFilters) return currentFilters.starred_note;
      return false;
    });

    const [managedByMe, setManagedByMe] = useState(() => {
      const currentFilters = getDataFromLocalStorage("filters", {});
      if ("managed_by_me" in currentFilters)
        return currentFilters.managed_by_me;
      return false;
    });

    const [daysOrder, setDaysOrder] = useState(() => {
      const currentFilters = getDataFromLocalStorage("filters", {});
      if ("days_order" in currentFilters) return currentFilters.days_order;
      return false;
    });

    useEffect(() => {
      const currentFilters = getDataFromLocalStorage("filters", {});
      setPriorityTasks(currentFilters.has_priority ?? false);
      setExpirationOrder(currentFilters.expiration_order ?? false);
      setNotCalled(currentFilters.not_called ?? false);
      setManagedByMe(currentFilters.managed_by_me ?? false);
      setDaysOrder(currentFilters.days_order ?? false);
      setStarredNote(currentFilters.starred_note ?? false);
    }, [changeTab, changeMoment]);

    const handlePriorityChecked = useCallback(
      (event) => {
        setExpirationOrder(false);
        setNotCalled(false);
        setManagedByMe(false);
        setDaysOrder(false);
        setStarredNote(false);
        const isChecked = event.target.checked;
        setDataToLocalStorage("avoidReloadTasks", true);
        setSessionFilters(FILTER_TASK, {
          page: 1,
          has_priority: isChecked,
          expiration_order: false,
          not_called: false,
          managed_by_me: false,
          days_order: false,
          starred_note: false,
        });
        setPriorityTasks(isChecked);
        dispatch(getTaskList());
      },
      [dispatch]
    );

    const handleExpirationChecked = useCallback(
      (event) => {
        setPriorityTasks(false);
        setNotCalled(false);
        setManagedByMe(false);
        setDaysOrder(false);
        setStarredNote(false);
        const isChecked = event.target.checked;
        setDataToLocalStorage("avoidReloadTasks", true);
        setSessionFilters(FILTER_TASK, {
          page: 1,
          expiration_order: isChecked,
          has_priority: false,
          not_called: false,
          managed_by_me: false,
          days_order: false,
          starred_note: false,
        });
        setExpirationOrder(isChecked);
        dispatch(getTaskList());
      },
      [dispatch]
    );

    const handleCountDaysChecked = useCallback(
      (event) => {
        setPriorityTasks(false);
        setNotCalled(false);
        setManagedByMe(false);
        setStarredNote(false);
        const isChecked = event.target.checked;
        setDataToLocalStorage("avoidReloadTasks", true);
        setSessionFilters(FILTER_TASK, {
          page: 1,
          expiration_order: false,
          has_priority: false,
          not_called: false,
          managed_by_me: false,
          days_order: isChecked,
          starred_note: false,
        });
        setExpirationOrder(false);
        setDaysOrder(isChecked);
        dispatch(getTaskList());
      },
      [dispatch]
    );

    const handleNotCalledChecked = useCallback(
      (event) => {
        setExpirationOrder(false);
        setPriorityTasks(false);
        setManagedByMe(false);
        setDaysOrder(false);
        setStarredNote(false);
        const isChecked = event.target.checked;
        setDataToLocalStorage("avoidReloadTasks", true);
        setSessionFilters(FILTER_TASK, {
          page: 1,
          has_priority: false,
          expiration_order: false,
          not_called: isChecked,
          managed_by_me: false,
          days_order: false,
          starred_note: false,
        });
        setNotCalled(isChecked);
        dispatch(getTaskList());
      },
      [dispatch]
    );

    const handleManagedByMeChecked = useCallback(
      (event) => {
        setNotCalled(false);
        setExpirationOrder(false);
        setPriorityTasks(false);
        setDaysOrder(false);
        setStarredNote(false);
        const isChecked = event.target.checked;
        setDataToLocalStorage("avoidReloadTasks", true);
        setSessionFilters(FILTER_TASK, {
          page: 1,
          has_priority: false,
          expiration_order: false,
          not_called: false,
          managed_by_me: isChecked,
          days_order: false,
          starred_note: false,
        });
        setManagedByMe(isChecked);
        dispatch(getTaskList());
      },
      [dispatch]
    );
    const handleStarredNoteChecked = useCallback(
      (event) => {
        setExpirationOrder(false);
        setPriorityTasks(false);
        setManagedByMe(false);
        setDaysOrder(false);
        setNotCalled(false);
        const isChecked = event.target.checked;
        setDataToLocalStorage("avoidReloadTasks", true);
        setSessionFilters(FILTER_TASK, {
          page: 1,
          has_priority: false,
          expiration_order: false,
          not_called: false,
          managed_by_me: false,
          days_order: false,
          starred_note: isChecked
        });
        setStarredNote(isChecked);
        dispatch(getTaskList());
      },
      [dispatch]
    );
    return (
      <div
        className="d-flex justify-content-start justify-content-lg-end align-items-center"
        style={{
          fontSize: "14px",
          height: "100%",
          flexWrap: "wrap",
          rowGap: "0.5rem",
        }}
      >
        {getGlobalConfig("enable_order_moment_tracking", false) &&
          changeTab === "moments" && (
            <NCheckBadge
              onChange={handleCountDaysChecked}
              checked={daysOrder}
              disabled={isDisabled}
              label={_.upperFirst("Fecha Ingreso Plan")}
              className="mr-2"
              icon={<FontAwesomeIcon icon={faCalendarDay} />}
            />
          )}
        <NCheckBadge
          onChange={handleExpirationChecked}
          checked={expirationOrder}
          disabled={isDisabled}
          label={_.upperFirst(t("expiration"))}
          className="mr-2"
          icon={<FontAwesomeIcon icon={faCalendarExclamation} />}
        />
        <NCheckBadge
          onChange={handlePriorityChecked}
          checked={priorityTasks}
          disabled={isDisabled}
          label={_.upperFirst(t("priority"))}
          className="mr-2"
          icon={<FontAwesomeIcon icon={faFlag} />}
        />
        <NCheckBadge
          onChange={handleNotCalledChecked}
          checked={notCalled}
          disabled={isDisabled}
          label={_.upperFirst(t("no_call"))}
          className="mr-2"
          icon={<FontAwesomeIcon icon={faPhoneSlash} />}
        />
        <NCheckBadge
          onChange={handleManagedByMeChecked}
          checked={managedByMe}
          disabled={isDisabled}
          label={t("managed_by_me")}
          className="mr-2"
          icon={<FontAwesomeIcon icon={faUserHeadset} />}
        />
        <NCheckBadge
          onChange={handleStarredNoteChecked}
          checked={starredNote}
          disabled={isDisabled}
          label={_.upperFirst(t("starred_note"))}
          className="mr-2"
          icon={<FontAwesomeIcon icon={faLightbulbOn} />}
        />
      </div>
    );
  }
);
export default NPriorityFilter;
