/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
export default function Actions() {
  let instance;

  // eslint-disable-next-line no-func-assign
  Actions = function () {
    return instance;
  };
  Actions.prototype = this;
  instance = new Actions();
  instance.constructor = Actions;
  instance.baseActions = [
    "dashboard",

    "task_list",

    "student_list",
    "student_search",
    "student_apply_actions",

    "report_list",

    "user_list",

    "moment_list",
    "initiatives_list",
    "help_list",
    "initiatives_tasks",
    "external_management_list",
  ];

  instance.excludeActions = function (actionsNotAllowed) {
    const auxBasePermissions = [...instance.baseActions];
    actionsNotAllowed.forEach((action) => {
      const pos = auxBasePermissions.indexOf(action);
      if (pos >= 0) {
        auxBasePermissions.splice(pos, 1);
      }
    });
    return auxBasePermissions;
  };

  return instance;
}
