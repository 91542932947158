import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import * as Skeletons from "components/Skeletons";
import { uuidv4, APP_MODULES, setSessionFilters } from "utils";
import { useDefault } from "hooks";
import { setActiveModule } from "store/actions/site.actions";
import { toggleSelected } from "store/actions/dashboard.actions";
import { markMoment, toggleClearFilter } from "store/actions/crm.actions";
import { getTaskList } from "store/actions/async/dashboard-async.actions";
import { noTasks } from "../../assets/images";
import styles from "./Dashboard.module.css";

import ListHeader from "./ListHeader";
import ListItem from "./ListItem";
import LastManagement from "../Task/LastManagement";

const showLastContact = false;
const studentInfo = null;

const DataList = () => {
  const dispatch = useDispatch();
  const {
    tasks,
    pending: { list: isPending },
  } = useSelector((state) => state.dashboard);
  const dashboardModuleTitle = useDefault("dashboardModuleTitle", "Dashboard");

  useEffect(() => {
    setSessionFilters(APP_MODULES.DASHBOARD.FILTER_NAME, {
      page: 1,
      page_size: 10,
    });
    dispatch(getTaskList());
    dispatch(toggleSelected(true)); // mark dashboard module in navbar

    // clear moment's filter
    dispatch(toggleClearFilter({ attr: "course", sw: false }));
    dispatch(markMoment(0));
  }, [dispatch]);

  useEffect(() => {
    if (dashboardModuleTitle)
      dispatch(
        setActiveModule({ title: dashboardModuleTitle, subtitle: null })
      );
  }, [dispatch, dashboardModuleTitle]);

  const handleClose = () => {
    //
  };

  const toggleContactModal = () => {
    //
  };

  const renderRows = () => {
    if (tasks.length > 0) {
      return (
        <Container style={{ minWidth: 992 }}>
          {tasks.map((s) => (
            <ListItem
              key={`${uuidv4(8)}_${s.id}`}
              row={s}
              swModal={toggleContactModal}
            />
          ))}
        </Container>
      );
    }

    if (!isPending) {
      return (
        <Container className={`mb-5 ${styles.noTasks}`}>
          <Row className="mb-5">
            <Col className="d-flex flex-column align-items-center mt-5">
              <h3 className={styles.fadeIn}>No hay tareas para mostrar</h3>
              <img
                style={{ maxHeight: 300 }}
                className={`img ${styles.fadeIn}`}
                src={noTasks}
                alt="empty"
              />
            </Col>
          </Row>
        </Container>
      );
    }
    return null;
  };

  if (isPending) {
    return (
      <Container className={styles.skeleton}>
        <Row>
          <Col className="d-flex justify-content-center mt-5">
            <Skeletons.Datalist />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      {tasks.length ? <ListHeader /> : null}

      {/* Tasks list */}
      {renderRows()}

      {/* Modal LastContact */}
      {showLastContact ? (
        <LastManagement
          student={studentInfo}
          isVisible={showLastContact}
          onClose={handleClose}
        />
      ) : null}
    </>
  );
};

export default React.memo(DataList);
