export const SET_SESSION = "SET_SESSION";
export const CLEAR_SESSION = "CLEAR_SESSION";
export const UPDATE_USER_SESSION = "UPDATE_USER_SESSION";
export const CHANGE_ANONYMOUS = "CHANGE_ANONYMOUS";
export const setSession = (session) => ({
  type: SET_SESSION,
  payload: session,
});
export const clearSession = () => ({ type: CLEAR_SESSION, payload: null });

export const updateUserSession = (session) => ({
  type: UPDATE_USER_SESSION,
  payload: session,
});

export const changeAnonymous = (anonymous) => ({
  type: CHANGE_ANONYMOUS,
  payload: anonymous,
});
