import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { replaceChars, capitalizeWord } from "utils";
import styles from "./NFilterBadge.module.css";

const FilterBadge = ({ filterInfo, onClickDelete, label }) => {
  if (Object.keys(filterInfo).length === 0) return null;
  return (
    <small className={`${styles.badgeFilter} `}>
      <span className="font-weight-bold mr-1">
        {capitalizeWord(
          replaceChars(filterInfo?.label ?? filterInfo.key, "_", " ")
        )}
        :{" "}
      </span>
      <span className="">
        {filterInfo?.keyValue
          ? filterInfo.value[filterInfo?.keyValue]
              ?.toString()
              ?.replace("++", " | ")
              ?.replace("##", "")
              ?.replace("<>", " - ")
          : filterInfo.value
          ? filterInfo.value
              ?.toString()
              ?.replace("++", " | ")
              ?.replace("##", "")
              ?.replace("<>", " - ")
          : ""}
      </span>
      <FontAwesomeIcon
        className={`ml-2 ${styles.badgeIcon}`}
        icon={faTimesCircle}
        onClick={() => {
          onClickDelete(filterInfo);
        }}
      />
    </small>
  );
};

export default FilterBadge;
