import React from "react";
import NBadge from "components/NBadge";

const NCheckBadge = ({
  label,
  icon,
  className,
  onChange,
  checked,
  customStyle = {},
  disabled,
}) => {
  return (
    <div className={className} style={customStyle}>
      <NBadge
        text={label}
        customStyle={{
          padding: "10px",
          borderRadius: "50px",
          fontSize: "14px",
          background: !checked ? "#D9E1F2" : "#334FFF",
          color: !checked ? "#2A2B2E" : "white",
          cursor: "pointer",
        }}
        colorIcon={!checked ? "#9CABC2" : "white"}
        icon={icon}
        positionIcon={"left"}
        onClick={() => {
          onChange({ target: { checked: !checked } });
        }}
        disabled={disabled}
      />
    </div>
  );
};

export default NCheckBadge;
