import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { noTasks } from "assets/images";
import styles from "containers/Task/Task.module.css";

const ContainerEmpty = ({ text, image, version = "1", description }) => {
  return (
    <Container fluid className={styles.noTasks}>
      <Row>
        <Col className="d-flex flex-column align-items-center mt-5">
          {version === "1" && <h3 className={styles.fadeIn}>{text}</h3>}

          {!image && (
            <img
              style={{ maxHeight: 300 }}
              className={`img ${styles.fadeIn}`}
              src={noTasks}
              alt="empty"
            />
          )}

          {image && <div>{image}</div>}
          {version !== "1" && (
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>{text}</div>
              <div>{description}</div>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ContainerEmpty;
