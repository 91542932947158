import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getDataFromLocalStorage } from "utils";

const useSession = () => {
  const sessionData = useSelector((state) => state.auth);

  const [user, setUser] = useState(getDataFromLocalStorage("user"));
  const [token, setToken] = useState(getDataFromLocalStorage("token"));

  useEffect(() => {
    if (sessionData.user) {
      setUser(sessionData.user);
    }
    if (sessionData.token) {
      setToken(sessionData.token);
    }
  }, [sessionData]);

  return { user, token };
};

export default useSession;
