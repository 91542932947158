import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/pro-regular-svg-icons";

import { TitleRisk, SubTitleRisk } from "./CardDashboard.styled";
import { IconContainer } from "../styled";

const RiskItem = ({ total, riskText, colorBackground, textColor }) => {
  const getSubtitleText = (text) => {
    return (
      <SubTitleRisk>
        <SubTitleRisk>
          <span dangerouslySetInnerHTML={{ __html: text }}></span>
        </SubTitleRisk>
      </SubTitleRisk>
    );
  };
  return (
    <div className="d-flex">
      <IconContainer size="30px" colorBackground={colorBackground}>
        <FontAwesomeIcon icon={faFlag} color={textColor} />
      </IconContainer>
      <div>
        <TitleRisk textColor={textColor}>{total}</TitleRisk>
        {getSubtitleText(riskText)}
      </div>
    </div>
  );
};

export default RiskItem;
