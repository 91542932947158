import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { Container } from "react-bootstrap";
import React from "react";
import Collapse from "react-bootstrap/Collapse";
import styled from "styled-components";
const HelpCenterDropDown = ({ title, content }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <ItemFAQ onClick={() => setOpen(!open)}>
      <Container fluid className="d-flex justify-content-between p-0">
        <div>
          <strong>{title}</strong>
        </div>
        <div>
          {open ? (
            <FontAwesomeIcon icon={faAngleUp} style={{ fontSize: "20px" }} />
          ) : (
            <FontAwesomeIcon icon={faAngleDown} style={{ fontSize: "20px" }} />
          )}
        </div>
      </Container>
      <Collapse in={open} style={{ marginTop: "12px" }}>
        <ContainerText
          className="p-0"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></ContainerText>
      </Collapse>
    </ItemFAQ>
  );
};

export default HelpCenterDropDown;

const ItemFAQ = styled.div`
  cursor: pointer;
  padding: 10px 0px 10px 0px;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  &:last-child {
    border-bottom: 0px;
  }
  &:first-child {
    border-bottom: 0px;
  }
`;

const ContainerText = styled(Container)`
  color: var(--gray-scale-1000, #9cabc2);
`;
