import React, { useEffect } from "react";
import _ from "lodash";
import { NNavbarDashboard, ScrollToTopOnMount } from "components/Nimbiv2";
import { useTranslation } from "react-i18next";
import { setActiveModule } from "store/actions/site.actions";
import { useDispatch, useSelector } from "react-redux";
import AssociatedForm from "containers/Associated/AssociatedForm";
import { Card, Col, Container } from "react-bootstrap";
import { DividerH, RowItem } from "components/Nimbiv2/styled";
import styles from "containers/User/User.module.css";
import {
  fetchAssociatedRoles,
  fetchAssociatedTrackingObjects,
} from "store/actions/async/associated-async.actions";
import { fetchUserTrackingObjects } from "store/actions/async/user-async.actions";
import { toast } from "react-toastify";
import { createAssociated } from "services/associated.service";
import { useHistory } from "react-router-dom";

const PageAddAssociated = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(
      setActiveModule({ title: _.upperFirst(t("officials")), subtitle: null })
    );
  }, [dispatch, t]);
  const {
    numberCalls: {
      listRoles: NumberCallRoles,
      listTrackingObject: NumberCallTracking,
    },
  } = useSelector((state) => state.associated);

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    role: null,
    tracking_objects: [],
  };
  useEffect(() => {
    dispatch(fetchUserTrackingObjects({ campus_ids: "-1" }));
  }, [dispatch]);

  useEffect(() => {
    if (NumberCallRoles === 0) {
      dispatch(fetchAssociatedRoles());
    }
  }, [dispatch, NumberCallRoles]);

  useEffect(() => {
    if (NumberCallTracking === 0) {
      dispatch(fetchAssociatedTrackingObjects());
    }
  }, [dispatch, NumberCallTracking]);

  const saveChanges = async (values, { setSubmitting }) => {
    let payload = { ...values };
    payload.tracking_objects = values.tracking_objects.join(";");
    const { status, title, message } = await createAssociated(payload);

    if (status === 201) {
      toast.success(t("success_created_official"));
      history.push("/administracion#officials");
    } else if (status === 400) {
      toast.error(`${title} ${message}`);
      setSubmitting(false);
      return false;
    } else {
      toast.error(t("error_create"));
      setSubmitting(false);
    }
  };

  return (
    <div>
      <ScrollToTopOnMount />
      <NNavbarDashboard />
      <Container fluid style={{ paddingBottom: "90px" }}>
        <Card className="mt-4" style={{ borderRadius: "15px", border: "0px" }}>
          <Container
            fluid
            style={{
              paddingTop: "32px",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <div
              style={{
                paddingLeft: "24px",
                paddingRight: "24px",
              }}
            >
              <RowItem style={{ marginBottom: "25px" }}>
                <Col>
                  <div className={styles.titleUserAction}>
                    {t("create_new_official")}
                  </div>
                </Col>
              </RowItem>
              <RowItem>
                <Col sm={6}>
                  <DividerH />
                </Col>
              </RowItem>
            </div>
            <div
              style={{
                background: "#F8FBFF",
                paddingTop: "32px",
                paddingBottom: "32px",
                paddingLeft: "24px",
                paddingRight: "24px",
              }}
            >
              <RowItem style={{ marginBottom: "24px" }}>
                <Col>
                  <div
                    className="font-weight-bold"
                    style={{ fontSize: "var(--font-size-lg)" }}
                  >
                    {t("personal_information_create")}
                  </div>
                </Col>
              </RowItem>
              <AssociatedForm
                formValues={initialValues}
                onFormSubmit={saveChanges}
              />
            </div>
          </Container>
        </Card>
      </Container>
    </div>
  );
};
export default PageAddAssociated;
