/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Highlighter } from "react-bootstrap-typeahead";
import { APP_MODULES, getText, setSessionFilters } from "utils";
import { getAllCampus } from "services/crmService";
import { TextError } from "components/Nimbiv2";
import NInputFilter from "../NInputFilter";

import styles from "./NSearchCampus.module.css";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";

const {
  STUDENT: { FILTER_NAME: FILTER_STUDENT },
} = APP_MODULES;
const filterFields = [];
const orderFields = ["campus"];

const NSearchCampus = ({
  filterKey,
  fieldName,
  onChange,
  dataset = [],
  isAsync = false,
  isDisabled = false,
  isMultiple = false,
  itemsSelected = [],
  placeholder = getText("placeholder_name_of_campus"),
  showIcon = false,
  errorText,
}) => {
  const [selectedItem, setSelectedItem] = useState([]);

  useEffect(() => {
    if (itemsSelected.length > 0) {
      setSelectedItem(itemsSelected);
    }
  }, [itemsSelected]);

  const handleFilterChanged = (data) => {
    const [selected = {}] = data;
    const query = selected[fieldName];
    setSessionFilters(FILTER_STUDENT, {
      page: 1,
      [filterKey]: query ?? "",
    });

    setSelectedItem(data);
    if (isMultiple) {
      onChange(data);
    } else {
      onChange(selected);
    }
  };

  const customMenuItem = (option, { text }) => (
    <div className={styles.filterBody}>
      <div>
        <Highlighter search={text}>{option.campus}</Highlighter>
      </div>
    </div>
  );

  const asyncSearchCampus = async (query) => {
    const isNumber = !isNaN(query);
    const params = {
      page_size: 10,
      search: query,
      ordering: isNumber ? orderFields.join() : orderFields.reverse().join(),
    };
    const {
      data: { results: matchedData },
    } = await getAllCampus(params);
    return Promise.resolve(matchedData);
  };

  return (
    <>
      <NInputFilter
        handleChange={handleFilterChanged}
        data={dataset}
        compositeLabel={["campus"]}
        placeholder={placeholder}
        selected={selectedItem}
        filterBy={filterFields}
        renderMenuItem={customMenuItem}
        asyncSearch={asyncSearchCampus}
        isAsync={isAsync}
        isDisabled={isDisabled}
        isMultiple={isMultiple}
        showIcon={showIcon}
      />
      {errorText && errorText !== "" && (
        <TextError
          text={errorText}
          icon={faExclamationCircle}
          marginTop="5px"
        />
      )}
    </>
  );
};

NSearchCampus.propTypes = {
  filterKey: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  dataset: PropTypes.arrayOf(PropTypes.object),
  isAsync: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default NSearchCampus;
