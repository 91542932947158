import { getDataFromLocalStorage } from "utils";
import PropTypes from "prop-types";
import { permissionChecker } from "./helpers";

const ProtectedBlock = ({ children, allowedCode = "", active = true }) => {
  const { groups = [] } = getDataFromLocalStorage("user", {});

  // in case the user don't belong to eClass
  const access = permissionChecker({ allowedCode, userRoles: groups });
  return access && active && children;
};

ProtectedBlock.propTypes = {
  children: PropTypes.element,
  allowedCode: PropTypes.string,
};

export default ProtectedBlock;
