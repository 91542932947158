import {
  GET_TASK,
  SET_TASK,
  REGISTER_ERROR,
  SET_CUSTOM_PERSON,
  GET_QUESTIONS,
  SET_QUESTIONS,
  GET_CUSTOM_PERSON,
  CLEAN_TASK,
} from "store/actions/task.actions";

const initialState = {
  listTasks: [],
  task: null,
  customInfoPerson: [],
  questions: [],
  pending: {
    listTasks: false,
    task: true,
    customInfoPerson: false,
    questions: false,
  },
};

const taskReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_TASK: {
      return {
        ...state,
        task: null,
        pending: { ...state.pending, task: true },
      };
    }
    case CLEAN_TASK: {
      return {
        ...state,
        task: null,
      };
    }

    case SET_TASK: {
      const results = action.payload;

      return {
        ...state,
        task: results,
        pending: { ...state.pending, task: false },
      };
    }

    case REGISTER_ERROR: {
      const auxPending = { ...state.pending };
      if (action.payload.key) auxPending[action.payload.key] = false;

      return { ...state, pending: auxPending };
    }

    case GET_CUSTOM_PERSON: {
      return {
        ...state,
        customInfoPerson: [],
        pending: { ...state.pending, customInfoPerson: false },
      };
    }

    case SET_CUSTOM_PERSON: {
      const results = action.payload;

      return {
        ...state,
        customInfoPerson: results,
        pending: { ...state.pending, customInfoPerson: false },
      };
    }

    case GET_QUESTIONS: {
      return {
        ...state,
        questions: [],
        pending: { ...state.pending, questions: true },
      };
    }

    case SET_QUESTIONS: {
      const results = action.payload;

      return {
        ...state,
        questions: results,
        pending: { ...state.pending, questions: false },
      };
    }

    default:
      return { ...state };
  }
};

export default taskReducers;
