import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt } from "@fortawesome/pro-regular-svg-icons";
import {
  fetchStudents,
  fetchStudentsInCourses,
} from "store/actions/async/student-async.actions";
import { NPagination } from "components";
import { APP_MODULES, getSessionFilters, setSessionFilters } from "utils";
import { RowItem } from "components/Nimbiv2/styled";

const PaginationBar = () => {
  const dispatch = useDispatch();

  const {
    list: studentList,
    pending: { list: isPending },
    pagination,
  } = useSelector((state) => state.student);

  const loadPage = (pageNumber, pageSize) => {
    const {
      _filteredList: isFilteredList = false,
      _courses: courseList = [],
    } = getSessionFilters(APP_MODULES.STUDENT.FILTER_NAME);
    setSessionFilters(APP_MODULES.STUDENT.FILTER_NAME, {
      page: pageNumber,
      page_size: Number(pageSize),
    });

    if (isFilteredList) {
      const finalCourses = courseList.map((course) =>
        Number(course.external_id)
      );
      dispatch(fetchStudentsInCourses(finalCourses));
    } else {
      dispatch(fetchStudents());
    }
  };

  if (studentList.length === 0) return null;

  return (
    <Container fluid className="mt-4" style={{ paddingBottom: "90px" }}>
      <RowItem>
        <Col className="d-flex align-items-center justify-content-start" xs={2}>
          <span className="d-none">
            <FontAwesomeIcon
              className="mr-2"
              size="1x"
              icon={faCloudDownloadAlt}
            />
            Excel
          </span>
        </Col>
        <Col
          style={{ paddingRight: 0 }}
          className="d-flex align-items-center justify-content-end"
        >
          <NPagination
            isPending={isPending}
            pagination={pagination}
            onPageChange={loadPage}
          />
        </Col>
      </RowItem>
    </Container>
  );
};

export default PaginationBar;
