import React from "react";
import styled from "styled-components";
import { NIcon } from "components/Nimbiv2";

const ListOptionClip = ({
  listOptions,
  selected,
  onChange = () => {},
  selectedAll = false,
  error,
  showError = false,
  keyValue = "value",
  keyTitle = "title",
  isDisabled = false,
  planeText = false,
}) => {
  return (
    <>
      <div style={{ display: "flex" }}>
        {listOptions.map((item, key) => {
          return (
            <OptionItemClip
              key={`${key}-initiatives-options-${item[keyValue]}`}
              item={item}
              selected={selectedAll ? true : item[keyValue] === selected}
              onClick={() => {
                onChange(item[keyValue], item);
              }}
              keyTitle={keyTitle}
              isDisabled={isDisabled}
              planeText={planeText}
            />
          );
        })}
      </div>
      {showError && (
        <div style={{ marginTop: "16px", color: "#E23232" }}>
          {error}
          <NIcon
            iconname="exclamationCircle"
            style={{ marginLeft: "4px" }}
          ></NIcon>
        </div>
      )}
    </>
  );
};

const OptionItemClip = ({
  item,
  selected,
  onClick,
  keyTitle,
  isDisabled,
  planeText,
}) => {
  return (
    <OptionItem
      selected={selected}
      onClick={onClick}
      isDisabled={isDisabled ? 1 : 0}
      planeText={planeText}
    >
      {item.icon && (
        <NIcon
          iconname={item.icon}
          style={{ marginRight: "8px", fontSize: "var(--font-size-lg)" }}
        ></NIcon>
      )}
      {item[keyTitle]}
    </OptionItem>
  );
};
export default ListOptionClip;
const OptionItem = styled.div`
  display: flex;
  justify-content: center;

  align-items: center;
  ${(props) =>
    !props?.planeText
      ? `
      margin-left: 10px;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 100px;
  font-size: var(--font-size-lg);

  `
      : "   margin-left: 20px;   color: #334FFF; background: transparent !important;"}
  background: ${(props) =>
    props.selected
      ? "#334FFF"
      : "linear-gradient(0deg, #edf1f7, #edf1f7), #ffffff"};

  ${(props) =>
    props.selected && !props?.planeText
      ? "border: 1px solid #334FFF; color: #FFFFFF;"
      : ""}

  &:first-child {
    margin-left: 0px;
  }

  ${(props) =>
    props.isDisabled === 1
      ? `
  pointer-events: none;
  opacity: 0.4;
  `
      : ``}
`;
