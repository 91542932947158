import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { APP_MODULES, setDataToLocalStorage, getSessionFilters } from "utils";
import { getTaskList } from "store/actions/async/crm-async.action";
import { searchStudentsInMoment } from "services/student.service";
import styles from "./Task.module.css";
import NDynamicFilters from "components/NDynamicFilters";
import { applyMomentFilter } from "utils/moments";
import { RowItem } from "components/Nimbiv2/styled";
const QUERY_PARAMS = {
  FILTER_KEY: "student_tracking__student__external_id",
  FIELD_NAME: "external_id",
};

const FilterBar = () => {
  const dispatch = useDispatch();

  const {
    pending: { list: isPendingList },
    pagination: { pageSize },
    moments,
  } = useSelector((state) => state.crm);

  const activeMoment = useMemo(() => {
    const results = moments.filter((moment) => moment.selected);
    return results.length > 0 ? results[0] : null;
  }, [moments]);

  const searchStudents = useCallback(
    async (query) => {
      if (!activeMoment) return Promise.resolve([]);
      const params = { page_size: 20 };
      const payload = { moment_id: activeMoment.id, value: query };
      const response = await searchStudentsInMoment(params, payload);
      let results;
      if (response.data) results = response.data.results;
      return Promise.resolve(results ?? []);
    },
    [activeMoment]
  );

  const getAllTasks = useCallback(
    (selected) => {
      setDataToLocalStorage("avoidReloadTasks", true);
      const queryParams = getSessionFilters(APP_MODULES.TASK.FILTER_NAME, true);
      const query = selected[QUERY_PARAMS.FIELD_NAME];
      const payload = {
        ...queryParams,
        [QUERY_PARAMS.FILTER_KEY]: query ?? "",
      };
      dispatch(getTaskList(payload));
    },
    [dispatch]
  );

  const getAllResults = useCallback(() => {
    // task request with new filters
    setDataToLocalStorage("avoidReloadTasks", true);
    dispatch(getTaskList());
  }, [dispatch]);

  return (
    <>
      <RowItem>
        <Col className="mt-4" md={12} style={{ paddingLeft: "0px" }}>
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <SelectedMoment />
            </div>
            <div>
              <Form.Control
                as="select"
                value={moments.find((moment) => moment.selected)?.id}
                onChange={(event) => {
                  applyMomentFilter(dispatch, event.target.value, pageSize);
                }}
              >
                {moments &&
                  moments.map((moment, key) => {
                    return (
                      <option key={`moment-${key}`} value={moment.id}>
                        {moment.name}
                      </option>
                    );
                  })}
              </Form.Control>
            </div>
          </div>
        </Col>
      </RowItem>
      <RowItem className={`align-items-center mt-3 ${styles.toolbarBlock}`}>
        <Col className={`mb-3 p-0 ${styles.tabContainer}`} md={12}>
          <NDynamicFilters
            panel={APP_MODULES.TASK.ID}
            isPendingList={isPendingList}
            onGetSingleStudent={getAllTasks}
            onGetSuggestStudents={searchStudents}
            onLazySecondList={getAllResults}
            showPriorityFilter
          />
        </Col>
      </RowItem>
      <RowItem>
        <Col className="mb-4" md={12} style={{ paddingLeft: "8px" }}>
          <ResultInfo />
        </Col>
      </RowItem>
    </>
  );
};

export default React.memo(FilterBar);

const SelectedMoment = () => {
  const moments = useSelector((state) => state.crm.moments);
  if (moments.length === 0) return null;

  return (
    <div style={{ fontWeight: 500, fontSize: "1.25em", lineHeight: "36px" }}>
      {moments.find((moment) => moment.selected)?.name}
    </div>
  );
};

const ResultInfo = () => {
  const {
    pagination: { count: totalRows },
    moments,
    pending: { list: pendingList },
  } = useSelector((state) => state.crm);

  if (moments.length === 0 || pendingList) return null;

  return (
    <div
      style={{ fontWeight: "normal", fontSize: "0.875em", lineHeight: "21px" }}
    >
      Se encontraron <strong>{totalRows}</strong> tareas en{" "}
      <strong>{moments.find((moment) => moment.selected)?.name}</strong>
    </div>
  );
};
