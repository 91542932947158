// Set titles constants
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_HELP_ITEM_TYPES = "SET_HELP_ITEM_TYPES";
export const SET_HELP_ITEMS = "SET_HELP_ITEMS";
export const GET_HELP_ITEMS = "GET_HELP_ITEMS";

// Export actual actions
export const setIsLoading = (value) => ({
  type: SET_IS_LOADING,
  payload: value,
});

export const setHelpItemTypes = (data) => ({
  type: SET_HELP_ITEM_TYPES,
  payload: data,
});

export const setHelpItems = (data) => ({
  type: SET_HELP_ITEMS,
  payload: data,
});

export const getHelpItems = (data) => ({
  type: GET_HELP_ITEMS,
  payload: data,
});
