import React from "react";
import { StepTitle, TextTitle } from "components/Nimbiv2/InitiativesComponent";
import { DividerH, CardNimbi, RowItem } from "components/Nimbiv2/styled";
import { Col } from "react-bootstrap";
import { NIcon, NButton } from "components/Nimbiv2";
import { useTranslation } from "react-i18next";
import { ButtonSize } from "utils/constants";
import NCheckbox from "components/Nimbiv2/NCheckbox";

const PageStudentResponse = ({
  onChange,
  formValues,
  onChangeForm,
  pageNumber,
  errors,
  edit = false,
  studentResponses = [],
}) => {
  const { t } = useTranslation();
  return (
    <div style={{ maxWidth: "922px", margin: "auto", paddingBottom: "50px" }}>
      <CardNimbi padding="0px">
        <div style={{ padding: "33px 35px 0px 35px" }}>
          <RowItem>
            <Col xs="8" style={{ paddingLeft: "0px" }}>
              <StepTitle>
                {pageNumber ? pageNumber : t("step_number", { number: "4" })}
              </StepTitle>
              <div style={{ marginTop: "16px" }}>
                <TextTitle>{"Seleccionar respuestas de estudiantes"}</TextTitle>
              </div>
              <DividerH style={{ marginTop: "9px" }} />
            </Col>
          </RowItem>
          {errors.hasOwnProperty("required_student_responses") && (
            <div style={{ marginTop: "15px" }}>
              <div style={{ color: "#E23232" }}>
                * {errors["required_student_responses"]}
                <NIcon
                  iconname="exclamationCircle"
                  style={{ marginLeft: "4px" }}
                ></NIcon>
              </div>
            </div>
          )}
          <div style={{ paddingBottom: "20px" }}>
            {studentResponses && studentResponses?.length > 0 && (
              <NCheckbox
                initialData={formValues?.student_responses}
                dataset={studentResponses}
                label={"response_text"}
                value={"id"}
                onChange={(data) => {
                  onChangeForm("student_responses", data);
                }}
              />
            )}
            {studentResponses && studentResponses?.length === 0 && (
              <div>No tiene respuestas de estudiantes para mostrar.</div>
            )}
          </div>
        </div>
      </CardNimbi>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div>
          <NButton
            sizeStyle={ButtonSize.lg}
            style={{
              marginTop: "24px",
              borderRadius: "24px",
              marginRight: "16px",
            }}
            onClick={() => {
              onChange("last");
            }}
            outlined
            icon={<NIcon iconname="arrowLeft" />}
            color="second"
          >
            {t("go_back")}
          </NButton>
          <NButton
            sizeStyle={ButtonSize.lg}
            style={{
              marginTop: "24px",
              borderRadius: "24px",
              textTransform: "capitalize",
            }}
            onClick={() => {
              onChange("next");
            }}
            icon={<NIcon iconname="arrowRight" />}
            positionIcon="right"
            color="second"
          >
            {t("continue")}
          </NButton>
        </div>
      </div>
    </div>
  );
};

export default PageStudentResponse;
