import { NButton, NIcon } from "components/Nimbiv2";
import {
  StepTitle,
  TextQuestion,
} from "components/Nimbiv2/InitiativesComponent";
import { CardNimbi, DividerH, RowItem } from "components/Nimbiv2/styled";
import NRadioButton from "components/Nimbiv2/NRadioButton";
import React, { useEffect, useMemo } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ButtonSize } from "utils/constants";

const PageSelectQuestion = ({
  errors,
  pageNumber,
  descriptionStep,
  questions,
  onChange,
  onChangeForm,
  formValues,
}) => {
  const { t } = useTranslation();
  const questionList = useMemo(() => {
    let data = [...questions];
    data.unshift({
      description: t("do_not_use_form"),
      detail: "",
      id: -1,
    });
    return data;
  }, [questions, t]);

  useEffect(() => {
    if (!formValues?.question_selected) {
      onChangeForm("question_selected", {
        description: t("do_not_use_form"),
        detail: "",
        id: -1,
      });
    }
  }, [formValues?.question_selected, t]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ paddingBottom: "50px" }}>
      <div style={{ maxWidth: "922px", margin: "auto" }}>
        <CardNimbi padding="0px">
          <div style={{ padding: "33px 35px 16px 35px" }}>
            <RowItem>
              <Col sm={12} lg="7" md="9">
                {/* <StepTitle>{textStep}</StepTitle> */}
                <StepTitle>
                  {pageNumber ? pageNumber : t("step_number", { number: "2" })}
                </StepTitle>
                <TextQuestion style={{ marginTop: "16px" }}>
                  {descriptionStep}
                </TextQuestion>
                <DividerH style={{ marginTop: "9px" }} />
              </Col>
            </RowItem>
            <div
              style={{
                marginTop: "10px",
                paddingTop: "30px",
                paddingLeft: "20px",
                paddingRight: "20px",
                backgroundColor: "rgb(248, 251, 255)",
              }}
            >
              {errors.hasOwnProperty("required_question") && (
                <div style={{ marginTop: "15px" }}>
                  <div style={{ color: "#E23232" }}>
                    * {errors["required_question"]}
                    <NIcon
                      iconname="exclamationCircle"
                      style={{ marginLeft: "4px" }}
                    ></NIcon>
                  </div>
                </div>
              )}
              {questions && questions?.length > 0 && (
                <div style={{ paddingBottom: "20px" }}>
                  <NRadioButton
                    initialData={
                      formValues?.question_selected ?? {
                        description: t("do_not_use_form"),
                        detail: "",
                        id: -1,
                      }
                    }
                    dataset={questionList}
                    label={"description"}
                    value={"id"}
                    onChange={(data) => {
                      onChangeForm("question_selected", data);
                    }}
                  />
                </div>
              )}
              {questionList && questionList?.length === 0 && (
                <div>No tiene cuestionarios para mostrar.</div>
              )}
            </div>
          </div>
        </CardNimbi>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div>
            <NButton
              sizeStyle={ButtonSize.lg}
              style={{
                marginTop: "24px",
                borderRadius: "24px",
                marginRight: "16px",
              }}
              onClick={() => {
                onChange("last");
              }}
              outlined
              icon={<NIcon iconname="arrowLeft" />}
              color="second"
            >
              {t("go_back")}
            </NButton>
            <NButton
              sizeStyle={ButtonSize.lg}
              style={{
                marginTop: "24px",
                borderRadius: "24px",
                textTransform: "capitalize",
              }}
              onClick={() => {
                onChange("next");
              }}
              icon={<NIcon iconname="arrowRight" />}
              positionIcon="right"
              color="second"
            >
              {t("continue")}
            </NButton>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageSelectQuestion;
