import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { fetchTeams } from "store/actions/async/team-async.actions";
import { teamsAdvancedSearch } from "services/team.service";
import { NIcon, SearchContainer, NButton } from "components/Nimbiv2";
import { TeamList, FormTeam } from "containers/Team";
import { BaseModal } from "components";
import {
  APP_MODULES,
  deleteLocalStorage,
  getDataFromLocalStorage,
  setDataToLocalStorage,
  setSessionFilters,
} from "utils";
import { useTranslationLocal } from "hooks";
import ContainerFilters from "components/Nimbiv2/ContainerFilters";

const PageTeam = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setSessionFilters(APP_MODULES.TEAM.FILTER_NAME, {
      page: 1,
      page_size: 10,
    });
    const data = getDataFromLocalStorage("searchTeam", null);
    if (data) {
      dispatch(fetchTeams({ id: data[0]["id"] }));
    } else {
      dispatch(fetchTeams());
    }
  }, [dispatch]);

  const {
    pending: { teams: isPending },
    pagination,
  } = useSelector((state) => state.team);

  const customMenuItem = (option, { text }) => {
    return <div>{option.name}</div>;
  };

  const onChange = (selected) => {
    let data = {};
    if (selected.id) {
      data = {
        id: selected["id"],
      };
    }
    dispatch(fetchTeams(data));
    if (_.isEmpty(selected)) {
      deleteLocalStorage("searchTeam");
    } else {
      setDataToLocalStorage("searchTeam", [selected]);
    }
  };

  return (
    <Container fluid>
      <BaseModal
        title="Crear Equipo"
        isVisible={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <FormTeam
          team={{ name: "" }}
          onClose={() => {
            setOpenModal(false);
          }}
        />
      </BaseModal>
      <SearchContainer
        placeholder={translation(
          "placeholder_search_team",
          "",
          t("enter_team_name")
        )}
        fetchSearch={teamsAdvancedSearch}
        filterKeySearch="id"
        fieldNameSearch="id"
        compositeLabel={["name"]}
        isDisabled={isPending}
        customMenuItem={customMenuItem}
        filterName="team_filters"
        persist={false}
        onChange={onChange}
        persistSearch="searchTeam"
        buttonsCreate={
          <NButton
            padding="9px 15px"
            border="24px"
            onClick={() => {
              setOpenModal(true);
            }}
            color="second"
            style={{ minWidth: "180px", marginLeft: "24px" }}
            size="14px"
          >
            <NIcon
              style={{
                fontSize: "var(--font-size-caption)",
                marginRight: "5px",
              }}
              iconname="plus"
            />
            {t("create_team")}
          </NButton>
        }
      />

      <div style={{ marginTop: "16px" }}>
        <ContainerFilters
          textCount={t("teams_found")}
          countItems={pagination?.count}
        />
      </div>
      <TeamList />
    </Container>
  );
};

export default PageTeam;
