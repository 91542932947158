/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";

const Toolbar = ({ children, isHidden = false }) => {
  if (isHidden || !children) return null;

  return <Container fluid>{children}</Container>;
};

Toolbar.propTypes = {
  isHidden: PropTypes.bool,
};

export default Toolbar;
