import styled from "styled-components";

const IconContainer = styled.div`
  min-width: ${(props) => (props.size ? props.size : "50px")};
  background: ${(props) =>
    props.colorBackground ? props.colorBackground : "#edf1f7"};
  border-radius: ${(props) => (props.radius ? props.radius : "50%")};
  width: ${(props) => (props.size ? props.size : "50px")};
  height: ${(props) => (props.size ? props.size : "50px")};
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  color: ${(props) => (props.color ? props.color : "#000000")};
`;
export default IconContainer;
