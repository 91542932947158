import React, { useEffect, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faPhone } from "@fortawesome/pro-regular-svg-icons";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { NSpinnerPoints } from "components/Nimbiv2";
import { CardNimbi, RowItem } from "components/Nimbiv2/styled";
import CardIndicator from "components/Nimbiv2/CardIndicator";
import { TextTitle } from "components/Nimbiv2/InitiativesComponent";
import { useTranslationLocal } from "hooks";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const PageIndicatorCall = () => {
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const {
    initiative,
    insights,
    pending: { initiative: isPending, insights: isPendingInsights },
  } = useSelector((state) => state.initiative);

  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const [dataset, setDataset] = useState(null);
  const [itemInsights, setItemInsights] = useState(null);
  useEffect(() => {
    if (
      insights &&
      insights.insights &&
      insights.insights.graph_data &&
      insights.insights.graph_data.total_calls &&
      insights.insights.graph_data.comparative_calls
    ) {
      const { insights: itemInsightsList } = insights;
      setItemInsights(itemInsightsList);
      let labelAux = [];
      let dataAux = [];
      const { insights: listInsights } = insights;
      for (let i = 0; i < listInsights.graph_data.total_calls.length; i++) {
        labelAux.push(
          listInsights.graph_data.total_calls[i].day +
            " " +
            moment(listInsights.graph_data.total_calls[i].date).format("DD-MM")
        );
        dataAux.push(listInsights.graph_data.total_calls[i].calls);
      }
      setLabels(labelAux);
      setData(dataAux);

      let labelsCompare = [];
      let dataCalls = [];
      let dataCallsSuccess = [];
      for (
        let x = 0;
        x < listInsights.graph_data.comparative_calls.length;
        x++
      ) {
        labelsCompare.push(
          listInsights.graph_data.comparative_calls[x].day +
            " " +
            moment(listInsights.graph_data.comparative_calls[x].date).format(
              "DD-MM"
            )
        );
        dataCalls.push(
          listInsights.graph_data.comparative_calls[x].total_calls
        );
        dataCallsSuccess.push(
          listInsights.graph_data.comparative_calls[x].successfull_calls
        );
      }
      setDataset({
        labels: labelsCompare,
        datasets: [
          {
            label: t("calls_made"),
            data: dataCalls.map((item) => item),
            fill: 1,
            backgroundColor: "#334FFF",
            borderColor: "#334FFF",
          },
          {
            label: t("successful_calls"),
            data: dataCallsSuccess.map((item) => item),
            fill: 1,
            backgroundColor: "#3AC8AC",
            borderColor: "#3AC8AC",
          },
        ],
      });
    }
  }, [insights, t]);

  const total_call_send = {
    title: t("stundets_in_the_initiative", {
      count: "-",
      students: _.upperFirst(translation("students", "", t("students"))),
    }),
    total: "-",
    description: t("students_have_been_called_answered", {
      count: "-",
      students: translation("students", "startCase", t("students")),
    }),
    icon: "student",
  };
  const total_email_error = {
    title: t("total_calling_tasks", { count: "-" }),
    total: "",
    description: t("pending_call_tasks"),
    icon: "call",
  };
  const opening_email = {
    title: t("contactability_rate"),
    total: "-%",
    description: t("of_your_calls_were_answered_by_the_student", {
      student: translation("student", "", t("student")),
    }),
    icon: "check",
  };
  // const clicks_links_email = {
  //   title: "Tasa de éxito de tus llamadas",
  //   total: "59%",
  //   description:
  //     "De tus llamadas contestadas lograron que el <strong>alumno cumpliera el objetivo</strong>",
  //   icon: "star",
  // };

  const chartOptions = {
    line: {
      responsive: true,
      plugins: {
        datalabels: false,
      },
    },
    bar: {
      responsive: true,
      plugins: {
        datalabels: false,
      },
      scales: {
        x: {
          offset: true,
        },
      },
    },
  };

  // const { insights: itemInsights } = insights;
  return (
    <div>
      {(isPending || isPendingInsights) && !itemInsights && <NSpinnerPoints />}
      {!isPending && isPending !== null && initiative != null && itemInsights && (
        <ContainerPage>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={faChartLine}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            <TitleText>{t("overall_performance")}</TitleText>
          </div>
          <RowItem style={{ marginTop: "40px" }}>
            <Col lg="9" className="max-7">
              <CardNimbi style={{ height: "auto" }}>
                <TitleText>
                  {t("total_calls_made")}: {itemInsights.called}
                </TitleText>
                <div
                  style={{
                    color:
                      itemInsights.graph_data.total_last_week < 0
                        ? "#C30E0E"
                        : "#00A38C",
                    marginTop: "4px",
                  }}
                >
                  <strong>
                    {itemInsights.graph_data.total_last_week ?? "0"}%{" "}
                  </strong>
                  {t("last_week")}
                </div>
                <div
                  style={{
                    color: "#787C83",
                    fontSize: "var(--font-size-small)",
                    marginTop: "4px",
                  }}
                >
                  {moment(insights.insights.start_date).format("YYYY-MM-DD")} -{" "}
                  {moment(insights.insights.end_date).format("YYYY-MM-DD")}
                </div>
                <div>
                  <Line
                    options={chartOptions.line}
                    data={{
                      labels,
                      datasets: [
                        {
                          label: "Llamados realizados",
                          data: data.map((item) => item),
                          fill: 1,
                          backgroundColor: "#334fff",
                          borderColor: "#334fff",
                        },
                      ],
                    }}
                  />
                </div>
              </CardNimbi>
            </Col>
            <Col md="12" lg="3" className="pt-3 pt-lg-0 max-5">
              <RowItem>
                <Col md="12" lg="12" xl="12" sm="12" className="p-0 max-6">
                  <div
                    style={{ marginBottom: "12px" }}
                    className="w-max-100 mr-max-1"
                  >
                    <CardIndicator
                      data={total_call_send}
                      height="auto"
                      total={itemInsights.called}
                      description={t("students_have_been_called_answered", {
                        count: `<strong class="colorTextSecondary">${itemInsights.answered}</strong>`,
                        students: translation(
                          "students",
                          "startCase",
                          t("students")
                        ),
                      })}
                      title={t("stundets_in_the_initiative", {
                        count: `<strong class="colorTextSecondary">${itemInsights.total_students}</strong>`,
                        students: _.upperFirst(
                          translation("students", "", t("students"))
                        ),
                      })}
                    />
                  </div>
                </Col>
                <Col md="12" lg="12" xl="12" sm="12" className="p-0 max-6">
                  <div className="w-max-100">
                    <CardIndicator
                      data={total_email_error}
                      height="auto"
                      total={itemInsights.pending}
                      title={t("total_calling_tasks", {
                        count: itemInsights.total,
                      })}
                    />
                  </div>
                </Col>
              </RowItem>
            </Col>
          </RowItem>
          <div
            className="d-flex align-items-center"
            style={{ marginTop: "40px" }}
          >
            <FontAwesomeIcon
              icon={faPhone}
              style={{ fontSize: "16px", marginRight: "8px" }}
            />
            <TitleText>{t("call_tasks")}</TitleText>
          </div>
          <RowItem style={{ marginTop: "40px" }}>
            <Col lg="9" sm="12" className="max-7">
              <CardNimbi style={{ height: "auto" }}>
                <TextTitle>{t("calls_made_vs_successful_calls")}</TextTitle>
                <div
                  style={{
                    marginTop: "4px",
                    color:
                      itemInsights.graph_data.comparison_last_week < 0
                        ? "#C30E0E"
                        : "#00A38C",
                  }}
                >
                  <strong>
                    {itemInsights.graph_data.comparison_last_week ?? "0"}%
                  </strong>{" "}
                  {t("last_week")}
                </div>
                <div
                  style={{
                    color: "#787C83",
                    fontSize: "var(--font-size-small)",
                    marginTop: "4px",
                  }}
                >
                  {moment(insights.insights.start_date).format("YYYY-MM-DD")} -{" "}
                  {moment(insights.insights.end_date).format("YYYY-MM-DD")}
                </div>

                <div style={{ marginTop: "24px" }}>
                  {dataset && <Bar options={chartOptions.bar} data={dataset} />}
                </div>
              </CardNimbi>
            </Col>
            <Col lg="3" sm="12" className="pt-3 pt-lg-0 max-5">
              <Col lg="12" className="p-0">
                <div className="max-6">
                  <CardIndicator
                    data={opening_email}
                    action={() => {}}
                    height="auto"
                    total={`${itemInsights.contactablity_rate}%`}
                  />
                </div>
              </Col>
              {/* <div style={{ marginTop: "24px" }}>
                <CardIndicator
                  data={clicks_links_email}
                  action={() => {}}
                  height="auto"
                />
              </div> */}
            </Col>
          </RowItem>
        </ContainerPage>
      )}
    </div>
  );
};

export default PageIndicatorCall;

const TitleText = styled.div`
  font-family: Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 24px */

  /* text/primary */

  color: #2a2b2e;
`;

const ContainerPage = styled.div`
  @media screen and (min-width: 1480px) {
    .max-7 {
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .max-5 {
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .max-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .w-max-100 {
      height: 100%;
      .card {
        height: 100% !important;
      }
    }
    .mr-max-1 {
      margin-right: 10px;
    }
  }
`;
