/* eslint-disable no-unused-vars */
import React, { useEffect, useCallback, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Container, Row, Col, Tab, Dropdown } from "react-bootstrap";
import { toggleCheckableList } from "store/actions/student.actions";
import { fetchStudents } from "store/actions/async/student-async.actions";
import { fetchClientFilters } from "store/actions/async/site-async.action";
import { advancedSearch } from "services/student.service";
import { NMarkItemsBlock, NSidebarContainer } from "components";
import {
  APP_MODULES,
  getGlobalConfig,
  getSessionFilters,
  setSessionFilters,
} from "utils";
import AdvancedFilters from "containers/Student/AdvancedFilters";
import SelectedInfoBar from "containers/Student/SelectedInfoBar";
import taskStyles from "containers/Task/Task.module.css";
import studentStyles from "containers/Student/Student.module.css";
import { permissionChecker } from "routes/helpers";
import { getDataFromLocalStorage } from "utils";
import { NTabs, RowItem } from "components/Nimbiv2/styled";
import NDynamicFilters from "components/Nimbiv2/NDynamicFilters";
import { updateClientFilters } from "store/actions/site.actions";
import { useTranslationLocal } from "hooks";
import { NButton } from "components/Nimbiv2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInboxFull } from "@fortawesome/pro-regular-svg-icons";
import FormExternalMass from "containers/ExternalManagement/FormExternalMass";
import StudentAdviceForm from "containers/Student/StudentAdviceForm";

const {
  STUDENT: { FILTER_NAME: FILTER_STUDENT },
} = APP_MODULES;

const SEARCH = {
  STUDENT: {
    FILTER_KEY: "external_id",
    FIELD_NAME: "external_id",
  },
  TASK: {
    FILTER_KEY: "student_tracking__student__external_id",
    FIELD_NAME: "external_id",
  },
};
const TABKEY = {
  SEARCH: "search",
  FILTER: "filter",
};
const StudentFilters = (props) => {
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const user = useSelector((state) => state.auth.user);
  const { clientFilters } = useSelector((state) => state.site);
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const { groups = [] } = getDataFromLocalStorage("user", {});
  const {
    listCheckable,
    pending: { list: isPendingList },
  } = useSelector((state) => state.student, shallowEqual);
  const initialParam = getSessionFilters(FILTER_STUDENT, true);
  const [tabSelect, setTabSelect] = useState(TABKEY.SEARCH);
  const [openSide, setOpenSide] = useState(false);
  const [openSideAssist, setOpenSideAssist] = useState(false);

  useEffect(() => {
    dispatch(toggleCheckableList(false));
  }, [dispatch]);

  const tabSelected = (k) => {
    if (k === TABKEY.SEARCH) {
      dispatch(toggleCheckableList(false));
    } else if (k === TABKEY.FILTER) {
      dispatch(toggleCheckableList(true));
    }
    if (props.onChangeSelected) {
      props.onChangeSelected(k);
    }
    setTabSelect(k);
  };

  const getAllStudents = useCallback(
    (selected) => {
      // setSessionFilters(FILTER_STUDENT, { _filteredList: false });
      const query = selected[SEARCH.STUDENT.FIELD_NAME];
      const send_query = {
        page: 1,
        page_size: 10,
        [SEARCH.STUDENT.FILTER_KEY]: query ?? "",
      };
      const extra_query = query ? { selectStudent: selected } : {};
      dispatch(fetchStudents(send_query));
      setSessionFilters(
        FILTER_STUDENT,
        { ...send_query, ...extra_query },
        true
      );
      dispatch(
        updateClientFilters({
          data: clientFilters.data,
          filters: {
            ...clientFilters.filters,
            ...{ [APP_MODULES.STUDENT.ID]: {} },
          },
        })
      );
    },
    [dispatch, clientFilters]
  );

  const searchStudents = useCallback(async (query) => {
    const params = { page_size: 100, query: query.trim() };
    const response = await advancedSearch(params);
    let results;
    if (response.data) results = response.data.results;
    return Promise.resolve(results ?? []);
  }, []);

  const getAllResults = useCallback(() => {
    let queryParams = getSessionFilters(FILTER_STUDENT, true);
    if (step === 0 && queryParams.studenttracking__campus__name) {
      delete queryParams.studenttracking__campus__name;
      delete queryParams._courses;
      delete queryParams._filteredList;
      queryParams = setSessionFilters(FILTER_STUDENT, queryParams, true);
      setStep(1);
    }

    const payload = {
      ...queryParams,
    };
    dispatch(fetchStudents(payload));
  }, [dispatch, step]);

  useEffect(() => {
    dispatch(fetchClientFilters({ module: APP_MODULES.STUDENT.ID }));
  }, [dispatch]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{
        paddingLeft: "10px",
        paddingRight: "10px",
        cursor: "pointer",
        width: "auto",
      }}
    >
      <NButton
        href=""
        outlined
        color="second"
        positionIcon="left"
        icon={<FontAwesomeIcon icon={faInboxFull} />}
        style={{ minWidth: "190px" }}
        showIconDown
      >
        {children}
      </NButton>
    </div>
  ));
  return (
    <>
      <RowItem>
        <Col
          md={
            getGlobalConfig("enable_massive_external_management", false) ||
            getGlobalConfig("enable_attendance_group_couceling", false)
              ? 8
              : 12
          }
          sm="12"
          xs="12"
          className="p-0"
          lg={
            getGlobalConfig("enable_massive_external_management", false) ||
            getGlobalConfig("enable_attendance_group_couceling", false)
              ? 9
              : 12
          }
          xl={
            getGlobalConfig("enable_massive_external_management", false) ||
            getGlobalConfig("enable_attendance_group_couceling", false)
              ? 10
              : 12
          }
        >
          <NTabs
            defaultActiveKey={TABKEY.SEARCH}
            id="filter-tabs"
            onSelect={tabSelected}
            marginbottom="10px"
          >
            <Tab
              eventKey={TABKEY.SEARCH}
              title={_.upperFirst(t("search"))}
            ></Tab>

            {permissionChecker({
              allowedCode: "student_apply_actions",
              userRoles: groups,
            }) && (
              <Tab
                eventKey={TABKEY.FILTER}
                title={_.upperFirst(t("actions"))}
              ></Tab>
            )}
          </NTabs>
        </Col>
        {(getGlobalConfig("enable_massive_external_management", false) ||
          getGlobalConfig("enable_attendance_group_couceling", false)) && (
          <Col xl="2" xs="12" lg="3" md="4">
            <div className="d-flex justify-content-end align-items-center h-100">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  as={CustomToggle}
                >
                  {t("import_management")}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {getGlobalConfig(
                    "enable_attendance_group_couceling",
                    false
                  ) && (
                    <Dropdown.Item
                      onClick={() => {
                        setOpenSideAssist(true);
                      }}
                    >
                      Asistencias a asesorías
                    </Dropdown.Item>
                  )}
                  {getGlobalConfig(
                    "enable_massive_external_management",
                    false
                  ) && (
                    <Dropdown.Item
                      onClick={() => {
                        setOpenSide(!openSide);
                      }}
                    >
                      Gestiones externas masivas
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
        )}
      </RowItem>
      <div>
        {tabSelect === TABKEY.SEARCH && (
          <Container fluid>
            <Row
              className={`align-items-center mt-3 ${taskStyles.toolbarBlock}`}
            >
              <Col className={`mb-3 p-0 ${taskStyles.tabContainer}`} md={12}>
                <NDynamicFilters
                  panel={APP_MODULES.STUDENT.ID}
                  isPendingList={isPendingList}
                  filterNameLS={APP_MODULES.STUDENT.FILTER_NAME}
                  onGetSingleStudent={getAllStudents}
                  onGetSuggestStudents={searchStudents}
                  onLazySecondList={getAllResults}
                  showRiskOrder={user?.client_area_ongoing_risk}
                  defaultSearch={initialParam["selectStudent"] ?? ""}
                  countItems={props?.totalRows ?? 0}
                  textCount={t("found_students", {
                    students: translation("students", "", t("students")),
                  })}
                />
              </Col>
            </Row>
          </Container>
        )}
        {tabSelect === TABKEY.FILTER && (
          <div className={studentStyles.filterTab}>
            <AdvancedFilters />
          </div>
        )}
      </div>
      <NSidebarContainer
        title={t("import_management")}
        openSide={openSide}
        onClose={() => setOpenSide(false)}
        width="634px"
      >
        <FormExternalMass
          onClose={() => setOpenSide(false)}
          openSide={openSide}
        />
      </NSidebarContainer>
      <NSidebarContainer
        title={"Asistencia asesorías"}
        openSide={openSideAssist}
        onClose={() => setOpenSideAssist(false)}
        width="634px"
      >
        <StudentAdviceForm
          onClose={() => setOpenSideAssist(false)}
          openSide={openSide}
        />
        {/* <FormExternalMass
          onClose={() => setOpenSideAssist(false)}
          openSide={openSide}
        /> */}
      </NSidebarContainer>
      {listCheckable ? (
        <RowItem className="mt-4">
          <Col xs={12}>
            <NMarkItemsBlock>
              <SelectedInfoBar />
            </NMarkItemsBlock>
          </Col>
        </RowItem>
      ) : null}
    </>
  );
};

export default StudentFilters;
