import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APP_MODULES, setSessionFilters } from "utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-regular-svg-icons";

import { NTable } from "components/Nimbiv2";
import { CardNimbi, NButtonCircle } from "components/Nimbiv2/styled";
import { BaseModal, Col, NPagination } from "components";
import { fetchTeams } from "store/actions/async/team-async.actions";
import { useTranslationLocal } from "hooks";
import FormTeam from "./Modals/FormTeam";
import { ButtonSize } from "utils/constants";

const TeamList = () => {
  const dispatch = useDispatch();
  const { translation } = useTranslationLocal();
  const [selectItem, setSelectItem] = useState(null);
  const columns = [
    {
      title: translation("name", "", "Nombre"),
      dataIndex: "name",
      key: "name",
      render: (text) => <strong className="font-size-caption">{text}</strong>,
    },
    {
      title: translation("users_count", "", "Cantidad de usuarios"),
      dataIndex: "users_count",
      key: "users_count",
      width: "30%",
      render: (text) => <strong className="font-size-caption">{text}</strong>,
    },
    {
      title: translation("action", "upper"),
      key: "mail",
      width: "10%",
      render: (item) => (
        <NButtonCircle
          sizeStyle={ButtonSize.lg}
          onClick={() => setSelectItem(item)}
          icon={<FontAwesomeIcon icon={faPen} style={{ marginTop: "5px" }} />}
        />
      ),
    },
  ];
  const {
    teams,
    pending: { teams: isPending },
    pagination,
  } = useSelector((state) => state.team);

  const loadPage = (pageNumber, pageSize) => {
    setSessionFilters(APP_MODULES.TEAM.FILTER_NAME, {
      page: pageNumber,
      page_size: parseInt(pageSize, 10),
    });
    dispatch(fetchTeams());
  };

  return (
    <div style={{ marginTop: "11.5px" }}>
      <CardNimbi padding="0px 16px 16px 16px">
        <NTable
          widthSkeletons="100%"
          columns={columns}
          dataSource={teams}
          check={false}
          upperCaseHeader
          isPending={isPending}
          textEmpty="No hay equipos para mostrar"
          // pagination={pagination}
          // stylePagination={{ marginBotton: "11.5px", marginTop: "25px" }}
        ></NTable>
      </CardNimbi>
      <div>
        <div className="mt-4 pb-4">
          <Col
            style={{ paddingRight: 0 }}
            className="d-flex align-items-center justify-content-end"
          >
            <NPagination
              isPending={isPending}
              pagination={pagination}
              onPageChange={loadPage}
            />
          </Col>
        </div>
      </div>
      <BaseModal
        title="Editar Equipo"
        isVisible={selectItem !== null ? true : false}
        onClose={() => {
          setSelectItem(null);
        }}
      >
        <FormTeam
          team={selectItem}
          onClose={() => {
            setSelectItem(null);
          }}
        />
      </BaseModal>
    </div>
  );
};
export default TeamList;
