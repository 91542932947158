import React from "react";
import styled from "styled-components";
const MIN = 0;
const MAX = 10;
const NSurveyNps = ({ survey, onClick, score }) => {
  const range = (start, end) => {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  };
  return (
    <ContainerDesign>
      <div
        style={{
          margin: "0px",
          marginBottom: "12px",
          fontSize: "16px",
          fontWeight: "bold",
        }}
      >
        {survey.body}
      </div>
      <SurveyContainer>
        <div className="container-all-numbers">
          {range(MIN, MAX).map((i) => (
            <div
              key={i}
              className={
                score === i ? "container-number active" : "container-number"
              }
              onClick={() => onClick(i)}
            >
              <div>{i}</div>
            </div>
          ))}
        </div>
        <div
          className="d-flex justify-content-between"
          style={{ marginTop: "10px" }}
        >
          <div style={{ color: "#999" }}>Pocas probabilidades</div>
          <div style={{ color: "#999" }}>Muchas probabilidades</div>
        </div>
      </SurveyContainer>
    </ContainerDesign>
  );
};

export default NSurveyNps;
const ContainerDesign = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 30px auto;
  text-align: center;
`;
const SurveyContainer = styled.div`
  margin-top: 10px;
  margin: 0px auto;
  width: 418px;
  margin: 0px auto;
  .container-all-numbers {
    display: flex;
  }
  .container-number {
    margin: 0px 3px;
    border-radius: 50%;
    border: 1px solid;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2f5fd;
    cursor: pointer;
    &:hover {
      transform: scale(1.25);
      background: var(--call-in-action) !important;
    }
  }
  .active {
    background: var(--call-in-action) !important;
  }
`;
