import Serializer from "./Serializer";
import { IModel } from "./Interfaces";

export class ContactInfo implements IModel {
  private data: object[];
  id: any;
  contactType: string = "";
  value: string = "";
  isAllowed: string = "";
  whatsappAllowed: boolean = false;
  createdAt: string = "";
  modifiedAt: string = "";
  fieldMap: object = {};
  isNew: boolean = false;
  error: boolean = false;

  constructor(attrs: any = {}) {
    this.fieldMap = {
      id: "id",
      contactType: "contact_type",
      value: "value",
      isAllowed: "is_allowed",
      whatsappAllowed: "whatsapp_allowed",
      createdAt: "created_at",
      modifiedAt: "modified_at",
      isNew: "is_new",
      error: "error",
    };
    const isArray = Array.isArray(attrs);
    this.data = [];

    if (isArray) {
      this.data = attrs;
    } else {
      Object.assign(this, Serializer.Serialize(attrs, this.fieldMap));
    }
  }

  getList(): Array<ContactInfo> {
    return this.data.map((c) => new ContactInfo(c));
  }

  deserialize(): object {
    const instance: any = { ...Serializer.Deserialize(this, this.fieldMap) };
    if (instance.is_new) {
      delete instance.id;
    }
    delete instance.error;
    delete instance.is_new;
    return instance;
  }
}
