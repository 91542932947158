export const GET_TASK = "GET_MOMENT";
export const SET_TASK = "SET_MOMENT";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const SET_CUSTOM_PERSON = "SET_CUSTOM_PERSON";
export const SET_QUESTIONS = "SET_QUESTIONS";
export const GET_QUESTIONS = "GET_QUESTIONS";
export const GET_CUSTOM_PERSON = "GET_CUSTOM_PERSON";
export const CLEAN_TASK = "CLEAN_TASK";

export const registerError = ({ err = null, key = "" }) => ({
  type: REGISTER_ERROR,
  payload: { err, key },
});

export const setTask = (data) => ({
  type: SET_TASK,
  payload: data,
});
export const cleanTask = () => ({
  type: CLEAN_TASK,
  payload: null,
});
export const getTask = () => ({
  type: GET_TASK,
  payload: null,
});

export const getCustomPerson = () => ({
  type: GET_CUSTOM_PERSON,
  payload: null,
});

export const setCustomPerson = (data) => ({
  type: SET_CUSTOM_PERSON,
  payload: data,
});

export const setTaskQuestions = (data) => ({
  type: SET_QUESTIONS,
  payload: data,
});

export const getTaskQuestions = () => ({
  type: GET_QUESTIONS,
  payload: null,
});
