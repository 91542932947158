import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RowItem, ColLargeScreen } from "components/Nimbiv2/styled";
import { ContainerEmpty } from "components/Nimbiv2";
import { applyMomentFilter } from "utils/moments";
import CardMoment from "components/Nimbiv2/CardMoment";
import MomentSVG from "assets/images/momentSVG";
const MomentList = ({ moments, pageSize }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const goMoment = (momentId) => {
    applyMomentFilter(dispatch, momentId, 10, false, true);
    history.push("/tareas");
  };
  if (moments && moments.length === 0) {
    return (
      <ContainerEmpty
        version="2"
        text={t("there_are_no_action_plans")}
        image={<MomentSVG />}
        description={
          <div
            style={{
              fontSize: "16px",
              color: "#5C5F62",
              textAlign: "center",
              marginTop: "5px",
            }}
          >
            Aquí se mostrarán los planes de acción cuando estén disponibles.{" "}
            <br />
            Activa un nuevo plan escribiendo a{" "}
            <a href="mailto:planesdeaccion@nimbi.cl">planesdeaccion@nimbi.cl</a>
          </div>
        }
      />
    );
  }

  return (
    <RowItem style={{ paddingBottom: "90px" }}>
      {moments &&
        moments.map((item, position) => {
          return (
            <ColLargeScreen
              key={`card-moment${item.id}`}
              lg="3"
              style={{ marginTop: "20px" }}
            >
              <CardMoment
                moment={item}
                onChange={goMoment}
                position={position + 1}
                isAnonymous={user?.anonymous}
              />
            </ColLargeScreen>
          );
        })}
    </RowItem>
  );
};

export default MomentList;
