import React from "react";
import styled from "styled-components";
import { NIcon } from "..";

const NInputSearch = ({
  onChange,
  placeholder = "Buscar plantilla",
  style,
  onClick,
  activeSearch = false,
  value = "",
  handleKeypress = (value) => {},
  widgetInput,
}) => {
  return (
    <InputDesign style={style}>
      {!widgetInput && (
        <input
          type="text"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          onKeyPress={handleKeypress}
        />
      )}
      {widgetInput && <>{widgetInput}</>}
      {!activeSearch && (
        <NIcon
          iconname="search"
          style={{
            height: "27px",
            marginLeft: "10px",
            color: "#334FFF",
            cursor: "pointer",
          }}
          onClick={onClick}
        />
      )}
      {activeSearch && (
        <NIcon
          iconname="close"
          style={{
            height: "27px",
            marginLeft: "10px",
            color: "#334FFF",
            cursor: "pointer",
          }}
          onClick={onClick}
        />
      )}
    </InputDesign>
  );
};
export default NInputSearch;
const InputDesign = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #cdd4de;
  border-radius: 8px;
  padding: 10px 12px;
  .form-control {
    border: 0px solid #ced4da !important;
    padding: 0px;
  }
  input {
    width: 100%;
    border: 0px;
  }
  input:focus {
    outline: none;
  }
  .rbt {
    width: 100%;
  }
`;
