import { ProSidebar, MenuItem, SidebarContent } from "react-pro-sidebar";
import styled from "styled-components";

export const ConProSidebar = styled(ProSidebar)`
  height: 100vh;
  color: white;
  border-radius-top-left: 10px;
  position: sticky;
  top: 0;
  background: #edf1f7;
  ${(props) => (props.collapsed ? "margin-right: 15px;" : "")}
  .pro-sidebar-inner {
    background: var(--brand-color);
    color: var(--color-surface);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .pro-icon-wrapper {
    background: transparent !important;
    margin-right: 8px !important;
    .pro-icon {
      font-size: 18px;
    }
  }
  .pro-sidebar-footer {
    border-top: 0px !important;
  }
  .pro-sidebar-inner > .pro-sidebar-layout {
    overflow-y: initial !important;
    overflow-x: initial !important;
  }
  .pro-sidebar-layout .pro-sidebar-header {
    border-bottom: none !important;
  }
`;
export const ConSidebarContent = styled(SidebarContent)`
  ${(props) => (props.collapsed ? "padding: 0px 16px;" : "padding: 0px 9px;")}
`;

export const NMenuItem = styled(MenuItem)`
  border-radius: 10px;
  color: white !important;
  &:hover {
    background: var(--brand-color-hover);
    color: white !important;
  }
  ${(props) =>
    props.active ? "background: var(--brand-color-secundary);" : ""}
  ${(props) => (props.active ? "font-weight: bold;" : "")}

.pro-inner-item {
    padding-top: 7px !important;
    padding-bottom: 6px !important;
    ${(props) =>
      props.collapsedx
        ? "padding-left: 7px !important; padding-right: 7px !important;"
        : "padding-left: 15px !important; padding-right: 15px !important;"}
  }
`;

export const ImageLogo = styled.img`
  height: 22px;
  margin-left: 5px;
`;
export const ContainerLogo = styled.div`
  display: flex;
  justify-content: ${(props) => (props.collapsed ? "center" : "flex-end ")};
  padding-bottom: 32px;
  color: #b5b5b5;
  ${(props) => (props.collapsed ? "" : "padding-right: 16px")}
`;

export const ButtonCircle = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: var(--brand-color-secundary);
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const ButtonCircleT = styled(ButtonCircle)`
  position: fixed;
  top: 24px;
  left: 65px;
  z-index: ${(props) => (props.show ? "1010" : "-1")};
  opacity: ${(props) => (props.show ? 1 : 0)};
  ${(props) => (props.show ? "transition: opacity 2s;" : 0)};
`;

export const ContainerHeader = styled.div`
  padding: ${(props) => (props.collapsed ? "24px 16px" : "24px 24px")};
  text-transform: "uppercase";
  font-weight: "bold";
  font-size: 14;
  letter-spacing: "1px";
  overflow: "hidden";
  text-overflow: "ellipsis";
  white-space: "nowrap";
`;

export const ButtonCloseSlide = styled.div`
  cursor: pointer;
  padding: 30px 8px 30px 13px;
  background: var(--brand-color);
  position: absolute;
  right: ${(props) => props.rightPosition ?? "-13px"};
  color: white;
  top: 19px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  &::before {
    transform: skew(42deg, 0deg);
    display: block;
    content: " ";
    position: absolute;
    top: -5px;
    right: 10px;
    height: 20px;
    width: 28px;
    background-color: var(--brand-color);
  }
  &::after {
    transform: skew(322deg, 0deg);
    display: block;
    content: " ";
    position: absolute;
    bottom: -5px;
    right: 10px;
    height: 20px;
    width: 28px;
    background-color: var(--brand-color);
  }
`;

export const ContainerSlide = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;
