import React from "react";
const PreviewCheckbox = ({ response }) => {
  return (
    <div>
      {response.map((item, key) => {
        return (
          <div key={`${key}-text-multi-select`}>
            {item.text}{" "}
            {item.multi_select && item.multi_select.length > 0 ? "-" : ""}{" "}
            {item.multi_select && item.multi_select.length > 0
              ? item.multi_select.map((item) => item.name).join(", ")
              : ""}
          </div>
        );
      })}
    </div>
  );
};
export default PreviewCheckbox;
