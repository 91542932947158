/* eslint-disable no-param-reassign */
import { getReports } from "services/report.service";

import * as ReportActions from "../report.actions";
import { handleErrorStatus } from "../../../services/errors";

const handleErrorResponse = (err) => {
  const status = err instanceof Map ? err.get("status") : 500;
  if (status > 0) return handleErrorStatus(status);
};

const fetchReports = () => {
  return (dispatch) => {
    dispatch(ReportActions.getReports());
    getReports()
      .then(({ data }) => dispatch(ReportActions.setReports(data)))
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(ReportActions.registerError({ err, key: "list" }));
      });
  };
};

export { fetchReports };
