import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import styles from "./Task.module.css";

const DashboardItem = () => {
  const { tasks, isActive } = useSelector((state) => state.dashboard);

  return (
    <div className={`px-3 ${styles.momentItem} ${styles.wborder}`}>
      <Link
        className={`text-center ${styles.momentTitle} ${isActive ? styles.selected : null}`}
        to="/dashboard"
        onClick={() => {}}
        aria-hidden="true"
      >
        Dashboard
        <div className={`text-center ${styles.momentSubTitle} ${styles.selected}`}>
          <span
            className="badge badge-secondary n-text-secondary"
            onClick={() => {}}
            aria-hidden="true"
          >
            {tasks.length}
          </span>
        </div>
      </Link>
    </div>
  );
};

export default React.memo(DashboardItem);
