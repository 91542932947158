import React from "react";
import { TaskComponents, UserComponents } from "containers";

const UsersPage = () => {
  return <MainPage />;
};

const MainPage = () => {
  return (
    <div>
      <TaskComponents.Toolbar>
        <UserComponents.FilterBar />
      </TaskComponents.Toolbar>

      <UserComponents.UserList />
      <UserComponents.PaginationBar />
    </div>
  );
};

export default UsersPage;
