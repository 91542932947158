/* eslint-disable react/require-default-props */
/* eslint-disable no-underscore-dangle */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Highlighter } from "react-bootstrap-typeahead";
import NInputFilter from "../../NInputFilter";
import { replaceChars, capitalizeWord } from "utils";
import ContainerInputFilter from "./ContainerInputFilter";
const NSelectObject = ({
  onChange,
  isMultiple = false,
  isDisabled = false,
  dataset = { values: [], subtype_component: null },
  initialValue = null,
  meta = {},
  canAutoFit = false,
  versionInput,
}) => {
  useEffect(() => {
    // if (isDisabled) setItemsSelected([]);
  }, [isDisabled]);

  const objectMenuItem = (option, { text }) => {
    return (
      <div>
        <div>
          <Highlighter search={text}>{option[dataset?.labelItems]}</Highlighter>
        </div>
      </div>
    );
  };

  const handleFilterChanged = (data) => {
    let selected = data;
    if (!isMultiple) selected = data.length > 0 ? data[0] : null;
    if (isMultiple && data.length === 0) selected = null;
    const x = dataset["filter_field"];
    onChange(x, selected, dataset);
  };

  return (
    <ContainerInputFilter
      label={capitalizeWord(replaceChars(dataset.label, "_", " "))}
      versionInput={versionInput}
    >
      <NInputFilter
        handleChange={handleFilterChanged}
        data={dataset.values}
        placeholder={capitalizeWord(replaceChars(dataset.label, "_", " "))}
        selected={
          isMultiple
            ? initialValue
              ? initialValue
              : []
            : initialValue
            ? [initialValue]
            : []
        }
        renderMenuItem={objectMenuItem}
        isMultiple={isMultiple}
        isDisabled={isDisabled}
        canAutoFit={canAutoFit}
        compositeLabel={dataset?.compositeLabel}
      />
    </ContainerInputFilter>
  );
};

NSelectObject.propTypes = {
  onChange: PropTypes.func.isRequired,
  isMultiple: PropTypes.bool,
  isDisabled: PropTypes.bool,
  canAutoFit: PropTypes.bool,
  dataset: PropTypes.shape({}),
  //initialValue: PropTypes.arrayOf(PropTypes.string),
  meta: PropTypes.shape({}),
};

export default NSelectObject;
