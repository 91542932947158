export const GET_ZENDESK = "GET_ZENDESK";
export const SET_ZENDESK = "SET_ZENDESK";

export const getZendesk = (queryParams) => ({
  type: GET_ZENDESK,
  payload: queryParams,
});
export const setZendesk = (data) => ({
  type: SET_ZENDESK,
  payload: data,
});
