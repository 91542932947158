import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
const NAuthor = ({ photo_url, full_name, role, showLabel = true }) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex align-items-center justify-content-end">
      {showLabel && (
        <strong style={{ fontSize: "16px", marginRight: "10px" }}>
          {_.upperFirst(t("author"))}
        </strong>
      )}
      <Avatar image={photo_url}></Avatar>
      <div style={{ marginLeft: "12px" }}>
        <div style={{ fontSize: "var(--font-size-lg)" }}>{full_name}</div>
        <div style={{ fontSize: "var(--font-size-md)", color: "#787c83" }}>
          {role}
        </div>
      </div>
    </div>
  );
};
export default NAuthor;

const Avatar = styled.div`
  border: 2.4px solid #3ac8ac;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  ${(props) => (props.image ? `  background: url(${props.image});` : "")}
  background-size:cover;
`;
