import React, { useCallback, useEffect, useMemo, useState } from "react";
import NTable from "components/Nimbiv2/NTable";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import NInput from "components/Nimbiv2/NInput";

const CustomAcademicProgress = ({
  key,
  dataset,
  isDisabled,
  onChange,
  keyPosition,
  lastKeyPosition,
  style,
}) => {
  const { t } = useTranslation();
  const {
    academicProgress,
    pending: { academicProgress: isPending },
    // academicStudentId,
  } = useSelector((state) => state.student);

  const [response, setResponse] = useState({});
  const [load, setLoad] = useState(0);
  const [selectOther, setSelectOther] = useState([]);

  // const statusMPE = {
  //   false: "redOriginal",
  //   true: "greenOriginal",
  // };

  useEffect(() => {
    if (load === 1) {
      onChange("", "", dataset, response);
    } else {
      setLoad(1);
    }
  }, [response]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeSelect = (item, value, comment) => {
    let data = {
      course_id: item.course_id,
      course_name: item.course_name,
      response: value,
      period: item.period,
    };
    if (value === "other") {
      data["comment"] = comment;
    }

    const new_response = { ...response };
    if (value === "") {
      if (`${item.course_id}-${item.period}` in new_response) {
        delete new_response[`${item.course_id}-${item.period}`];
      }
    } else {
      new_response[`${item.course_id}-${item.period}`] = data;
    }
    new_response["question_child"] =
      dataset.answers && dataset.answers.length > 0
        ? dataset.answers[0]["question_child"]
        : null;
    setResponse(new_response);
  };

  const addIdOther = useCallback(
    (item) => {
      let new_others = [...selectOther];
      new_others.push(item);
      setSelectOther(new_others);
    },
    [selectOther]
  );
  const removedIdOther = useCallback(
    (item) => {
      let new_others = [...selectOther];
      const index = new_others.indexOf(item);
      if (index > -1) {
        new_others.splice(index, 1);
        setSelectOther(new_others);
      }
    },
    [selectOther]
  );

  const columns = [
    {
      title: _.upperFirst(t("modules")),
      key: "course_name",
      dataIndex: "course_name",
      width: "200px",
      render: (item, key) => <div>{item}</div>,
    },
    {
      title: t("average_grades"),
      key: "academic_risks",
      center: true,
      contentCenter: true,
      width: "100px",
      render: (item, key) => {
        return <div>{item?.average_grade?.toString()}</div>;
      },
    },
    {
      title: _.upperFirst(t("mechanism")),
      key: "academic_risks",
      center: true,
      contentCenter: true,
      width: "150px",
      render: (item, key, position) => {
        let options = [];
        if (item.grades_extras["ayudantia"]) {
          options.push("ayudantia");
        }
        if (item.grades_extras["remedial"]) {
          options.push("remedial");
        }
        if (item.grades_extras["atencion_docente"]) {
          options.push("atención docente");
        }
        if (item.grades_extras["mpe"]) {
          options.push("mpe");
        }
        return (
          <>
            <Form.Control
              style={{ fontSize: "0.8rem" }}
              as="select"
              // value={optionSelected}
              onChange={(event) => {
                changeSelect(item, event.target.value);
                if (event.target.value === "other") {
                  addIdOther(position);
                } else {
                  removedIdOther(position);
                }
              }}
              disabled={!item.grades_extras["riesgo_rendimiento"]}
            >
              <option value="">{t("select_an_option")}</option>
              {options.map((opt) => (
                <option key={opt} value={opt}>
                  {_.upperFirst(opt)}
                </option>
              ))}
              <option key={"other"} value={"other"}>
                Otro
              </option>
            </Form.Control>
            {selectOther.includes(position) && (
              <div style={{ marginTop: "5px" }}>
                <NInput
                  minWidth="auto"
                  placeholder="Escribir comentario"
                  onChange={(data) => {
                    changeSelect(item, "other", data);
                  }}
                />
              </div>
            )}
          </>
        );
      },
    },
  ];

  const listAcademicProgress = useMemo(() => {
    return (
      academicProgress?.progress?.filter((item) => {
        return item.grades_extras["riesgo_rendimiento"];
      }) ?? []
    );
  }, [academicProgress]);
  return (
    <div className="mt-2" style={style}>
      <div>{dataset.text}</div>
      <div className="mt-2">
        <NTable
          columns={columns}
          dataSource={listAcademicProgress}
          isPending={isPending}
          // collapse={true}
          // positionOpenCollapse={selectOther}
          // widgetCollapse={(item) => {
          //   return (
          //     <NInput
          //       placeholder="Escribe un comentario"
          //       onChange={(data) => {
          //         changeSelect(item, "other", data);
          //       }}
          //     />
          //   );
          // }}
        ></NTable>
      </div>
    </div>
  );
};

export default CustomAcademicProgress;
