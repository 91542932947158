import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ContactInfo from "./ContactInfo";

const ContactList = ({ contacts, onUpdate }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(contacts);
  }, [contacts]);

  const updateContact = (newInfo) => {
    const auxData = [...data];
    const pos = auxData.findIndex((row) => row.id === newInfo.id);

    if (pos >= 0) {
      auxData[pos] = newInfo;
      setData(auxData);
      onUpdate(auxData);
    }
  };

  const removeContact = (contact) => {
    const contactsSet = new Set(contacts);
    contactsSet.delete(contact);
    setData(Array.from(contactsSet));
    onUpdate(Array.from(contactsSet));
  };

  if (data) {
    return (
      <>
        {data.map((contact) => (
          <div key={contact.id}>
            <ContactInfo contact={contact} onUpdate={updateContact} onDelete={removeContact} />
          </div>
        ))}
      </>
    );
  }

  return null;
};

ContactList.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default React.memo(ContactList);
