import React from "react";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NSpinnerPoints = ({ size, ...props }) => {
  return (
    <FontAwesomeIcon
      icon={faSpinner}
      className="fa-spin"
      style={{ fontSize: size }}
      {...props}
    />
  );
};

NSpinnerPoints.defaultProps = {
  size: "50px",
};

export default NSpinnerPoints;
