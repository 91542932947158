import api from "./api";

const getReports = () => {
  return api.get("reports/reports/?page_size=20");
};

const getTicket = () => {
  return api.get("reports/ticket/");
};

export { getReports, getTicket };
