export const boolValues = {
  1: "SI",
  0: "NO",
  True: "SI",
  False: "NO",
  undefined: "NO",
};

export const Genders = {
  F: "female",
  M: "male",
  O: "other",
};

export const Channels = {
  Call: "llamada",
  Email: "correo",
  Whatsapp: "whatsapp",
  whatsapp: "whatsapp",
  WhatsApp: "whatsapp",
};

export const ButtonSize = {
  lg: "large",
  md: "medium",
  sm: "small",
};

export const colorsBadge = {
  light: {
    inactive: {
      background: "#E8F0FC",
      fontColor: "#71819C",
    },
    success: {
      background: "#C1F2E9",
      fontColor: "#08997C",
    },
    information: {
      background: "#ECF2FF",
      fontColor: "#334FFF",
    },
    danger: {
      background: "#FFEBEB",
      fontColor: "#C54646",
    },
    warning: {
      background: "#FFF2DD",
      fontColor: "#B2840D",
    },
  },
  dark: {
    inactive: {
      background: "#9CABC2",
      fontColor: "#FFF",
    },
    success: {
      background: "#06B793",
      fontColor: "#FFF",
    },
    information: {
      background: "#334FFF",
      fontColor: "#FFF",
    },
    danger: {
      background: "#C54646",
      fontColor: "#FFF",
    },
    warning: {
      background: "#E1B256",
      fontColor: "#FFF",
    },
  },
};

export const colorBase = {
  inactive: "#9CABC2",
  success: "#06B793",
  information: "#334FFF",
  danger: "#C54646",
  warning: "#E1B256",
};

export const VERSIONS_FILTERS_DESIGN = {
  v1: "1",
  v2: "2",
};

export const colorProgress = {
  danger: "#C54646",
  warning: "#E1B256",
  success: "#3AC8AC",
};
