import api from "./api";
import { generateUrlWithParams } from "utils";
import { CancelToken } from "axios";
import logger from "./logService";

const tokens = {
  advancedSearch: null,
};
const fetchHelpItemTypes = () => {
  const path = "help-center/types/";
  return api.get(path);
};

const fetchHelpItems = async (queryParams) => {
  if (tokens.advancedSearch)
    tokens.advancedSearch.cancel("Operation canceled due to new request.");
  tokens.advancedSearch = CancelToken.source();
  const path = generateUrlWithParams("help-center/items/", queryParams);
  try {
    return api.get(path, {
      cancelToken: tokens.advancedSearch.token,
    });
  } catch (err) {
    logger.log(err);
    return err;
  }
};

export { fetchHelpItemTypes, fetchHelpItems };
