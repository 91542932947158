import React from "react";
import { Col } from "react-bootstrap";
import { CardReport, ContainerEmpty } from "components/Nimbiv2";

import { RowItem } from "components/Nimbiv2/styled";
import { useTranslation } from "react-i18next";
import EmpTyReport from "assets/images/EmptyReport";

const ReportList = ({ reports }) => {
  const { t } = useTranslation();
  if (reports.length === 0) {
    return (
      <ContainerEmpty
        version="2"
        text={t("there_are_no_reports")}
        image={<EmpTyReport />}
        description={
          <div style={{ marginTop: "5px" }}>
            Aquí se mostrarán los reportes del progreso tus estudiantes <br /> y
            gestiones cuando estén disponibles. <br /> Activa los reportes
            escribiendo a{" "}
            <a href="mailto:reportes@nimbi.cl">reportes@nimbi.cl</a>
          </div>
        }
      />
    );
  }
  return (
    <div style={{ width: "100%" }}>
      <RowItem>
        {reports.map((report) => (
          <Col key={report.id} className="mb-3" xs={12} lg={6}>
            <CardReport report={report} />
          </Col>
        ))}
      </RowItem>
    </div>
  );
};

export default React.memo(ReportList);
