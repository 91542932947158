import styles from "./NBadge.module.css";
export const colors = {
  red: {
    background: styles.redBG,
    fontColor: styles.lightFont,
  },
  redOriginal: {
    background: styles.redOriginalLightBG,
    fontColor: styles.redFont,
  },
  greenOriginal: {
    background: styles.greenOriginalLightBG,
    fontColor: styles.greenFont,
  },
  yellowOriginal: {
    background: styles.yellowBase,
    fontColor: styles.yellowFont,
  },
  gray: {
    background: styles.grayBG,
    fontColor: styles.lightFont,
  },
  orange: {
    background: styles.orangeBG,
    fontColor: styles.lightFont,
  },
  aqua: {
    background: styles.aquaBG,
    fontColor: styles.darkFont,
  },
  custom: {
    background: styles.customBG,
    fontColor: styles.lightFont,
  },
  greenBase: {
    background: styles.greenBase,
    fontColor: styles.lightFont,
  },
  invalidGrey: {
    background: styles.invalidGrey,
    fontColor: styles.textGrey,
  },
  default: {
    background: styles.lightBG,
    fontColor: styles.darkFont2,
  },
};
