import { getZendesk } from "services/integrations";
import { getSessionFilters } from "utils";
import * as IntegrationActions from "../integrations.actions";
import { handleErrorStatus } from "../../../services/errors";

const handleErrorResponse = (err) => {
  const status = err instanceof Map ? err.get("status") : 500;
  if (status > 0) return handleErrorStatus(status);
};

export const fetchZendesk = (studentId, initialParams = null) => {
  return (dispatch) => {
    if (studentId) {
      const queryParams = getSessionFilters("filter_zendesk");
      dispatch(IntegrationActions.getZendesk(queryParams));
      getZendesk(studentId, initialParams ?? queryParams)
        .then(({ data }) => {
          dispatch(IntegrationActions.setZendesk(data));
        })
        .catch((err) => {
          handleErrorResponse(err);
        });
    }
  };
};
