/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ClientFactory from "models/ClientFactory";
import styles from "./Student.module.css";
const FilterBar = () => {
  const Institution = new ClientFactory().client();
  const [tabSelect, setTabSelect] = useState(null);
  const {
    pagination: { count: totalRows },
  } = useSelector((state) => state.student);
  return (
    <div className={`${styles.toolbarBlock}`} style={{ paddingBottom: "0px" }}>
      <div>
        <Institution.StudentFilters
          onChangeSelected={(k) => {
            setTabSelect(k);
          }}
          totalRows={totalRows}
        />
      </div>
      <div
        style={
          tabSelect !== "filter" ? { marginTop: "0px" } : { marginTop: "10px" }
        }
      ></div>
    </div>
  );
};

export default React.memo(FilterBar);
