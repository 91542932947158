import { Col } from "react-bootstrap";
import styled from "styled-components";

const ColLargeScreen = styled(Col)`
  @media (min-width: 1900px) {
    max-width: 20% !important;
  }
  @media (min-width: 2000px) {
    max-width: 16% !important;
  }
  @media (min-width: 2500px) {
    max-width: 12% !important;
  }
  @media (min-width: 4000px) {
    max-width: 8% !important;
  }
  @media (min-width: 5000px) {
    max-width: 6% !important;
  }
`;

export default ColLargeScreen;
