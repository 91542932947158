import { create, isCancel } from "axios";
import { getDataFromLocalStorage } from "utils";
import { handleError, showErrorNotification } from "./errors";
import logger from "./logService";

const backendURL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_VERSION}`;

const api = create({
  baseURL: backendURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

api.defaults.timeout = 60000;
api.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");

    if (token) {
      token = JSON.parse(token);
      // eslint-disable-next-line no-param-reassign
      if (token) config.headers.common.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => Promise.resolve(response),
  async (error) => {
    const originalConfig = error.config;
    let expectedError = error.response ?? null;
    let mapErrorInfo = new Map([
      ["status", 0],
      ["title", ""],
      ["message", ""],
    ]);

    // the current request was cancelled!
    if (isCancel(error)) {
      expectedError = { data: null, status: 200, statusText: "OK" };
      return Promise.resolve(expectedError);
    }

    if (expectedError) mapErrorInfo = handleError(expectedError);
    const isUnhandledException =
      mapErrorInfo.get("status") === 0 || mapErrorInfo.get("status") >= 500;

    if (expectedError && !isUnhandledException) {
      let isLoging = false;
      const { responseURL } = error.response.request;
      const chunks = String(responseURL).split("/");
      if (chunks.length > 0) {
        isLoging = chunks.indexOf("token") > -1;
      }

      if (isLoging) mapErrorInfo.set("status", 400);

      // R E F R E S H    T O K E N
      const status = mapErrorInfo.get("status");

      if (status === 401 || status === 403) {
        const refreshToken = localStorage.getItem("refresh");

        if (!refreshToken) {
          localStorage.clear();
          return Promise.reject(mapErrorInfo);
        }

        const payload = { refresh: JSON.parse(refreshToken) };
        const response = await fetch(`${backendURL}/accounts/token/refresh/`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        });
        const { status: statusRefresh } = response;

        if (statusRefresh === 200) {
          const { access } = await response.json();
          localStorage.setItem("token", JSON.stringify(access));
          originalConfig.headers.Authorization = `Bearer ${access}`;

          // the token has been refreshed successfully...
          return api(originalConfig);
        }

        // the token couldn't be refreshed...
      }

      // track error in sentry...
      if (status === 0 || status >= 500) logger.error(error);

      return Promise.reject(mapErrorInfo);
    }

    // track error in sentry...
    logger.error(error);

    mapErrorInfo.set("status", 0); // overrides status from 500 into 0
    mapErrorInfo.set("message", "message" in error ? error.message : "");
    showErrorNotification(error);

    return Promise.reject(mapErrorInfo);
  }
);

api.interceptors.request.use((config) => {
  const user = getDataFromLocalStorage("user");
  config.headers["Accept-Language"] = user?.user_language
    ? user.user_language
    : "es";
  return config;
});

export default api;
