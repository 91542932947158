/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";
import { NBadge } from "components";
import { uuidv4 } from "utils";
import styles from "../Task.module.css";

const CourseCol = ({ children, enrollment, subtitleBadge = null }) => {
  const renderTagBadges = () => {
    if (enrollment.tracking_object.tags.length === 0) return null;
    return (
      <div className={`mr-1 mt-2 ${styles.rowText}`}>
        {enrollment.tracking_object.tags.map((element) => (
          <NBadge
            className="mr-2"
            key={uuidv4(4)}
            text={element.tag}
            kind="aqua"
          />
        ))}
      </div>
    );
  };

  return (
    <div
      className={`d-flex justify-content-left align-items-center ${styles.firstColumn} p-0`}
    >
      <div className="d-flex flex-column justify-content-left align-items-left">
        <div className={`mr-1 ${styles.headerTitle}`}>{children}</div>
        <div className={`mr-1 mt-2 ${styles.rowText}`}>{subtitleBadge}</div>
        {renderTagBadges()}
      </div>
    </div>
  );
};

CourseCol.propTypes = {
  enrollment: PropTypes.shape({}).isRequired,
  subtitleBadge: PropTypes.element,
};

export default React.memo(CourseCol);
