/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Highlighter } from "react-bootstrap-typeahead";
import { getText, setSessionFilters } from "utils";
import { searchCourses, getCoursesFromCampus } from "services/crmService";
import NInputFilter from "../NInputFilter";

const NSearchCourses = ({
  filterKey,
  fieldName,
  onChange,
  filterType,
  isMultiple,
  isDisabled,
  searchType,
  dataset,
  isAsync,
  campusSelected,
  defaultValue = false,
  placeholder = getText("placeholder_name_id_course"),
  showIcon = false,
}) => {
  const { _temp: filters } = useSelector((state) => state.site);
  const [itemsSelected, setItemsSelected] = useState([]);
  useEffect(() => {
    // setItemsSelected([]);
  }, [isDisabled]);

  useEffect(() => {
    if (campusSelected && campusSelected.length === 0) {
      setItemsSelected([]);
    } else {
      if (defaultValue) {
        setItemsSelected(campusSelected);
      }
    }
  }, [campusSelected, defaultValue]);

  const handleFilterChanged = (data) => {
    let selected = data;

    if (!isMultiple) {
      selected = data.length > 0 ? data[0] : {};
      const query = selected[fieldName];
      setSessionFilters(filterType, {
        page: 1,
        [filterKey]: query ?? "",
      });
    }

    setItemsSelected(data);
    onChange(selected);
  };

  const customMenuItem = (option, { text }) => (
    <>
      <Highlighter search={text}>{option.name}</Highlighter>
      <div>
        <small>{option.external_id.toLocaleString()}</small>
      </div>
    </>
  );

  const getRows = async (params, payload = {}) => {
    try {
      if (searchType === "campus_courses") {
        const {
          data: { results: matchedData },
        } = await getCoursesFromCampus({
          queryParams: filters.pagination,
          payload,
        });
        return Promise.resolve(matchedData);
      }

      const {
        data: { results: matchedData },
      } = await searchCourses({ queryParams: params });
      return Promise.resolve(matchedData);
    } catch (err) {
      return [];
    }
  };

  const asyncSearchCourses = (query) => {
    const orderFields = ["external_id", "name"];
    const isNumber = !Number.isNaN(Number(query));
    const params = {
      page_size: 10,
      search: query,
      ordering: isNumber ? orderFields.join() : orderFields.reverse().join(),
    };
    const payload = filters
      ? { course: query, campus: filters.campus }
      : { course: query };
    const matchedData = getRows(params, payload);
    return matchedData;
  };

  return (
    <NInputFilter
      handleChange={handleFilterChanged}
      data={dataset}
      compositeLabel={["external_id", "name"]}
      placeholder={placeholder}
      selected={itemsSelected}
      filterBy={["external_id", "name"]}
      renderMenuItem={customMenuItem}
      asyncSearch={asyncSearchCourses}
      isMultiple={isMultiple}
      isDisabled={isDisabled}
      isAsync={isAsync}
      showIcon={showIcon}
    />
  );
};

NSearchCourses.propTypes = {
  filterKey: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  filterType: PropTypes.oneOf(["filters", "stud_filters", "dash_filters"])
    .isRequired,
  onChange: PropTypes.func.isRequired,
  isMultiple: PropTypes.bool,
  isDisabled: PropTypes.bool,
  searchType: PropTypes.oneOf(["single_course", "campus_courses"]),
  dataset: PropTypes.arrayOf(PropTypes.object),
  isAsync: PropTypes.bool,
};

NSearchCourses.defaultProps = {
  isMultiple: false,
  isDisabled: false,
  searchType: "single_course",
  dataset: [],
  isAsync: false,
};
export default NSearchCourses;
