import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
const ExpirationDate = ({ item }) => {
  const { t } = useTranslation();
  const displayExpirationDate = (row) => {
    if (row.expiration_date) {
      const today = moment();
      const expiration = moment(row.expiration_date);
      const diff = expiration.diff(today, "day");
      if (moment(row.expiration_date).isSame(today, "day")) {
        return (
          <div>
            <div
              className="d-flex justify-content-center"
              style={{
                fontSize: "var(--font-size-md)",
                lineHeight: "120%",
                color: "#E23232",
                fontWeight: "bold",
              }}
            >
              HOY
            </div>
          </div>
        );
      }
      if (diff === 0) {
        return (
          <span className="d-flex justify-content-center"> &lt; 1 día</span>
        );
      }
      return <span className="d-flex justify-content-center">{diff} días</span>;
    }
    return (
      <div className="d-flex justify-content-center">
        {t("no_information_line")}
      </div>
    );
  };
  return displayExpirationDate(item);
};

export default ExpirationDate;
