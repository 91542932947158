import React, { useEffect, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Container, Dropdown, Tab, Col } from "react-bootstrap";

import { setActiveModule } from "store/actions/site.actions";
import { NNavbarDashboard, ModalConfirm } from "components/Nimbiv2";
import { NTabs, RowItem } from "components/Nimbiv2/styled";
import {
  fetchInitiative,
  fetchInitiativeInsights,
} from "store/actions/async/initiatives-async.actions";
import { updateInitiativeStatus } from "services/initiativeService";
import {
  PageInformation,
  PageStudentTracking,
  PageIndicatorEmail,
  PageIndicatorCall,
} from "containers/Initiatives/detail";
import PageIndicatorWhatsapp from "containers/Initiatives/detail/PageIndicatorWhatsapp";
import { useTranslationLocal } from "hooks";
import { BaseModal } from "components";
import FormInitiativeEndDate from "containers/Initiatives/FormInitiativeEndDate";

const InitiativesDetail = (props) => {
  const dispatch = useDispatch();
  const [tabSelect, setTabSelect] = useState("information");
  const { id } = useParams();
  const { translation } = useTranslationLocal();
  const { initiative } = useSelector((state) => state.initiative);
  const { t } = useTranslation();
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalDate, setModalDate] = useState(false);

  useEffect(() => {
    if (initiative) {
      dispatch(
        setActiveModule({
          title:
            initiative.name && initiative.name.length > 59
              ? initiative.name.slice(0, 55) + "..."
              : initiative.name,
          subtitle: null,
          breadcrumbles: [
            {
              title: _.upperFirst(t("initiatives")),
              url: "/initiatives",
            },
            {
              title: _.upperFirst(
                t("initiative_title_detail", {
                  text:
                    initiative.name && initiative.name.length > 59
                      ? initiative.name.slice(0, 55) + "..."
                      : initiative.name,
                })
              ),
              url: null,
            },
          ],
        })
      );
    }
  }, [initiative, dispatch, t]);
  useEffect(() => {
    dispatch(
      setActiveModule({
        title: "Nueva campaña",
        subtitle: null,
        breadcrumbles: [
          {
            title: "Campañas",
            url: "/initiatives",
          },
          {
            title: "Detalle campaña",
            url: null,
          },
        ],
      })
    );
    dispatch(fetchInitiative(id));
    dispatch(fetchInitiativeInsights(id));
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const options = {
    draft: [
      {
        text: "Programar Campaña",
        value: "programmed",
      },
      {
        text: "Cancelar Campaña",
        value: "canceled",
      },
    ],
    programmed: [
      {
        text: "Cancelar Campaña",
        value: "canceled",
      },
    ],
    active: [
      {
        text: t("update_end_date"),
        value: "update_final_date",
      },
    ],
    paused: [],
    completed: [],
    canceled: [],
  };

  const changeStatus = async (newStatus) => {
    const { status } = await updateInitiativeStatus(id, newStatus);
    if (status === 200) {
      dispatch(fetchInitiative(id));
      if (newStatus === "canceled") {
        toast.success("La campaña se ha cancelado exitosamente");
      } else {
        toast.success(`Cambio de estado de campaña exitoso!`);
      }
    } else {
      if (status === 403) {
        toast.error("Error sin permisos para realizar esta acción");
      } else {
        toast.error("Error al cambiar el estado de la campaña!.");
      }
    }
    setModalConfirm(false);
  };

  const updateStatus = async (newStatus) => {
    if (newStatus === "canceled") {
      setModalConfirm(true);
    } else {
      await changeStatus(newStatus);
    }
  };

  const cancel = () => {
    setModalConfirm(false);
  };

  return (
    <>
      <ModalConfirm
        title="¿Cancelar campaña?"
        detail="Si cancelas la campaña no podrás volver a activarla más tarde"
        isVisible={modalConfirm}
        onCancel={cancel}
        titleCancel="Volver"
        titleApproved={"Cancelar campaña"}
        colorApproved="danger"
        size="lg"
        onAccept={async () => {
          await changeStatus("canceled");
        }}
      />
      <BaseModal
        isVisible={modalDate}
        onClose={() => {
          setModalDate(false);
        }}
        title={t("update_end_date")}
      >
        <FormInitiativeEndDate
          id={id}
          onClose={() => {
            setModalDate(false);
          }}
        />
      </BaseModal>

      <NNavbarDashboard
        breadcrumbles={true}
        textwhite={1}
        activeCircle={true}
      />
      <Container fluid style={{ marginTop: "72px", paddingBottom: "40px" }}>
        <RowItem>
          <Col xs="8" style={{ paddingLeft: "0px" }}>
            <NTabs
              onSelect={(data) => {
                setTabSelect(data);
              }}
            >
              <Tab
                eventKey="information"
                title={_.upperFirst(t("information"))}
              ></Tab>
              <Tab
                eventKey="students"
                title={_.upperFirst(translation("students", "", t("students")))}
              ></Tab>
              {initiative && (
                <Tab
                  eventKey="indicators"
                  title={_.upperFirst(t("indicators"))}
                ></Tab>
              )}
              {/* <Tab eventKey="indicators" title="Indicadores"></Tab>
              <Tab eventKey="last_movements" title="Últimos movimientos"></Tab> */}
            </NTabs>
          </Col>
          <Col xs="1"></Col>
          <Col xs="3" style={{ paddingRight: "0px" }}>
            {initiative && options[initiative.status].length > 0 && (
              <DropdownDesign
                onSelect={(value) => {
                  if (value === "update_final_date") {
                    setModalDate(true);
                  } else {
                    updateStatus(value);
                  }
                }}
              >
                <Dropdown.Toggle variant="secundary" id="dropdown-basic">
                  {_.upperFirst(t("actions"))}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {initiative &&
                    options[initiative.status] &&
                    options[initiative.status].map((item, key) => {
                      return (
                        <Dropdown.Item
                          key={`action-${key}`}
                          eventKey={item.value}
                        >
                          {item.text}
                        </Dropdown.Item>
                      );
                    })}
                </Dropdown.Menu>
              </DropdownDesign>
            )}
          </Col>
        </RowItem>
        {tabSelect === "information" && <PageInformation />}
        {tabSelect === "students" && (
          <div style={{ paddingBottom: "45px" }}>
            <PageStudentTracking />
          </div>
        )}
        {initiative &&
          tabSelect === "indicators" &&
          initiative.initiative_type.name === "Email" && <PageIndicatorEmail />}
        {initiative &&
          tabSelect === "indicators" &&
          initiative.initiative_type.name === "Call" && <PageIndicatorCall />}
        {initiative &&
          tabSelect === "indicators" &&
          initiative.initiative_type.name === "WhatsApp" && (
            <PageIndicatorWhatsapp />
          )}
      </Container>
    </>
  );
};
export default InitiativesDetail;

const DropdownDesign = styled(Dropdown)`
  button {
    background-color: #edf1f7 !important;
    color: #787c83;
    border-color: #787c83;
    width: 100%;
    height: 49px;
    text-align: inherit;
  }
  .btn:focus,
  .btn.focus {
    outline: none !important;
    box-shadow: none !important;
  }
`;
