import _ from "lodash";
import React, { useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Spinner } from "components";
import { NInput, NButton } from "components/Nimbiv2";
import { createTeam, updateTeam } from "services/team.service";
import { fetchTeams } from "store/actions/async/team-async.actions";

const FormTeam = ({ team, onClose = () => {} }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isPending, setPending] = useState(false);

  const saveTeam = async (values) => {
    setPending(true);
    if (team.id) {
      const { status } = await updateTeam(team.id, values);

      if (status === 404) {
        toast.error(t("team_not_found"));
      } else if (status === 200) {
        dispatch(fetchTeams());
        onClose();
        toast.success(t("success_update_team"));
      } else {
        toast.error(t("error_update"));
        setPending(false);
      }
    } else {
      const { status } = await createTeam(values);
      if (status === 201) {
        dispatch(fetchTeams());
        onClose();
        toast.success(t("success_save_team"));
      } else {
        toast.error(t("error_save_team"));
        setPending(false);
      }
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "El nombre es requerido";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: team,
    onSubmit: saveTeam,
    validate,
  });

  return (
    <div>
      {team && (
        <form onSubmit={formik.handleSubmit}>
          <NInput
            name="name"
            label="Nombre de equipo"
            defaultValue={formik.values?.name}
            onChangeEvent={formik.handleChange}
            errorText={formik.errors["name"]}
          />
          <div style={{ marginTop: "20px" }}>
            <NButton
              type="submit"
              className={`float-right `}
              disabled={isPending}
              style={{ height: "100%" }}
            >
              {isPending ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                _.upperFirst(t("save"))
              )}
            </NButton>
          </div>
        </form>
      )}
    </div>
  );
};
export default FormTeam;
