import React from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col } from "react-bootstrap";
import { permissionRedirect } from "routes/helpers";
import styled from "styled-components";
import { backgrounbdErrorInternal, bgErrorInternal } from "assets/images";
import { NButton } from "components/Nimbiv2";
import { useTranslation } from "react-i18next";

const InternalServerError = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth, _.isEqual);
  const goDashboard = () => {
    history.replace({
      pathname: permissionRedirect({
        userRoles: user ? user.groups : [],
      }),
    });
  };

  return (
    <div>
      <div style={{ position: "absolute", right: "0" }}>
        <img
          src={backgrounbdErrorInternal}
          style={{ maxHeight: "100vh" }}
          alt="background internal error"
        />
      </div>
      <div
        style={{
          position: "absolute",
          right: "0",
          bottom: "0",
        }}
      >
        <img
          src={bgErrorInternal}
          style={{ maxHeight: "85vh" }}
          alt="plug internal error"
        />
      </div>
      <ContainerText
        style={{ height: "100vh" }}
        className="d-flex justify-content-center align-items-center"
      >
        <div className="row" style={{ width: "100%" }}>
          <Col lg="6">
            <div
              style={{
                color: "#334FFF",
                fontSize: "120px",
                fontWeight: "bold",
                height: "120px",
                display: "flex",
                alignItems: "center",
              }}
            >
              500
            </div>
            <div
              style={{ fontSize: "32px", marginTop: "0px", fontWeight: "bold" }}
            >
              {t("internal_error")}
            </div>
            <div style={{ fontSize: "20px", marginTop: "15px" }}>
              {t("description_internal_error")}
            </div>
            <div style={{ marginTop: "50px" }}>
              <NButton padding="9px 50px" border="8px" onClick={goDashboard}>
                {t("return_start")}
              </NButton>
            </div>
          </Col>
        </div>
      </ContainerText>
    </div>
  );
};

export default InternalServerError;

const ContainerText = styled.div`
  padding-left: calc(100% / 10);
`;
