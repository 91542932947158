import React from "react";
import styled from "styled-components";

const AnonymousContainer = ({ isAnonymous, radius, children }) => {
  if (!isAnonymous) {
    return <>{children}</>;
  }
  return <BlurSpan radius={radius}>{children}</BlurSpan>;
};

const BlurSpan = styled.span`
  -webkit-filter: blur(${(props) => props.radius});
  filter: blur(${(props) => props.radius});
`;

AnonymousContainer.defaultProps = {
  radius: "3px",
  isAnonymous: false,
};

export default AnonymousContainer;
